export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const formatWithZero = (value: number): string => {
  return value < 10 ? `0${value}` : `${value}`;
};

export default function DateUtil(dateValue?: Date) {
  const _date = dateValue || new Date();

  const _getTimezoneOffset = () => {
    const offset = _date.getTimezoneOffset();

    const result = Math.abs(offset) / 60;
    return offset < 0 ? result : -result;
  };

  const getMilliseconds = (): number => _date.getMilliseconds();

  const getSeconds = (): string => formatWithZero(_date.getSeconds());

  const getMinutes = (): string => formatWithZero(_date.getMinutes());

  const getHours = (): string => formatWithZero(_date.getHours());

  const getDate = (): string => formatWithZero(_date.getDate());

  const getMonth = (): string => formatWithZero(_date.getMonth() + 1);

  const getFullYear = (): number => _date.getFullYear();

  /**
   * @returns Formatted HH:MM:SS
   */
  const getLocalTimeStr = (): string => `${getHours()}:${getMinutes()}:${getSeconds()}`;

  /**
   * @returns Formatted YYYY-MM-DD
   */
  const getLocalDateStr = (): string => `${getFullYear()}-${getMonth()}-${getDate()}`;

  /**
   * @returns Formatted YYYY-MM-DD HH:MM:SS
   */
  const getLocalTimestampStr = (): string => `${getLocalDateStr()} ${getLocalTimeStr()}`;

  /**
   * @returns Timezone offset from -12 to 12
   */
  const getLocalTimezone = (): number => _getTimezoneOffset();

  /**
   * @returns Formatted Timezone offset: +05:00 or -08:00
   */
  const getLocalTimezoneStr = (): string => {
    const offset = _date.getTimezoneOffset();
    const sign = offset < 0 ? '+' : '-';
    const offsetAbs = Math.abs(offset);

    const hour = formatWithZero(offsetAbs / 60 || 0);
    const mins = formatWithZero(offsetAbs % 60);
    return `${sign}${hour}:${mins}`;
  };

  const getUTCDateStr = (): string => {
    const day = _date.getUTCDate();
    const month = _date.getUTCMonth();
    const year = _date.getUTCFullYear();

    return `${formatWithZero(year)}-${formatWithZero(month + 1)}-${formatWithZero(day)}`;
  };

  const getUTCTimeStr = (): string => {
    const hours = _date.getUTCHours();
    const minutes = _date.getUTCMinutes();
    const seconds = _date.getUTCSeconds();

    return `${formatWithZero(hours)}:${formatWithZero(minutes)}:${formatWithZero(seconds)}`;
  };

  const getUTCTimestampStr = (): string => {
    return `${getUTCDateStr()} ${getUTCTimeStr()}`;
  };

  return {
    getSeconds,
    getMinutes,
    getHours,
    getMilliseconds,
    getDate,
    getMonth,
    getFullYear,
    getLocalTimeStr,
    getLocalDateStr,
    getLocalTimestampStr,
    getLocalTimezone,
    getLocalTimezoneStr,
    getUTCDateStr,
    getUTCTimeStr,
    getUTCTimestampStr,
  };
}

export function getFormattedDate(date: Date) {
  let d: string | number = date.getDate();
  const year = date.getFullYear();
  const month = MONTHS[date.getMonth()];

  if (d === 1) {
    d = `${d}st`;
  } else if (d === 2) {
    d = `${d}nd`;
  } else if (d === 3) {
    d = `${d}rd`;
  } else {
    d = `${d}th`;
  }

  return `${month} ${d} ${year}`;
}
