import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../reducers';
import { PomodoroPhases } from './constants';
import { usePomodoroDisplayValues } from './useTimerDisplayValue';

export function usePomodoroPhase() {
  const sessionTimeMode = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionPlayType,
  );
  const pomodoroPhase = usePomodoroDisplayValues().phase;
  return useMemo(
    () =>
      pomodoroPhase === PomodoroPhases.Inactive || sessionTimeMode !== 'NORMAL'
        ? null
        : pomodoroPhase,
    [pomodoroPhase],
  );
}
