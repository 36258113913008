import { useToken } from '@Authentication';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { authSliceActions } from '../reducers/authentication';

export function useGetTokenFromCookie() {
  const [isCheckingToken, setCheckingToken] = useState(true);

  const dispatch = useDispatch();
  const token = useToken();

  useEffect(() => {
    const onPageLoad = () => {
      const cookies = document.cookie.split(';');

      const tokenFromCookie = cookies.find(elem => elem.trim().startsWith('token='));

      if (tokenFromCookie) {
        const splitToken = tokenFromCookie.split('=');

        if (splitToken[1]) {
          dispatch(authSliceActions.setToken(splitToken[1]));
        }
      }

      setCheckingToken(false);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => document.removeEventListener('load', onPageLoad);
    }
  }, [dispatch]);

  return { isCheckingToken, token };
}
