import styled from 'styled-components';

import { FontColors } from '@Cortex';

import PlayerSelectedIconSVG from '.././../assets/images/player_selected_icon.svg?react';
import PlayerUnselectedIconSVG from '.././../assets/images/player_unselected_icon.svg?react';
import LibrarySelectedIconSVG from '.././../assets/images/library_selected_icon.svg?react';
import LibraryUnselectedIconSVG from '.././../assets/images/library_unselected_icon.svg?react';
import ExploreSelectedIconSVG from '.././../assets/images/explore_selected_icon.svg?react';
import ExploreUnselectedIconSVG from '.././../assets/images/explore_unselected_icon.svg?react';
import ProfileSelectedIconSVG from '.././../assets/images/profile_selected_icon.svg?react';
import ProfileUnselectedIconSVG from '.././../assets/images/profile_unselected_icon.svg?react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: space-around;
  position: sticky;
  bottom: 0;
  z-index: 10;
  padding: 20px;
  background-color: rgba(13, 12, 17, 1);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;

const BaseIcon = styled.svg<{ active: boolean }>`
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  transition: opacity 0.15s ease-in-out;
`;

export const TabButton = styled.div<{ active: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  letter-spacing: 1px;
  font-family: ${({ theme }) => theme.font.family.default.bold};
  color: ${({ active }) => (active ? `${FontColors.White}` : `${FontColors.WhiteTransparent}`)};
  gap: 8px;
  transition: color 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${FontColors.White};
    ${BaseIcon} {
      opacity: 1;
    }
  }
`;

// export const PlayerIcon = styled(BaseIcon).attrs({ as: PlayerIconSVG })``;

export const PlayerIcon = styled(BaseIcon).attrs(({ active }) => ({
  as: active ? PlayerSelectedIconSVG : PlayerUnselectedIconSVG,
}))``;

export const LibraryIcon = styled(BaseIcon).attrs(({ active }) => ({
  as: active ? LibrarySelectedIconSVG : LibraryUnselectedIconSVG,
}))``;

export const ExploreIcon = styled(BaseIcon).attrs(({ active }) => ({
  as: active ? ExploreSelectedIconSVG : ExploreUnselectedIconSVG,
}))``;

export const ProfileIcon = styled(BaseIcon).attrs(({ active }) => ({
  as: active ? ProfileSelectedIconSVG : ProfileUnselectedIconSVG,
}))``;
