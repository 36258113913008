import { TransitionStatus } from 'react-transition-group';
import styled, { keyframes, css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const MenuContainer = styled.div<{ animationState: TransitionStatus }>`
  position: absolute;
  z-index: 999;
  right: 2rem;
  bottom: 8.5rem;
  padding: 1rem 0;
  transition:
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;

  transform: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return `translateY(0.5rem)`;
      case 'entered':
        return `translateY(0)`;
      case 'exiting':
        return `translateY(0)`;
      case 'exited':
        return `translateY(0.5rem)`;
    }
  }};

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0;
      case 'entered':
        return 1;
      case 'exiting':
        return 0.5;
      case 'exited':
        return 0;
    }
  }};
`;

export const ContentWrapper = styled.div`
  max-height: calc(100vh - 290px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  min-width: 380px;
  padding-bottom: 1.5rem;

  box-shadow: 0 -8px 24px 0 rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(34px);
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  padding: 1.5rem;
`;
export const ContentSection = styled.div`
  padding: 0 1.5rem;
`;

export const BadgesSection = styled.div`
  padding: 0 1.5rem;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const Badge = styled.div`
  background: linear-gradient(360deg, rgba(13, 12, 17, 0.5) 0%, rgba(13, 12, 17, 0.1) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 180.5px;
  height: 172px;
  padding: 24px 12px 24px 12px;
  border-radius: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const bounceAnimation = keyframes`
  0% {
    transform: scale(0.8);
  }
  20% {
    transform: scale(1.6);
  }
  40% {
    transform: scale(0.5);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`;

export const BadgeImage = styled.img<{ isAnimated?: boolean }>`
  width: 50%;
  height: auto;
  ${({ isAnimated }) =>
    isAnimated &&
    css`
      animation: ${bounceAnimation} 0.8s ease-in-out;
    `};
`;

export const BadgeTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  color: rgba(255, 255, 255, 0.75);
  font-size: 0.75rem;
  margin-top: 10px;
`;

export const BadgeDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.75rem;
  margin-top: 5px;
`;

export const HeaderText = styled.div`
  display: flex;
  gap: 0.4rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  align-items: center;
`;
export const DescriptionText = styled.div`
  color: rgba(255, 255, 255, 0.75);
  font-size: 0.8rem;
  text-align: center;
  margin-top: 1.5rem;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
`;

export const Share = styled.div`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.8rem;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
`;

export const ButtonText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.8rem;
  text-align: center;
`;

export const ButtonContent = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

export const StreaksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Separator = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  margin: 1.5rem 0;
`;

export const VerticalSeparator = styled.div`
  border-left: 1px solid rgba(255, 255, 255, 0.2);
`;
