import styled, { css } from 'styled-components';

type Props = {
  backgroundColor?: string;
  borderRadius: string;
  isScrollable: boolean;
  isBlurred: boolean;
  padding: string;
};

const SharedStyles = css<Props>`
  border-radius: ${props => props.borderRadius};
  padding: ${props => props.padding};
  position: relative;
  width: 100%;

  ${props => (props.isScrollable ? 'overflow-x: hidden' : 'overflow: hidden')};

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const PanelOnStyles = css<Props>`
  background-color: rgba(255, 255, 255, 0.1);

  ${props =>
    props.isBlurred &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);
        z-index: -1;
      }
    `}
`;

export const Panel = styled.div<Props>`
  ${SharedStyles}
  ${PanelOnStyles}
`;
