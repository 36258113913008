import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import SupportIcon from '../assets/support_icon.svg';
import { ProfileItemActionType, ProfileItemType } from '../Profile.types';

export enum CompanyMenuItems {
  ContactSupport = 'Contact Support',
}

export const PROFILE_ROUTE = `/user`;

export function useCompanyItems(): ProfileItemType[] {
  const email = useSelector((state: RootReducerType) => state.user.info?.email) || '';

  return [
    {
      type: ProfileItemActionType.Linkout,
      urlPath: null,
      label: CompanyMenuItems.ContactSupport,
      href: `mailto:support@brain.fm?subject=Support%20request%20for%20my.brain.fm&body=Account%3A%20${email}`,
      testid: 'contactSupport',
      iconSrc: SupportIcon,
    },
  ].filter(Boolean);
}
