import styled from 'styled-components';
import { JUMP_BACK_IN_HEIGHT } from './constants';
import { TransitionStatus } from 'react-transition-group';
import { TextSemiBold } from '@Cortex';
import LoadingIcon from '../../../../components/icons/LoadingIcon';

export const Container = styled.div<{ isOpen: boolean; isMobileView: boolean }>`
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 99;
  isolation: isolate;
  top: 0;

  backdrop-filter: ${({ isOpen }) => (isOpen ? 'blur(24px)' : 'blur(0px)')};
  -webkit-backdrop-filter: ${({ isOpen }) => (isOpen ? 'blur(24px)' : 'blur(0px)')};
  transform: translateY(
    ${({ isOpen }) => (isOpen ? `calc(-100vh + ${JUMP_BACK_IN_HEIGHT} )` : '0px')}
  );

  transition:
    transform 0.5s,
    backdrop-filter 0.5s;

  will-change: transform;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    height: 15vh;
    ${({ isOpen, isMobileView }) =>
      isOpen &&
      isMobileView &&
      `
        height: 100vh;
        position: fixed;
        transform: none;
    `};
  }
`;

export const ButtonContainer = styled.div<{ isOpen: boolean }>`
  margin-top: ${({ isOpen }) => (isOpen ? `8rem` : '0')};
  margin-bottom: 4rem;
  position: relative;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    margin-top: ${({ isOpen }) => (isOpen ? `2rem` : '0')};
  }
`;

export const TransitionWrapper = styled.div<{ animationState: TransitionStatus }>`
  position: relative;
  transition: opacity 0.2s;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0;
      case 'entered':
        return 1;
      case 'exiting':
        return 0;
      case 'exited':
        return 0;
    }
  }};
`;
export const ContentWrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: 500px;
`;

export const TitleWrapper = styled.div`
  height: 2rem;
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  opacity: 0.5;
`;

export const Title = styled(TextSemiBold)`
  margin-right: 5px;
`;

export const LoadingIconAnimating = styled(LoadingIcon)`
  width: 1rem;
  height: 1rem;
`;

export const RecentSessionsContainer = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const RecentSessionWrapper = styled.div<{ isRight: boolean }>`
  width: 50%;
  padding-right: ${({ isRight }) => (isRight ? '6px' : '0px')};
  padding-left: ${({ isRight }) => (isRight ? '0px' : '6px')};
  padding-bottom: 8px;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
`;
