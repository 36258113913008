import { CloseButton } from '@Cortex';
import { ToastOptions, Zoom, toast } from 'react-toastify';
import { DEFAULT_OFFLINE_ERROR_MESSAGE } from '../../constants';
import { GLOBAL_TOAST } from '@Globals';

interface ShowToastParams {
  message: string;
  options?: ToastOptions;
  containerId?: string;
}

export function showToast({ message, options = {}, containerId }: ShowToastParams) {
  // Only apply toastID if message is offline message to prevent duplicate offline toast
  const toastId = message === DEFAULT_OFFLINE_ERROR_MESSAGE ? 'offline-toast-id' : undefined;
  toast(message, {
    toastId: toastId,
    containerId: containerId ?? GLOBAL_TOAST, // Defaults to global toast in index
    hideProgressBar: true,
    position: 'bottom-right',
    transition: Zoom,
    className: 'custom-toast',
    closeButton: ({ closeToast }) => <CloseButton hasHoverEffect onClick={closeToast} />,
    ...options,
  });
}
