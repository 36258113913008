import { ForgotPasswordFormValues } from '@Authentication';
import { Form } from '@Cortex';
import { FormikConfig } from 'formik';

import * as Yup from 'yup';

import * as S from './ForgotPasswordScreen.styles';
import { Assets } from '../../../../utils/assets';

type FormInput<Values> = {
  label: string;
  name: Extract<keyof Values, string>;
  placeholder: string;
  type: string;
};

export type Props<Values> = {
  formError: string | null;
  formInitialValues: Values;
  formInputs: FormInput<Values>[];
  formValidationSchema: Yup.ObjectSchema<ForgotPasswordFormValues>;
  isLoading: boolean;
  onClickSignIn: () => void;
  onClickSignInAnalyticsEvent: string;
  onFormChange?: () => void;
  onFormSubmit: FormikConfig<Values>['onSubmit'];
  onFormSubmitAnalyticsEvent: string;
};

export function ForgotPasswordScreenDisplay<Values extends { [key: string]: string }>(
  props: Props<Values>,
) {
  return (
    <S.ForgotPasswordForm>
      <S.TopAuraImage src={Assets.images.authAuraSmall.url} />
      <S.Header>
        <S.IconContainer>
          <S.IconImage alt={Assets.images.logoWithText.alt} src={Assets.images.logoWithText.url} />
        </S.IconContainer>
        <S.TextContainer>
          <S.SigninText>Ready to sign in?</S.SigninText>
          <S.SigninButton
            data-analytics-event={props.onClickSignInAnalyticsEvent}
            data-testid="sign-in"
            onClick={props.onClickSignIn}
          >
            SIGN IN
          </S.SigninButton>
        </S.TextContainer>
      </S.Header>

      <S.FormWrapper>
        <S.FormBlurView>
          <S.LargeText data-testid="forgot-password-title">Forgot Your Password?</S.LargeText>
          <S.Form>
            <Form
              formError={props.formError}
              formInitialValues={props.formInitialValues}
              formInputs={props.formInputs}
              formValidationSchema={props.formValidationSchema}
              isLoading={props.isLoading}
              submitButtonText="EMAIL ME"
              onFormChange={props.onFormChange}
              onFormSubmit={props.onFormSubmit}
              onFormSubmitAnalyticsEvent={props.onFormSubmitAnalyticsEvent}
            />
          </S.Form>
        </S.FormBlurView>
      </S.FormWrapper>
      <S.BottomAuraImage src={Assets.images.authAuraBig.url} />
    </S.ForgotPasswordForm>
  );
}
