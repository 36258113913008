import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { usePost } from '../../client/client';
import { Endpoints } from '../../common/endpoints';
import { TVerifyEmailResponse, TVerifyEmailRequestBody } from './types';

export const useRequestHandler = () => {
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);
  const post = usePost<TVerifyEmailRequestBody, TVerifyEmailResponse>();

  return useCallback(
    async (code: string) => {
      return await post({
        path: Endpoints.verifyEmail(userId!),
        body: {
          code,
        },
        options: { apiVersion: 3 },
      });
    },
    [post],
  );
};
