import { Serving, TagTypes, Track } from '@Model';

import { activities } from '../../../utils/activities';
import { isServing } from './isServing';

export function getTrackActivityId(trackOrServing?: Track | Serving | null): string {
  if (isServing(trackOrServing)) {
    const activityNames = trackOrServing.track.tags
      .filter(tag => tag.type === TagTypes.Activity)
      .map(tag => tag.value);

    return (
      Object.values(activities.byId).find(x => activityNames.some(y => x.display === y))?._id || ''
    );
  }

  return trackOrServing?.activityId || '';
}
