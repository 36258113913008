import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import {
  MembershipPromotion,
  MembershipPromotionTypes,
  MembershipState,
  RedemptionCodeMembershipPromotion,
} from '../reducer';

export function getAvailableRedemptionCodePromotion(
  state: MembershipState,
): MembershipPromotion | null {
  return state.promotion;
}

export function useGetAvailableRedemptionCodePromotion(): RedemptionCodeMembershipPromotion | null {
  const membershipState = useSelector((state: RootReducerType) => state.membership);
  const availablePromotion = getAvailableRedemptionCodePromotion(membershipState);

  if (availablePromotion && availablePromotion.type !== MembershipPromotionTypes.RedemptionCode) {
    return null;
  }

  return availablePromotion;
}
