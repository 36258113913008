import styled from 'styled-components';
import TimerIconSVG from '../assets/timer.svg?react';
import IntervalIconSVG from '../assets/interval.svg?react';
import InfiniteIconSVG from '../assets/infinite.svg?react';
import { FontColors } from '@Cortex';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  isolation: isolate;
  display: flex;
  padding: 1rem;
  justify-content: center;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.6);
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
export const Section = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const Information = styled.div`
  gap: 4px;
`;

export const DescriptionText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${FontColors.WhiteTransparent};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
`;

const BaseIcon = styled.svg`
  height: 24px;
  width: 24px;
  fill: white;
  flex-shrink: 0;
`;

export const InfiniteIcon = styled(BaseIcon).attrs({ as: InfiniteIconSVG })``;

export const TimerIcon = styled(BaseIcon).attrs({ as: TimerIconSVG })``;

export const IntervalIcon = styled(BaseIcon).attrs({ as: IntervalIconSVG })``;
