import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 2.625rem;
`;

export const Item = styled.button<{ isActive: boolean }>`
  flex: 1;
  border: none;
  background: none;
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${({ isActive }) => (isActive ? '2px' : '1px')};
    border-radius: 4px;
    background-color: ${({ isActive }) => (isActive ? 'white' : 'gray')};
    transition: background-color 0.3s ease;
  }
`;

export const Pill = styled.div`
  position: absolute;
  z-index: 1;
  background: white;
  border-radius: 4.75rem;
  height: 100%;
  transition:
    transform 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
`;
