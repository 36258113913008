import React, { useState } from 'react';
import * as S from './InputField.styles';
import EyeIcon from '../../../../../assets/images/eye.svg';
import EyeSlashIcon from '../../../../../assets/images/eye_slash.svg';

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant: 'default' | 'first' | 'last' | 'individual';
  error?: string;
}

export const InputField = ({ variant, error, type, style, ...inputProps }: InputFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <S.InputContainer style={style} variant={variant}>
      <S.InputField
        {...inputProps}
        style={{ backgroundPosition: error ? '97%' : undefined }}
        type={type === 'password' && showPassword ? 'text' : type}
      />

      {error ? <S.ErrorText>{error}</S.ErrorText> : null}
      <S.PasswordInputEyeIcon
        src={showPassword ? EyeSlashIcon : EyeIcon}
        style={{ right: error ? '3.5rem' : '1.5rem' }}
        onClick={handleShowPassword}
      />
    </S.InputContainer>
  );
};
