export const CLOSE_TIMEOUT_MS = 500;

export const baseStyles = {
  content: {
    height: '100%',
    width: '100%',
    inset: 'unset',
    border: 'unset',
    backgroundColor: 'unset',
    padding: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  overlay: {
    backgroundColor: 'unset',
  },
};
