import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 1rem;
  position: relative;
  max-width: 464px;
  width: 100%;
`;

export const Form = styled.form`
  margin-bottom: 1rem;
`;

export const InputSpacer = styled.div`
  margin-bottom: 1px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormHeader = styled.h1`
  color: #ffffff;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-family: ${({ theme }) => theme.font.family.default.medium};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 2.25rem;
  letter-spacing: -0.32px;
  margin-bottom: 8px;
`;

export const FormErrorTextContainer = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  height: 24px;
`;

export const FormErrorText = styled.span`
  color: red;
  font-size: 12px;
  font-family: ${({ theme }) => theme.font.family.default.regular};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  text-align: center;
`;

export const Text = styled.span`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.family.default.regular};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin-bottom: 2.5rem;
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  bottom: -50px;
  z-index: 1;
  pointer-events: none;
`;

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ToggleErrorContainer = css`
  opacity: 1;
  visibility: visible;
`;

export const ErrorContainer = styled.div<{ error: boolean }>`
  width: 100%;
  background-color: red;
  padding: 1rem;
  border-radius: 0.5rem;
  opacity: 0;
  visibility: none;
  transition:
    opacity 0.2s ease-in,
    transform 0.2s ease-in;
  display: flex;
  position: relative;

  ${({ error }) => error && ToggleErrorContainer}
`;

export const ErrorText = styled.span`
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const ErrorCloseButton = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  background-color: rgba(255, 255, 255, 0.6);
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  transition:
    transform 0.4s ease-in-out,
    background-color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

export const ErrorCloseImage = styled.img`
  margin: auto;
  width: 1.25rem;
  height: 1.25rem;
`;
