import { EffectLevels, Serving, Track } from '@Model';

import { getNelDisplayValue } from '../lib/getNeuralEffectLevelDisplayValue';
import { isServing } from './isServing';

export function getTrackBrightness(
  trackOrServing?: Track | Serving | null,
): EffectLevels | undefined {
  if (isServing(trackOrServing)) {
    return getNelDisplayValue(trackOrServing.track.brightnessLevel || 0.5);
  }

  return undefined;
}
