import { TransitionStatus } from 'react-transition-group';
import { Assets } from '../../../../utils/assets';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 32px 24px 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const HeadingContainer = styled.div`
  margin-bottom: 0.5rem;
  text-transform: capitalize;
`;

export const TagHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  animation: fadeInDown 0.3s ease-in-out;

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
`;

export const GenreContainer = styled.div<{ slide: boolean }>`
  width: 200%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  transform: ${({ slide }) => (slide ? 'translateX(-50%)' : 'translateX(0)')};
  transition: transform 0.3s ease-in-out;
`;

export const Page = styled.div<{ fadeIn: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease-in;
  opacity: ${({ fadeIn }) => (fadeIn ? 1 : 0)};
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.25rem;

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const TopItemContainer = styled.div`
  margin-bottom: 1.25rem;
`;

export const TabBarContainer = styled.div`
  margin-bottom: 1.25rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  padding-right: 0.25rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    flex-direction: column;
  }
`;

export const FilterWrapper = styled.div`
  margin-right: 0.75rem;
  margin-bottom: 0.5rem;
  width: 100%;

  &:last-child {
    margin-right: 0;
  }
`;

export const FadeInWrapper = styled.div<{ animationState: TransitionStatus }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  animation: fadeInDown 0.3s ease-in-out;
  position: relative;
  visibility: ${({ animationState }) => (animationState == 'entered' ? 'visible' : 'hidden')};

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const BackIcon = styled.div`
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

export const FakeIcon = styled.div`
  opacity: 0;
`;
