import { Stripe } from '@stripe/stripe-js';
import { Dispatch } from 'react';
import * as analyticsActions from '../../../../../actions/analytics';
import * as userActions from '../../../../../actions/user';
import { post } from '../../../../../api/client/client';
import { PaymentTransactionState, EnhancedMembershipPlanType } from '../../../../../types';
import { ERROR_DISPLAY_VALUES } from '../constants';
import { confirmCardPayment } from './confirmCardPayment';
import { getCreateSubscriptionPath } from './getCreateSubscriptionPath';
import { getStripeCustomerId } from './getStripeCustomerId';

type Props = {
  email?: string;
  paymentMethodId: string;
  plan?: EnhancedMembershipPlanType;
  stripe: Stripe | null;
  token: string | null;
  userId?: string;
  onError: (msg: string | null) => void;
  utmSource?: string;
  couponId?: string;
  subscribeScreenFrom?: string;
  dispatch: Dispatch<any>;
};

export const createSubscription = async (props: Props) => {
  const {
    email,
    paymentMethodId,
    plan,
    stripe,
    token,
    userId,
    utmSource,
    couponId,
    subscribeScreenFrom,
    dispatch,
    onError,
  } = props;

  const customerId = await getStripeCustomerId({ token, email });

  if (!plan || !customerId) return null;

  const { result, status, messages } = await post<
    {
      couponId?: string;
      customerId: string;
      paymentMethodId: string;
      planId: number;
      promotionCode?: string;
      userId?: string;
      utmSource?: string;
    },
    { status: number; result: PaymentTransactionState; messages?: string[] }
  >({
    path: getCreateSubscriptionPath(plan),
    body: {
      couponId: plan.couponId,
      customerId,
      paymentMethodId,
      planId: plan.id,
      promotionCode: plan.promotionCode,
      userId,
      utmSource,
    },
    token,
  });

  if (status !== 200) {
    const errorMessage = ERROR_DISPLAY_VALUES[status] || messages?.[0] || 'Something went wrong';

    onError(errorMessage);
    throw new Error(errorMessage);
  }

  const subscription = result as PaymentTransactionState;

  if (subscription.status === 'requires_action') {
    await confirmCardPayment({
      paymentMethodId,
      planId: plan.id,
      customerId,
      transaction: subscription,
      stripe,
      token,
      userId,
      onError,
    });
  }

  dispatch(
    analyticsActions.logEventWithProperties({
      event: 'subscription_purchase_success',
      props: {
        coupon_id: couponId,
        plan_type: plan.title,
        selected_plan: plan,
        subscribe_screen_from: subscribeScreenFrom,
      },
    }),
  );
  dispatch(userActions.getInfo());
};
