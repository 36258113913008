import { useEffect, useState } from 'react';

export const useIsWindowFocused = () => {
  const [isWindowFocused, setWindowFocused] = useState(true);

  useEffect(() => {
    const handleWindowFocusChange = () => {
      if (document.visibilityState === 'hidden') {
        setWindowFocused(false);
      } else {
        setWindowFocused(true);
      }
    };

    window.addEventListener('visibilitychange', handleWindowFocusChange);

    return () => window.removeEventListener('visibilitychange', handleWindowFocusChange);
  }, []);

  return isWindowFocused;
};
