import styled from 'styled-components';
import { TextSemiBold } from '@Cortex';

export const Container = styled.button<{ isOpen: boolean }>`
  background: none;
  height: 48px;
  padding: 0 1rem 0 2rem;
  border-radius: 3rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition:
    background 0.3s,
    transform 0.6s ease-in-out 0.2s;
  cursor: pointer;
  transform: translateY(${({ isOpen }) => (isOpen ? '2rem' : '0px')});

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

export const IconContainer = styled.div<{ isOpen: boolean }>`
  margin-left: 1rem;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 0.5s;
`;

export const ButtonText = styled(TextSemiBold)`
  letter-spacing: 1px;
  font-size: 0.875rem;
`;
