import { FontColors } from '@Cortex';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Loading = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:after {
    animation: rotation 0.8s linear infinite;
    border-radius: 100%;
    border-right: 3px solid transparent;
    border-top: 3px solid rgba(255, 255, 255, 0.8);
    content: '';
    height: 2rem;
    position: absolute;
    width: 2rem;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const Content = styled.div<{ maxWidth?: string }>`
  max-width: ${props => props.maxWidth || '65rem'};
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 1;
`;

export const ImageColumn = styled.div`
  position: relative;
  width: 85%;
  flex-grow: 1;
  min-height: 220px;
`;

export const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    width: 130%;
  }
`;

export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  align-self: center;
  text-align: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 100%;
  }
`;

export const Subtitle = styled.div`
  color: #d4d2ea;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  letter-spacing: 0.2em;
  margin-bottom: 0.75rem;
  white-space: pre-line;
`;

export const Title = styled.div`
  color: ${FontColors.White};
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 2rem;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 1rem;
  white-space: pre-line;
`;

export const Body = styled.div`
  color: #d4d2ea;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  margin-bottom: 0.75rem;
  line-height: 1.5rem;
  white-space: pre-line;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 15px;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    bottom: auto;
    justify-content: center;
    position: relative;
    right: auto;
    width: 100%;
  }
`;

export const SkipButton = styled.div`
  display: flex;
  margin-right: 1rem;
`;
