import { NOOP } from '@Globals';
import { cancelStripeSubscription } from '@Payment';
import { useRef, useState } from 'react';
import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import { Analytics } from '../../../../utils/analytics';
import { CoreAnalyticsEventTypes } from '../../../Analytics/coreAnalytics.types';
import { acceptStripeSubscriptionDiscount } from '../../../Payment/actions';

import * as S from './CancelMembership.styles';
import { Cancellation } from './components/Cancellation';
import { BUDGET_REASON } from './components/Cancellation/constants';
import { Confirmation } from './components/Confirmation';
import { DiscountOffer } from './components/DiscountOffer';
import { DiscountOfferDetails } from './components/DiscountOfferDetails';
import { useIsEligibleForDiscount } from './hooks/useIsEligibleForDiscount';

type CancellationOption =
  | 'confirmation'
  | 'cancellation'
  | 'discountOffer'
  | 'discountOfferDetails';

interface Props {
  onClose?: () => void;
}

export const CancelMembership = forwardRef<HTMLDivElement, Props>(({ onClose = NOOP }, ref) => {
  const confirmationRef = useRef<HTMLDivElement>(null);
  const cancellationRef = useRef<HTMLDivElement>(null);
  const discountOfferRef = useRef<HTMLDivElement>(null);
  const discountOfferDetailsRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [state, setState] = useState<CancellationOption>('confirmation');
  const [selectedReason, setSelectedReason] = useState<string>('');
  const isEligibleForDiscount = useIsEligibleForDiscount();

  return (
    <S.Container ref={ref}>
      <Transition
        in={state === 'confirmation'}
        mountOnEnter
        nodeRef={confirmationRef}
        timeout={0}
        unmountOnExit
      >
        {state => {
          return (
            <Confirmation
              ref={confirmationRef}
              animationState={state}
              onClose={onClose}
              onContinue={() => setState('cancellation')}
            />
          );
        }}
      </Transition>

      <Transition
        in={state === 'cancellation'}
        mountOnEnter
        nodeRef={cancellationRef}
        timeout={0}
        unmountOnExit
      >
        {state => {
          return (
            <Cancellation
              ref={cancellationRef}
              animationState={state}
              onClose={onClose}
              onContinue={reason => {
                setSelectedReason(reason);
                if (isEligibleForDiscount) {
                  setState('discountOffer');
                } else {
                  dispatch(cancelStripeSubscription({ reason }));
                  onClose();
                }
              }}
            />
          );
        }}
      </Transition>

      <Transition
        in={state === 'discountOffer'}
        mountOnEnter
        nodeRef={cancellationRef}
        timeout={0}
        unmountOnExit
      >
        {state => {
          return (
            <DiscountOffer
              ref={discountOfferRef}
              animationState={state}
              onBack={() => setState('cancellation')}
              onCancelSubscription={() => {
                dispatch(cancelStripeSubscription({ reason: selectedReason }));
                onClose();
              }}
              onClose={onClose}
              onContinue={() => {
                Analytics.logEventWithProperties(
                  CoreAnalyticsEventTypes.CancellationDiscountViewOffer,
                  {},
                );
                setState('discountOfferDetails');
              }}
            />
          );
        }}
      </Transition>

      <Transition
        in={state === 'discountOfferDetails'}
        mountOnEnter
        nodeRef={cancellationRef}
        timeout={0}
        unmountOnExit
      >
        {state => {
          return (
            <DiscountOfferDetails
              ref={discountOfferDetailsRef}
              animationState={state}
              onBack={() => setState('cancellation')}
              onCancelSubscription={() => {
                dispatch(cancelStripeSubscription({ reason: selectedReason }));
                onClose();
              }}
              onClose={onClose}
              onContinue={() => {
                dispatch(acceptStripeSubscriptionDiscount());
                onClose();
              }}
            />
          );
        }}
      </Transition>
    </S.Container>
  );
});
