import { UserMentalStatePreferenceTypes, useUser } from '@User';
import { FeatureFlags, useFeatureFlag } from '@Utils';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { logEventWithProperties } from '../../../../../actions/analytics';
import { usePlayerAnalyticsParams } from '../../../../../hooks/usePlayerAnalyticsParams';
import { RootReducerType } from '../../../../../reducers';
import { MentalStates } from '../../../../../types';
import { TimerEvents } from '../../../../../utils/analytics/events';
import { trackTimerStartEvent } from '../../../../Analytics/coreAnalytics';
import { TimerOrigin } from '../../../../Analytics/coreAnalytics.types';
import { useDynamicSession } from '../../../lenses/useDynamicSession';

export enum TimerMode {
  Infinite = 'infinite',
  Interval = 'interval',
  Custom = 'custom',
  Quotes = 'quotes',
}

type Props = {
  timerMode: TimerMode;
  props?: {
    pomodoroIntervals?: {
      focusTime: string;
      breakTime: string;
      soundType: string;
    };
  };
  customTimerLength?: number;
};

export const useTrackTimerModeChange = () => {
  const dispatch = useDispatch();
  const user = useUser();
  const session = useDynamicSession();

  const currentPreferences = session
    ? user.mentalStatePreferences[session.mentalState.id as MentalStates.Focus]
    : null;

  const activityDisplayValue = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.displayValue,
  );
  const mentalStateDisplayValue = useSelector(
    (state: RootReducerType) =>
      state.sessionManager.sessionDynamicActivity?.mentalState.displayValue,
  );

  const {
    activity,
    trackGenre,
    filterGenre,
    filterNel,
    sessionModalContentOrigin,
    trackNel,
    mentalState,
  } = usePlayerAnalyticsParams();

  return useCallback(
    ({ timerMode, props, customTimerLength }: Props) => {
      trackTimerStartEvent({
        activity,
        trackGenre,
        filterGenre,
        mentalState,
        filterNel,
        trackNel,
        origin: sessionModalContentOrigin as TimerOrigin,
        timerLength:
          timerMode === TimerMode.Custom ? ((customTimerLength || 0) / 60).toString() : '',
        timerMode: timerMode,
        timerIntervalSoundType: props?.pomodoroIntervals?.soundType,
      });

      dispatch(
        logEventWithProperties({
          event: TimerEvents.PlayerTimerStart,
          props: {
            timer_mode: timerMode,
            interval_focus_length: props?.pomodoroIntervals?.focusTime || '',
            interval_break_length: props?.pomodoroIntervals?.breakTime || '',
            interval_sound_type: props?.pomodoroIntervals?.soundType || '',
            mentalState: mentalStateDisplayValue,
            activity: activityDisplayValue,
            genre: currentPreferences
              ? currentPreferences[UserMentalStatePreferenceTypes.Genres].join(',')
              : '',
            neuralEffectLevel: currentPreferences
              ? currentPreferences[UserMentalStatePreferenceTypes.NeuralEffectLevels].join(',')
              : '',
          } as any,
        }),
      );
    },
    [dispatch, session, user, sessionModalContentOrigin],
  );
};
