import { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 15rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
`;

const Text = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.family.default.medium};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin: 0 0.5rem;
`;

type AuthButtonProps = Pick<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'> & {
  label: string;
  analyticsEvent: string;
};

export const AuthButton: React.FC<AuthButtonProps> = ({ src, alt, label, analyticsEvent }) => {
  return (
    <Container data-analytics-event={analyticsEvent}>
      <Image alt={alt} src={src} />
      <Text>{label}</Text>
    </Container>
  );
};
