import { Serving } from '@Model';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { useRequestHandler } from './useRequestHandler';

export const useDynamicMentalStateMoods = () => {
  const mentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );
  const dynamicMentalStateId = useSelector(
    (state: RootReducerType) => state.ui.sideDeck.exploreSelectedMentalState?.id,
  );

  const getMoodsByDynamicMentalState = useRequestHandler();

  return useQuery<string[], Error>(
    Queries.getMoodsByDynamicMentalState(dynamicMentalStateId || mentalStateId!),
    () => getMoodsByDynamicMentalState(dynamicMentalStateId || mentalStateId!),
    {
      onError: () => {
        Logger.error(new Error('Error getting Moods'));
      },
      enabled: Boolean(mentalStateId) || Boolean(dynamicMentalStateId),
    },
  );
};
