import { EnhancedMembershipPlanType } from '../../../../../../../types';
import { getHasDiscount } from './getHasDiscount';

export const getDiscountPercentage = (selectedPlan: EnhancedMembershipPlanType | null) => {
  const hasDiscount = getHasDiscount(selectedPlan);
  if (!hasDiscount) return 0;
  return selectedPlan?.originalCost && selectedPlan?.displayCost
    ? Math.round(
        (1 -
          (parseFloat(selectedPlan?.displayCost) || 0) /
            (parseFloat(selectedPlan?.originalCost) || 1)) *
          100,
      )
    : 0;
};
