import { useField } from 'formik';
import { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { Assets } from '../../utils/assets';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
`;

const InputField = styled.input`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-family: ${({ theme }) => theme.font.family.default.medium};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  padding: 1.5rem 0.5rem 0.75rem 0.5rem;
  outline: none;
  border: none;
  background: ${({ theme }) => theme.colors.brandPrimaryDark};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    box-shadow: 0 0 0 300px ${({ theme }) => theme.colors.brandPrimary} inset;
    animation-name: autofill;
    animation-fill-mode: both;
    border-radius: 0.25rem;
    font-size: ${({ theme }) => theme.fontSize.medium};
    -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.8;
  }
`;

const LabelTransition = css`
  transform: translateY(-0.5rem);
  opacity: 0;
  visibility: hidden;
`;

const InputLabel = styled.label<{ hide: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.extraSmall};
  font-family: ${({ theme }) => theme.font.family.default.medium};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  opacity: 0.8;
  text-transform: uppercase;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  transition: all 0.2s ease-in-out;

  ${({ hide }) => hide && LabelTransition}
`;

const IconTransition = css`
  transform: translateX(0.5rem), translateY(50%);
  opacity: 0;
`;

const IconWrapper = styled.div<{ hide: boolean }>`
  position: absolute;
  right: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;

  ${({ hide }) => hide && IconTransition}
`;

const Image = styled.img`
  width: 1.6rem;
  height: 1.6rem;
`;

const Border = styled.div`
  width: 100%;
  height: 0.25rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
}

export const FormInput: React.FC<InputProps> = ({ name, label, ...rest }) => {
  const [field, meta] = useField(name);

  return (
    <Container>
      <InputLabel hide={field.value.length === 0} htmlFor={name}>
        {label}
      </InputLabel>
      <InputField id={name} {...rest} />
      <IconWrapper hide={!(meta.touched && meta.error)}>
        <Image alt={Assets.icons.warning.alt} src={Assets.icons.warning.url} />
      </IconWrapper>
      <Border />
    </Container>
  );
};
