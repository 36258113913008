import { Serving, Track } from '@Model';

import { ExploreEvents } from '../../../../../../utils/analytics/events';
import * as S from './TracksByTag.styles';
import {
  getTrackGenreName,
  getTrackHasMultipleNELs,
  getTrackId,
  getTrackImageUrl,
  getTrackIsNewlyCreated,
  getTrackName,
  getTrackNeuralEffectLevel,
  TrackInformationCard,
} from '@Music';
import { TagsPlayAllCard } from '../TagsPlayAllCard';
import { getTrackRelatedActivities } from '../../../../lenses/getTrackRelatedActivities';

interface Props {
  genreName: string;
  onPlayAllClick: () => void;
  shouldRenderPlayAllButton: boolean;
  tracks: ((Track | Serving) & {
    isFavorited: boolean;
    onAddToFavorites: () => void;
    onClickPlay: () => void;
    onClickMore: () => void;
    onRemoveFromFavorites: () => void;
  })[];
}

export const TracksByMoodDisplay = (props: Props) => (
  <S.Container>
    {props.shouldRenderPlayAllButton ? (
      <S.PlayAllContainer>
        <TagsPlayAllCard genreType={props.genreName} onClick={props.onPlayAllClick} />
      </S.PlayAllContainer>
    ) : null}

    {props.tracks?.map(track => (
      <S.TrackCardWrapper key={getTrackId(track)}>
        <TrackInformationCard
          hasMultipleNELs={getTrackHasMultipleNELs(track)}
          imageUrl={getTrackImageUrl(track)}
          isFavorited={track.isFavorited}
          isNewlyCreated={getTrackIsNewlyCreated(track)}
          moreIconTestId={`trackCardMoreInfo__${getTrackId(track)}`}
          neuralEffectLevel={getTrackNeuralEffectLevel(track)}
          relatedActivities={getTrackRelatedActivities(track)}
          subtitle={getTrackGenreName(track)}
          title={getTrackName(track)}
          onAddToFavorites={track.onAddToFavorites}
          onClick={track.onClickPlay}
          onClickMore={track.onClickMore}
          onRemoveFromFavorites={track.onRemoveFromFavorites}
        />
      </S.TrackCardWrapper>
    ))}
  </S.Container>
);
