import { FontColors, TextSemiBold } from '@Cortex';

import * as S from './MentalStateSelector.styles';
import { DynamicMentalState } from '@Model';

interface Props {
  mentalStates?: DynamicMentalState[];
  selectedMentalState?: DynamicMentalState | null;
  onSelect: (value: any) => void;
}

export const MentalStateSelectorDisplay = ({
  mentalStates,
  selectedMentalState,
  onSelect,
}: Props) => {
  if (!mentalStates) {
    return <></>;
  }

  return (
    <S.Container>
      {mentalStates?.map(mentalState => (
        <S.Item
          key={mentalState.id}
          data-testid={mentalState.id}
          isActive={mentalState.id === selectedMentalState?.id}
          type="button"
          onClick={() => {
            onSelect(mentalState);
          }}
        >
          <TextSemiBold
            color={
              mentalState.id === selectedMentalState?.id
                ? FontColors.Black
                : FontColors.WhiteTransparent
            }
            size="0.875rem"
            style={{ textTransform: 'capitalize' }}
          >
            {mentalState.displayValue}
          </TextSemiBold>
        </S.Item>
      ))}
    </S.Container>
  );
};
