import { useCallback, useEffect, useState } from 'react';
import { DesktopAnnouncementBannerDisplay } from './DesktopAnnouncementBanner.display';
import { DESKTOP_ANNOUNCEMENT_BANNER_LOCAL_STORAGE_KEY } from './DesktopAnnouncementBanner.constants';
import {
  trackDesktopAnnouncementBannerExperimentExposure,
  useIsDesktopAnnouncementBannerExperimentEnabled,
} from '../../../Utils/useAmplitudeExperiments';
import { Analytics } from '../../../../utils/analytics';

export function DesktopAnnouncementBannerContainer() {
  const [isDismissed, setIsDismissed] = useState(
    localStorage.getItem(DESKTOP_ANNOUNCEMENT_BANNER_LOCAL_STORAGE_KEY) === 'true',
  );
  const isDesktopAnnouncementBannerExperimentEnabled =
    useIsDesktopAnnouncementBannerExperimentEnabled();
  const isVisible = !isDismissed && isDesktopAnnouncementBannerExperimentEnabled;

  useEffect(() => {
    if (isVisible) {
      trackDesktopAnnouncementBannerExperimentExposure();
    }
  }, [isVisible]);

  const handleClickClose = useCallback(() => {
    setIsDismissed(true);
    localStorage.setItem(DESKTOP_ANNOUNCEMENT_BANNER_LOCAL_STORAGE_KEY, 'true');
    Analytics.logEvent('test_desktop_announcement_banner_dismiss');
  }, [setIsDismissed]);

  const handleClickDownload = useCallback(() => {
    setIsDismissed(true);
    localStorage.setItem(DESKTOP_ANNOUNCEMENT_BANNER_LOCAL_STORAGE_KEY, 'true');
    Analytics.logEvent('test_desktop_announcement_banner_click_download');
  }, [handleClickClose]);

  return isVisible ? (
    <DesktopAnnouncementBannerDisplay
      onClickClose={handleClickClose}
      onClickDownload={handleClickDownload}
    />
  ) : null;
}
