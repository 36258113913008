import { membershipActions } from '@Memberships';
import { takeEvery, select, put, take, all } from 'redux-saga/effects';

import * as appActions from '../actions/app';
import * as membershipPlansActions from '../actions/membershipPlans';
import * as userActions from '../actions/user';
import { RootReducerType } from '../reducers';
import { appSliceActions } from '../reducers/app';
import { membershipPlansSliceActions } from '../reducers/membershipPlans';
import { Analytics } from '../utils/analytics';
import { Logger } from '../utils/logger';
import { teamsActions, teamsSliceActions } from '@Teams';

function* appInit() {
  yield put(appSliceActions.initializeApp());
  try {
    const { token }: RootReducerType['auth'] = yield select((state: RootReducerType) => state.auth);
    if (token) {
      yield put(userActions.getInfo());
      yield put(membershipPlansActions.getMembershipPlans());
      yield put(membershipActions.fetchMembership());
      yield put(teamsActions.getTeams());
      Analytics.setUserProperties({ current_platform: 'web' });
      yield all([
        take([
          membershipPlansActions.membershipPlansInitiallyFetched.type,
          membershipPlansSliceActions.getPlansFailed.type,
        ]),
        take([
          membershipActions.membershipInitiallyFetched.type,
          membershipActions.receiveMembershipError.type,
        ]),
        take([
          teamsSliceActions.teamsInitiallyFetched.type,
          teamsSliceActions.teamsInitialFetchError.type,
        ]),
      ]);
    }
  } catch (error) {
    Logger.error(error);
  }

  yield put(appSliceActions.initializeAppComplete());
}

export default function* watchAppSaga() {
  yield takeEvery(appActions.init.type, appInit);
}
