import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.span`
  color: rgba(255, 255, 255, 0.75);
  font-size: 1rem;
  font-style: normal;
  line-height: 1.5rem;
  font-family: ${({ theme }) => theme.font.family.default.regular};
  font-weight: ${({ theme }) => theme.font.weight.regular};
`;

export const Button = styled.button`
  display: flex;
  padding: 0.75rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  outline: none;
  background: none;
  color: #ffffff;
  font-size: 0.825rem;
  font-family: ${({ theme }) => theme.font.family.default.medium};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 120%;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-left: 1rem;
  cursor: pointer;
`;
