import styled from 'styled-components';

import { FontColors } from '@Cortex';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  isolation: isolate;
  display: flex;
  padding: 1rem;
  justify-content: center;
  display: flex;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

export const ModalWrapper = styled.div`
  display: flex;
  align-self: center;
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  background-position-x: center;
  background-position-y: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1.5rem;
`;

export const Image = styled.img`
  position: absolute;
  top: -7.5rem;
  width: 20rem;
  height: 20rem;
  align-self: center;
  z-index: 2;
`;

export const AuraImage = styled.img`
  position: absolute;
  border-radius: 10px;
  top: 0rem;
  width: 100%;
  height: 14rem;
  align-self: center;
  z-index: 0;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  z-index: 1;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 1.25rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1rem;
`;

export const Description = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
  color: ${FontColors.WhiteTransparent};
  text-align: center;
`;

export const DescriptionBold = styled.div`
  font-weight: 500;
  color: ${FontColors.White};
  margin-left: 0.3rem;
  margin-right: 0.3rem;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 999;
`;

export const CloseButton = styled.img`
  cursor: pointer;
`;
export const ShareText = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 1rem;
  text-align: center;
  color: #ffffff;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;

export const Icon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.8rem;
  text-align: center;
`;

export const ButtonContent = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;
