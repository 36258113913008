import { MentalStates } from '../../../../types';

export const WelcomeTexts: { [key in MentalStates]: string } = {
  [MentalStates.Meditate]:
    'Begin your meditation session and allow approximately 5 minutes for the music to enhance your focus and deepen your meditation.',
  [MentalStates.Sleep]:
    'Prepare for bed as you normally would, and allow the music about 5 minutes to help you drift into a peaceful sleep.',
  [MentalStates.Relax]:
    'Settle in comfortably and give yourself about 5 minutes to fully ease into the relaxing effects of the music.',
  [MentalStates.Focus]:
    'Just start working normally and allow 5 minutes for the music to take full effect.',
  [MentalStates.Workout]:
    "Welcome to Workout Beta! Just do your workout routine as normal while listening. We'd love to hear feedback on your experience!",
};
