import { DEFAULT_FADE_DURATION } from '../../../components/audioPlayer/audioPlayerDynamic';
import { PomodoroPhases, TimerDisplayTypes, TimerStatuses } from '../../../domains/Timer/constants';
import { RootReducerType } from '../../../reducers';

const POMODORO_BREAK_ENDING_TIME_IN_MILLISECONDS = 1000 * 2;
const POMODORO_BREAK_FADE_OUT_PHASE_TIME_IN_MILLISECONDS =
  POMODORO_BREAK_ENDING_TIME_IN_MILLISECONDS + DEFAULT_FADE_DURATION;
export const POMODORO_WORK_FADE_OUT_PHASE_TIME_IN_MILLISECONDS = DEFAULT_FADE_DURATION;

const padZero = (num: number) => (num < 10 ? `0${num}` : num);

/**
 * Formats time to show in timer
 * @param timeLeft number in seconds
 * @returns formatted digital clock time.
 */
export const formatTime = (timeLeft: number) => {
  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = Math.floor(timeLeft % 60);

  const secondsFormatted = padZero(seconds);

  if (hours) {
    const minutesFormatted = padZero(minutes);
    return `${hours}:${minutesFormatted}:${secondsFormatted}`;
  }
  return `${minutes}:${secondsFormatted}`;
};

function addLeftPaddedZeroes(number: number): string {
  if (number >= 10) return number.toString();
  return `0${number}`;
}

export function getDisplayValueFromMilliseconds(milliseconds: number): string {
  const elapsedSeconds = milliseconds / 1000;

  const hours = Math.floor(elapsedSeconds / 3600);
  const minutes = Math.floor((elapsedSeconds % 3600) / 60);
  const seconds = Math.floor(elapsedSeconds % 60);
  const secondsDisplayValue = addLeftPaddedZeroes(seconds);

  if (hours === 0) return `${minutes}:${secondsDisplayValue}`;
  return `${hours}:${addLeftPaddedZeroes(minutes)}:${secondsDisplayValue}`;
}

export function calculatePomodoroDisplayValues(timerState: RootReducerType['timer']) {
  const { focusTime, breakTime } = timerState.pomodoroSettings.intervals;

  const focusTimeInMilliseconds = 1000 * 60 * +focusTime;
  const breakTimeInMilliseconds = 1000 * 60 * +breakTime;
  const cycleTimeInMilliseconds = focusTimeInMilliseconds + breakTimeInMilliseconds;

  const elapsedTimeInMilliseconds =
    timerState.currentTime - (timerState.startTime ?? 0) - (timerState.timeSpentPaused ?? 0);
  const elapsedTimeInCurrentCycleInMilliseconds =
    elapsedTimeInMilliseconds % cycleTimeInMilliseconds;
  const remainingTimeInCurrentCycleInMilliseconds =
    cycleTimeInMilliseconds - elapsedTimeInCurrentCycleInMilliseconds;

  if (
    timerState.displayType !== TimerDisplayTypes.Pomodoro ||
    timerState.status === TimerStatuses.Initial
  )
    return {
      time: getDisplayValueFromMilliseconds(focusTimeInMilliseconds),
      phase: PomodoroPhases.Work,
      remainingTimeInCurrentCycleInMilliseconds,
      fadeOutPhase: false,
    };

  if (remainingTimeInCurrentCycleInMilliseconds > breakTimeInMilliseconds) {
    return {
      time: getDisplayValueFromMilliseconds(
        remainingTimeInCurrentCycleInMilliseconds - breakTimeInMilliseconds,
      ),
      phase: PomodoroPhases.Work,
      fadeOutPhase:
        remainingTimeInCurrentCycleInMilliseconds - breakTimeInMilliseconds <=
        POMODORO_WORK_FADE_OUT_PHASE_TIME_IN_MILLISECONDS,
    };
  }

  return {
    time: getDisplayValueFromMilliseconds(remainingTimeInCurrentCycleInMilliseconds),
    phase:
      remainingTimeInCurrentCycleInMilliseconds > POMODORO_BREAK_ENDING_TIME_IN_MILLISECONDS
        ? PomodoroPhases.Break
        : PomodoroPhases.BreakEnding,
    fadeOutPhase:
      remainingTimeInCurrentCycleInMilliseconds <=
      POMODORO_BREAK_FADE_OUT_PHASE_TIME_IN_MILLISECONDS,
  };
}
