import { useGetAvailableStripeCouponPromotion } from '@Memberships';
import { useFeatureFlag, FeatureFlags } from '@Utils';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackSubscriptionImpression } from '../../../../Analytics/coreAnalytics';
import { getAnalyticsOrigin } from '../lib/getAnalyticsOrigin';

interface HistoryState {
  redirect: boolean;
  from?: string;
}

export const useTrackSubscriptionImpression = () => {
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const location = useLocation();
  const expirationModalTest = useFeatureFlag<'default' | 'withMusic'>(FeatureFlags.ExpirationModal);
  const isStripeCheckoutEnabled = useFeatureFlag(FeatureFlags.StripeCheckout);

  useEffect(() => {
    trackSubscriptionImpression({
      couponId: availablePromotion?.couponId,
      origin: getAnalyticsOrigin((location.state as HistoryState)?.from),
      expirationModalTest: expirationModalTest || 'default',
      isStripeCheckout: isStripeCheckoutEnabled,
    });
  }, []);
};
