import { EnhancedMembershipPlanType } from 'src/types';
import * as S from './PlanCard.styles';

type Props = {
  plan: EnhancedMembershipPlanType;
  isSelected: boolean;
  hasDiscount: boolean;
  discountAmount?: number;
  isExtendedPromo?: boolean;
  onClick: () => void;
};

export const PlanCard = ({
  plan,
  isSelected = false,
  hasDiscount = false,
  isExtendedPromo = false,
  discountAmount = 0,
  onClick,
}: Props) => {
  const isYearly = plan?.title === 'Yearly' || plan?.displayInterval === 'yr';
  let planCost = isYearly
    ? `$${plan.displayCost}/${plan.displayInterval} ($${plan.price}/year)`
    : `$${plan.displayCost}/${plan.displayInterval}`;
  const totalCost = `${Boolean(plan.trialLength) ? 'then ' : ''}$${plan.price}/year`;

  if (hasDiscount) {
    planCost = `$${plan.displayCost}/${isYearly ? 'year' : 'month'}`;
  }

  return (
    <S.Wrapper>
      {!isExtendedPromo && isYearly && isSelected && !hasDiscount && <S.Save>SAVE 40%</S.Save>}
      {!isExtendedPromo && isYearly && isSelected && hasDiscount && (
        <S.Save>SAVE {discountAmount}%</S.Save>
      )}
      <S.Container data-testid={`plan-${plan?.title}`} isSelected={isSelected} onClick={onClick}>
        <S.TopContainer>
          {!isExtendedPromo && <S.PlanName>{isYearly ? 'YEARLY' : 'MONTHLY'} PLAN</S.PlanName>}
          {Boolean(plan.trialLength) && (
            <S.PlanTrialLength>{`${plan.trialLength} Days Free`}</S.PlanTrialLength>
          )}
        </S.TopContainer>
        <S.BottomContainer isSelected={isSelected}>
          {isExtendedPromo ? totalCost : planCost}
        </S.BottomContainer>
      </S.Container>
    </S.Wrapper>
  );
};
