export const processDynamicMentalStateColor = (inputColor: string, opacity = 1) => {
  // Validate opacity
  if (opacity < 0 || opacity > 1) {
    throw new Error('Opacity must be between 0 and 1');
  }

  // Check if the color is in hex format
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(inputColor)) {
    // Remove the hash at the start
    let hex = inputColor.replace('#', '');

    // Parse the hex color
    let r, g, b;
    if (hex.length === 3) {
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else {
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    }

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  // Check if the color is in RGB or RGBA format
  else if (/^rgb(a)?\(\d{1,3}, \d{1,3}, \d{1,3}(, \d(.\d+)?)?\)$/.test(inputColor)) {
    // Extract the RGB(A) values
    let rgba = inputColor.match(/\d+(\.\d+)?/g)!.map(Number);

    // Adjust the opacity
    if (rgba.length === 3) {
      rgba.push(opacity); // For RGB, add opacity
    } else if (rgba.length === 4) {
      rgba[3] = opacity; // For RGBA, adjust existing opacity
    }

    return `rgba(${rgba.join(', ')})`;
  } else {
    throw new Error('Invalid color format');
  }
};
