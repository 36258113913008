import styled from 'styled-components';

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 999;
`;

export const CloseButton = styled.img`
  cursor: pointer;
`;
