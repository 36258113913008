import {
  getTrackComplexity,
  getTrackGenreName,
  getTrackName,
  getTrackNeuralEffectLevel,
  useGetCurrentTrack,
} from '@Music';
import { useUser, UserMentalStatePreferenceTypes } from '@User';
import { useSelector } from 'react-redux';

import { TimerOrigin } from '../domains/Analytics/coreAnalytics.types';
import { useDynamicSession } from '../domains/Session/lenses/useDynamicSession';
import { RootReducerType } from '../reducers';
import { MentalStates } from '../types';
import { useTimerMode } from './useTimerMode';

export const usePlayerAnalyticsParams = () => {
  const session = useDynamicSession();
  const sessionDynamicActivityTitle = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.displayValue,
  );
  const currentTrack = useGetCurrentTrack();
  const user = useUser();
  const timerMode = useTimerMode();
  const ui = useSelector((state: RootReducerType) => state.ui);
  const currentPreferences = session
    ? user.mentalStatePreferences[session.mentalState.id as MentalStates.Focus]
    : null;
  const sessionMentalStateDisplayValue = useSelector(
    (state: RootReducerType) =>
      state.sessionManager.sessionDynamicActivity?.mentalState.displayValue,
  );

  const { sessionModalContentOrigin } = ui;

  return {
    activity: sessionDynamicActivityTitle || '',
    trackGenre: getTrackGenreName(currentTrack),
    filterGenre: currentPreferences
      ? (currentPreferences[UserMentalStatePreferenceTypes.GenreNames] || []).join(',')
      : '',
    filterNel: currentPreferences
      ? (currentPreferences[UserMentalStatePreferenceTypes.NeuralEffectLevels] || []).join(',')
      : '',
    trackNel: getTrackNeuralEffectLevel(currentTrack),
    mentalState: (sessionMentalStateDisplayValue || '') as MentalStates,
    sessionModalContentOrigin: sessionModalContentOrigin as TimerOrigin,
    timerMode: timerMode,
    complexity: getTrackComplexity(currentTrack),
    trackName: getTrackName(currentTrack),
  };
};
