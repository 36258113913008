import { TextBold, FontFamilies, TextSemiBold, Text } from '@Cortex';
import styled from 'styled-components';
import { fadeIn } from '../PlanCard/PlanCard.styles';

export const HeaderSpacer = styled.div`
  height: 48px;
  width: 48px;
`;

export const CloseIconContainer = styled.div`
  backdrop-filter: blur(34px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 48px;
  width: 48px;
  transition: box-shadow 0.2s ease-in-out;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 0.5);
  }
`;

export const CloseIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
`;

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    max-width: 750px;
    margin: 0 auto;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  margin: 8px 8px 0 0;
`;

export const HeaderIconContainer = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    display: block;
  }
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1250px;
  justify-content: center;
  position: relative;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    flex-direction: column;
  }
`;

export const LeftBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 45%;
  position: relative;
  z-index: 9999;
  isolation: isolate;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    width: 100%;
  }
`;

export const LeftBoxHeaderIconContainer = styled.div`
  display: block;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    display: none;
  }
`;

export const RightBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  width: 50%;
  justify-content: center;
  align-items: center;
  gap: 20px;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    display: none;
  }
`;

export const DesktopImage = styled.img`
  position: absolute;
  right: -127px;
  height: 680px;
  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    display: none;
  }
`;

export const AboveTitle = styled(TextBold)`
  margin-top: 2rem;
  color: rgba(254, 90, 131, 1);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-family: ${FontFamilies.SemiBold};
  font-size: 14px;
`;

export const Title = styled(TextBold)`
  font-size: 70px;
  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    font-size: 60px;
  }
`;

export const Description = styled(Text)`
  margin-top: 0.5rem;
  color: rgba(255, 255, 255, 0.75);
  b {
    color: white;
    font-family: ${FontFamilies.Bold};
  }
`;

export const DealBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  border-radius: 16px;
  margin-top: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(
    46deg,
    rgba(20, 20, 26, 0.75),
    rgba(20, 20, 26, 0.75),
    rgba(57, 37, 63, 0.5)
  );

  box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.3);
`;

export const Checkmarks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 30px;
  margin-top: 5rem;
  max-width: 1250px;
  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    flex-direction: column;
    gap: 10px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

export const Badge = styled.div`
  background: linear-gradient(139.73deg, #9130bb -19.11%, #f34675 86.25%, #f39946 203.68%);
  font-size: 10px;
  color: #ffffff;
  z-index: 1;
  text-transform: uppercase;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px 6px;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const PriceBlock = styled.div`
  display: flex;
  align-items: baseline;
  gap: 15px;
  margin-top: 10px;
  sup {
    vertical-align: text-top;
  }
`;
export const Price = styled(TextBold)`
  font-size: 44px;
`;
export const PreviousPrice = styled(TextBold)`
  font-size: 24px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 3px; /* Adjust thickness */
    background-color: #ff5a5f; /* Set the line color */
    transform: rotate(-13deg);
  }
`;
export const DealDescription = styled(TextBold)`
  font-size: 16px;
  i {
    font-style: normal;
    font-family: ${FontFamilies.Regular};
    font-size: 14px;
    color: rgba(255, 255, 255, 0.75);
    ${({ theme }) => theme.mediaQuery.maxWidth.md} {
      display: block;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin: 15px 0;
  align-self: center;
  width: 100%;
`;

export const OfferExpiration = styled(Text)`
  font-family: ${FontFamilies.Regular};
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  align-self: center;
`;
