import { useIsTrialUser } from '@Memberships';
import { useTeamSubscriptionInfo } from '../../../../../hooks/useHasTeamSubscription';
import { useIsExpired } from '../../../lenses/isExpired';

export const useShouldRenderMembershipWidget = () => {
  const { hasTeamSubscription, isDataReady } = useTeamSubscriptionInfo();
  const isTrial = useIsTrialUser();
  const isExpired = useIsExpired();

  return !hasTeamSubscription && isDataReady && (isTrial || isExpired);
};
