import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeadingContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
`;

export const CardContainer = styled.div`
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;
`;
