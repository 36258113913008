import { CloseButton, Text, TextBold } from '@Cortex';
import { NOOP } from '@Globals';
import React from 'react';

import * as S from './SessionProTip.styles';

export interface Props {
  name?: string;
  welcomeText: string;
  onClose?: () => void;
}

export const SessionProTipDisplay = ({ onClose = NOOP, name, welcomeText }: Props) => {
  return (
    <S.Container>
      <S.CloseButton>
        <CloseButton data-testid="sessionPropTipsCloseButton" onClick={onClose} />
      </S.CloseButton>
      <S.HeaderText>
        <TextBold>{`Welcome to Brain.fm${name ? `, ${name}` : ''}!`}</TextBold>
      </S.HeaderText>
      <Text>{welcomeText}</Text>
    </S.Container>
  );
};
