import { teamsSliceActions, UserTeamType } from '@Teams';
import { APIResponse } from '@Utils';
import { select, put, delay } from 'redux-saga/effects';

import { RequestMethods } from '../../../api/client/types';
import { RootReducerType } from '../../../reducers';
import { requestSaga } from '../../../sagas/httpRequest';
import { Logger } from '../../../utils/logger';

export function* getTeamsSaga() {
  try {
    let user: RootReducerType['user'] | undefined = undefined;

    // Continuously poll the state until user is defined
    while (!user?.info?.id) {
      user = yield select((state: RootReducerType) => state.user);
      if (!user?.info?.id) {
        // If user is not defined, delay a little
        yield delay(20);
      }
    }
    const { result } = (yield requestSaga(
      RequestMethods.GET,
      `/users/${user.info?.id}/teams`,
      null,
      3,
    )) as APIResponse<UserTeamType[]>;

    yield put(teamsSliceActions.setTeams(result));
    yield put(teamsSliceActions.teamsInitiallyFetched());
  } catch (error) {
    yield put(teamsSliceActions.teamsInitialFetchError());
    Logger.error(error);
  }
}
