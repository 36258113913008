import { Button, ButtonVariants } from '@Cortex';
import { OutsideClickDetector } from '@Utils';
import ChevronRightIcon from '../../../../assets/images/chevron_right_icon.svg';
import CloseIcon from '../../../../assets/images/close_icon.svg';
import Icon from '../../../Cortex/Icon';
import { FaqItem } from '../../types';

import { CardWrapperTwo } from '../CardWrapperTwo';
import * as S from './FaqCard.styles';
import BrandLogo from '../../../../assets/images/brand_logo.svg';
import React, { useEffect, useState, useCallback } from 'react';
import { CoreAnalytics } from '@Analytics';

export type Props = {
  activeStepIndex: number;
  numberOfSteps: number;
  onClickNext?: () => void;

  ctaButtonText?: string;
  body?: string;
  image?: string;
  title?: string;
  stepText?: string;
  items?: FaqItem[];
};

export const Body = ({ text }: { text: string }) => {
  const parts = text.split(/\*([^*]+)\*/g);

  return (
    <S.Body>
      {parts.map((part, index) => {
        if (index % 2 === 1) {
          return <S.BodyTextBold key={part + index}>{part}</S.BodyTextBold>;
        } else {
          return part;
        }
      })}
    </S.Body>
  );
};

export function FaqCard(props: Props) {
  useEffect(() => {
    CoreAnalytics.trackOnboardingCardImpressionEvent({
      title: props.title ?? '',
      body: props.body ?? '',
      step: props.stepText ?? '',
    });
  }, []);

  const [currentItem, setCurrentItem] = useState<FaqItem | null>(null);
  const onClickItem = useCallback(
    (i: number) => () => {
      if (!props.items) return;
      const faqItem = props.items[i];
      setCurrentItem(faqItem);
      CoreAnalytics.trackOnboardingFaqQuestionImpressionEvent({
        title: faqItem.title ?? '',
        step: props.stepText ?? '',
      });
    },
    [setCurrentItem, props.items, currentItem],
  );

  const onCloseModal = useCallback(() => {
    setCurrentItem(null);
  }, [setCurrentItem]);

  return (
    <CardWrapperTwo>
      <S.Header>
        <S.Logo src={BrandLogo} />
        {props.activeStepIndex !== null &&
        props.numberOfSteps !== null &&
        props.numberOfSteps !== 1 ? (
          <S.StepSection>
            <S.StepText>{props.stepText || 'Onboarding'}</S.StepText>
            <S.StepPills>
              {[...Array(props.numberOfSteps)].map((_, index) => (
                <S.StepPill
                  key={index}
                  isActive={index === props.activeStepIndex}
                  isCompleted={index <= props.activeStepIndex}
                />
              ))}
            </S.StepPills>
          </S.StepSection>
        ) : null}
        <S.LogoPlaceholder src={BrandLogo} />
      </S.Header>
      <S.ContentWrapper>
        <S.Content isBlurred={Boolean(currentItem)}>
          <S.OnboardingCardTextColumn>
            {props.title ? <S.Title isCentered={!props.image}>{props.title}</S.Title> : null}
            {props.body ? <Body text={props.body} /> : null}
            {props.items && (
              <S.FaqItems>
                {props.items.map((item, i) => (
                  <S.FaqItem onClick={onClickItem(i)}>
                    {item.label || item.title}
                    <Icon size={24} src={ChevronRightIcon} />
                  </S.FaqItem>
                ))}
              </S.FaqItems>
            )}
          </S.OnboardingCardTextColumn>
          {props.image ? (
            <S.OnboardingCardImageColumn>
              <S.Image src={props.image} />
            </S.OnboardingCardImageColumn>
          ) : null}
        </S.Content>
        {currentItem && (
          <OutsideClickDetector onOutsideClick={onCloseModal}>
            <S.Modal>
              <S.Close src={CloseIcon} onClick={onCloseModal} />
              {currentItem.image && (
                <S.ModalImage
                  alt={currentItem.title || currentItem.label}
                  src={currentItem.image}
                />
              )}
              {currentItem.title ? <S.ModalTitle>{currentItem.title}</S.ModalTitle> : null}
              {currentItem.listElements && (
                <ul>
                  {currentItem.listElements.map(listElement => (
                    <S.ModalListItem>{listElement}</S.ModalListItem>
                  ))}
                </ul>
              )}
              {currentItem.bottomLink && (
                <S.ModalLink href={currentItem.bottomLink.url} target="_blank">
                  {currentItem.bottomLink.label}
                </S.ModalLink>
              )}
            </S.Modal>
          </OutsideClickDetector>
        )}
      </S.ContentWrapper>

      <S.Buttons>
        {props.onClickNext && (
          <S.ButtonContainer>
            <Button
              isFullWidth
              keepTextCase
              variant={ButtonVariants.Secondary}
              onClick={props.onClickNext}
            >
              {props.ctaButtonText || 'Continue'}
            </Button>
          </S.ButtonContainer>
        )}
      </S.Buttons>
    </CardWrapperTwo>
  );
}
