import { Button, ButtonVariants, CloseButton, Text, TextBold } from '@Cortex';
import { NOOP } from '@Globals';

import * as S from './QuizToast.styles';

interface Props {
  onClickOpenPreferences: () => void;
  onClose?: () => void;
  shouldShowButtonToOpenPreferences: boolean;
  welcomeText: string;
}

export const QuizToastDisplay = ({
  onClickOpenPreferences,
  onClose = NOOP,
  shouldShowButtonToOpenPreferences,
  welcomeText,
}: Props) => {
  return (
    <S.Container>
      <S.CloseButton>
        <CloseButton
          data-testid="sessionPropTipsCloseButton"
          onClick={event => {
            event.stopPropagation();
            onClose();
          }}
        />
      </S.CloseButton>
      <S.Text>
        <TextBold>Your Focus Mix</TextBold>
      </S.Text>
      <S.Text>
        <Text>{welcomeText}</Text>
      </S.Text>
      {shouldShowButtonToOpenPreferences ? (
        <Button
          variant={ButtonVariants.Primary}
          onClick={event => {
            event.stopPropagation();
            onClickOpenPreferences();
            onClose();
          }}
        >
          Open Preferences
        </Button>
      ) : null}
    </S.Container>
  );
};
