import { FontColors, FontFamilies, Paper, Text } from '@Cortex';
import { NOOP } from '@Globals';

import * as S from './Answer.styles';

export type Props = {
  title?: string;
  description?: string;
  selected: boolean;
  onClick?: () => void;
};

export function Answer({ onClick = NOOP, ...props }: Props) {
  const { title, description, selected } = props;

  return (
    <S.Container hasSelected={selected} onClick={onClick}>
      <S.TextContainer>
        <Text color={FontColors.White} family={FontFamilies.Regular} size={24}>
          {title}
        </Text>
        <Text color={FontColors.WhiteTransparent} size={18}>
          {description}
        </Text>
      </S.TextContainer>
      {selected ? <S.RadioSelected /> : <S.RadioUnselected />}
    </S.Container>
  );
}
