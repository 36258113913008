import { useIsTrialUser } from '@Memberships';
import { ProfileMenuItemUrlPaths } from '@User';
import { useFeatureFlag, FeatureFlags } from '@Utils';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setModal } from '../../../../actions/ui';
import { RootReducerType } from '../../../../reducers';
import { getDescriptionCopy } from './lib/getDescriptionCopy';
import { PaywallModalDisplay } from './PaywallModal.display';
import { uiSliceActions } from '../../../../reducers/uiReducer';
import { ProfileSection, SideDeckTab } from '../../../../types';

export function PaywallModal() {
  const navigate = useNavigate();
  const isTrialUser = useIsTrialUser();
  const dispatch = useDispatch();
  const { modalType } = useSelector((state: RootReducerType) => state.ui);
  const expirationModalTest = useFeatureFlag<'default' | 'withMusic'>(FeatureFlags.ExpirationModal);
  const isDemoTrackEnabled = expirationModalTest === 'withMusic';

  const onCtaClick = useCallback(() => {
    dispatch(setModal(null));
    if (isTrialUser) {
      navigate('/payment');
    } else {
      dispatch(uiSliceActions.setSideDeckOpen(true));
      dispatch(
        uiSliceActions.setSideDeckTab({
          tab: SideDeckTab.Profile,
          profileSection: ProfileSection.Subscription,
        }),
      );
    }
  }, [isTrialUser, navigate, dispatch]);
  const description = getDescriptionCopy({ isDemoTrackEnabled, isTrialUser });

  return (
    <PaywallModalDisplay
      ctaDescription={
        isTrialUser ? 'Ready to unlock all the music?' : 'Ready to unlock all the music again?'
      }
      description={description}
      isDemoTrackEnabled={isDemoTrackEnabled}
      isOpen={modalType === 'paywall'}
      primaryButtonText={isTrialUser ? 'JOIN BRAIN.FM TODAY' : 'RENEW MY MEMBERSHIP'}
      title={isTrialUser ? 'Your trial has ended' : 'Your subscription has expired'}
      onPrimaryButtonClick={onCtaClick}
    />
  );
}
