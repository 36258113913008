import React from 'react';

import { useIsWindowFocused } from '../../../../hooks';
import { TimerContainerDynamic } from '../TimerContainerDynamic';
import * as S from './FocusSectionDynamic.styles';

export const FocusSectionDynamic = () => {
  const isWindowFocused = useIsWindowFocused();

  if (!isWindowFocused) return null;
  return (
    <S.Wrapper>
      <TimerContainerDynamic />
    </S.Wrapper>
  );
};
