import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { Assets } from '../../utils/assets';
import { AuthButton } from './AuthButton';

const Wrapper = styled.button`
  display: block;
  border-radius: 0.25rem;
  background-color: #000;
  cursor: pointer;
  appearance: none;
  border: none;
  outline: none;
  margin: 0.25rem;
  font-family: ${({ theme }) => theme.font.family.default.medium};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
  transition: box-shadow 0.4s ease-in-out;

  &:hover {
    box-shadow: 0 4px 14px 0 rgb(255 255 255 / 15%);
  }
`;

export interface AppleButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  analyticsEvent: string;
}

export const AppleButton: React.FC<AppleButtonProps> = ({ label, analyticsEvent, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <AuthButton
        alt={Assets.icons.apple.alt}
        analyticsEvent={analyticsEvent}
        label={label}
        src={Assets.icons.apple.url}
      />
    </Wrapper>
  );
};
