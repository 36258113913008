export const getDescriptionCopy = ({
  isTrialUser,
  isDemoTrackEnabled,
}: {
  isTrialUser: boolean;
  isDemoTrackEnabled: boolean;
}) => {
  if (isDemoTrackEnabled) {
    return isTrialUser
      ? 'But you can still enjoy a sample track!'
      : 'Get back in the zone with a sample track on us.';
  }

  return isTrialUser
    ? 'But you can keep the music flowing!'
    : "But we'd love to have you back as a member!";
};
