import { Text, TextBold } from '@Cortex';

import * as S from './NoSearchResults.styles';
import FavoriteTrackIcon from './assets/favorite_track.svg';

export function NoSearchResults({ title = 'Start Searching' }: { title?: string }) {
  return (
    <S.Container>
      <S.ImageContainer>
        <S.Image src={FavoriteTrackIcon} />
      </S.ImageContainer>
      <S.HeadingContainer>
        <S.TextWrapper>
          <TextBold>{title}</TextBold>
        </S.TextWrapper>
        <S.TextWrapper>
          <Text>Find music by name, genre, instrumentation, or mood.</Text>
        </S.TextWrapper>
      </S.HeadingContainer>
    </S.Container>
  );
}
