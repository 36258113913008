import { MenuItemIcons } from '@Cortex';
import { useIsTrialUser } from '@Memberships';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { profileScreenLogEvent } from '../../actions/analytics';
import { signOut } from '../../actions/authentication';
import { RootReducerType } from '../../reducers';

export enum ProfileMenuItems {
  Account = 'Account',
  ContactSupport = 'Contact Support',
  Gift = 'Gift Brain.fm',
  Logout = 'Logout',
  Refer = 'Refer a friend',
  Settings = 'Settings',
}

export const PROFILE_ROUTE = `/user`;

export const ProfileMenuItemUrlPaths = {
  Account: `${PROFILE_ROUTE}/account`,
  Gift: 'https://giftup.app/place-order/e382fe5e-19a6-4be5-936e-1bda244f95aa?platform=hosted',
  Refer: `${PROFILE_ROUTE}/refer`,
  Settings: `${PROFILE_ROUTE}/settings`,
};

type MenuItemType = {
  hasTopDivider?: boolean;
  href?: string;
  icon: MenuItemIcons;
  label: ProfileMenuItems;
  onClick: () => void;
  testid: string;
  urlPath: string | null;
};

export function useMenuItems(): MenuItemType[] {
  const dispatch = useDispatch();
  const email = useSelector((state: RootReducerType) => state.user.info?.email) || '';
  const navigate = useNavigate();
  const isSubscriber = !useIsTrialUser();
  const location = useLocation();
  const isInProfileScreen = location.pathname.includes(PROFILE_ROUTE);

  return [
    {
      urlPath: ProfileMenuItemUrlPaths.Account,
      label: ProfileMenuItems.Account,
      icon: MenuItemIcons.Account,
      testid: 'account',
      onClick: () => {
        dispatch(profileScreenLogEvent('profile_account'));
        if (isInProfileScreen) {
          navigate(ProfileMenuItemUrlPaths.Account, { replace: true });
        } else {
          navigate(ProfileMenuItemUrlPaths.Account);
        }
      },
    },
    {
      urlPath: ProfileMenuItemUrlPaths.Settings,
      label: ProfileMenuItems.Settings,
      icon: MenuItemIcons.Settings,
      testid: 'settings',
      onClick: () => {
        dispatch(profileScreenLogEvent('profile_settings'));
        if (isInProfileScreen) {
          navigate(ProfileMenuItemUrlPaths.Settings, { replace: true });
        } else {
          navigate(ProfileMenuItemUrlPaths.Settings);
        }
      },
    },
    {
      urlPath: null,
      label: ProfileMenuItems.ContactSupport,
      href: `mailto:support@brain.fm?subject=Support%20request%20for%20my.brain.fm&body=Account%3A%20${email}`,
      icon: MenuItemIcons.Support,
      testid: 'contactSupport',
      onClick: () => {
        dispatch(profileScreenLogEvent('profile_contact_support'));
      },
    },
    ...includeIfTrue(
      {
        urlPath: ProfileMenuItemUrlPaths.Refer,
        label: ProfileMenuItems.Refer,
        hasTopDivider: true,
        icon: MenuItemIcons.Broadcast,
        testid: 'refer',
        onClick: () => {
          dispatch(profileScreenLogEvent('profile_refer'));
          if (isInProfileScreen) {
            navigate(ProfileMenuItemUrlPaths.Refer, { replace: true });
          } else {
            navigate(ProfileMenuItemUrlPaths.Refer);
          }
        },
      },
      isSubscriber,
    ),
    ...includeIfTrue(
      {
        urlPath: ProfileMenuItemUrlPaths.Gift,
        label: ProfileMenuItems.Gift,
        icon: MenuItemIcons.Gift,
        testid: 'gift',
        onClick: () => {
          window.open(ProfileMenuItemUrlPaths.Gift, '_blank');
        },
      },
      isSubscriber,
    ),
    {
      urlPath: null,
      label: ProfileMenuItems.Logout,
      testid: 'logout',
      hasTopDivider: true,
      onClick: () => {
        dispatch(profileScreenLogEvent('profile_logout'));
        dispatch(signOut());
      },
      icon: MenuItemIcons.Logout,
    },
  ].filter(Boolean);
}

function includeIfTrue<Item>(item: Item, isTrue: boolean): Item[] {
  if (isTrue) {
    return [item];
  }

  return [];
}
