import { forwardRef, useState } from 'react';
import * as S from './ProfilePayment.styles';
import { CancelMembership } from '@Memberships';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerType } from '../../../../reducers';
import { Transition } from 'react-transition-group';
import { Elements } from '@stripe/react-stripe-js';
import { BillingPanel, UpdateStripePaymentMethodPanel } from '@Payment';
import stripe, { ELEMENT_OPTIONS } from '../../../../utils/stripe';
import { logEventWithProperties } from '../../../../actions/analytics';

enum PaymentScreen {
  CancelPayment,
  UpdatePayment,
}

export const ProfilePayment = forwardRef<HTMLDivElement>((_, ref) => {
  const dispatch = useDispatch();
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);
  const [paymentScreen, setPaymentScreen] = useState<PaymentScreen | null>(null);

  const handleCancelSubscription = () => {
    dispatch(
      logEventWithProperties({
        event: 'subscription_cancel',
        props: { plan_type: legacyMembership?.title },
      }),
    );
    setPaymentScreen(PaymentScreen.CancelPayment);
  };

  const handleUpdatePayment = () => {
    setPaymentScreen(PaymentScreen.UpdatePayment);
  };

  return (
    <S.Container ref={ref}>
      <BillingPanel
        onClickCancel={handleCancelSubscription}
        onPaymentMethodChange={handleUpdatePayment}
      />

      <Transition
        in={paymentScreen === PaymentScreen.UpdatePayment}
        mountOnEnter
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <S.FloatingWindow animationState={state}>
              <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
                <UpdateStripePaymentMethodPanel onClose={() => setPaymentScreen(null)} />
              </Elements>
            </S.FloatingWindow>
          );
        }}
      </Transition>

      <Transition
        in={paymentScreen === PaymentScreen.CancelPayment}
        mountOnEnter
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <S.FloatingWindow animationState={state}>
              <CancelMembership onClose={() => setPaymentScreen(null)} />
            </S.FloatingWindow>
          );
        }}
      </Transition>
    </S.Container>
  );
});
