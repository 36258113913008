import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const SlideContainer = styled.div<{ active: boolean }>`
  position: relative;
  height: 8px;
  width: 8rem;
  color: #fff;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

export const SlideBg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(25, 23, 54, 0.5);
  border-radius: 0.5rem;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
`;

export const SlideFill = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  background: rgba(254, 91, 131, 1);
  border-radius: 0.5rem;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
`;

export const Slide = styled.input`
  position: absolute;
  top: 0;
  appearance: none;
  background: none;
  margin: 0;
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  outline: none;
  cursor: pointer;

  &::-webkit-slider-thumb {
    appearance: none;
    background: white;
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
    transition: transform 0.15s ease-in-out;
  }

  &::-moz-slider-thumb {
    appearance: none;
    background: white;
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
    transition: transform 0.15s ease-in-out;
  }

  &::-webkit-slider-thumb:hover {
    transform: scale(1.1);
  }

  &::-moz-slider-thumb:hover {
    transform: scale(1.1);
  }
`;

export const Image = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 16px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 300ms linear;

  &:hover {
    opacity: 1;
  }
`;
