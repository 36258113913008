import { useIsPomodoroActive } from '@Timer';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../reducers';
import { getTimerMode } from '../utils/getTimerMode';

export const useTimerMode = () => {
  const sessionPlayType = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionPlayType,
  );
  const isPomodoro = useIsPomodoroActive();
  return getTimerMode(sessionPlayType, isPomodoro);
};
