import { Serving, Track } from '@Model';

import { isServing } from './isServing';

export function getTrackDynamicMentalStateId(trackOrServing?: Track | Serving | null): string {
  if (isServing(trackOrServing)) {
    return trackOrServing.track.dynamicMentalState.id;
  }

  return trackOrServing?.dynamicMentalState.id || '';
}
