import { Button, ButtonVariants, Panel } from '@Cortex';
import ReactModal from 'react-modal';
import { FastSubscriptionInfo } from '../../../../api/modules/FastSubscriptionData/types';

import { baseStyles, CLOSE_TIMEOUT_MS } from '../../../../modals/modalStyles';
import { Assets } from '../../../../utils/assets';
import { VariantStates } from '../../../Cortex/components/Button/Button';
import * as S from './FastSubscriptionModal.styles';
import { getPaymentData } from './utils/getPaymentData';

export type Props = {
  data?: FastSubscriptionInfo;
  isOpen: boolean;
  isLoading: boolean;
  isFullPaywallAvailable: boolean;
  isCreating: boolean;
  onClose: () => void;
  onNavigateToPaywall: () => void;
  primaryButtonAction: () => void;
  title: string;
  ctaText: string;
};

export function FastSubscriptionModalPresentation(props: Props) {
  const {
    title,
    ctaText,
    primaryButtonAction,
    isLoading,
    isFullPaywallAvailable,
    isCreating,
    data,
    onClose,
    onNavigateToPaywall,
  } = props;

  return (
    <ReactModal
      closeTimeoutMS={CLOSE_TIMEOUT_MS}
      isOpen={props.isOpen}
      style={baseStyles}
      onRequestClose={props.onClose}
    >
      <S.Overlay>
        <S.ModalWrapper>
          <Panel
            padding="2rem 0"
            style={{
              background:
                'linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              overflow: 'visible',
            }}
          >
            <S.ModalContent>
              <S.CloseButtonWrapper type="button" onClick={onClose}>
                <S.Close src={Assets.icons.close_white.url} />
              </S.CloseButtonWrapper>
              <S.Image alt={Assets.images.keepAccess.alt} src={Assets.images.keepAccess.url} />
              <S.AuraImage
                alt={Assets.images.keepAccessAura.alt}
                src={Assets.images.keepAccessAura.url}
              />
              <S.TextContainer>{title && <S.Title>{title}</S.Title>}</S.TextContainer>
              <S.MembershipInformationContainer>
                <S.MembershipInformation>
                  <S.Icon alt={Assets.icons.checkmark.alt} src={Assets.icons.checkmark.url} />
                  <S.MembershipTextContainer>
                    <S.MembershipHeaderText>
                      <S.Skeleton isLoading={isLoading}>
                        Payment method: {data?.paymentMethod.type}
                      </S.Skeleton>
                    </S.MembershipHeaderText>
                    <S.MembershipInfoText>
                      <S.Skeleton isLoading={isLoading}>
                        {data?.paymentMethod.brand} ending {data?.paymentMethod.last4}
                      </S.Skeleton>
                    </S.MembershipInfoText>
                  </S.MembershipTextContainer>
                </S.MembershipInformation>
                <S.MembershipInformation>
                  <S.Icon alt={Assets.icons.checkmark.alt} src={Assets.icons.checkmark.url} />
                  <S.MembershipTextContainer>
                    <S.MembershipHeaderText>
                      <S.Skeleton isLoading={isLoading}>
                        ${(data?.offeredPlan.price || 0) / 100} {data?.offeredPlan.title}
                      </S.Skeleton>
                    </S.MembershipHeaderText>
                    <S.MembershipInfoText>
                      <S.Skeleton isLoading={isLoading}>
                        {getPaymentData(data?.lastSubscriptionInfo.currentPeriodEnd)}
                      </S.Skeleton>
                    </S.MembershipInfoText>
                  </S.MembershipTextContainer>
                </S.MembershipInformation>
                <S.PoweredByImage src={Assets.images.poweredByStripe.url} />
              </S.MembershipInformationContainer>
              {isFullPaywallAvailable && (
                <S.Description>
                  Go to{' '}
                  <S.Link data-testid="toToFullPaywall" onClick={onNavigateToPaywall}>
                    Payments page
                  </S.Link>{' '}
                  to make changes if needed
                </S.Description>
              )}

              <S.ButtonWrapper>
                <Button
                  data-testid="successButtonPrimary"
                  disabled={isLoading}
                  isFullWidth
                  keepTextCase
                  style={{ fontSize: '0.75rem', letterSpacing: '1.2px' }}
                  variant={ButtonVariants.Secondary}
                  variantState={isCreating ? VariantStates.Loading : VariantStates.Idle}
                  onClick={primaryButtonAction}
                >
                  {ctaText}
                </Button>
              </S.ButtonWrapper>
            </S.ModalContent>
          </Panel>
        </S.ModalWrapper>
      </S.Overlay>
    </ReactModal>
  );
}
