export const SLIDES = [
  {
    title: 'Increase focus',
    description: 'Science-based music designed to sustain flow state',
    images: {
      sm: 'https://cdn.brain.fm/images/signup/increase_focus_sm.webp',
      md: 'https://cdn.brain.fm/images/signup/increase_focus_md.webp',
    },
  },
  {
    title: 'Reduce distraction',
    description: 'Eliminate interruptions and stay centered on your goals',
    images: {
      sm: 'https://cdn.brain.fm/images/signup/reduce-distraction_sm.webp',
      md: 'https://cdn.brain.fm/images/signup/reduce-distraction_md.webp',
    },
  },
  {
    title: 'Get more done',
    description: 'Boost your productivity and efficiency',
    images: {
      sm: 'https://cdn.brain.fm/images/signup/get-more-done_sm.webp',
      md: 'https://cdn.brain.fm/images/signup/get-more-done_md.webp',
    },
  },
];
