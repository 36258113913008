import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../../reducers';

export const useMembershipPeriodTimeLeft = () => {
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);

  const expirationDate = new Date(legacyMembership?.expirationDate || '');
  const timeLeftInMilliseconds = expirationDate.getTime() - Date.now();
  const timeLeftInHours = Math.round(timeLeftInMilliseconds / 1000 / 60 / 60);
  const timeLeftInDays = Math.round(timeLeftInHours / 24);

  const gracePeriodExpirationDate = new Date(legacyMembership?.gracePeriodExpirationDate || '');
  const gracePeriodTimeLeftInMilliseconds = gracePeriodExpirationDate.getTime() - Date.now();
  const gracePeriodTimeLeftInMinutes = Math.floor(gracePeriodTimeLeftInMilliseconds / 1000 / 60);
  const gracePeriodTimeLeftInHours = Math.floor(gracePeriodTimeLeftInMinutes / 60);
  const gracePeriodTimeLeftInDays = Math.floor(gracePeriodTimeLeftInHours / 24);

  const isInGracePeriod =
    gracePeriodTimeLeftInMilliseconds >= 0 && legacyMembership?.isInGracePeriod;

  return {
    expirationDate,
    timeLeftInDays,
    timeLeftInHours,
    timeLeftInMilliseconds,
    gracePeriodTimeLeftInMilliseconds,
    gracePeriodTimeLeftInMinutes,
    gracePeriodTimeLeftInHours,
    gracePeriodTimeLeftInDays,
    isInGracePeriod,
  };
};
