import { FontFamilies } from '@Cortex';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const Form = styled.div`
  display: flex;
  gap: 15px;
  align-items: flex-start;
`;
export const ButtonWrapper = styled.div`
  margin-top: 26px;
  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    margin-top: 20px;
  }
`;

export const CardTitle = styled.div`
  margin-bottom: 10px;
`;
export const CardDetails = styled.div`
  position: relative;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

/** ------------ MODERN LAYOUT ---------------- */

export const ModernContainer = styled.div<{ isFocused?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: ${({ isFocused }) =>
    isFocused ? '1px solid #ffffff' : '1px solid rgba(255, 255, 255, 0.2)'};
  transition: border 0.2s ease-in-out;
  border-radius: 8px;
  padding: 8px 8px 8px 16px;
`;

export const ModernForm = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

export const ModernFormInput = styled.input`
  font-size: 14px;
  color: #ffffff;
  width: 100%;
  outline: none;
  border: none;
  background: none;
  flex: 1;
  font-weight: 500;
  font-family: ${FontFamilies.SemiBold};
`;

export const Button = styled.button<{ isFocused?: boolean }>`
  border: none;
  outline: none;
  background-color: ${({ isFocused }) => (isFocused ? '#ffffff' : 'rgba(255, 255, 255, 0.2)')};
  color: #000000;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  font-size: 14px;
  font-family: ${FontFamilies.SemiBold};
  font-weight: 500;
`;

export const ErrorText = styled.span`
  color: red;
  font-family: ${FontFamilies.Regular};
  font-size: 12px;
  position: absolute;
  bottom: 2px;
  left: 16px;
`;
