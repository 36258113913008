import { Text } from '@Cortex';
import styled from 'styled-components';

import { FORM_PADDING } from '../../constants';

export const FormBody = styled.form`
  margin-bottom: 1.5rem;
`;

export const EmailWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const SignUpForm = styled.div`
  align-items: center;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  padding: 0 1rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    overflow-y: inherit;
  }
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 10vh;
  padding-top: 3rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    margin-bottom: 2vh;
    padding-top: 2vh;
  }
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
`;

export const Button = styled.div`
  width: 48%;
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const FormWrapper = styled.div`
  width: 100%;
  align-self: center;
`;
export const FormLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  width: 100%;
`;

export const Form = styled.div`
  margin-bottom: 1.5rem;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
`;

const Link = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const LinkLast = styled(Link)`
  margin-bottom: 0;
`;

export const SignUpWithWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1.5rem;
`;

export const HR = styled.hr`
  color: rgba(55, 62, 91, 0.7);
  width: 100%;
`;

export const SignUpWithText = styled(Text)`
  flex-shrink: 0;
  padding: 0 1rem;
  text-align: center;
  text-transform: lowercase;
`;

export const Content = styled.div`
  display: flex;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    flex-direction: column;
  }
`;

export const ContentColumn = styled.div`
  width: 50%;
  display: flex;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    width: 100%;
  }
`;

export const VerticalSeparator = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 0 ${FORM_PADDING};

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    /* surprise! it's now a horizontal separator */
    margin: 0 0 2rem;
  }
`;

export const WidthContainer = styled.div`
  max-width: 1280px;
`;

export const DisclaimerWrapper = styled.div`
  padding-bottom: 2rem;
`;
