import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export interface AppStateType {
  status: 'loading' | 'ready' | 'idle';
  lastUpdatedAt: number | null;
}

const initialState: AppStateType = {
  status: 'idle',
  lastUpdatedAt: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    initializeApp: state => {
      state.status = 'loading';
      return state;
    },
    initializeAppComplete: state => {
      state.status = 'ready';
      state.lastUpdatedAt = Date.now();
      return state;
    },
  },
});

const persistConfig = {
  key: 'app',
  storage,
  blacklist: ['status'],
};

export const appSliceActions = appSlice.actions;
export default persistReducer(persistConfig, appSlice.reducer);
