import styled from 'styled-components';

export const Confetti = styled.div`
  position: absolute;
  align-self: center;
  top: -17.5rem;
  width: 55rem;
  margin: auto;
  height: auto;
  z-index: -1;
`;
