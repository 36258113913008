import { Button, ButtonVariants, Panel } from '@Cortex';
import React from 'react';
import ReactModal from 'react-modal';

import { baseStyles, CLOSE_TIMEOUT_MS } from '../../../../modals/modalStyles';
import { StreakMilestone } from '../../../../types';
import { Assets } from '../../../../utils/assets';
import { getNextAchievementDescription } from './lib/getNextAchievementDescription';
import * as S from './StreakMilestoneModal.styles';

export type Props = {
  streakMilestone: StreakMilestone;
  nextAchievement: StreakMilestone | null;
  isOpen: boolean;
  onClose: () => void;
  primaryButtonAction: () => void;
  primaryButtonText: string;
};

export function StreakMilestoneModalDisplay(props: Props) {
  const {
    streakMilestone,
    isOpen,
    onClose,
    primaryButtonAction,
    primaryButtonText,
    nextAchievement,
  } = props;
  return (
    <ReactModal
      closeTimeoutMS={CLOSE_TIMEOUT_MS}
      isOpen={isOpen}
      style={baseStyles}
      onRequestClose={onClose}
    >
      <S.Overlay>
        <S.ModalWrapper>
          <Panel
            padding="2rem 0"
            style={{
              background:
                'linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              overflow: 'visible',
            }}
          >
            <S.ModalContent>
              <S.BackgroundImage
                alt={Assets.images.congrats.alt}
                src={Assets.images.congrats.url}
              />
              <S.Image
                alt={Assets.images.badges.streak[streakMilestone].alt}
                src={Assets.images.badges.streak[streakMilestone].url}
              />

              <S.TextContainer>
                <S.Title>Congratulations!</S.Title>
                <S.Description>
                  You've just earned your {streakMilestone}-week streak badge!
                </S.Description>
              </S.TextContainer>
              {nextAchievement && (
                <S.Description>
                  {getNextAchievementDescription(streakMilestone, nextAchievement)}
                </S.Description>
              )}
              <S.ButtonWrapper>
                <Button
                  data-testid="successButtonPrimary"
                  isFullWidth
                  keepTextCase
                  style={{ fontSize: '0.75rem', letterSpacing: '1.2px' }}
                  variant={ButtonVariants.Secondary}
                  onClick={primaryButtonAction}
                >
                  {primaryButtonText}
                </Button>
              </S.ButtonWrapper>
              <S.AuraImage alt={Assets.images.blueAura.alt} src={Assets.images.blueAura.url} />
            </S.ModalContent>
          </Panel>
        </S.ModalWrapper>
      </S.Overlay>
    </ReactModal>
  );
}
