import { NOOP } from '@Globals';
import { RefObject } from 'react';

import * as S from './SearchBar.styles';
import CloseIcon from './assets/close.svg';
import SearchIcon from './assets/search.svg';

interface Props {
  handleCloseIconClick?: () => void;
  handleSearchIconClick?: () => void;
  inputRef: RefObject<HTMLInputElement>;
  inputValue: string;
  isActive: boolean;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputFocus?: () => void;
  testId?: string;
}

export const SearchBarDisplay = ({
  handleCloseIconClick = NOOP,
  handleSearchIconClick = NOOP,
  inputRef,
  inputValue,
  isActive = false,
  onInputChange,
  onInputFocus = NOOP,
  testId = 'exploreSearchInput',
}: Props) => {
  return (
    <S.Container isExpanded={true}>
      {isActive ? (
        <S.IconButton
          data-testid="exploreSearchCloseButton"
          isExpanded={true}
          onClick={handleCloseIconClick}
        >
          <S.Icon src={CloseIcon} />
        </S.IconButton>
      ) : (
        <S.IconButton isExpanded={true} onClick={handleSearchIconClick}>
          <S.Icon src={SearchIcon} />
        </S.IconButton>
      )}
      <S.Input
        ref={inputRef}
        data-testid={testId}
        placeholder="Search by name, genre, instrumentation or mood..."
        type="text"
        value={inputValue}
        onChange={onInputChange}
        onFocus={onInputFocus}
      />
    </S.Container>
  );
};
