import { Serving } from '@Model';
import { APIResponse } from '@Utils';
import { Action } from '@reduxjs/toolkit';
import { delay, put, select } from 'redux-saga/effects';
import { RootReducerType } from 'src/reducers';

import { RequestMethods } from '../../../api/client/types';
import { requestSaga } from '../../../sagas/httpRequest';
import { Logger } from '../../../utils/logger';
import { dynamicSearchTracks } from '../actions';
import { musicActions } from '../reducer';

export function* dynamicSearchTracksSaga(action: Action) {
  if (dynamicSearchTracks.match(action)) {
    try {
      if (action.payload.query.length < 1) {
        yield put(musicActions.setSearchState({ state: 'idle', results: null, error: null }));
        return;
      }

      const exploreMentalState: RootReducerType['ui']['sideDeck']['exploreSelectedMentalState'] =
        yield select((state: RootReducerType) => state.ui.sideDeck.exploreSelectedMentalState);

      yield put(musicActions.setSearchState({ state: 'loading' }));

      const { result } = (yield requestSaga(
        RequestMethods.GET,
        `/servings/search?query=${action.payload.query}&dynamicMentalStateId=${exploreMentalState?.id}`,
        null,
        3,
      )) as APIResponse<Serving[]>;

      yield put(musicActions.setSearchState({ state: 'idle', results: result, error: null }));
    } catch (error) {
      Logger.error(new Error('Could not get search results'), { reason: error });
      yield put(
        musicActions.setSearchState({ state: 'error', error: 'Could not get search results' }),
      );
    } finally {
      yield delay(2000);
      yield put(musicActions.setSearchState({ state: 'idle', error: null }));
    }
  }
}
