import { useSelector } from 'react-redux';
import { RootReducerType } from 'src/reducers';

import { activities } from '../../../utils/activities';

export function useActivityFilters() {
  const selectedActivityNames = useSelector(selectActivityFilters);
  return activities.byArray.filter(x => selectedActivityNames.includes(x.display));
}

function selectActivityFilters(state: { music: RootReducerType['music'] }) {
  return state.music.activityFilters;
}
