import styled, { keyframes, css } from 'styled-components';

import { FontColors, TextBold, TextSemiBold } from '@Cortex';
import { TransitionStatus } from 'react-transition-group';
import { TrackInfoModalVariant } from './TrackInfoModal';

export const Overlay = styled.div<{ isMobileFixed: boolean; variant: TrackInfoModalVariant }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: ${({ variant }) =>
    variant === TrackInfoModalVariant.MoreInfo ? 'flex-end' : 'center'};
  align-items: flex-end;
  z-index: 100;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    justify-content: center;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    ${({ isMobileFixed }) =>
      isMobileFixed
        ? css`
            position: fixed;
            width: 100vw;
            left: 0;
            bottom: 0;
            z-index: 120;
          `
        : ''};
  }
`;

export const ContentWrapper = styled.div<{
  isOpen: boolean;
  width?: number;
  variant: TrackInfoModalVariant;
}>`
  display: flex;
  position: sticky;
  bottom: 0;
  width: 100%;
  max-width: ${({ width }) => (width ? `${width}px` : '100%')};
  flex-direction: row;
  max-height: 90%;
  animation: ${({ isOpen }) => (isOpen ? slideUp : slideDown)} 0.3s ease-in-out;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
  padding: 2rem 1rem;
  overflow-y: auto;
  margin-right: ${({ variant }) => (variant === TrackInfoModalVariant.MoreInfo ? '3rem' : '0rem')};
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  z-index: 100;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    margin-right: 0;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

export const FadeInWrapper = styled.div<{ animationState: TransitionStatus }>`
  overflow: auto;
  animation: fadeInDown 0.3s ease-in-out;
  visibility: ${({ animationState }) => (animationState === 'entered' ? 'visible' : 'hidden')};
  padding-right: 0.25rem;
  padding-bottom: 12px;
  margin-bottom: 48px;
  width: 100%;

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const TrackInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
`;

export const TrackImageContainer = styled.div`
  position: relative;
`;

export const TrackImage = styled.img`
  height: 96px;
  width: 96px;
  border-radius: 0.5rem;
  box-shadow: 0px 12px 28px 0px rgba(0, 0, 0, 0.5);
  object-fit: cover;
  aspect-ratio: 1 / 1;
`;

export const TrackDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: flex-start;
`;

export const TrackInfoDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const TrackAttributes = styled.div``;

export const FrequencyTitleSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const FrequencyLevelDescription = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.875rem;
  text-transform: capitalize;
`;

export const FrequencyLevelContainer = styled.div`
  width: 100%;
  height: 8px;
  margin-top: 0.5rem;
  border-radius: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  align-items: center;
  justify-content: center;
`;

export const FrequencyLevelBar = styled.div<{ level: string }>`
  width: ${({ level }) =>
    level.toLowerCase() === 'high'
      ? '100%'
      : level.toLocaleLowerCase() === 'medium'
        ? '66%'
        : '33%'};
  height: 8px;
  border-radius: 32px;
  background-color: white;
  color: red;
`;

export const TrackDetailsTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const TrackDetailsTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-weight: 700;
  color: ${FontColors.White};
  font-size: 2rem;
  line-height: 36px;
  letter-spacing: -0.32px;
`;

export const TrackDetailsNeuralEffect = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: 500;
  font-size: 0.875rem;
  color: ${FontColors.White};
`;

export const TrackDetailsGenreAndBPM = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: 500;
  font-size: 0.6875rem;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
`;

export const NewTag = styled.div`
  display: flex;
  height: 17px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: white;
  padding: 4px 4px 3px 4px;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: 10px;
  color: ${FontColors.Black};
  font-weight: 700;
  letter-spacing: 1px;
`;

export const TrackActions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 1.5rem;
  justify-content: space-around;
`;

export const TrackAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: 500;
  font-size: 0.875rem;
  color: ${FontColors.WhiteTransparent};
`;

export const TrackActionButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  opacity: 0.75;
  cursor: pointer;
  outline: none;
  width: fit-content;
`;

export const SectionTitle = styled(TextBold)`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-weight: 700;
  font-size: 0.75rem;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: ${FontColors.WhiteTransparent};
  margin-bottom: 8px;
`;

export const SectionDescription = styled(TextSemiBold)`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: 400;
  color: ${FontColors.White};
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-transform: capitalize;
`;

export const ActivityContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 2rem;
`;

export const ActivitiesSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 8px;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1rem;
`;

export const ActivityViewMoreContainer = styled.div`
  display: flex;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  margin-top: 8px;
  cursor: pointer;
`;

export const ActivityViewMoreInnerContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;

  &:hover {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.08) 100%
    );
  }
`;

export const ViewMoreText = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 0.875;
  font-weight: 500;
  text-transform: none;
  width: 70%;
`;

export const Activity = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ActivityHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const ActivityHeaderTitle = styled(TextBold)``;

export const EmptyIcon = styled.div`
  opacity: 0;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
`;
