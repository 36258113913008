import styled from 'styled-components';

import MoreInfoSVG from './MoreInfo.svg?react';

export const MoreInfo = styled(MoreInfoSVG)`
  stroke-width: 1.5;
  transition:
    transform 0.15s ease-in-out,
    fill-opacity 0.15s ease-in-out,
    stroke-width 0.15s ease-in-out;

  &:hover {
    stroke-width: 2;
    transform: scale(1.03);
  }
`;
