import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  UserMembershipType,
  UserInfoType,
  UserStateType,
  UserUsage,
  UserInteractionType,
  StreaksType,
  VerificationStatus,
  UserEmailVerificationType,
} from '../types/user';

export const initialState: UserStateType = {
  info: {
    id: '',
    email: '',
    firstName: '',
    age: null,
    gender: null,
    partnerId: null,
    enterpriseId: null,
  },
  streaks: {
    data: {
      daily: {
        currentCount: 0,
      },
      weekly: {
        currentCount: 0,
        comment: '',
        longestCount: 0,
      },
    },
  },
  membership: {
    membershipTypeId: null,
    isActive: null,
    isCancelled: null,
    isOverdue: null,
    isPaused: null,
    isInGracePeriod: null,
    expirationDate: '',
    gracePeriodExpirationDate: '',
    renewalCount: 0,
    createdAt: '',
    productId: null,
    title: '',
    source: null,
    interval: null,
    length: 0,
    type: '',
  },
  verification: {
    status: VerificationStatus.Excluded,
    isVerified: false,
  },
  usage: {
    totalSessionsPlayed: 0,
    totalMinutesPlayed: 0,
  },
  downloadsByType: {
    focus: [],
    relax: [],
    sleep: [],
  },
  interactions: {},
  lastPlayedActivities: {},
  lastPlayedDynamicActivities: {},
  status: 'idle',
  updatedAt: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserInfo: state => {
      state.status = 'loading';
      return state;
    },

    // set data

    setUserInfo: (state, action: PayloadAction<UserInfoType>) => {
      state.info = action.payload;
      state.updatedAt = Date.now();
      state.status = 'idle';
      return state;
    },

    setStreaks: (state, action: PayloadAction<StreaksType['data']>) => {
      state.streaks.data = action.payload;
      return state;
    },

    setMembershipInfo: (state, action: PayloadAction<UserMembershipType>) => {
      state.membership = action.payload;
      state.updatedAt = Date.now();
      state.status = 'idle';
      return state;
    },

    setEmailVerificationInfo: (state, action: PayloadAction<UserEmailVerificationType>) => {
      state.verification = action.payload;
      return state;
    },

    setUserUsage(state, action: PayloadAction<UserUsage>) {
      state.usage = action.payload;
      return state;
    },

    updateUserHistory(state, action: PayloadAction<number>) {
      return {
        ...state,
        usage: {
          totalSessionsPlayed: state.usage.totalSessionsPlayed + 1,
          totalMinutesPlayed: state.usage.totalMinutesPlayed + action.payload / 60,
        },
      };
    },

    setInteraction(state, action: PayloadAction<UserInteractionType>) {
      state.interactions[action.payload] = Date.now();

      return state;
    },

    setPlayedActivity: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.lastPlayedActivities = {
        ...state.lastPlayedActivities,
        ...action.payload,
      };

      return state;
    },

    setPlayedDynamicActivity: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.lastPlayedDynamicActivities = {
        ...state.lastPlayedDynamicActivities,
        ...action.payload,
      };

      return state;
    },

    // clear
    clearUserState: () => {
      return initialState;
    },
  },
});

export const userSliceActions = userSlice.actions;
export default persistReducer<UserStateType>(
  {
    key: 'user',
    storage,
    migrate: state => Promise.resolve(state),
  },
  userSlice.reducer,
);
