import { useDispatch } from 'react-redux';
import { FontFamilies } from '@Cortex';

import * as S from './styles';
import Icon from './assets/new_music_new_activities.svg';

import { GreenPulse } from '../GreenPulse';
import { setPlayerWidget } from '../../../../actions/ui';
import { SideDeckTab } from '../../../../types';
import { uiSliceActions } from '../../../../reducers/uiReducer';
import { Analytics } from '../../../../utils/analytics';

export const NewMusicWidget = () => {
  const dispatch = useDispatch();

  const handleNewMusicClick = () => {
    Analytics.logEventWithProperties('hear_whats_new_widget_clicked', {});

    dispatch(uiSliceActions.setSideDeckTab({ tab: SideDeckTab.Explore }));
    dispatch(setPlayerWidget(null));
  };

  return (
    <S.Container onClick={handleNewMusicClick}>
      <S.Icon src={Icon} />
      <S.SText family={FontFamilies.Bold} size={14}>{`HEAR WHAT'S NEW`}</S.SText>

      <S.Pulse>
        <GreenPulse />
      </S.Pulse>
    </S.Container>
  );
};
