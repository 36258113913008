import { TrackActionOrigins } from '@Analytics';
import { NOOP } from '@Globals';
import {
  addToDislikes,
  addToFavorites,
  CurrentTrackInformationCard,
  getTrackGenreName,
  getTrackId,
  getTrackImageUrl,
  getTrackIsNewlyCreated,
  getTrackName,
  getTrackNeuralEffectLevel,
  isTrackInDislikes,
  removeFromDislikes,
  removeFromFavorites,
  useIsTrackInFavorites,
} from '@Music';
import { getTrackVariationId } from '@Music';
import { useUser } from '@User';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useShareTrack } from '../../../../../../hooks/useShareTrack';
import { RootReducerType } from '../../../../../../reducers';
import { MentalStates } from '../../../../../../types';
import { useDynamicSession } from '../../../../../Session/lenses/useDynamicSession';
import * as S from './TrackInformation.styles';

interface Props {
  onClick: () => void;
  isFavoritingDisabled: boolean;
  isDislikingDisabled: boolean;
}

export const TrackInformation = ({
  onClick = NOOP,
  isFavoritingDisabled,
  isDislikingDisabled,
}: Props) => {
  const dispatch = useDispatch();
  const session = useDynamicSession();
  const user = useUser();
  const { handleGetShareLink } = useShareTrack();

  const currentTrack = useSelector((state: RootReducerType) => state.music.currentTrack);
  const currentTrackId = getTrackId(currentTrack);

  const isCurrentTrackInDislikes = useSelector(
    isTrackInDislikes({ id: currentTrack ? currentTrackId : '' }),
  );
  const isCurrentTrackInFavorites = useIsTrackInFavorites(currentTrackId);

  const handleDislikeClick = () => {
    // disliking is track-based only
    if (!currentTrack) return;

    if (isCurrentTrackInDislikes) {
      dispatch(removeFromDislikes({ trackId: currentTrackId }));
    } else {
      dispatch(addToDislikes({ track: currentTrack }));
      window.setTimeout(() => window.dispatchEvent(new Event('skip-keep-play-state')), 1000);
    }
  };

  const handleFavoriteClick = () => {
    if (!currentTrack) return;

    if (isCurrentTrackInFavorites) {
      dispatch(
        removeFromFavorites({
          track: currentTrack,
        }),
      );
    } else {
      dispatch(addToFavorites({ origin: TrackActionOrigins.TrackPlayer, track: currentTrack }));
    }
  };

  const onShareTrack = useCallback(() => {
    if (!currentTrack) return;
    handleGetShareLink(currentTrack);
  }, [handleGetShareLink, currentTrack]);

  return (
    <S.PlayerContainer onClick={onClick}>
      {currentTrack ? (
        <CurrentTrackInformationCard
          dislikeButtonTestId={
            isCurrentTrackInDislikes
              ? `removeFromDislikes__${currentTrackId}`
              : `addToDislikes__${currentTrackId}`
          }
          genre={getTrackGenreName(currentTrack)}
          imageUrl={getTrackImageUrl(currentTrack)}
          isDisliked={isCurrentTrackInDislikes}
          isDislikingDisabled={isDislikingDisabled}
          isFavorited={isCurrentTrackInFavorites}
          isFavoritingDisabled={isFavoritingDisabled}
          isNewlyCreated={getTrackIsNewlyCreated(currentTrack)}
          neuralEffectLevel={getTrackNeuralEffectLevel(currentTrack)}
          title={getTrackName(currentTrack)}
          onAddToDislikes={handleDislikeClick}
          onAddToFavorites={handleFavoriteClick}
          onRemoveFromDislikes={handleDislikeClick}
          onRemoveFromFavorites={handleFavoriteClick}
          onShareTrack={onShareTrack}
        />
      ) : (
        <S.NoTracksText data-testid="noTracksMessage">
          {Boolean(
            session &&
              (user.mentalStatePreferences[session.mentalState.id as MentalStates.Focus].genreNames
                .length ||
                user.mentalStatePreferences[session.mentalState.id as MentalStates.Focus]
                  .neuralEffectLevels.length),
          )
            ? 'No tracks found matching your preferences.'
            : 'No Tracks Loaded'}
        </S.NoTracksText>
      )}
    </S.PlayerContainer>
  );
};
