import { FontColors, Text } from '@Cortex';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const Title = styled(Text)`
  color: ${FontColors.WhiteSecondary};
  transition: color 0.2s ease;
  font-size: 14px;
  text-transform: capitalize;
`;

export const Input = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Checkbox = styled.span<{ isChecked: boolean }>`
  margin-left: 1rem;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  justify-content: center;
  object-fit: contain;
  transition: border 0.2s ease;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover ${Title} {
    color: ${FontColors.White};
  }
`;
