import styled from 'styled-components';

export const Button = styled.button`
  width: 100%;
  align-items: center;
  border-radius: 100px;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 0.75rem 1rem;
  min-height: 3rem;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 0.875rem;
  outline: none;

  transition:
    background 0.2s ease-in-out,
    color 0.15s ease-in-out;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
`;

export const Container = styled.button`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
`;

export const CarouselItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
`;

export const CarouselItemContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
`;
