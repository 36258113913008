import { SessionType } from '../sessionManager';

export interface UserMembershipType {
  createdAt: string;
  expirationDate: string;
  gracePeriodExpirationDate: string;
  interval: 'Days' | 'Months' | 'Years' | 'Lifetime' | null;
  isActive: boolean | null;
  isCancelled: boolean | null;
  isInGracePeriod: boolean | null;
  isOverdue: boolean | null;
  isPaused: boolean | null;
  length: number | null;
  membershipTypeId: number | null;
  productId: string | null;
  renewalCount: number | null;
  source: 'Stripe' | 'Apple' | 'Google' | 'GiftUp' | null;
  title: string;
  type: string;
}

export interface UserInfoType {
  id: string;
  email: string;
  firstName: string;
  age: number | null;
  gender: number | null;
  partnerId: number | null;
  enterpriseId: number | null;
}

export enum VerificationStatus {
  Unverified = 'unverified',
  Verified = 'verified',
  Excluded = 'excluded',
}

export interface UserEmailVerificationType {
  isVerified: boolean;
  status: VerificationStatus;
}
export const FAVORITES_MILESTONES = [0, 1, 3, 5, 10, 25, 50, 100] as const;
export type FavoritesMilestone = (typeof FAVORITES_MILESTONES)[number];

export const STREAK_MILESTONES = [0, 1, 3, 5, 10, 25, 50, 100] as const;
export const STREAK_MILESTONES_SHOW_MILESTONE_MODAL = [3, 5, 10, 25, 50, 100] as const;
export type StreakMilestone = (typeof STREAK_MILESTONES)[number];

export interface StreaksType {
  data: {
    daily: {
      currentCount: number;
    };
    weekly: {
      currentCount: number;
      comment: string;
      longestCount: number;
    };
  };
}

export interface UserUsage {
  totalMinutesPlayed: number;
  totalSessionsPlayed: number;
}

export type UserInteractionType =
  | 'timer_modal'
  | 'explore_modal'
  | 'onboarding_welcome'
  | 'session_pro_tips';
export type UserInteractions = {
  [key in UserInteractionType]?: number;
};

interface ByType {
  focus: [];
  relax: [];
  sleep: [];
}

export interface UserStateType {
  info?: UserInfoType;
  streaks: StreaksType;
  membership?: UserMembershipType;
  verification?: UserEmailVerificationType;
  downloadsByType: ByType;
  usage: UserUsage;
  interactions: UserInteractions;
  lastPlayedActivities: {
    [key in SessionType]?: string;
  };
  lastPlayedDynamicActivities: {
    [key: string]: string;
  };
  status: 'loading' | 'idle';
  updatedAt: number;
}
