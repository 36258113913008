import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as Yup from 'yup';

import { get, post } from '../../../../api/client/client';
import { useQuery } from '../../../../hooks';
import { RootReducerType } from '../../../../reducers';
import { getGAEvent } from '../../../../utils/analytics/ga';

import { Input } from '../Input';
import { Button, ButtonVariants } from '@Cortex';
import * as S from './styles';
import { Assets } from '../../../../utils/assets';
import { NavigateFunction } from 'react-router-dom';

const validationSchema = Yup.object({
  password: Yup.string().required('Type in your new password'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Make sure passwords match')
    .required('Confirm your new password'),
});

type ResetPasswordFormState = 'loading' | 'error' | 'success' | 'idle';
type Props = {
  navigate: NavigateFunction;
};

export const ResetPasswordForm: React.FC<Props> = ({ navigate }) => {
  const query = useQuery();
  const { token } = useSelector((state: RootReducerType) => state.auth);
  const [error, setError] = useState('');
  const [status, setStatus] = useState<ResetPasswordFormState>('idle');
  const queryToken = query.get('token');

  useEffect(() => {
    (async () => {
      const { status, messages } = await get<{ status: number; messages: string[] }>({
        path: `/auth/reset-password?token=${queryToken}`,
        token,
      });

      if (status !== 200) {
        setError(messages.join(' '));
        setStatus('error');
      } else {
        setError('');
        setStatus('idle');
      }
    })();
  }, [queryToken, token]);

  const handleFormSubmit = async (password: string) => {
    setStatus('loading');

    const { status, messages } = await post<
      { password: string },
      { status: number; messages: string[] }
    >({
      path: `/auth/reset-password?token=${query.get('token')}`,
      body: { password },
      token,
    });

    if (status !== 200) {
      setError(messages.join(' '));
      setStatus('error');
    } else {
      setStatus('success');
    }
  };

  return (
    <S.Container>
      <S.HeaderContainer>
        <S.Image src="https://cdn.brain.fm/images/reset_password_header.webp" />
        {status === 'success' ? (
          <>
            <S.FormHeader>Password has been reset</S.FormHeader>
            <S.Text>Proceed to sign in screen to log in.</S.Text>
          </>
        ) : (
          <>
            <S.FormHeader>Reset Password</S.FormHeader>
            <S.Text />
          </>
        )}
      </S.HeaderContainer>

      {status !== 'success' ? (
        <Formik
          initialValues={{ password: '', repeatPassword: '' }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validationSchema}
          onSubmit={values => handleFormSubmit(values.password)}
        >
          {({ handleChange, handleSubmit, handleBlur, values, errors, touched }) => {
            return (
              <S.Form onSubmit={handleSubmit}>
                <Input
                  key="password"
                  name="password"
                  placeholder="New Password"
                  type="password"
                  value={values.password}
                  variant="first"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <S.InputSpacer />
                <Input
                  key="repeat-password"
                  name="repeatPassword"
                  placeholder="Repeat New Password"
                  type="password"
                  value={values.repeatPassword}
                  variant="last"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <S.FormErrorTextContainer>
                  {(touched.password || touched.repeatPassword) &&
                  (errors.password || errors.repeatPassword) ? (
                    <S.FormErrorText>
                      {errors.password} {errors.repeatPassword}
                    </S.FormErrorText>
                  ) : null}
                </S.FormErrorTextContainer>

                <S.ButtonWrapper>
                  <Button
                    analyticsEvent={getGAEvent('Signin', 'reset_password', 'set-new-password')}
                    disabled={status === 'loading'}
                    isFullWidth
                    keepTextCase
                    type="submit"
                    variant={ButtonVariants.Secondary}
                  >
                    SUBMIT
                  </Button>
                </S.ButtonWrapper>
              </S.Form>
            );
          }}
        </Formik>
      ) : null}

      {status === 'success' ? (
        <S.ButtonWrapper>
          <Button
            analyticsEvent={getGAEvent('Session', 'reset_password', 'success')}
            isFullWidth
            keepTextCase
            variant={ButtonVariants.Secondary}
            onClick={() => navigate('/signin', { replace: true })}
          >
            SIGN IN
          </Button>
        </S.ButtonWrapper>
      ) : null}

      <S.ErrorContainer error={!!error}>
        {error && (
          <>
            <S.ErrorText>{error}</S.ErrorText>
            <S.ErrorCloseButton onClick={() => setError('')}>
              <S.ErrorCloseImage alt={Assets.icons.close.alt} src={Assets.icons.close.url} />
            </S.ErrorCloseButton>
          </>
        )}
      </S.ErrorContainer>
    </S.Container>
  );
};
