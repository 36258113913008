import styled from 'styled-components';

export const Container = styled.div<{ isSelected: boolean }>`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  border-radius: 0.75rem;
  background: ${({ isSelected }) => (isSelected ? 'rgba(255,255,255,0.1)' : 'transparent')};
  outline: ${({ isSelected }) =>
    isSelected ? '2px solid #FFFFFF' : '1px solid rgba(255,255,255,0.1)'};

  transition:
    background 0.2s ease-in-out,
    opacity 0.2s ease-in-out,
    outline 0.2s ease-in-out;
  min-height: 6rem;
  cursor: pointer;
  position: relative;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    opacity: 1;
  }

  &:active {
    background: #333152;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
`;

export const Radio = styled.div<{ isSelected: boolean }>`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 60px;
  border: ${({ isSelected }) =>
    isSelected ? '1px solid rgba(255, 255, 255, 0.5)' : '1px solid rgba(255, 255, 255, 0.1)'};
  background-color: ${({ isSelected }) =>
    isSelected ? 'rgba(255, 255, 255, 0.2)' : 'transparent'};
  position: relative;
  justify-content: center;
  align-items: center;

  transition:
    background-color 0.2s ease-in-out,
    opacity 0.2s ease-in-out,
    border 0.2s ease-in-out;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${({ isSelected }) => (isSelected ? '#ffffff' : 'transparent')};
    border-radius: 8px;
    transition: background-color 0.2s ease-in-out;
    box-shadow: ${({ isSelected }) => (isSelected ? '0px 1px 5px #353538' : 'none')};
  }
`;

export const Title = styled.h6`
  align-items: center;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 1rem;
  color: #ffffff;
  display: flex;
`;

export const Description = styled.p`
  margin-top: 4px;
  color: rgba(255, 255, 255, 0.75);
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const NeuralEffectLevelIcon = styled.div`
  margin-right: 0.5rem;
`;
