import { FontFamilies, TextBold, TextSemiBold } from '@Cortex';
import styled, { css, keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
`;

export const Container = styled.div<{ isSelected: boolean }>`
  height: 5.85rem;
  min-height: 96px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ isSelected }) =>
    isSelected ? '0 0 0 2px #FFFFFF' : '0 0 0 1px rgba(255, 255, 255, 0.2)'};
  overflow: hidden;
  border-radius: 12px;

  transition:
    box-shadow 0.2s ease-in-out,
    border-width 0.2s ease-in-out;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 4px;
`;

export const BottomContainer = styled.div<{ isSelected: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 24px;
  font-family: ${FontFamilies.Regular};
  font-weight: 500;

  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out,
    border-top 0.2s ease-in-out;

  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        border-top: 1px solid #ffffff;
        background-color: #ffffff;
        color: #000000;
      `;
    } else {
      return css`
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        background-color: rgba(255, 255, 255, 0.1);
        color: #ffffff;
      `;
    }
  }};
`;

export const PlanName = styled(TextBold)`
  font-size: 10px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.75);
  letter-spacing: 11;
`;

export const PlanTrialLength = styled(TextSemiBold)`
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;
`;

export const PlanTotalCost = styled(TextBold)`
  font-size: 10px;
  color: rgba(255, 255, 255, 0.75);
  letter-spacing: 11;
`;

export const Save = styled.div`
  background: linear-gradient(139.73deg, #9130bb -19.11%, #f34675 86.25%, #f39946 203.68%);
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  padding: 4px 8px;
  color: #ffffff;
  z-index: 1;
  text-transform: uppercase;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px 6px;
  align-self: center;
  animation: ${fadeIn} 0.3s ease-in-out;
`;
