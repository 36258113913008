import { TextBold } from '@Cortex';
import styled from 'styled-components';

export const Image = styled.img`
  position: absolute;
  object-fit: contain;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
`;

export const AuraImage = styled.img`
  position: absolute;
  object-fit: contain;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out,
    transform 0.3s ease-in-out;
`;

export const Container = styled.div`
  height: 133px;
  overflow: hidden;
  position: relative;
  flex: 1;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  padding: 2rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: flex-end;

  &:hover ${Image} {
    opacity: 0.5;
    transform: scale(1.05);
  }

  &:hover ${AuraImage} {
    opacity: 1;
    transform: scale(1.05);
  }
`;

export const Body = styled.div`
  z-index: 99;
`;

export const Title = styled(TextBold)`
  font-size: 20px;
  line-height: 24px;
`;

export const ImageContainer = styled.div`
  position: absolute;
  width: 240px;
  height: 240px;
  top: -9.5%;
  right: -13.75%;
  z-index: 1;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    width: 200px;
    height: 200px;
    top: -20%;
    right: -5%;
  }
`;
