import { TextSpaced } from '@Cortex';
import styled, { keyframes } from 'styled-components';

export const redAura = {
  outer: `linear-gradient(313.03deg, #F34675 34.15%, rgba(243, 70, 117, 0) 92.55%)`,
  inner: `linear-gradient(232.01deg, rgba(255, 0, 31, 0.8) -3.6%, rgba(207, 61, 87, 0.8) 68.92%)`,
};
export const normalAura = {
  outer:
    'linear-gradient(187.11deg, rgba(208, 26, 234, 0.8) 19.55%, rgba(44, 168, 234, 0.8) 87.99%)',
  inner:
    'linear-gradient(240.5deg, rgba(208, 26, 234, 0.8) 16.31%, rgba(44, 168, 234, 0.8) 67.25%)',
};

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  border-radius: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2rem;
  width: 100%;

  // safari has a bug with overflow:hidden on absolutely positioned children;
  // this is a workaround for it.
  // https://stackoverflow.com/questions/44948735/overflow-hidden-is-not-working-with-absolute-element-in-safari
  transform: translateZ(0);
`;

const backgroundAnimation = keyframes`
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const Header = styled.div`
  display: flex;
  font-family: TTNormsPro-Regular;
  margin-bottom: 1.5rem;
  align-items: center;
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const HeaderTopElements = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderIcon = styled.div`
  margin-right: 1rem;
  position: relative;
  z-index: 1; // see note at top
`;

export const IconBackground = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
`;

export const HeaderText = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1.25rem;
  width: ${props => props.width};
  z-index: 2; // see note at top
`;

export const HeaderTextTitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1.25rem;
`;

export const HeaderTextSubtitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  color: #d4d2ea;
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;
export const SubscriptionInfoText = styled.div`
  color: #d4d2ea;
  font-weight: 300;
  font-size: 0.875rem;
  letter-spacing: 0.1rem;
`;

export const Tag = styled(TextSpaced)`
  background: #f34675;
  border-radius: 2.75rem;
  padding: 0.25rem 0.5rem;
  text-align: center;
  text-transform: uppercase;
`;

export const HeaderIconAura = styled.div`
  position: absolute;
  top: -100%;
  left: -100%;
  z-index: -1; // see note at top
`;

export const Children = styled.div`
  text-transform: initial;
  z-index: 2; // see note at top
`;

export const ManageButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    margin-top: 1rem;
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
`;
