import { PlanTypes } from '../../../../Analytics/coreAnalytics.types';

export const getPlanTypeFromDisplayInterval = (displayInterval: 'mo' | 'yr'): PlanTypes => {
  switch (displayInterval) {
    case 'mo':
      return PlanTypes.Monthly;
    case 'yr':
    default:
      return PlanTypes.Yearly;
  }
};
