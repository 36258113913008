import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../../../../../actions/ui';

export function useOpenUpgradeStripeSubscriptionModal() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(setModal('upgradeStripeSubscription'));
  }, [dispatch]);
}
