import { Button, ButtonVariants } from '@Cortex';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  OnboardingQuizAnswer,
  OnboardingQuizQuestion,
  OnboardingQuizAnswerType,
} from '../../types';
import { Answer } from '../Answer';
import { CardWrapperTwo } from '../CardWrapperTwo';
import BrandLogo from '../../../../assets/images/brand_logo.svg';
import * as S from './QuestionCard.styles';
import { CoreAnalytics } from '@Analytics';
import { MusicAnswer } from '../MusicAnswer';
import { OnboardingVolumeControl } from '../OnboardingVolumeControl';
import { isMusicAnswer } from '../../utils/isMusicAnswer';

export type Props = {
  answers: OnboardingQuizAnswerType[];
  activeStepIndex: number;
  hasPrivacyNotice?: boolean;
  image?: string;
  numberOfSteps: number;
  questionIndex: number;
  title?: string;
  description?: string;
  secondaryButtonText?: string;
  stepText?: string;
  onClickBack?: () => void;
  onClickContinue: (
    questionIndex: number,
    answerIndex: number,
    responseCards?: OnboardingQuizAnswer['responseCards'],
    testimonials?: OnboardingQuizAnswer['testimonials'],
    benefits?: OnboardingQuizAnswer['benefits'],
    dynamicActivityId?: OnboardingQuizAnswer['dynamicActivityId'],
    userPropertyToSet?: OnboardingQuizAnswer['userPropertyToSet'],
  ) => () => void;
};

export const QuestionCard = (props: Props) => {
  const {
    answers,
    activeStepIndex,
    hasPrivacyNotice,
    image,
    questionIndex,
    numberOfSteps,
    title,
    description,
    secondaryButtonText,
    stepText,
    onClickBack,
    onClickContinue,
  } = props;
  const audioRef = useRef(new Audio());
  audioRef.current.loop = true;
  audioRef.current.volume = 0.8;
  const [selectedAnswer, setSelectedAnswer] = useState<OnboardingQuizAnswerType | undefined>(
    undefined,
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentlyTrackIndex, setCurrentlyTrackIndex] = useState<number | null>(null);

  useEffect(() => {
    CoreAnalytics.trackOnboardingQuizQuestionImpressionEvent({
      title: title ?? '',
      body: description ?? '',
      step: stepText ?? '',
    });
  }, []);

  const handleOnClickPlay = useCallback(
    (trackUrl: string, index: number) => {
      if (isPlaying && currentlyTrackIndex === index) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.src = trackUrl;
        audioRef.current.play();
        setIsPlaying(true);
      }
      setCurrentlyTrackIndex(index);
    },
    [audioRef.current, isPlaying, currentlyTrackIndex, setIsPlaying],
  );

  const handleVolumeChange = useCallback(
    (volume: number) => {
      audioRef.current.volume = volume;
    },
    [audioRef.current],
  );

  const handleClickContinue = useCallback(() => {
    onClickContinue(
      questionIndex,
      answers.findIndex(answer => answer === selectedAnswer),
      selectedAnswer?.responseCards,
      selectedAnswer?.testimonials,
      selectedAnswer?.benefits,
      selectedAnswer?.dynamicActivityId,
      selectedAnswer?.userPropertyToSet,
    )();

    if (isPlaying) {
      audioRef.current.pause();
    }
  }, [questionIndex, selectedAnswer, answers, onClickContinue, setIsPlaying]);

  return (
    <CardWrapperTwo>
      <S.ContainerRow>
        <S.Logo src={BrandLogo} />
        {activeStepIndex !== null && numberOfSteps !== null && numberOfSteps !== 1 ? (
          <S.StepSection>
            <S.StepText>{stepText || 'Quiz'}</S.StepText>
            <S.StepPills>
              {[...Array(props.numberOfSteps)].map((_, index) => (
                <S.StepPill
                  key={index}
                  isActive={index === props.activeStepIndex}
                  isCompleted={index <= props.activeStepIndex}
                />
              ))}
            </S.StepPills>
          </S.StepSection>
        ) : null}
        <S.LogoPlaceholder src={BrandLogo} />
      </S.ContainerRow>

      <S.Content>
        <S.ContainerColumn>
          {title ? <S.Title>{title}</S.Title> : null}
          {description ? <S.Body>{description}</S.Body> : null}
          {answers.every(a => isMusicAnswer(a)) ? (
            <S.MusicAnswers>
              {answers.map((a, answerIndex) =>
                isMusicAnswer(a) ? (
                  <MusicAnswer
                    key={`${a.title}${a.description}`}
                    description={a.description}
                    imageUrl={a.imageUrl}
                    isPlaying={isPlaying && currentlyTrackIndex === answerIndex}
                    selected={a.title === selectedAnswer?.title}
                    title={a.title}
                    onClick={() => setSelectedAnswer(answers[answerIndex])}
                    onPlayClick={() => handleOnClickPlay(a.trackUrl || '', answerIndex)}
                  />
                ) : null,
              )}
            </S.MusicAnswers>
          ) : (
            <S.Answers>
              {answers.map((a, answerIndex) => (
                <Answer
                  key={`${a.title}${a.description}`}
                  description={a.description}
                  selected={a.title === selectedAnswer?.title}
                  title={a.title}
                  onClick={() => setSelectedAnswer(answers[answerIndex])}
                />
              ))}
            </S.Answers>
          )}

          {hasPrivacyNotice ? (
            <S.PrivacyNotice>
              Any and all data is kept 100% private. See our{' '}
              <S.PrivacyNoticeLinkWrapper>
                <S.PrivacyNoticeLink href="https://brain.fm/privacy" target="_blank">
                  Privacy Policy
                </S.PrivacyNoticeLink>
              </S.PrivacyNoticeLinkWrapper>
            </S.PrivacyNotice>
          ) : null}
        </S.ContainerColumn>
      </S.Content>
      <S.BottomContainer>
        {answers.some(a => isMusicAnswer(a)) ? (
          <S.VolumeControlWrapper>
            <OnboardingVolumeControl onChange={handleVolumeChange} />
          </S.VolumeControlWrapper>
        ) : null}
        <S.ButtonContainer>
          <Button
            disabled={!selectedAnswer}
            isFullWidth
            keepTextCase
            variant={ButtonVariants.Secondary}
            onClick={() => handleClickContinue()}
          >
            Continue
          </Button>
        </S.ButtonContainer>
      </S.BottomContainer>
    </CardWrapperTwo>
  );
};
