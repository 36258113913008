import * as S from './styles';
import { getGAEvent } from '../../../../utils/analytics/ga';

type Props = {
  onClick: () => void;
};

export const SigninText = ({ onClick }: Props) => {
  return (
    <S.Container>
      <S.Text>Don’t need to reset?</S.Text>
      <S.Button
        data-analytics-event={getGAEvent('Session', 'reset_password', 'sign-in')}
        onClick={onClick}
      >
        SIGN IN
      </S.Button>
    </S.Container>
  );
};
