import styled, { keyframes } from 'styled-components';
import { normalAura } from '../MembershipPanelWrapper/MembershipPanelWrapper.styles';

const backgroundAnimation = keyframes`
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const LifetimeContainer = styled.div`
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2rem;
  color: white;
  background: ${normalAura.inner}, ${normalAura.outer};
  background-size: 200% 200%;
  animation: ${backgroundAnimation} 20s ease infinite;

  @media (prefers-reduced-motion) {
    animation: none;
    background-size: 100% 100%;
  }

  /* filter: invert(1); */
  // safari has a bug with overflow:hidden on absolutely positioned children;
  // this is a workaround for it.
  // https://stackoverflow.com/questions/44948735/overflow-hidden-is-not-working-with-absolute-element-in-safari
  transform: translateZ(0);
`;

export const Header = styled.div`
  display: flex;
  font-family: TTNormsPro-Regular;
  margin-bottom: 1.5rem;
  align-items: center;
`;

export const HeaderIcon = styled.div`
  margin-right: 0.6rem;
  position: relative;
  z-index: 1; // see note at top
`;

export const Children = styled.div`
  text-transform: initial;
  z-index: 2; // see note at top
`;

export const HeaderBadge = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-weight: 700;
  font-size: 0.7rem;
  background-color: white;
  border-radius: 16px;
  color: black;
  padding: 5px 8px 4px 8px;
`;

export const Logo = styled.img`
  width: clamp(80px, 18vw, 140px);
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 1.25rem;
  width: auto;
  align-items: center;
  z-index: 5;
  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
`;

export const HeaderTextSubtitle = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  margin-left: 1.4rem;
  padding-left: 1.2rem;
  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    border: none;
    margin-left: 0;
    padding-left: 0;
  }
`;
