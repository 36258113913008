import { MembershipPromotionTypes } from '@Memberships';
import { getTrackGenreName, getTrackLengthInSeconds, getTrackName } from '@Music';
import * as Braze from '@braze/web-sdk';
import { Action } from '@reduxjs/toolkit';
import Amplitude from 'amplitude-js';
import { put, select, SelectEffect, takeEvery } from 'redux-saga/effects';

import * as analyticsActions from '../actions/analytics';
import * as userActions from '../actions/user';
import { get } from '../api/client/client';
import { RequestMethods } from '../api/client/types';
import { formatTime } from '../components/session/utils/formatTime';
import { RootReducerType } from '../reducers';
import { userSliceActions } from '../reducers/user';
import { requestSaga } from '../sagas/httpRequest';
import {
  AnalyticsEventsProperties,
  AnalyticsSigninEvents,
  AnalyticsSessionEvents,
  AnalyticsPlayerEvents,
} from '../types';
import { Analytics } from '../utils/analytics';
import { ExploreEvents } from '../utils/analytics/events';
import DateUtil from '../utils/date';
import { Logger } from '../utils/logger';

/**
 * Helper function to gather player event properties.
 * @returns Events properties that all Player events should send
 */
export function* getPlayerEvents(): Generator<
  SelectEffect,
  Partial<AnalyticsEventsProperties>,
  RootReducerType
> {
  const {
    music: { currentTrack },
    sessionManager,
    currentSession,
  }: RootReducerType = yield select((state: RootReducerType) => state);

  const activity = sessionManager.sessionDynamicActivity?.id;
  const mentalState = sessionManager.sessionDynamicActivity?.mentalState.id;

  const sessionMinutesPlayed =
    (sessionManager.sessionPlayTime + sessionManager.currentTrackTimeStamp) / 60;

  const trackGenre = getTrackGenreName(currentTrack);
  const filterNEL = sessionManager.sessionPowerLevel;
  const trackMinutesPlayed = sessionManager.currentTrackTimeStamp / 60;
  // values is in seconds, convert to minutes
  const timerAlarmSetting = sessionManager.timerLength
    ? sessionManager.timerLength / 60
    : 'Infinite';
  const timerMode = sessionManager.sessionPlayType;
  const startedFrom = currentSession.session.startedFrom;

  // TODO: Circle back to this after we have Sprint sessions implemented.
  const timerMinutesLeft =
    timerMode === 'NORMAL'
      ? 'Infinite'
      : (sessionManager.timerLength || 0) -
        (sessionManager.timerPlayTime || 0) +
        sessionManager.currentTrackTimeStamp;

  const trackMinutesLeft = currentTrack
    ? (getTrackLengthInSeconds(currentTrack) - sessionManager.currentTrackTimeStamp) / 60
    : 0;
  // combines total session played with current track progress, then substracts 5 mins (300 secs).
  const timerCountupTimeMinutes =
    timerMode === 'NORMAL' && sessionManager.sessionPassed5Min
      ? (sessionManager.sessionPlayTime + sessionManager.currentTrackTimeStamp - 300) / 60
      : 0;

  // taken from timer component;
  const getFormattedTimerTime = () => {
    if (sessionManager.sessionPlayType === 'TIMER') {
      const totalTimerPlayTime =
        (sessionManager.timerPlayTime || 0) + sessionManager.currentTrackTimeStamp;
      const timerDisplay = (sessionManager.timerLength || 0) - totalTimerPlayTime;

      return formatTime(timerDisplay >= 0 ? timerDisplay : 0);
    } else {
      const currentTime = sessionManager.sessionPlayTime + sessionManager.currentTrackTimeStamp;
      let time = currentTime + 1; // we add 1 second before hand to account for animation of player.

      if (time <= 300) {
        time = 301 - time; // set 1 second before 5:00 so it transitions from 0:01 to 5:00
      }

      return formatTime(time);
    }
  };

  return {
    session_minutes_played: sessionMinutesPlayed,
    timer_minutes_left: timerMinutesLeft,
    timer_alarm_setting: timerAlarmSetting,
    timer_mode: timerMode,
    timer_countup_time: timerCountupTimeMinutes,
    track_minutes_played: trackMinutesPlayed,
    track_minutes_left: trackMinutesLeft,
    filter_NEL: filterNEL,
    track_name: getTrackName(currentTrack),
    track_genre: trackGenre,
    activity,
    mental_state: mentalState,
    timer_shows: getFormattedTimerTime(),
    started_from: startedFrom,
  };
}

function* setUserSaga(action: Action) {
  if (analyticsActions.setUser.match(action)) {
    const { id, email, enterpriseId, partnerId } = action.payload.user;

    try {
      yield Amplitude.getInstance().setUserId(id);
      yield Amplitude.getInstance().setUserProperties({ email: email });
      yield Braze.changeUser(id);
      yield Braze.getUser()?.setEmail(email);

      yield Amplitude.getInstance().setUserProperties({
        enterprise_id: enterpriseId,
        partner_id: partnerId,
      });
      yield Braze.getUser()?.setCustomUserAttribute('enterprise_id', enterpriseId);
      yield Braze.getUser()?.setCustomUserAttribute('partner_id', partnerId);
    } catch (e) {
      Logger.error(new Error('Failed to set braze/amplitude custom attributes.'), { reason: e });
    }
  }
}

function* createUserSaga(action: Action) {
  if (analyticsActions.createUser.match(action)) {
    const { user, authType } = action.payload;
    const timezone = DateUtil().getLocalTimezone();

    const availablePromotion = (yield select(
      (state: RootReducerType) => state.membership.promotion,
    )) as RootReducerType['membership']['promotion'];

    try {
      const defaultProps = Analytics.getDefaultUserProperties({
        account_creation: authType,
        first_name: user.firstName,
        email: user.email,
        enterprise_id: user.enterpriseId || 0,
        initial_promotion:
          availablePromotion?.type === MembershipPromotionTypes.StripeCoupon
            ? availablePromotion.name
            : '',
        initial_referrer: document.referrer || '',
        partner_id: user.partnerId || 0,
        timezone,
      });

      yield Braze.changeUser(user.id);
      yield Braze.getUser()?.setEmail(user.email);
      yield Amplitude.getInstance().setUserId(user.id);
      yield Amplitude.getInstance().setUserProperties(defaultProps);

      Object.entries(defaultProps).forEach(([key, value]) => {
        Braze.getUser()?.setCustomUserAttribute(key, value);
      });
    } catch (e) {
      Logger.error(new Error('Failed to set braze/amplitude custom attributes.'), { reason: e });
    }
  }
}

function* logEventSaga(action: Action) {
  if (analyticsActions.logEvent.match(action)) {
    yield Analytics.logEvent(action.payload);
  }
}

function* logEventWithPropertiesSaga(action: Action) {
  if (analyticsActions.logEventWithProperties.match(action)) {
    yield Analytics.logEventWithProperties(action.payload.event, action.payload?.props || {});
  }
}

function* fetchUserForSignupAnalyticsSaga(action: Action) {
  try {
    if (analyticsActions.fetchUserForSignupAnalytics.match(action)) {
      yield put(userSliceActions.getUserInfo());

      const { token, authType } = action.payload;
      const { result } = yield get({ path: '/users/me', token, options: { apiVersion: 2 } });

      yield put(userSliceActions.setUserInfo(result.user));
      yield put(userSliceActions.setMembershipInfo(result.membership));

      yield put(analyticsActions.createUser({ user: result.user, authType }));
    }
  } catch (error) {
    Logger.error(error);
  }
}

function* fetchUserForSigninAnalyticsSaga(action: Action) {
  try {
    if (analyticsActions.fetchUserForSigninAnalytics.match(action)) {
      yield put(userSliceActions.getUserInfo());

      const { result } = yield get({
        path: '/users/me',
        token: action.payload.token,
        options: { apiVersion: 2 },
      });

      yield put(userSliceActions.setUserInfo(result.user));
      yield put(userSliceActions.setMembershipInfo(result.membership));
      if (result.verification) {
        yield put(userSliceActions.setEmailVerificationInfo(result.verification));
      }
      yield put(userActions.getStreaks());
      yield put(analyticsActions.setUser({ user: result.user }));
    }
  } catch (error) {
    Logger.error(error);
  }
}

// SESSION
function* sessionLogEventSaga(action: Action<AnalyticsSessionEvents>) {
  if (analyticsActions.sessionLogEvent.match(action)) {
    const playerEvents: Partial<AnalyticsEventsProperties> = yield getPlayerEvents();

    Analytics.logEventWithProperties(action.payload, playerEvents);
    Analytics.logEventGA('Session', action.payload);
  }
}

// PLAYER
function* playerLogEventSaga(action: Action<AnalyticsPlayerEvents>) {
  if (analyticsActions.playerLogEvent.match(action)) {
    const playerEvents: Partial<AnalyticsEventsProperties> = yield getPlayerEvents();

    yield Analytics.logEventWithProperties(action.payload, playerEvents);
    yield Analytics.logEventGA('Player', action.payload);
  }
}

function* playerLogEventWithPropsSaga(action: Action) {
  if (analyticsActions.exploreMusicLogEventWithProps.match(action)) {
    const playerEvents: Partial<AnalyticsEventsProperties> = yield getPlayerEvents();

    yield Analytics.logEventWithProperties(action.payload.event, {
      ...playerEvents,
      ...action.payload.value,
    });
    yield Analytics.logEventGA('Player', action.payload.event);
  }
}

function* playerResumeSaga(action: Action) {
  if (analyticsActions.playerResume.match(action)) {
    const playerEvents: Partial<AnalyticsEventsProperties> = yield getPlayerEvents();

    Analytics.logEventWithProperties('player_resume', {
      ...playerEvents,
    });
    Analytics.logEventGA('Player', 'player_resume');
  }
}

// SIGN_IN
function* signinLogEventSaga(action: Action<AnalyticsSigninEvents>) {
  if (analyticsActions.signinLogEvent.match(action)) {
    yield Analytics.logEventWithProperties(action.payload, {});
    yield Analytics.logEventGA('Signin', action.payload);
  }
}

// EXPLORE_MUSIC
function* exploreMusicLogEventSaga(action: Action<ExploreEvents>) {
  if (analyticsActions.exploreMusicLogEvent.match(action)) {
    const playerEvents: Partial<AnalyticsEventsProperties> = yield getPlayerEvents();

    yield Analytics.logEventWithProperties(action.payload, playerEvents);
    yield Analytics.logEventGA('ExploreMusic', action.payload);
  }
}

function* exploreMusicLogEventWithPropsSaga(action: Action) {
  if (analyticsActions.exploreMusicLogEventWithProps.match(action)) {
    const playerEvents: Partial<AnalyticsEventsProperties> = yield getPlayerEvents();

    yield Analytics.logEventWithProperties(action.payload.event, {
      ...playerEvents,
      ...action.payload.value,
    });
    yield Analytics.logEventGA('ExploreMusic', action.payload.event);
  }
}

// POST SESSION LOGGING
function* postSessionLogEventSaga(action: Action) {
  if (analyticsActions.postSessionLogEvent.match(action)) {
    yield Analytics.logEventWithProperties(action.payload, {});
    yield Analytics.logEventGA('PostSession', action.payload);
  }
}

function* postSessionLogEventWithPropsSaga(action: Action) {
  if (analyticsActions.postSessionLogEventWithProps.match(action)) {
    yield Analytics.logEventWithProperties(action.payload.event, action.payload.value);
    yield Analytics.logEventGA('PostSession', action.payload.event);
  }
}

// HOME_SCREEN
function* homeScreenLogEventSaga(action: Action) {
  if (analyticsActions.homeScreenLogEvent.match(action)) {
    yield Analytics.logEventWithProperties(action.payload, {});
    yield Analytics.logEventGA('HomeScreen', action.payload);
  }
}

// PROFILE_SCREEN
function* profileScreenLogEventSaga(action: Action) {
  if (analyticsActions.profileScreenLogEvent.match(action)) {
    yield Analytics.logEventWithProperties(action.payload, {});
    yield Analytics.logEventGA('ProfileScreen', action.payload);
  }
}

function* subscriptionLogEventSaga(action: Action) {
  if (analyticsActions.subscriptionLogEvent.match(action)) {
    yield Analytics.logEventWithProperties(action.payload, {});
    yield Analytics.logEventGA('Subscription', action.payload);
  }
}

function* logTrackStartEventSaga(action: Action) {
  if (!analyticsActions.logTrackStartEvent.match(action)) return;

  const { info }: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
  if (!info) return;

  try {
    yield requestSaga(RequestMethods.POST, `/users/${info.id}/events`, {
      mysqlUserId: info.id,
      timestamp: Date.now(),
      trackId: action.payload.trackId,
      trackVariationId: action.payload.trackVariationId,
      type: 'startTrack',
    });
  } catch (error) {
    // do not want to fail the whole saga if this fails
  }
}

export default function* watchAnalyticsSaga() {
  yield takeEvery(analyticsActions.setUser.type, setUserSaga);
  yield takeEvery(analyticsActions.createUser.type, createUserSaga);

  yield takeEvery(analyticsActions.logEvent.type, logEventSaga);
  yield takeEvery(analyticsActions.logEventWithProperties.type, logEventWithPropertiesSaga);
  yield takeEvery(
    analyticsActions.fetchUserForSignupAnalytics.type,
    fetchUserForSignupAnalyticsSaga,
  );
  yield takeEvery(
    analyticsActions.fetchUserForSigninAnalytics.type,
    fetchUserForSigninAnalyticsSaga,
  );

  yield takeEvery(analyticsActions.playerResume.type, playerResumeSaga);

  yield takeEvery(analyticsActions.playerLogEvent.type, playerLogEventSaga);
  yield takeEvery(analyticsActions.playerLogEventWithProps.type, playerLogEventWithPropsSaga);

  yield takeEvery(analyticsActions.signinLogEvent.type, signinLogEventSaga);

  yield takeEvery(analyticsActions.exploreMusicLogEvent.type, exploreMusicLogEventSaga);
  yield takeEvery(
    analyticsActions.exploreMusicLogEventWithProps.type,
    exploreMusicLogEventWithPropsSaga,
  );

  yield takeEvery(analyticsActions.postSessionLogEvent.type, postSessionLogEventSaga);
  yield takeEvery(
    analyticsActions.postSessionLogEventWithProps.type,
    postSessionLogEventWithPropsSaga,
  );

  yield takeEvery(analyticsActions.sessionLogEvent.type, sessionLogEventSaga);

  yield takeEvery(analyticsActions.homeScreenLogEvent.type, homeScreenLogEventSaga);
  yield takeEvery(analyticsActions.profileScreenLogEvent.type, profileScreenLogEventSaga);
  yield takeEvery(analyticsActions.logTrackStartEvent.type, logTrackStartEventSaga);
  yield takeEvery(analyticsActions.subscriptionLogEvent.type, subscriptionLogEventSaga);
}
