import AppleIcon from '../../assets/apple_icon.svg';
import FacebookIcon from '../../assets/facebook_icon.svg';
import * as S from './AuthenticatedWith.styles';

interface Props {
  platform: string;
}

export const AuthenticatedWith = ({ platform }: Props) => {
  return (
    <S.Container>
      <S.IconContainer>
        <S.Icon src={platform === 'apple' ? AppleIcon : FacebookIcon} />
      </S.IconContainer>
      <S.Text>Authenticated with {platform}</S.Text>
    </S.Container>
  );
};
