import { DynamicMentalState } from './DynamicMentalState';
import { Tag } from './Track';

export enum DynamicActivityTypes {
  Normal = 'normal',
}

export enum QueryTagInclusions {
  All = 'all',
  Any = 'any',
  None = 'none',
}

export type QueryTag = {
  id: string;
  dynamicPlaylistId: string;
  type:
    | Tag['type']
    | 'name'
    | 'isNewlyCreated'
    | 'mentalState.type'
    | 'mentalState.id'
    | 'dynamicMentalState.id';
  values: Tag['value'][];
  inclusion: QueryTagInclusions;
};

export type DynamicPlaylist = {
  id: string;
  title: string;
  order: number;
  queryTags: QueryTag[];
};

export enum DynamicActivityStatuses {
  Draft = 'draft',
  Active = 'active',
  Archived = 'archived',
}

type DynamicActivityMetadata = {
  images: {
    background: {
      imageURL: string;
      altText: string;
    };
    aura: {
      imageURL: string;
      altText: string;
    };
  };
};

type DynamicActivityNormal = {
  id: string;
  displayValue: string;
  description: string;
  type: DynamicActivityTypes.Normal;
  playlists: DynamicPlaylist[];
  mentalState: Omit<
    DynamicMentalState,
    | 'defaultActivity'
    | 'yourFirstSessionActivity'
    | 'yourFirstSessionSkipActivity'
    | 'yourFirstSessionFavoritesActivity'
  >;
  order: number;
  isDefault: boolean;
  status: DynamicActivityStatuses;
  metadata?: DynamicActivityMetadata;
};

export type DynamicActivity = DynamicActivityNormal;
