import { Text, TextBold } from '@Cortex';

import * as S from './NoFavorites.styles';
import NoFavoriteImage from './assets/no_favorites.webp';
import { DynamicMentalState } from '@Model';

interface Props {
  mentalState?: DynamicMentalState | null;
}

export const NoFavorites = ({ mentalState }: Props) => {
  return (
    <S.Container>
      <S.ImageContainer>
        <S.Image src={NoFavoriteImage} />
      </S.ImageContainer>
      <S.HeadingContainer>
        <S.TextWrapper>
          {mentalState && (
            <TextBold size={'1.25rem'}>
              No {mentalState.displayValue} tracks added to Favorites yet.
            </TextBold>
          )}
        </S.TextWrapper>
        <S.TextWrapper>
          <Text>Favorite tracks in the Player to save them here, and play them anytime. </Text>
        </S.TextWrapper>
      </S.HeadingContainer>
    </S.Container>
  );
};
