import { useState } from 'react';

import { Analytics } from '../../../../utils/analytics';
import { ExploreEvents } from '../../../../utils/analytics/events';
import { FilterDisplay, FilterDisplayProps } from './Filter.display';

interface Props {
  filters: FilterDisplayProps['filters'];
}

export const Filter = ({ filters }: Props) => {
  const [isFilterListOpen, setIsFilterListOpen] = useState(false);

  function handleCloseFilterList() {
    setIsFilterListOpen(state => {
      if (state) {
        Analytics.logEventWithProperties(ExploreEvents.toggle_filters_expansion__explore, {
          type: 'close',
        } as any);

        return false;
      }

      return state;
    });
  }

  function handleOpenFilterList() {
    setIsFilterListOpen(state => {
      if (!state) {
        Analytics.logEventWithProperties(ExploreEvents.toggle_filters_expansion__explore, {
          type: 'open',
        } as any);

        return true;
      }

      return state;
    });
  }

  return (
    <FilterDisplay
      filters={filters}
      isFilterListOpen={isFilterListOpen}
      onCloseFilterList={handleCloseFilterList}
      onOpenFilterList={handleOpenFilterList}
    />
  );
};
