import { EnhancedMembershipPlanType } from '../../../../../../../../types';
import { DEFAULT_TRIAL_LENGTH } from '../../../../constants';
import * as S from './PlanCard.styles';

type Props = {
  plan: EnhancedMembershipPlanType;
  isSelected: boolean;
  hasDiscount: boolean;
  discountPercentage?: number;
  isExtendedPromo?: boolean;
  onClick: () => void;
};

export const PlanCard = ({
  plan,
  isSelected = false,
  hasDiscount = false,
  isExtendedPromo = false,
  discountPercentage = 0,
  onClick,
}: Props) => {
  const isYearly = plan?.title === 'Yearly' || plan?.displayInterval === 'yr';
  let planCost = isYearly
    ? `$${plan.displayCost}/${plan.displayInterval} ($${plan.price}/year)`
    : `$${plan.displayCost}/${plan.displayInterval}`;
  const totalCost = `${Boolean(plan.trialLength) ? 'then ' : ''}$${plan.price}/year`;

  if (hasDiscount) {
    planCost = `$${plan.displayCost}/${isYearly ? 'year' : 'month'}`;
  }

  const isExtendedPromoWithTrial = isExtendedPromo && Boolean(plan.trialLength);

  return (
    <S.Wrapper>
      {!isExtendedPromo && isYearly && isSelected && !hasDiscount && <S.Save>SAVE 40%</S.Save>}
      {!isExtendedPromoWithTrial && isYearly && isSelected && hasDiscount && discountPercentage && (
        <S.Save>SAVE {discountPercentage}%</S.Save>
      )}
      <S.Container data-testid={`plan-${plan?.title}`} isSelected={isSelected} onClick={onClick}>
        <S.TopContainer>
          {isExtendedPromoWithTrial ? (
            <S.PlanTrialLength>{`${plan.trialLength} Days Free`}</S.PlanTrialLength>
          ) : (
            <>
              {Boolean(plan.trialLength) ? (
                <S.PlanName>{isYearly ? 'YEARLY' : 'MONTHLY'} PLAN</S.PlanName>
              ) : (
                <S.PlanNameAccent>{isYearly ? 'Yearly' : 'Monthly'} Plan</S.PlanNameAccent>
              )}
              {Boolean(plan.trialLength) && (
                <S.PlanTrialLength>{`${plan.trialLength}-Day Free Trial`}</S.PlanTrialLength>
              )}
            </>
          )}
        </S.TopContainer>
        <S.BottomContainer isSelected={isSelected}>
          {isExtendedPromoWithTrial ? totalCost : planCost}
        </S.BottomContainer>
      </S.Container>
    </S.Wrapper>
  );
};
