import { takeLatest } from 'redux-saga/effects';

import * as userActions from '../actions';
import {
  addSessionPreferencesSaga,
  getSessionPreferencesSaga,
  removeSessionPreferencesSaga,
  setDefaultDisplayTypeSaga,
  toggleSessionPreferencesSaga,
  getUserPreferencesSaga,
  updateUserPreferencesSaga,
} from './workerSagas';

export function* watchUserPreferencesSaga() {
  yield takeLatest(userActions.addSessionPreference, addSessionPreferencesSaga);
  yield takeLatest(userActions.getSessionPreferences, getSessionPreferencesSaga);
  yield takeLatest(userActions.getUserPreferences, getUserPreferencesSaga);
  yield takeLatest(userActions.removeSessionPreference, removeSessionPreferencesSaga);
  yield takeLatest(userActions.setDefaultDisplayType, setDefaultDisplayTypeSaga);
  yield takeLatest(userActions.toggleSessionPreference, toggleSessionPreferencesSaga);
  yield takeLatest(userActions.updateUserPreferences, updateUserPreferencesSaga);
}
