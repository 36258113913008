import { useQuery } from '@tanstack/react-query';

import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { TPromotionResponse } from './types';
import { useRequestHandler } from './useRequestHandler';
import { isOfflineError } from '../../../utils/isOfflineError';

export const usePromotions = () => {
  const getPromotions = useRequestHandler();

  return useQuery<TPromotionResponse['result'], Error>(
    Queries.getPromotions,
    () => getPromotions(),
    {
      onError: error => {
        if (isOfflineError(error)) return;
        Logger.error(new Error('Error getting Promotions list'));
      },
    },
  );
};
