import * as S from './Panel.styles';

export type Props = {
  backgroundColor?: string;
  borderRadius?: string;
  children?: React.ReactNode;
  isScrollable?: boolean;
  isBlurred?: boolean;
  padding?: string;
  style?: React.CSSProperties;
};

export function Panel({
  backgroundColor,
  borderRadius = '12px',
  children,
  isScrollable = false,
  isBlurred = false,
  padding = '2rem',
  style,
}: Props) {
  return (
    <S.Panel
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      isBlurred={isBlurred}
      isScrollable={isScrollable}
      padding={padding}
      style={style}
    >
      {children}
    </S.Panel>
  );
}
