import { membershipActions, MembershipState } from '@Memberships';
import { put } from 'redux-saga/effects';

import { RequestMethods } from '../../../api/client/types';
import { requestSaga, SuccessResponseType } from '../../../sagas/httpRequest';

type Response = {
  result: Omit<MembershipState, 'promotion'>;
};

export function* fetchMembership() {
  try {
    const response: SuccessResponseType<Response> = yield requestSaga(
      RequestMethods.GET,
      '/membership',
    );

    if (response.status === 200) {
      yield put(membershipActions.receiveMembership(response.result));
      yield put(membershipActions.membershipInitiallyFetched());
      return;
    }
  } catch (e) {}

  yield put(membershipActions.receiveMembershipError());
}
