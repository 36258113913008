import { Text, Button, ButtonVariants } from '@Cortex';
import { NOOP } from '@Globals';
import { forwardRef } from 'react';
import { TransitionStatus } from 'react-transition-group';

import * as S from './Confirmation.styles';
import { Assets } from '../../../../../../utils/assets';
import { ProfileFormContainer } from '../../../../../shared/ProfileFormContainer';
import WarningHeader from '../assets/warning_header.svg';

export interface Props {
  onClose?: () => void;
  onContinue?: () => void;
  animationState: TransitionStatus;
}

export const Confirmation = forwardRef<HTMLDivElement, Props>(
  ({ onClose = NOOP, onContinue = NOOP, animationState }, ref) => {
    return (
      <S.Container ref={ref} animationState={animationState}>
        <ProfileFormContainer
          description={
            'Listening to Brain.fm for just 30 minutes a day can boost your productivity up to 33%.'
          }
          headerImage={WarningHeader}
          keepTextCase
          maxWidth="33rem"
          title="Don’t give up on productivity!"
          onClose={onClose}
        >
          <S.Image src={Assets.images.hubSpotReview.url} />
          <S.TextWrapper>
            <S.Text>
              More than <span>1 million</span> high performers have unlocked greater focus with
              Brain.fm.
            </S.Text>
            <br />
            <S.Text>Give it another try?</S.Text>
          </S.TextWrapper>
          <S.Footer>
            <S.ButtonWrapper>
              <Button isFullWidth keepTextCase variant={ButtonVariants.Secondary} onClick={onClose}>
                TRY IT AGAIN
              </Button>
            </S.ButtonWrapper>
            <S.ButtonWrapper>
              <Button
                data-testid="confirmCancelSubscriptionButton"
                isFullWidth
                keepTextCase
                variant={ButtonVariants.Base}
                onClick={onContinue}
              >
                PROCEED WITH CANCELLATION
              </Button>
            </S.ButtonWrapper>
          </S.Footer>
        </ProfileFormContainer>
      </S.Container>
    );
  },
);
