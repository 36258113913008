import { createSlice } from '@reduxjs/toolkit';

export enum AudioPlayerPlayStyles {
  Default = 'Default',
  Repeat = 'Repeat',
}

export type AudioPlayerState = {
  playStyle: AudioPlayerPlayStyles;
};

export const initialState: AudioPlayerState = {
  playStyle: AudioPlayerPlayStyles.Default,
};

export const { actions: audioPlayerActions, reducer: audioPlayerReducer } = createSlice({
  name: 'AUDIO_PLAYER_V2',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },

    setPlayStyleToDefault: state => ({
      ...state,
      playStyle: AudioPlayerPlayStyles.Default,
    }),

    setPlayStyleToRepeat: state => ({
      ...state,
      playStyle: AudioPlayerPlayStyles.Repeat,
    }),
  },
});
