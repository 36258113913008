import { Transition } from 'react-transition-group';
import { HeroCard } from '../../components/HeroCard';

import { InfoCard } from '../../components/InfoCard';
import { InfoCardTwo } from '../../components/InfoCardTwo';
import { VideoCard } from '../../components/VideoCard';
import { OnboardingCardType } from '../../types';
import { isHeroCard } from '../../utils/isHeroCard';
import { isInfoCard } from '../../utils/isInfoCard';
import { isInfoCardTwo } from '../../utils/isInfoCardTwo';
import { isVideoCard } from '../../utils/isVideoCard';
import * as S from './ResultCards.styles';

type Props = {
  isLoading: boolean;
  currentCardIndex: number;
  mountedCardIndex: number;
  cards: OnboardingCardType[];
  onHandleNextCard: () => void;
  onHandleMountNextCard: () => void;
  onHandleSkipCard: () => void;
};

export const ResultCardsPresentation = (props: Props) => {
  const {
    onHandleNextCard,
    onHandleMountNextCard,
    onHandleSkipCard,
    cards,
    currentCardIndex,
    mountedCardIndex,
    isLoading,
  } = props;

  return (
    <S.Wrapper>
      {isLoading && <S.Loading />}
      {cards.map((card, index) => (
        <Transition
          key={index}
          in={currentCardIndex === index && mountedCardIndex === index}
          mountOnEnter
          timeout={100}
          unmountOnExit
          onExited={onHandleMountNextCard}
        >
          {state => (
            <S.AnimationContainer animationState={state}>
              <S.Content>
                {isInfoCard(card) && (
                  <InfoCard
                    activeStepIndex={currentCardIndex}
                    body={card.body}
                    ctaButtonText={index === cards.length - 1 ? 'Continue' : 'Next'}
                    heading={card.heading}
                    image={card.image}
                    numberOfSteps={cards.length}
                    title={card.title}
                    onClickClose={onHandleSkipCard}
                    onClickNext={onHandleNextCard}
                    onClickSkip={index === cards.length - 1 ? undefined : onHandleSkipCard}
                  />
                )}
                {isInfoCardTwo(card) && (
                  <InfoCardTwo
                    activeStepIndex={currentCardIndex}
                    belowBodyImage={card.belowBodyImage}
                    body={card.body}
                    ctaButtonText="Continue"
                    heading={card.heading}
                    image={card.image}
                    numberOfSteps={cards.length}
                    title={card.title}
                    onClickNext={onHandleNextCard}
                    onClickSkip={index === cards.length - 1 ? undefined : onHandleSkipCard}
                  />
                )}
                {isHeroCard(card) && (
                  <HeroCard
                    activeStepIndex={currentCardIndex}
                    body={card.body}
                    ctaButtonText={card.ctaButtonText}
                    desktopBackgroundImage={card.desktopBackgroundImage}
                    mobileBackgroundImage={card.mobileBackgroundImage}
                    numberOfSteps={cards.length}
                    stepText={card.stepText}
                    title={card.title}
                    onClickNext={onHandleNextCard}
                  />
                )}
                {isVideoCard(card) && (
                  <VideoCard
                    activeStepIndex={currentCardIndex}
                    ctaButtonText={index === cards.length - 1 ? 'Get Started' : 'Next'}
                    heading={card.heading}
                    image={card.image}
                    numberOfSteps={cards.length}
                    title={card.title}
                    youtubeVideoId={card.youtubeVideoId}
                    onClickClose={onHandleSkipCard}
                    onClickNext={onHandleNextCard}
                    onClickSkip={index === cards.length - 1 ? undefined : onHandleSkipCard}
                  />
                )}
              </S.Content>
            </S.AnimationContainer>
          )}
        </Transition>
      ))}
    </S.Wrapper>
  );
};
