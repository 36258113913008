import { musicActions } from '@Music';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootReducerType } from '../../../../reducers';
import { Analytics } from '../../../../utils/analytics';
import { ExploreEvents } from '../../../../utils/analytics/events';
import { SortSelectDisplay } from './SortSelect.display';
import { SortSelectBy } from './SortSelect.types';

export const SortSelect = () => {
  const [isMenuExpanded, setMenuExpanded] = useState(false);
  const sortByFilters = useSelector((state: RootReducerType) => state.music.sortByFilters);
  const dispatch = useDispatch();
  const sortByList = Object.entries(SortSelectBy).map(([key, value]) => ({ key, value }));

  useEffect(() => {
    return () => {
      dispatch(musicActions.setSortByFilters([]));
    };
  }, []);

  function handleSortingSelect(sortBy: string) {
    dispatch(musicActions.setSortByFilters([sortBy]));
    handleSetMenuExpanded(false);
  }

  function handleSetMenuExpanded(value: boolean) {
    setMenuExpanded(state => {
      if (state !== value) {
        Analytics.logEventWithProperties(ExploreEvents.toggle_sortby_expansion__explore, {
          type: value ? 'close' : 'open',
        } as any);
        return value;
      }

      return state;
    });
  }

  return (
    <SortSelectDisplay
      isMenuExpanded={isMenuExpanded}
      selectedSort={sortByFilters[0] || sortByList[0].value}
      setMenuExpanded={handleSetMenuExpanded}
      sortByList={Object.values(SortSelectBy)}
      onSortingSelect={handleSortingSelect}
    />
  );
};
