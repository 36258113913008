import { DynamicActivity } from '@Model';
import * as S from './ActivitySettings.styles';
import { Activity } from './components/Activity';

export interface Props {
  activities: DynamicActivity[];
  selectedActivityId: string;
  onSelectActivity: (value: DynamicActivity) => void;
}

export const ActivitySettingsDynamicDisplay = ({
  selectedActivityId,
  onSelectActivity,
  activities,
}: Props) => {
  return (
    <S.ContentWrapper>
      {activities.map(activity => {
        return (
          <Activity
            key={activity.id}
            data-testid={`activity_${activity.displayValue.toLowerCase()}`}
            description={activity.description}
            isSelected={selectedActivityId === activity.id}
            title={activity.displayValue}
            onClick={() => onSelectActivity(activity)}
          />
        );
      })}
    </S.ContentWrapper>
  );
};
