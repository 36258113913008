import { Navigate, useParams } from 'react-router-dom';

interface JoinTeamHandlerProps {
  isAuthenticated: boolean;
}

const JoinTeamHandler = ({ isAuthenticated }: JoinTeamHandlerProps) => {
  const params = useParams<{ teamId: string }>();

  if (!params.teamId) {
    return <Navigate to="/" />;
  }

  if (!isAuthenticated) {
    return <Navigate to={`/signup?join=${params.teamId}`} />;
  }

  return <Navigate to={`/joined/${params.teamId}`} />;
};

export default JoinTeamHandler;
