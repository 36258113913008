import { Serving, Track } from '@Model';

import { isServing } from './isServing';

export function getTrackHasMultipleNELs(trackOrServing?: Track | Serving | null): boolean {
  if (isServing(trackOrServing)) {
    return trackOrServing.track.hasMultipleNELs;
  }

  return trackOrServing?.serving.track.hasMultipleNELs || false;
}
