import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Loading = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:after {
    animation: rotation 0.8s linear infinite;
    border-radius: 100%;
    border-right: 3px solid transparent;
    border-top: 3px solid rgba(255, 255, 255, 0.8);
    content: '';
    height: 2rem;
    position: absolute;
    width: 2rem;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const AnimationContainer = styled.div<{ animationState: TransitionStatus }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  transition: opacity 0.3s ease-in-out;

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0;
      case 'entered':
        return 1;
      case 'exiting':
        return 1;
      case 'exited':
        return 0;
    }
  }};
`;

export const Content = styled.div<{ maxWidth?: string }>`
  height: 100%;
  width: 100%;
  padding: 2rem 3rem 3rem 3rem;
  display: flex;
  overflow-y: auto;
`;
