import {
  Button,
  ButtonVariants,
  ButtonVariantStates,
  TextBold,
  Text,
  Icon,
  TextSemiBold,
} from '@Cortex';
import { NOOP } from '@Globals';
import { Formik } from 'formik';
import React, { ReactNode, useState } from 'react';
import * as Yup from 'yup';

import { EnhancedMembershipPlanType } from '../../../../../../../../types';
import { Assets } from '../../../../../../../../utils/assets';
import { Review } from './SingleScreenSlideUpPaywallPaymentForm.styles';
import * as S from './SingleScreenSlideUpPaywallPaymentForm.styles';
import { Checkmark } from '../Checkmark/Checkmark';
import { CouponCode } from '@Payment';
import { GiftCard } from '../../../../../GiftCard';

const validationSchema = Yup.object({
  name: Yup.string().required('Please fill out billing name'),
});

export interface Props {
  billingInterval?: string;
  billingName?: string;
  buttonSubtitle?: string;
  errorMessage?: string | null;
  hasDiscount: boolean;
  onBack: () => void;
  onSubmit?: (value: string) => void;
  selectedPlan: EnhancedMembershipPlanType | null;
  children: ReactNode | undefined;
  variantState: ButtonVariantStates;
  footnote: string;
}

export type PaymentStatus = 'idle' | 'loading' | 'success' | 'error';

export const SingleScreenSlideUpPaywallPaymentForm: React.FC<Props> = ({
  footnote,
  billingName = '',
  buttonSubtitle,
  children,
  errorMessage = null,
  onSubmit = NOOP,
  onBack,
  hasDiscount,
  selectedPlan,
  variantState,
}) => {
  const [couponSectionVisible, setCouponSectionVisible] = useState(false);
  const [nameInputFocused, setNameInputFocused] = useState(false);

  return (
    <S.Container>
      <S.PaymentContainer>
        <S.ChevronWrapper>
          <Icon
            size={34}
            src={Assets.icons.arrowUp.url}
            style={{ cursor: 'pointer' }}
            onClick={onBack}
          />
        </S.ChevronWrapper>
        <S.Content>
          <S.Left>
            <S.ReviewsCaption>
              Join millions of others focusing on-demand with Brain.fm
            </S.ReviewsCaption>
            <S.Review>
              <S.Avatar src="https://cdn.brain.fm/images/review_avatar_1.png" />
              <S.ReviewContent>
                <Text size="1rem">
                  Usually I find it hard to get into the flow state for things I don’t like doing
                  but with the focus audio it was so easy. It took 2 minutes and suddenly I didn’t
                  want to stop doing my tasks anymore. The hour felt like 10 minutes.
                </Text>
                <S.Author>SIYA</S.Author>
              </S.ReviewContent>
            </S.Review>
            <S.Review>
              <S.Avatar src="https://cdn.brain.fm/images/review_avatar_2.png" />
              <S.ReviewContent>
                <Text size="1rem">
                  I’m obsessed with increasing focus and getting productivity gains. I must say
                  Brain.fm is worth every penny I have paid for it. Flow state instantly activated.
                </Text>
                <S.Author>DAPPADAN</S.Author>
              </S.ReviewContent>
            </S.Review>
            <S.Review>
              <S.Avatar src="https://cdn.brain.fm/images/review_avatar_3.png" />
              <S.ReviewContent>
                <Text size="1rem">
                  I’m an adult with ADHD. I’ve seen a huge difference in my ability to focus when I
                  listen to the highest neural effect setting with my noise-canceling headphones.
                  I’m so glad this app exists! ❤️
                </Text>
                <S.Author>MORRI B.</S.Author>
              </S.ReviewContent>
            </S.Review>
          </S.Left>
          <S.Right>
            <form style={{ display: 'flex', justifyContent: 'center' }}>
              <Formik
                initialValues={{ name: billingName }}
                validationSchema={validationSchema}
                onSubmit={values => onSubmit(values.name)}
              >
                {({ handleChange, handleSubmit, errors, touched, values }) => {
                  const hasErrors = !!(touched.name && errors.name);

                  return (
                    <S.FormBox>
                      <S.CardBox>
                        <S.TrialInfo>
                          {selectedPlan?.trialLength
                            ? `${selectedPlan?.trialLength}-Day Free Trial`
                            : `Start your ${selectedPlan?.title} Membership`}
                        </S.TrialInfo>
                        <S.PlanDescription>{selectedPlan?.description}</S.PlanDescription>
                        <S.NameInputContainer>
                          <S.NameInputLabel htmlFor="billingName" isError={hasErrors}>
                            {hasErrors ? errors.name : 'Billing Name'}
                          </S.NameInputLabel>
                          <S.NameInput
                            data-testid="billingName"
                            id="billingName"
                            isFocused={nameInputFocused}
                            name="name"
                            placeholder="Billing Name"
                            value={values.name}
                            onBlur={() => setNameInputFocused(false)}
                            onChange={handleChange}
                            onFocus={() => setNameInputFocused(true)}
                          />
                        </S.NameInputContainer>

                        <S.NameInputLabel htmlFor="billingName">Card Information</S.NameInputLabel>
                        <S.CardContainer data-testid="cardContainer">{children}</S.CardContainer>
                        <S.BelowCardInfo>
                          <S.PoweredByImage src={Assets.images.poweredByStripe.url} />
                          <Checkmark customIcon={Assets.icons.locker.url}>
                            Guaranteed safe and secure checkout
                          </Checkmark>
                          <Checkmark customIcon={Assets.icons.guarantee.url}>
                            60-Day money back guarantee
                          </Checkmark>
                        </S.BelowCardInfo>

                        <S.StartContainer>
                          {selectedPlan?.trialLength ? (
                            <S.StartInfoContainer>
                              <S.PriceInfo
                                data-testid={
                                  hasDiscount ? 'discountAdditionalInfo' : 'planAdditionalInfo'
                                }
                              >
                                <Text size="1rem">Due Today</Text>
                                <TextBold size="2rem">$0.00</TextBold>
                              </S.PriceInfo>
                              <S.YearlyPriceTextContainer>
                                <S.YearlyPriceText data-testid="trialLengthInfo">
                                  {`After ${selectedPlan?.trialLength} days: $${
                                    hasDiscount ? selectedPlan?.displayCost : selectedPlan?.price
                                  }`}
                                </S.YearlyPriceText>
                              </S.YearlyPriceTextContainer>
                            </S.StartInfoContainer>
                          ) : (
                            <S.StartInfoContainer>
                              <S.PriceInfo
                                data-testid={
                                  hasDiscount ? 'discountAdditionalInfo' : 'planAdditionalInfo'
                                }
                              >
                                <Text size="1rem">Price</Text>
                                <TextBold size="2rem">
                                  ${hasDiscount ? selectedPlan?.displayCost : selectedPlan?.price}
                                </TextBold>
                              </S.PriceInfo>
                            </S.StartInfoContainer>
                          )}
                          <S.ButtonContainer>
                            <Button
                              data-testid="handlePurchase"
                              isFullWidth
                              keepTextCase
                              type="button"
                              variant={ButtonVariants.Secondary}
                              variantState={variantState}
                              onClick={() => handleSubmit()}
                            >
                              {selectedPlan?.trialLength
                                ? 'Start free trial'
                                : 'Start Subscription'}
                            </Button>
                          </S.ButtonContainer>
                        </S.StartContainer>
                        {buttonSubtitle ? (
                          <S.ButtonSubtitle>{buttonSubtitle}</S.ButtonSubtitle>
                        ) : null}

                        <S.CouponContainer>
                          {!couponSectionVisible ? (
                            <S.CouponText>
                              Have a gift card or coupon code?
                              <S.CouponButton
                                data-testid="displayCouponForm"
                                type="button"
                                onClick={() => setCouponSectionVisible(true)}
                              >
                                Click here
                              </S.CouponButton>
                            </S.CouponText>
                          ) : null}

                          {couponSectionVisible ? (
                            <S.CouponGiftCardContainer>
                              <S.CouponSection>
                                <S.CouponTextWrapper>
                                  <TextSemiBold>Coupon Code</TextSemiBold>
                                </S.CouponTextWrapper>
                                <S.CouponSectionInputWrapper>
                                  <CouponCode layout="modern" />
                                </S.CouponSectionInputWrapper>
                              </S.CouponSection>

                              <S.CouponSection>
                                <S.CouponTextWrapper>
                                  <TextSemiBold>Gift Card</TextSemiBold>
                                </S.CouponTextWrapper>
                                <S.CouponSectionInputWrapper>
                                  <GiftCard layout="modern" />
                                </S.CouponSectionInputWrapper>
                              </S.CouponSection>
                            </S.CouponGiftCardContainer>
                          ) : null}
                        </S.CouponContainer>
                      </S.CardBox>
                      {errorMessage ? (
                        <S.ErrorWrapper>
                          <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
                        </S.ErrorWrapper>
                      ) : null}
                    </S.FormBox>
                  );
                }}
              </Formik>
            </form>
          </S.Right>
        </S.Content>
        <S.SubscriptionInfoText>{footnote}</S.SubscriptionInfoText>
      </S.PaymentContainer>
    </S.Container>
  );
};
