import styled from 'styled-components';

export const StyledAudioControlBar = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 2rem;
  border: 1px solid rgba(55, 62, 91, 0.7);
  display: flex;
  flex-direction: column;
  margin: 0 auto 2.25rem auto;
  padding: 1rem;
  width: 100%;
  backdrop-filter: blur(34px);
  -webkit-backdrop-filter: blur(34px);
  background: rgba(255, 255, 255, 0.05);
  transition: background 2s ease-in-out;

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    align-items: baseline;
    background: transparent;
    padding-bottom: 24px;
    padding-right: 24px;
    padding-left: 24px;
    border-radius: 0px;
    flex-direction: row;
    margin: 0;
    width: 100%;
    border: none;
    background: transparent;
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
`;

export const ControlLayout = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    flex-direction: row;
  }
`;

export const TrackInformationColumn = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 5;
`;

export const PlayerControlsColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 2;
  position: relative;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    align-items: center;
  }
`;

export const VolumeColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 5;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    display: none;
  }
`;

export const VolumeWrapper = styled.div`
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    max-width: 130px;
  }
`;

export const StreaksWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const FakeContainer = styled.div`
  opacity: 0;
`;
