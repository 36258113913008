import { useDispatch, useSelector } from 'react-redux';

import { RootReducerType } from '../../../../../../reducers';
import { Analytics } from '../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../utils/analytics/events';
import { StreaksWidgetLabel } from '../StreaksWidgetLabel';
import { StreaksWidgetModal } from '../StreaksWidgetModal';
import { TrackControls } from '../TrackControls';
import { TrackInformation } from '../TrackInformation';
import { VolumeControl } from '../VolumeControl';
import * as S from './AudioControls.styles';
import { setSessionModalContent } from '../../../../../../actions/ui';
import { SessionModalContent } from '../../../../../../types';

export const AudioControls = () => {
  const dispatch = useDispatch();
  const currentTrack = useSelector((state: RootReducerType) => state.music.currentTrack);

  // hide the audio control if there is no current track
  if (!currentTrack) return null;

  const handleOpenTrackDetails = () => {
    Analytics.logEventWithProperties(ExploreEvents.toggle_track_card_expansion__player, {
      type: 'open',
    } as any);
    dispatch(
      setSessionModalContent({
        sessionModalContent: SessionModalContent.TrackDetails,
        origin: 'player',
      }),
    );
  };

  return (
    <>
      <S.StyledAudioControlBar data-testid="audio-control">
        <S.ControlLayout>
          <S.PlayerControlsColumn>
            <S.TrackInformationColumn>
              <TrackInformation
                isDislikingDisabled={false}
                isFavoritingDisabled={false}
                onClick={() => handleOpenTrackDetails()}
              />
            </S.TrackInformationColumn>
            <TrackControls />

            <S.VolumeColumn>
              <S.StreaksWrapper>
                <StreaksWidgetLabel />
              </S.StreaksWrapper>
              <S.VolumeWrapper>
                <VolumeControl />
              </S.VolumeWrapper>
            </S.VolumeColumn>
          </S.PlayerControlsColumn>
        </S.ControlLayout>
      </S.StyledAudioControlBar>
      <StreaksWidgetModal />
    </>
  );
};
