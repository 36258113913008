import { FontColors, TextBold, TextSemiBold } from '@Cortex';
import { EffectLevels } from '@Model';
import styled, { css } from 'styled-components';

import LevelIcon from '../assets/effect_level.svg?react';

export const Container = styled.div`
  padding: 1rem;
  text-transform: lowercase;
`;

export const TrackInfoBodySection = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
`;

export const SectionTitle = styled(TextBold)`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-weight: 700;
  font-size: 0.75rem;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: ${FontColors.WhiteTransparent};
`;

export const SectionDescription = styled(TextSemiBold)`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: 400;
  color: ${FontColors.White};
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-transform: capitalize;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

export const EffectLevelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

export const GradientCircle = styled.div`
  background: linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 0.5rem;
`;

export const EffectLevelIcon = styled(LevelIcon)<{ $effectLevel: string }>`
  width: 8px;
  height: 8px;

  ${({ $effectLevel }) => {
    switch ($effectLevel) {
      case EffectLevels.Low:
        return css`
          & #high,
          & #medium {
            opacity: 0.5;
          }
        `;
      case EffectLevels.Medium:
        return css`
          & #high {
            opacity: 0.5;
          }
        `;
    }
  }}
`;

export const VariationsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
`;

export const Variation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  align-items: center;
  margin-top: 0.5rem;
  padding: 0.75rem 0.8rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
`;

export const VariationData = styled.div`
  display: flex;
  align-items: center;
`;

export const VariationDescription = styled(TextSemiBold)`
  color: ${FontColors.White};
  font-size: 0.875rem;
  margin-left: 0.5rem;
  text-transform: capitalize;
`;

export const PlayButton = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
`;

export const Circle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${FontColors.White};
`;

export const Triangle = styled.polygon`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 10px;
  border-color: transparent transparent transparent black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
`;

export const FrequencyTitleSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const FrequencyLevelDescription = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.875rem;
  text-transform: capitalize;
`;

export const FrequencyLevelContainer = styled.div`
  width: 100%;
  height: 8px;
  margin-top: 0.5rem;
  border-radius: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  align-items: center;
  justify-content: center;
`;

export const FrequencyLevelBar = styled.div<{ level: string }>`
  width: ${({ level }) =>
    level.toLowerCase() === 'high'
      ? '100%'
      : level.toLocaleLowerCase() === 'medium'
        ? '66%'
        : '33%'};
  height: 8px;
  border-radius: 32px;
  background-color: white;
  color: red;
`;

export const ActivitiesSection = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 8px;
`;
