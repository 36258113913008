import { SuccessModal } from '@Cortex';
import { RedemptionCodeForwarder, useRedirectUserToPaymentOnPromotion } from '@Memberships';
import { DeepLinkRedirector, useIntegrateWithMediaSessionAPI } from '@Music';
import { Onboarding } from '@Onboarding';
import { UpgradeStripeSubscriptionModal, PaywallModal, VerifyEmailModal } from '@Payment';
import { useChimeOnPomodoroPhaseChange, useEnableTimer } from '@Timer';
import { userActions } from '@User';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, ReactNode, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation, Routes, Navigate, useNavigationType } from 'react-router-dom';
import styled from 'styled-components';
import * as Braze from '@braze/web-sdk';

import * as appActions from '../actions/app';
import * as uiActions from '../actions/ui';
import AppLoading from '../components/appLoading';
import { StreakMilestoneModal } from '../components/session/components/StreakMilestoneModal';
import { Widget } from '../components/widget';
import AudioPlayerDynamic from '../components/audioPlayer/audioPlayerDynamic';
import { Session } from '../components/session';
import { DYNAMIC_PLAYER_ACTIVITY_PATH } from '../constants';
import { IntentionsHandler } from '../domains/Intentions/IntentionsHandler';
import { IntentionsPlayTrackHandler } from '../domains/Intentions/IntentionsPlayTrackHandler';
import { useStripeCheckoutSuccessModal } from '../domains/Memberships/hooks/useStripeCheckoutSuccessModal';
import { FastSubscriptionModal } from '../domains/Payment/components/FastSubscriptionModal';
import { RenewSubscriptionModal } from '../domains/Payment/components/RenewSubscriptionModal';
import { SubscriptionSuccessModal } from '../domains/Payment/components/SubscriptionSuccessModal';
import { featureFlagsState } from '../domains/Utils/featureFlagsState';
import { useHandlePageTitle } from '../hooks/useHandlePageTitle';
import { RootReducerType } from '../reducers';
import { Analytics } from '../utils/analytics';
import { setModalElement } from '../utils/setModalElement';
import JoinTeamHandler from './JoinTeamHandler';
import NotFound from './NotFound';
import { Home } from './Home';
import JoinTeam from './JoinTeam';
import { OnboardingPayment } from './signupPayment';

import { FirstSessionModal } from '../domains/Onboarding/FirstSessionModal';
import { IntentionsShowFavoritesHandler } from '../domains/Intentions/IntentionsShowFavoritesHandler';
import { fetchRecentSession } from '../actions/recentSessions';
import { fetchRecommendedActivities } from '../actions/recommendedActivities';
import { Activation } from '../domains/Activation/Activation.container';

import * as S from './Main.styles';
import { Transition } from 'react-transition-group';
import SideDeck from './SideDeck/SideDeck';
import { uiSliceActions } from '../reducers/uiReducer';
import { useIsMobileView } from '../hooks/useIsMobileView';
import { SideDeckTab } from '../types';
import { ProfileRedirect } from '../domains/Profile/ProfileRedirect';
import { ProfileSection } from '../types';
import { ToastContainer } from 'react-toastify';
import { MAIN_TOAST } from '@Globals';
import { useRecentTracks } from '../api/modules/RecentTracks';
import { useInitializeAmplitudeExperimentsOnMount } from '../domains/Utils/useAmplitudeExperiments';
import { DesktopAnnouncementBannerContainer } from '../domains/Memberships/components/DesktopAnnouncementBanner/DesktopAnnouncementBanner.container';

// Parent container that fades in the pages
// All page animations can be applied here
const AnimatedContainer = styled.div`
  width: inherit;
  height: inherit;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.6s;
`;

setModalElement();

function Main() {
  useInitializeAmplitudeExperimentsOnMount();
  useRecentTracks();
  useRedirectUserToPaymentOnPromotion();
  const { status } = useSelector((state: RootReducerType) => state.app);
  const { info } = useSelector((state: RootReducerType) => state.user);
  const isSideDeckOpen = useSelector((state: RootReducerType) => state.ui.sideDeck.isOpen);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigationType = useNavigationType();
  const isMobileView = useIsMobileView();
  const openedSideDeckRef = useRef<HTMLDivElement>(null);

  // tmp until DA is fully rollout
  useEffect(() => {
    Analytics.setUserProperties({
      // @ts-ignore
      web_production_dynamic_activities: true,
    });
  }, []);

  useStripeCheckoutSuccessModal();

  useEffect(() => {
    dispatch(appActions.init());
    Braze.subscribeToInAppMessage(inAppMessage => {
      if (inAppMessage.extras.noRender && inAppMessage.extras.noRender === 'true') {
        if (inAppMessage.extras.webAction === 'renewSubscription') {
          dispatch(uiActions.setModal('renewSubscription'));
        }

        if (inAppMessage.extras.webAction === 'newMusicAvailable') {
          Analytics.logEventWithProperties('hear_whats_new_widget_impression', {});
          dispatch(uiActions.setPlayerWidget('newMusicAvailable'));
        }

        return;
      }
      Braze.showInAppMessage(inAppMessage);
    });

    Braze.openSession();
  }, [dispatch]);

  useEffect(() => {
    if (info?.id) {
      // sometimes user info hasn't finished loading here so we need to check.
      dispatch(userActions.getSessionPreferences());
      dispatch(userActions.getUserPreferences());
      dispatch(fetchRecentSession());
      dispatch(fetchRecommendedActivities());
    }
  }, [dispatch, info?.id]);

  const renderMainView = (isPlayer: boolean) => {
    return isPlayer ? <Session /> : <Home />;
  };

  const renderBento = ({ isPlayer }: { isPlayer: boolean }) => (
    <S.Container>
      <S.Header>
        <S.LeftHeaderContainer isMobileView={isMobileView}>
          <DesktopAnnouncementBannerContainer />
        </S.LeftHeaderContainer>
        <S.RightHeaderContainer>
          <Widget />
        </S.RightHeaderContainer>
      </S.Header>
      <S.PanelWrapper>
        {!isMobileView && <S.LeftPanel>{renderMainView(isPlayer)}</S.LeftPanel>}
        <S.RightPanel isCollapsed={!isSideDeckOpen && !isMobileView} isMobileView={isMobileView}>
          <Transition in={isSideDeckOpen || isMobileView} nodeRef={openedSideDeckRef} timeout={300}>
            {state => (
              <S.SideDeckWrapper ref={openedSideDeckRef} animationState={state}>
                <SideDeck playerView={renderMainView(isPlayer)} />
              </S.SideDeckWrapper>
            )}
          </Transition>
        </S.RightPanel>
      </S.PanelWrapper>
      <ToastContainer
        containerId={MAIN_TOAST}
        style={{
          position: 'absolute',
          right: isMobileView ? '32px' : isSideDeckOpen ? '500px' : '100px',
          bottom: isMobileView ? '144px' : '56px',
        }}
      />
    </S.Container>
  );

  if (status === 'loading') {
    return <AppLoading />;
  }

  if (status === 'idle') {
    return <div />;
  }

  return (
    <>
      <Routes>
        <Route element={<Navigate replace to="/" />} path="/signup" />
        <Route element={<Navigate replace to="/" />} path="/signin" />

        <Route element={renderBento({ isPlayer: false })} index />
        <Route element={renderBento({ isPlayer: true })} path={DYNAMIC_PLAYER_ACTIVITY_PATH} />

        <Route
          element={
            <WithAnimatedTransition>
              <Onboarding />
            </WithAnimatedTransition>
          }
          path="welcome"
        />
        <Route
          element={
            <WithAnimatedTransition>
              <OnboardingPayment />
            </WithAnimatedTransition>
          }
          path="payment"
        />
        <Route element={<Navigate replace to="/payment?extended_promo=0" />} path="/paymentOffer" />

        {/* TODO- this is duplicated in app and they must change in sync */}
        <Route
          element={<ProfileRedirect section={ProfileSection.Account} />}
          path="/intentions/account"
        />
        <Route element={<DeepLinkRedirector />} path="/intentions/justAddedMusic" />
        <Route element={<Navigate replace={true} to="/" />} path="/intentions/home" />
        <Route element={<RedemptionCodeForwarder />} path="intentions/redeem/:redemptionCode" />
        <Route element={<IntentionsPlayTrackHandler />} path="/intentions/share" />
        <Route element={<OnboardingPayment />} path="/intentions/pay" />
        <Route element={<IntentionsHandler />} path="/intentions/:token" />
        <Route element={<IntentionsShowFavoritesHandler />} path="/intentions/focusFavorites" />
        <Route
          element={<ProfileRedirect section={ProfileSection.Settings} />}
          path="/intentions/settings"
        />
        <Route
          element={<JoinTeamHandler isAuthenticated={true} />}
          path="/intentions/join/:teamId"
        />
        <Route element={<JoinTeamHandler isAuthenticated={true} />} path="/join/:teamId" />
        <Route element={<JoinTeam />} path="/joined/:teamId" />
        <Route element={<NotFound isAuthenticated={true} />} path="*" />
        <Route element={<Activation />} path="/activate" />
      </Routes>

      <AudioPlayerDynamic
        currentRoute={location.pathname}
        shouldAttemptAutoPlay={navigationType === 'PUSH'}
      />

      <PaywallModal />
      <VerifyEmailModal />
      <UpgradeStripeSubscriptionModal />
      <SubscriptionSuccessModal />
      <StreakMilestoneModal />
      <RenewSubscriptionModal />
      <FastSubscriptionModal />
      <SuccessModal />
      <FirstSessionModal />
      <GlobalHooks />
    </>
  );
}

export default Main;

function GlobalHooks() {
  useIdentifyUserToLaunchDarkly();
  useChimeOnPomodoroPhaseChange();
  useEnableTimer();
  useIntegrateWithMediaSessionAPI();
  useHandlePageTitle();
  return null;
}

function useIdentifyUserToLaunchDarkly() {
  const client = useLDClient();
  const { info } = useSelector((state: RootReducerType) => state.user);

  useEffect(() => {
    if (client && info?.id) {
      client.identify(
        {
          key: info.id,
          email: info.email,
        },
        undefined,
        err => {
          if (err) {
            featureFlagsState.setState({ error: err, isSettled: true });
          } else {
            featureFlagsState.setState({ isReceived: true, isSettled: true });
          }
        },
      );
    }
  }, [client, info?.id, info?.email]);
}

function WithAnimatedTransition(props: { children: ReactNode }) {
  const location = useLocation();

  return <AnimatedContainer key={location.key}>{props.children}</AnimatedContainer>;
}
