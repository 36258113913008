import { Form } from '@Cortex';
import { FormikConfig } from 'formik';
import * as Yup from 'yup';

import { SignInFormValues } from '../../../../components/forms';

import * as S from './SignInForm.styles';
import { Assets } from '../../../../utils/assets';
import { AuthButton } from '../AuthButton';

type FormInput<Values> = {
  label: string;
  name: Extract<keyof Values, string>;
  placeholder: string;
  type: string;
};

export type Props<Values> = {
  callToAction?: string;
  formError: string | null;
  formInitialValues: Values;
  formInputs: FormInput<Values>[];
  formValidationSchema: Yup.ObjectSchema<SignInFormValues>;
  isLoading: boolean;
  onClickForgotPassword: () => void;
  onClickForgotPasswordAnalyticsEvent: string;
  onClickSignInWithApple: () => void;
  onClickSignInWithAppleAnalyticsEvent: string;
  onClickSignInWithFacebook: () => void;
  onClickSignInWithFacebookAnalyticsEvent: string;
  onClickSignUp: () => void;
  onClickSignUpAnalyticsEvent: string;
  onFormChange?: () => void;
  onFormSubmit: FormikConfig<Values>['onSubmit'];
  onFormSubmitAnalyticsEvent: string;
};

export function SignInForm<Values extends { [key: string]: string }>(props: Props<Values>) {
  return (
    <S.SignInForm>
      <S.TopAuraImage src={Assets.images.authAuraSmall.url} />
      <S.Header>
        <S.IconContainer>
          <S.IconImage alt={Assets.images.logoWithText.alt} src={Assets.images.logoWithText.url} />
        </S.IconContainer>
        <S.TextContainer>
          <S.SignupText>Don’t have an account?</S.SignupText>
          <S.SignupButton
            data-analytics-event={props.onClickSignUpAnalyticsEvent}
            data-testid="sign-in"
            onClick={props.onClickSignUp}
          >
            SIGN UP
          </S.SignupButton>
        </S.TextContainer>
      </S.Header>

      <S.FormWrapper>
        <S.FormBlurView>
          <S.LargeText data-testid="signin-title">Sign In</S.LargeText>
          {props.callToAction ? (
            <S.CallToActionText>{props.callToAction}</S.CallToActionText>
          ) : null}
          <S.Form>
            <Form
              formError={props.formError}
              formInitialValues={props.formInitialValues}
              formInputs={props.formInputs}
              formValidationSchema={props.formValidationSchema}
              isLoading={props.isLoading}
              renderAfterFormInputs={() => (
                <S.ForgotPasswordWrapper>
                  <S.ForgotPasswordButton
                    data-analytics-event={props.onClickForgotPasswordAnalyticsEvent}
                    type="button"
                    onClick={props.onClickForgotPassword}
                  >
                    Forgot Password?
                  </S.ForgotPasswordButton>
                </S.ForgotPasswordWrapper>
              )}
              submitButtonText="SIGN IN"
              onFormChange={props.onFormChange}
              onFormSubmit={props.onFormSubmit}
              onFormSubmitAnalyticsEvent={props.onFormSubmitAnalyticsEvent}
            >
              <S.SignInWithWrapper>
                <S.HR />
                <S.SignInWithText>OR SIGN IN WITH</S.SignInWithText>
                <S.HR />
              </S.SignInWithWrapper>

              <S.ButtonWrapper>
                <S.Button>
                  <AuthButton
                    data-analytics-event={props.onClickSignInWithAppleAnalyticsEvent}
                    platform="apple"
                    onClick={props.onClickSignInWithApple}
                  />
                </S.Button>
                <S.Button>
                  <AuthButton
                    data-analytics-event={props.onClickSignInWithFacebookAnalyticsEvent}
                    platform="facebook"
                    onClick={props.onClickSignInWithFacebook}
                  />
                </S.Button>
                <S.Button>
                  <div id="google-button" />
                </S.Button>
              </S.ButtonWrapper>
            </Form>
          </S.Form>
        </S.FormBlurView>
      </S.FormWrapper>
      <S.BottomAuraImage src={Assets.images.authAuraBig.url} />
    </S.SignInForm>
  );
}
