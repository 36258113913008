import { Button, ButtonVariantSizes, ButtonVariants } from '@Cortex';
import React from 'react';
import PinField from 'react-pin-field';
import { fontFamily } from '../../styles/typography';
import { Assets } from '../../utils/assets';
import { VariantStates } from '../Cortex/components/Button/Button';
import * as S from './Activation.styles';

type Props = {
  pin: string;
  isLoading: boolean;
  isErrored: boolean;
  onPinChange: (code: string) => void;
  onSubmit: () => void;
};

export const ActivationPresentation = (props: Props) => {
  const { onPinChange, pin, isLoading, isErrored, onSubmit } = props;
  return (
    <S.Wrapper>
      <S.Circles
        alt={Assets.images.activationCircles.alt}
        src={Assets.images.activationCircles.url}
      />
      <S.Title>Activate Account</S.Title>
      <S.Description>Enter your activation code below.</S.Description>
      <S.PinWrapper>
        <PinField
          length={10}
          style={{
            borderRadius: '8px',
            background: 'rgba(255, 255, 255, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            padding: '10px 0',
            maxWidth: '57px',
            minWidth: 0,
            width: 'auto',
            flexShrink: 1,
            maxHeight: '70px',
            textAlign: 'center',
            fontSize: '2rem',
            color: '#fff',
            fontFamily: fontFamily.regular,
            outline: 'none',
          }}
          validate={/^[a-zA-Z0-9-_.,@#$%^&*!]$/}
          onChange={onPinChange}
        />
      </S.PinWrapper>
      <S.SubmitWrapper>
        <Button
          disabled={pin.length !== 10 || isLoading}
          isFullWidth={true}
          keepTextCase={true}
          size={ButtonVariantSizes.Medium}
          style={{ fontSize: '0.75rem' }}
          variant={ButtonVariants.Secondary}
          variantState={isLoading ? VariantStates.Loading : VariantStates.Idle}
          onClick={onSubmit}
        >
          SUBMIT
        </Button>
      </S.SubmitWrapper>
      {isErrored && <S.ErrorWrapper>Invalid Activation Code!</S.ErrorWrapper>}
    </S.Wrapper>
  );
};
