import styled from 'styled-components';

import HeartIconSVG from './HeartIcon.svg?react';

export const HeartIcon = styled(HeartIconSVG)<{ fillOpacity: number }>`
  fill: white;
  stroke-width: 1.5;
  opacity: 0.5;
  transition:
    transform 0.15s ease-in-out,
    fill-opacity 0.15s ease-in-out,
    stroke-width 0.15s ease-in-out;

  &:hover {
    stroke-width: 2;
    transform: scale(1.03);
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    width: 1rem;
  }
  fill-opacity: ${({ fillOpacity }) => fillOpacity};
`;
