import styled from 'styled-components';

import { getGAEvent } from '../../utils/analytics/ga';

const TextContainer = styled.div`
  color: rgba(255, 255, 255, 0.75);
  font-size: 12px;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  line-height: 1rem;
  margin: 0.5 0.5rem 0 0.5rem;
  text-align: center;
`;

const StyledLink = styled.a`
  text-decoration: underline;
  color: #ffffff;
  font-size: 12px;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-weight: ${({ theme }) => theme.font.weight.regular};
`;

export const Disclaimer: React.FC = () => {
  return (
    <TextContainer>
      By creating an account, you agree to brain.fm&apos;s{' '}
      <StyledLink
        data-analytics-event={getGAEvent('Signin', 'terms_and_conditions')}
        href="https://try.brain.fm/terms"
        target="_blank"
      >
        Terms & Conditions
      </StyledLink>{' '}
      and{' '}
      <StyledLink
        data-analytics-event={getGAEvent('Signin', 'privacy_policy')}
        href="https://try.brain.fm/privacy"
        target="_blank"
      >
        Privacy Policy
      </StyledLink>
      .
    </TextContainer>
  );
};
