import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ animationState: TransitionStatus }>`
  animation: ${({ theme }) => theme.animations.fadeIn} 0.3s ease-in-out;
  visibility: ${({ animationState }) => (animationState == 'entered' ? 'visible' : 'hidden')};
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${({ theme }) => theme.mediaQuery.maxWidth.xs} {
    flex-direction: column-reverse;
  }
`;

export const ButtonWrapper = styled.div`
  flex-direction: row;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  position: absolute;
  top: -7.5rem;
  width: 20rem;
  height: 20rem;
  align-self: center;
  z-index: 2;
`;

export const AuraImage = styled.img`
  position: absolute;
  border-radius: 10px;
  top: 0rem;
  width: 100%;
  height: 14rem;
  align-self: center;
  z-index: 1;
`;
