import { Button, ButtonVariants } from '@Cortex';
import { NOOP } from '@Globals';
import WarningSVG from './assets/warning_pulse.svg';

import { ModalContainer } from '../../../../../shared/ModalContainer';
import * as S from './Confirmation.styles';

interface Props {
  headerText?: string;
  bodyText?: string;
  isDisabled?: boolean;
  onClose?: () => void;
  onContinue?: () => void;
}

export const Confirmation = ({
  headerText = '',
  bodyText = '',
  isDisabled = false,
  onClose = NOOP,
  onContinue = NOOP,
}: Props) => {
  return (
    <ModalContainer
      description={bodyText}
      headerImage={WarningSVG}
      headerStyles={{ height: '13.5rem', padding: '2rem', marginBottom: '0.8rem' }}
      title={headerText}
      onClose={onClose}
    >
      <S.SubmitButton>
        <Button
          data-testid="confirmDeleteAccount"
          disabled={isDisabled}
          isFullWidth={true}
          keepTextCase
          style={{ fontSize: '0.75rem' }}
          type="button"
          variant={ButtonVariants.Secondary}
          onClick={onContinue}
        >
          DELETE MY ACCOUNT
        </Button>
      </S.SubmitButton>
      <Button
        keepTextCase
        style={{ fontSize: '0.75rem' }}
        type="button"
        variant={ButtonVariants.Base}
        onClick={onClose}
      >
        GO BACK
      </Button>
    </ModalContainer>
  );
};
