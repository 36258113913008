import { Checkmark } from '../SignupBenefits/components/Checkmark';
import * as S from './TeamsSignupBenefits.styles';

export const TeamsSignupBenefits = () => {
  return (
    <S.Container>
      <S.TextContainer>
        <S.SmallTextCaps>Welcome to the team, let's amplify your focus together.</S.SmallTextCaps>
        <S.LargeText>Invited? Join for free!</S.LargeText>
      </S.TextContainer>

      <S.CheckmarksContainer>
        <Checkmark
          description="Dive into an extensive library of 1000+ tracks, spanning various genres and nature soundscapes, all curated to elevate your team's focus, relaxation, and productivity."
          title="Company-Wide Unlimited Sessions"
        />
        <Checkmark
          description="Fine-tune your auditory experience with science-based enhancements like Neural Effect Level, designed to propel your team to new heights of productivity and efficiency."
          title="Access Features for Peak Team Performance"
        />
        <Checkmark
          description="Download our mobile app, compatible with both iPhone and Android, and make productivity your companion wherever you go."
          title="Mobile App for Team On-the-Go"
        />
      </S.CheckmarksContainer>
    </S.Container>
  );
};
