import { useCallback } from 'react';
import { useGet } from '../../client/client';
import { Endpoints } from '../../common/endpoints';
import { FastSubscriptionResponseType } from './types';

export const useRequestHandler = () => {
  const get = useGet<FastSubscriptionResponseType>();

  return useCallback(async () => {
    const data = await get({
      path: Endpoints.getFastSubscriptionInfo(),
      options: { apiVersion: 2 },
    });
    return data.result;
  }, [get]);
};
