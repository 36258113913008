import { Assets } from '../../../../utils/assets';
import { TimerBreakSoundTypes, TimerPomodorIntervals } from './types';

export const timerTimes = [
  {
    label: '30 min',
    value: '30',
  },
  {
    label: '1 hr',
    value: '60',
  },
  {
    label: '2 hrs',
    value: '120',
  },
];

export const intervalWorkTimes = [
  {
    label: '25 min',
    value: '25',
  },
  {
    label: '30 min',
    value: '30',
  },
  {
    label: '35 min',
    value: '35',
  },
  {
    label: '40 min',
    value: '40',
  },
];

export const intervalRestTimes = [
  {
    label: '5 min',
    value: '5',
  },
  {
    label: '10 min',
    value: '10',
  },
  {
    label: '15 min',
    value: '15',
  },
  {
    label: '20 min',
    value: '20',
  },
];

export const DefaultPomodoroIntervals: TimerPomodorIntervals = {
  focus: { label: 'custom ', value: '25' },
  break: { label: 'custom ', value: '5' },
};

export const IntervalTimerBreakSounds: TimerBreakSoundTypes[] = [
  {
    type: 'voice',
    title: 'Voice',
    description: '',
    audioUrl: Assets.sounds.voiceBreakEnd.url,
  },
  {
    type: 'chime',
    title: 'Chime',
    description: '',
    audioUrl: Assets.sounds.chime.url,
  },
];
