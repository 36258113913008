import { ProfileMenuItemUrlPaths } from '@User';
import { useSelector } from 'react-redux';

import { setModal } from '../../../../actions/ui';
import { RootReducerType } from '../../../../reducers';
import { VerifyEmailModalDisplay } from './VerifyEmailModal.display';

export function VerifyEmailModal() {
  const { modalType } = useSelector((state: RootReducerType) => state.ui);

  return (
    <VerifyEmailModalDisplay
      description="Please verify your email address to access Brain.FM with Your Teams subscription"
      isOpen={modalType === 'verifyEmail'}
      primaryButtonAction={setModal(null)}
      primaryButtonHref={ProfileMenuItemUrlPaths.Account}
      primaryButtonText={'Verify'}
      title="Your email is not verified"
    />
  );
}
