import { Button, ButtonVariants } from '@Cortex';
import { NOOP } from '@Globals';
import { pluralize } from '@Utils';

import * as S from './GracePeriodCallToAction.styles';

export type Props = {
  daysRemaining: number;
  hoursRemaining: number;
  minutesRemaining: number;
  onClickUpdatePaymentDetails?: () => void;
};

export function GracePeriodCallToActionPresentation({
  daysRemaining,
  hoursRemaining,
  minutesRemaining,
  onClickUpdatePaymentDetails = NOOP,
}: Props) {
  return (
    <S.Container>
      <S.HeaderTextSubtitle>
        It looks like your default payment method is no longer working! No worries, we’ve provided
        you a grace period to get any details updated.
      </S.HeaderTextSubtitle>
      <S.HeaderTextTitle>Time remaining to update your payment details:</S.HeaderTextTitle>
      <S.TimeRemaining>
        <S.TimeValue>
          <S.TimeValueBig>{daysRemaining}</S.TimeValueBig>
          <S.TimeValueSmall>{pluralize('days', daysRemaining, false)}</S.TimeValueSmall>
        </S.TimeValue>
        <S.TimeColon>:</S.TimeColon>
        <S.TimeValue>
          <S.TimeValueBig>{hoursRemaining}</S.TimeValueBig>
          <S.TimeValueSmall>{pluralize('hrs', hoursRemaining, false)}</S.TimeValueSmall>
        </S.TimeValue>
        <S.TimeColon>:</S.TimeColon>
        <S.TimeValue>
          <S.TimeValueBig>{minutesRemaining}</S.TimeValueBig>
          <S.TimeValueSmall>min</S.TimeValueSmall>
        </S.TimeValue>
      </S.TimeRemaining>
      <div>
        <Button
          keepTextCase={true}
          style={{ fontSize: '0.75rem' }}
          variant={ButtonVariants.Secondary}
          onClick={onClickUpdatePaymentDetails}
        >
          UPDATE PAYMENT DETAILS
        </Button>
      </div>
    </S.Container>
  );
}
