import { NOOP } from '@Globals';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Analytics } from '../../../../utils/analytics';
import { ExploreEvents } from '../../../../utils/analytics/events';
import { dynamicSearchTracks } from '../../actions';
import { SearchBarDisplay } from './SearchBar.display';
import { RootReducerType } from '../../../../reducers';

interface Props {
  onSelect: (value: boolean) => void;
  onClearSearch: () => void;
}

export const SearchBar = ({ onSelect = NOOP, onClearSearch = NOOP }: Props) => {
  const dispatch = useDispatch();
  const mentalStateId = useSelector(
    (state: RootReducerType) => state.ui.sideDeck.exploreSelectedMentalState?.id,
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchIsActive, setSearchActive] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    return () => {
      // clear results on dismount.
      onClearSearch();
    };
  }, []);

  useEffect(() => {
    if (inputValue.length > 2) {
      Analytics.logEventWithProperties(ExploreEvents.SearchForTracks, {
        query: inputValue,
      } as any);

      dispatch(dynamicSearchTracks({ query: inputValue }));
    }
  }, [dispatch, inputValue, mentalStateId]);

  const handleActive = (value: boolean) => {
    onSelect(value);
    setSearchActive(value);
  };

  const handleCloseIconClick = () => {
    setInputValue('');
    handleActive(false);
    onClearSearch();
  };

  const handleSearchIconClick = () => {
    setSearchActive(true);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <SearchBarDisplay
      handleCloseIconClick={handleCloseIconClick}
      handleSearchIconClick={handleSearchIconClick}
      inputRef={inputRef}
      inputValue={inputValue}
      isActive={searchIsActive}
      onInputChange={handleInputChange}
      onInputFocus={() => handleActive(true)}
    />
  );
};
