import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Metric } from '../../../types';

export enum MembershipSources {
  Apple = 'Apple',
  Google = 'Google',
  Loading = 'Loading',
  Stripe = 'Stripe',
  GiftUp = 'GiftUp',
  Trial = 'Trial',
  Unknown = 'Unknown',
}

export enum MembershipStates {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Inactive = 'Inactive',
  Unknown = 'Unknown',
}

export enum MembershipPromotionTypes {
  RedemptionCode = 'RedemptionCode',
  StripeCoupon = 'StripeCoupon',
}

// TODO refactor this into coupon vs promotion code, or determine if we can remove one or the other
// both seems too much.
export type StripeCouponMembershipPromotion = {
  couponId?: string;
  description: string;
  displayCost: string;
  expirationDate?: Metric;
  name: string;
  promotionCode?: string;
  redirectToPath?: string;
  targetPlan: string;
  type: MembershipPromotionTypes.StripeCoupon;
};

export type RedemptionCodeMembershipPromotion = {
  redemptionCode: string;
  type: MembershipPromotionTypes.RedemptionCode;
};

export type MembershipPromotion =
  | StripeCouponMembershipPromotion
  | RedemptionCodeMembershipPromotion;

export type MembershipState = {
  cardLastFourDigits: string | null;
  cardType: string | null;
  currentPeriodEndDate: string | null;
  currentPeriodEndTimestamp: number | null;
  promotion: MembershipPromotion | null;
  renewalPrice: string | null;
  renewsOn: string | null;
  source: MembershipSources | null;
  state: MembershipStates | null;
  isOnTrial: boolean | null; // null - in case of unknown
  autoRenewActive: boolean | null; // null - in case of unknown
  isPermanentlyCancelled: boolean | null; // null - in case of unknown
};

export const initialState: MembershipState = {
  cardLastFourDigits: null,
  cardType: null,
  currentPeriodEndDate: null,
  currentPeriodEndTimestamp: null,
  promotion: null,
  renewalPrice: null,
  renewsOn: null,
  source: null,
  state: null,
  isOnTrial: null,
  autoRenewActive: null,
  isPermanentlyCancelled: null,
};

const { actions: membershipActions, reducer: membershipReducer } = createSlice({
  name: 'MEMBERSHIP_V2',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    fetchMembership: state => {
      return {
        ...initialState,
        promotion: state.promotion,
        source: MembershipSources.Loading,
      };
    },
    membershipInitiallyFetched: state => state,
    receiveMembership: (state, action: PayloadAction<Omit<MembershipState, 'promotion'>>) => {
      return {
        ...state,
        cardLastFourDigits: action.payload.cardLastFourDigits,
        cardType: action.payload.cardType,
        currentPeriodEndDate: action.payload.currentPeriodEndDate,
        renewalPrice: action.payload.renewalPrice,
        renewsOn: action.payload.renewsOn, // TODO deprecated
        source: action.payload.source,
        state: action.payload.state,
        currentPeriodEndTimestamp: action.payload.currentPeriodEndTimestamp,
        autoRenewActive: action.payload.autoRenewActive,
        isOnTrial: action.payload.isOnTrial,
        isPermanentlyCancelled: action.payload.isPermanentlyCancelled,
      };
    },
    receiveMembershipError: () => {
      return {
        ...initialState,
        source: MembershipSources.Unknown,
        state: MembershipStates.Unknown,
      };
    },
    receivePromotion: (state, action: PayloadAction<MembershipPromotion | null>) => {
      return {
        ...state,
        promotion: action.payload,
      };
    },
  },
});

export { membershipActions, membershipReducer };
