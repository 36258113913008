import { useDispatch } from 'react-redux';

import { PomodoroIntervals } from './constants';
import { timerActions } from './reducer';

export function useStorePomodoroIntervals() {
  const dispatch = useDispatch();

  return function storePomodoroIntervals(intervals: Partial<PomodoroIntervals>) {
    dispatch(timerActions.setPomodoroIntervals(intervals));
  };
}
