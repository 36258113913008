import { useTimeOfDayExperiment } from '../../../domains/Utils/useAmplitudeExperiments';

const TOD_CAPS = '$TOD$';
const TOD_LOWER = '$tod$';

export const useGetRecommendedActivitiesTitle = () => {
  const { title, subtitle } = useTimeOfDayExperiment();
  const currentHour = new Date().getHours();

  let timeCaps = '';

  if (currentHour >= 4 && currentHour < 12) {
    timeCaps = 'Morning';
  } else if (currentHour >= 12 && currentHour < 18) {
    timeCaps = 'Afternoon';
  } else {
    timeCaps = 'Evening';
  }

  const timeLower = timeCaps.toLowerCase();

  return {
    title: title?.replace(TOD_CAPS, timeCaps).replace(TOD_LOWER, timeLower) || null,
    subtitle: subtitle?.replace(TOD_CAPS, timeCaps).replace(TOD_LOWER, timeLower) || null,
  };
};
