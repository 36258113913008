import styled from 'styled-components';

export const Container = styled.div<{ hasSelected?: boolean }>`
  border: 1px solid ${props => (props.hasSelected ? '#d4d2ea' : 'rgba(255, 255, 255, 0.2)')};
  border-radius: 0.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 0;
  padding: 1rem;
  transition: background 0.2s ease-in-out;
  width: 100%;
  gap: 1rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.5);
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioSelected = styled.div`
  align-items: center;
  align-self: center;
  border: 12px solid white;
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
  height: 2.5rem;
  justify-content: center;
  margin-left: 1rem;
  width: 2.5rem;
`;

export const RadioUnselected = styled.div`
  align-items: center;
  align-self: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
  height: 2.5rem;
  justify-content: center;
  margin-left: 1rem;
  width: 2.5rem;
`;
