import {
  Button,
  ButtonVariants,
  ButtonVariantStates,
  FontColors,
  Icon,
  Text,
  TextSemiBold,
} from '@Cortex';
import { CouponCode } from '@Payment';
import React, { useState } from 'react';
import Logo from '../../../../../../../../assets/images/brand_logo.svg';

import { EnhancedMembershipPlanType } from '../../../../../../../../types';
import { GiftCard } from '../../../../../GiftCard';
import { Testimonials } from '../Testimonials/Testimonials';
import { ButtonWrapper } from './SingleScreenSlideUpPaywallPlanSelection.styles';
import * as S from './SingleScreenSlideUpPaywallPlanSelection.styles';
import { Checkmark } from '../Checkmark/Checkmark';
import CloseIcon from '../../../../../../../../assets/images/close_icon.svg';
import { OnboardingTestimonials } from '../../../../../../../Onboarding/types';
import { PlanCard } from '../PlanCard/PlanCard';
import { useLocation, useNavigate } from 'react-router-dom';

export interface Props {
  isCloseButtonAvailable: boolean;
  onClose: () => void;
  onSubmit: () => void;
  variantState: ButtonVariantStates;
}

export const SingleScreenSlideUpPaywallPlanSelectionDisplay: React.FC<Props> = ({
  isCloseButtonAvailable,
  onClose,
  onSubmit,
  variantState,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [couponSectionVisible, setCouponSectionVisible] = useState(false);

  const shouldRedirectBack = Boolean(location.state?.redirectBack);

  return (
    <S.Container>
      <S.HeaderWrapper>
        <S.HeaderSpacer />
        <S.HeaderIconContainer>
          <Icon size={56} src={Logo} />
        </S.HeaderIconContainer>
        {!isCloseButtonAvailable ? null : (
          <S.CloseIconContainer
            onClick={shouldRedirectBack ? () => navigate(location.state?.redirectBack) : onClose}
          >
            <S.CloseIcon src={CloseIcon} />
          </S.CloseIconContainer>
        )}
      </S.HeaderWrapper>

      <S.PaymentContainer>
        <S.LeftBoxContainer>
          <S.LeftBoxHeaderIconContainer>
            <Icon size={56} src={Logo} />
          </S.LeftBoxHeaderIconContainer>
          <S.AboveTitle>Limited Time offer: 50% off</S.AboveTitle>
          <S.Title>Black Friday</S.Title>
          <S.Description size="18px">
            Get 50% off the Brain.fm <b>yearly plan</b> and experience music scientifically proven
            to increase focus.
          </S.Description>
          <S.DealBox>
            <S.Badge>YEARLY PLAN</S.Badge>
            <S.PriceBlock>
              <S.Price>
                <sup>$</sup>34.99
              </S.Price>
              <S.PreviousPrice>
                <sup>$</sup>69.99
              </S.PreviousPrice>
            </S.PriceBlock>
            <S.DealDescription>
              14-Day Free Trial <i>then $2.91/month ($34.99/year)</i>
            </S.DealDescription>
            <S.ButtonWrapper>
              <Button
                data-testid="handlePurchase"
                isFullWidth
                keepTextCase
                type="button"
                variant={ButtonVariants.Secondary}
                variantState={variantState}
                onClick={onSubmit}
              >
                Get 50% Off Now
              </Button>
            </S.ButtonWrapper>
            <S.OfferExpiration>Cancel anytime; Offer expires on 11/30/2024</S.OfferExpiration>
          </S.DealBox>
        </S.LeftBoxContainer>

        <S.RightBoxContainer />
        <S.DesktopImage src="https://cdn.brain.fm/images/bfbg.webp" />
      </S.PaymentContainer>
      <S.Checkmarks>
        <Checkmark>Access to all of Brain.fm’s Science-Backed Music</Checkmark>
        <Checkmark>Boost Your Productivity Anytime, Anywhere</Checkmark>
        <Checkmark>Block Distractions and Stay Energized</Checkmark>
      </S.Checkmarks>
    </S.Container>
  );
};
