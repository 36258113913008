import styled from 'styled-components';

import CloseSvg from './assets/close.svg?react';

export const Wrapper = styled.div<{ hasHoverEffect?: boolean }>`
  transition: opacity 0.2s;
  ${({ hasHoverEffect }) =>
    hasHoverEffect &&
    `
    opacity: 0.6;
    &:hover { opacity: 1; }`};
`;

export const CloseIcon = styled(CloseSvg)`
  cursor: pointer;
`;
