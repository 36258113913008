import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { ResetPasswordForm } from './components/Form/ResetPasswordForm';
import { SigninText } from './components/SigninText';

export const ResetPassword: React.FC = () => {
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.SignInContainerLG>
        <SigninText onClick={() => navigate('/signin', { replace: true })} />
      </S.SignInContainerLG>

      <ResetPasswordForm navigate={navigate} />

      <S.SignInContainerSM>
        <SigninText onClick={() => navigate('/signin', { replace: true })} />
      </S.SignInContainerSM>
      <S.BackgroundImage />
    </S.Container>
  );
};
