import { QuizToastDisplay } from './QuizToast.display';

interface Props {
  onClickOpenPreferences: () => void;
  onClose?: () => void;
  shouldShowButtonToOpenPreferences: boolean;
}

export const QuizToast = (props: Props) => {
  return (
    <QuizToastDisplay
      shouldShowButtonToOpenPreferences={props.shouldShowButtonToOpenPreferences}
      welcomeText="We've updated your music listening experience based on your answers. You can change these at any time by opening the preferences panel."
      onClickOpenPreferences={props.onClickOpenPreferences}
      onClose={props.onClose}
    />
  );
};
