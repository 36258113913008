import { useCallback } from 'react';

import { useGet } from '../../client/client';
import { Endpoints } from '../../common/endpoints';
import { TGiftCardInfoResponse } from './types';

export const useRequestHandler = () => {
  const get = useGet<TGiftCardInfoResponse>();

  return useCallback(
    async (cardCode: string) => {
      return await get({ path: Endpoints.getGiftCardInfo(cardCode) });
    },
    [get],
  );
};
