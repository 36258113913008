import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { TextBold } from '@Cortex';
import { Serving, Track } from '@Model';
import {
  getTrackGenreName,
  getTrackHasMultipleNELs,
  getTrackId,
  getTrackImageUrl,
  getTrackIsNewlyCreated,
  getTrackName,
  getTrackNeuralEffectLevel,
  musicActions,
  TrackInformationCard,
  useApplyFiltersToTracks,
} from '@Music';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useShareTrack } from '../../../../../../hooks/useShareTrack';
import { RootReducerType } from '../../../../../../reducers';
import { Analytics } from '../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../utils/analytics/events';
import { getDynamicSimilarActivityPlayerPath } from '../../../../../../utils/getDynamicSimilarActivityPlayerPath';
import { removeFromFavorites, addToFavorites } from '../../../../actions';
import { NoSearchResults } from '../NoSearchResults/NoSearchResults';
import * as S from './SearchResults.styles';
import { getTrackRelatedActivities } from '../../../../lenses/getTrackRelatedActivities';

type TrackDictionary = {
  [key: string]: Track | Serving;
};

type Props = {
  onTrackClickMore: (track: Track | Serving) => void;
};

export function SearchResults({ onTrackClickMore }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const favorites = useSelector((state: RootReducerType) => state.music.favorites);
  const search = useSelector((state: RootReducerType) => state.music.search);

  const filteredTracks = useApplyFiltersToTracks(search.results || []);

  if (search.state === 'loading') {
    return <S.Loading />;
  }

  if (search.state === 'error' && search.error) {
    return <TextBold>{search.error}</TextBold>;
  }

  // sets up a dictionary for efficient lookup of favorite track
  const favoritesDictionary = favorites.reduce((acc, track) => {
    acc[getTrackId(track)] = track;
    return acc;
  }, {} as TrackDictionary);

  function handleClickPlay(track: Track | Serving) {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromSearch, {
      trackId: getTrackId(track),
      trackName: getTrackName(track),
    } as any);

    navigate(getDynamicSimilarActivityPlayerPath(getTrackId(track)));

    CoreAnalytics.trackSelectTrackEvent({
      origin: TrackActionOrigins.Search,
      trackName: getTrackName(track),
    });
  }

  return (
    <S.CardsContainer>
      {!search.results?.length ? (
        <NoSearchResults title={search.results === null ? 'Start Searching' : 'No Tracks Found'} />
      ) : null}
      {filteredTracks.map(track => {
        return (
          <S.CardContainer key={getTrackId(track)}>
            <TrackInformationCard
              hasMultipleNELs={getTrackHasMultipleNELs(track)}
              imageUrl={getTrackImageUrl(track)}
              isFavorited={!!favoritesDictionary[getTrackId(track)]}
              isNewlyCreated={getTrackIsNewlyCreated(track)}
              moreIconTestId={`trackCardMoreInfo__${getTrackId(track)}`}
              neuralEffectLevel={getTrackNeuralEffectLevel(track)}
              relatedActivities={getTrackRelatedActivities(track)}
              subtitle={getTrackGenreName(track)}
              title={getTrackName(track)}
              onAddToFavorites={() => {
                dispatch(addToFavorites({ origin: TrackActionOrigins.Recents, track }));
              }}
              onClick={() => handleClickPlay(track)}
              onClickMore={() => onTrackClickMore(track)}
              onRemoveFromFavorites={() =>
                dispatch(
                  removeFromFavorites({
                    track,
                  }),
                )
              }
            />
          </S.CardContainer>
        );
      })}
    </S.CardsContainer>
  );
}
