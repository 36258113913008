import { useDispatch, useSelector } from 'react-redux';

import { RootReducerType } from '../../reducers';
import { TimerStatuses } from './constants';
import { timerActions } from './reducer';
import { useStartTimer } from './useStartTimer';

export function useResetTimer(
  params: { shouldPreserveDisplayType?: boolean; shouldRestartRunningTimers?: boolean } = {},
) {
  const timerStatus = useSelector<RootReducerType, RootReducerType['timer']['status']>(
    state => state.timer.status,
  );
  const startTimer = useStartTimer();
  const dispatch = useDispatch();

  return function resetTimer() {
    const shouldRestartAfterReset =
      params.shouldRestartRunningTimers && timerStatus === TimerStatuses.Running;
    dispatch(timerActions.reset(params));
    if (shouldRestartAfterReset) startTimer();
  };
}
