import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const CheckMarkIconContainer = styled.div`
  margin-right: 1.5rem;
`;

export const TextContainer = styled.div`
  flex: 1;
`;

export const IconContainer = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  background: linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1.25rem;
  color: #fff;
  margin-bottom: 0.5rem;
  text-transform: lowercase;
`;

export const Description = styled.p`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.825rem;
  color: #d4d2ea;
`;
