import { DynamicMentalState } from '@Model';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../reducers';

export type Session = {
  mentalState: Omit<
    DynamicMentalState,
    | 'defaultActivity'
    | 'yourFirstSessionActivity'
    | 'yourFirstSessionSkipActivity'
    | 'yourFirstSessionFavoritesActivity'
  >;
} | null;

export function getDynamicSession(state: RootReducerType): Session {
  const mentalState = state.sessionManager.sessionDynamicActivity?.mentalState;
  if (!mentalState) return null;

  return { mentalState };
}

export function useDynamicSession() {
  const mentalState = useSelector(
    (state: Partial<RootReducerType>) => state.sessionManager?.sessionDynamicActivity?.mentalState,
  );

  if (!mentalState) return null;
  return { mentalState };
}
