import { membershipActions } from '@Memberships';
import { teamsActions } from '@Teams';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as userActions from '../../../actions/user';
import { RootReducerType } from '../../../reducers';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { TJoinTeamResponse } from './types';
import { useRequestHandler } from './useRequestHandler';

export const useJoinTeam = (teamId: string) => {
  const postJoinTeam = useRequestHandler();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);
  const navigate = useNavigate();

  return useMutation<TJoinTeamResponse, Error>(
    Queries.postJoinTeam(teamId),
    () => postJoinTeam(teamId),
    {
      onError: () => {
        Logger.error(new Error('Error joining the team'));
      },
      onSuccess: data => {
        if (data.status !== 200) {
          if (data.messages?.length) {
            Logger.info(data.messages[0]);
          }
          navigate('/');
          return;
        }
        queryClient.invalidateQueries(Queries.getUserTeams(userId!));
        dispatch(teamsActions.getTeams());
        dispatch(membershipActions.fetchMembership());
        dispatch(userActions.getInfo());
      },
    },
  );
};
