import { DynamicActivity, Serving, Track } from '@Model';

import { isServing } from './isServing';

export function getTrackRelatedActivities(
  trackOrServing?: Track | Serving | null,
): DynamicActivity[] {
  if (isServing(trackOrServing)) {
    return trackOrServing.track.relatedActivities;
  }

  return trackOrServing?.relatedActivities || [];
}
