import { TextBold, TextSemiBold } from '@Cortex';
import { NOOP } from '@Globals';

import * as S from './TrackTag.styles';

interface Props {
  icon?: HTMLImageElement['src'];
  name: string;
  onClick?: () => void;
}

export const TrackTag = ({ name, onClick = NOOP, icon }: Props) => {
  return (
    <S.Container onClick={onClick}>
      <S.InnerContainer>
        {icon ? (
          <S.ImageContainer>
            <S.Image src={icon} />
          </S.ImageContainer>
        ) : null}
        <TextSemiBold size="1rem">{name}</TextSemiBold>
      </S.InnerContainer>
    </S.Container>
  );
};
