import styled, { keyframes, css } from 'styled-components';

const bottomBar = keyframes`
  0% {
    opacity: 0.4;
  }
  14.28% {
    opacity: 1;
  }
  28.56% {
    opacity: 1;
  }
  42.84% {
    opacity: 1;
  }
  57.12% {
    opacity: 1;
  }
  71.4% {
    opacity: 1;
  }
  85.68% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
`;
const middleBar = keyframes`
  0% {
    opacity: 0.4;
  }
  14.28% {
    opacity: 0.4;
  }
  28.56% {
    opacity: 1;
  }
  42.84% {
    opacity: 1;
  }
  57.12% {
    opacity: 1;
  }
  71.4% {
    opacity: 1;
  }
  85.68% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.4;
  }
`;
const topBar = keyframes`
  0% {
    opacity: 0.4;
  }
  14.28% {
    opacity: 0.4;
  }
  28.56% {
    opacity: 0.4;
  }
  42.84% {
    opacity: 1;
  }
  57.12% {
    opacity: 1;
  }
  71.4% {
    opacity: 0.4;
  }
  85.68% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.4;
  }
`;

const animationDuration = 3;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 0.6rem;
`;

const baseCss = css`
  border-radius: 3px;
  height: 3px;
  margin-bottom: 2px;
  background: radial-gradient(134.6% 115.52% at 94.1% -15.52%, #ff496b 0%, #4915f8 100%), #ffffff;
`;
export const BottomBar = styled.div`
  animation: ${bottomBar} ${animationDuration}s linear infinite;
  ${baseCss};
`;
export const MiddleBar = styled.div`
  animation: ${middleBar} ${animationDuration}s linear infinite;
  ${baseCss};
`;
export const TopBar = styled.div`
  animation: ${topBar} ${animationDuration}s linear infinite;
  ${baseCss};
`;
