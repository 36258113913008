export enum UserTeamRoles {
  Member = 'member',
  Admin = 'admin',
  Owner = 'owner',
}

export type UserTeamType = {
  id: string;
  title: string;
  createdAt: number;
  updatedAt: string | null;
  hasPlatformAccess: boolean;
  role: UserTeamRoles;
};

export type TeamType = {
  id: string;
  title: string;
  createdAt: number;
  updatedAt: string | null;
  hasPlatformAccess: boolean;
};

export type TeamsState = {
  list: UserTeamType[];
  isFetched: boolean;
};
