import { AMPLITUDE_KEY } from '@Globals';
import Amplitude from 'amplitude-js';
import { useEffect } from 'react';

export function useSetDeviceIdFromCookie() {
  useEffect(() => {
    const onPageLoad = () => {
      const cookies = document.cookie.split(';');

      const amplitudeDeviceIdFromCookie = cookies.find(elem => elem.trim().startsWith('deviceId='));

      if (amplitudeDeviceIdFromCookie) {
        const splitToken = amplitudeDeviceIdFromCookie.split('=');

        if (splitToken[1]) {
          Amplitude.getInstance().init(AMPLITUDE_KEY, undefined, { deviceId: splitToken[1] });
        }
      }
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => document.removeEventListener('load', onPageLoad);
    }
  }, []);
}
