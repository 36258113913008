import { useToken } from '@Authentication';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { get, post } from '../../../api/client/client';
import { RootReducerType } from '../../../reducers';
import { userSliceActions } from '../../../reducers/user';
import { UserStateType } from '../../../types';
import { useGetAvailableRedemptionCodePromotion } from '../lenses/getAvailableRedemptionCodePromotion';
import { membershipActions, MembershipState } from '../reducer';

// TODO - Test Cover This.
// TODO - Handle Error pathways.
export function useApplyRedemptionCode() {
  const dispatch = useDispatch();
  const token = useToken();
  const userId = useSelector((state: RootReducerType) => {
    return state.user.info?.id;
  });
  const availableRedemptionCode = useGetAvailableRedemptionCodePromotion();

  return useCallback(async () => {
    if (availableRedemptionCode) {
      const { result } = await post<
        { userId?: string },
        { result: Omit<MembershipState, 'promotion'> }
      >({
        path: `/membership/redemption-codes/${availableRedemptionCode.redemptionCode}/redeem`,
        body: {
          userId,
        },
        token,
      });

      if (result) {
        dispatch(membershipActions.receiveMembership(result));
        dispatch(membershipActions.receivePromotion(null));
      }
      const { result: meResult } = await get<{ result: UserStateType }>({
        path: '/users/me',
        token,
        options: {
          apiVersion: 2,
        },
      });
      if (meResult.membership) {
        dispatch(userSliceActions.setMembershipInfo(meResult.membership));
      }
    }
  }, [availableRedemptionCode]);
}
