import { useTimerDisplayValue } from '@Timer';
import { useDefaultDisplayType, UserPreferenceDisplayTypes } from '@User';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { formatTime } from '../components/session/utils/formatTime';
import { DYNAMIC_PLAYER_ACTIVITY_PATH } from '../constants';
import { RootReducerType } from '../reducers';
import { getBasicPageTitle } from '../utils/getBasicPageTitle';

export const useHandlePageTitle = () => {
  const baseTitle = getBasicPageTitle();
  const timerDisplayValue = useTimerDisplayValue();
  const defaultDisplayType = useDefaultDisplayType();
  const { pathname } = useLocation();
  const { currentTrackTimeStamp, sessionPlayType, timerLength, timerPlayTime } = useSelector(
    (state: RootReducerType) => state.sessionManager,
  );
  const basePlayerRouteMatch = matchPath(DYNAMIC_PLAYER_ACTIVITY_PATH, pathname);

  const shouldRenderQuotes =
    defaultDisplayType === UserPreferenceDisplayTypes.Quotes && sessionPlayType !== 'TIMER';

  let timer: string;

  if (sessionPlayType === 'TIMER') {
    const totalTimerPlayTime = (timerPlayTime || 0) + currentTrackTimeStamp;
    const timerDisplay = (timerLength || 0) - totalTimerPlayTime;
    timer = formatTime(timerDisplay >= 0 ? timerDisplay : 0);
  } else {
    timer = timerDisplayValue;
  }

  useEffect(() => {
    if (shouldRenderQuotes || !basePlayerRouteMatch) {
      document.title = baseTitle;
      return;
    }

    document.title = `${baseTitle} (${timer})`;
  }, [timerDisplayValue, shouldRenderQuotes, basePlayerRouteMatch]);
};
