import { trackPurchase } from '@Analytics';
import { membershipActions } from '@Memberships';
import { CardElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Dispatch } from 'react';
import * as analyticsActions from '../../../../../actions/analytics';
import * as uiActions from '../../../../../actions/ui';
import { EnhancedMembershipPlanType } from '../../../../../types';
import { getErrorMessage } from '../../../../../utils/getErrorMessage';
import {
  trackSubscriptionStartAttempt,
  trackSubscriptionStartError,
  trackSubscriptionStartSuccess,
} from '../../../../Analytics/coreAnalytics';
import { PlanTypes } from '../../../../Analytics/coreAnalytics.types';
import { PaymentStatus } from '../types';
import { createSubscription } from './createSubscription';
import { getIsYearlyPlan } from './getIsYearlyPlan';
import { getPlanTypeFromDisplayInterval } from './getPlanType';

type Props = {
  email?: string;
  plan: EnhancedMembershipPlanType | null;
  stripe: Stripe | null;
  elements: StripeElements | null;
  token: string | null;
  userId?: string;
  utmSource?: string;
  couponId?: string;
  subscribeScreenFrom?: string;
  dispatch: Dispatch<any>;
  onError: (msg: string | null) => void;
  onPaymentComplete?: () => void;
  onPaymentStatusChange: (status: PaymentStatus) => void;
};

export const performPayment = (props: Props) => async (billingName: string) => {
  const {
    email,
    elements,
    plan,
    stripe,
    token,
    userId,
    onError,
    utmSource,
    couponId,
    subscribeScreenFrom,
    dispatch,
    onPaymentComplete,
    onPaymentStatusChange,
  } = props;

  if (!stripe || !elements || !plan) {
    return null;
  }

  trackSubscriptionStartAttempt({
    couponId: couponId || '',
    planType: getIsYearlyPlan(plan)
      ? PlanTypes.Yearly
      : getPlanTypeFromDisplayInterval(plan.displayInterval),
  });

  dispatch(
    analyticsActions.logEventWithProperties({
      event: 'subscription_purchase',
      props: {
        subscribe_screen_from: subscribeScreenFrom,
        plan_type: plan.title,
      },
    }),
  );

  try {
    onError(null);
    onPaymentStatusChange('loading');

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: billingName || undefined,
      },
    });

    if (error) {
      trackSubscriptionStartError({
        errorMessage: error?.message || 'unknown error',
      });
      onError(error?.message || null);
      return;
    }

    await createSubscription({
      paymentMethodId: paymentMethod?.id,
      email,
      plan,
      stripe,
      token,
      userId,
      onError,
      utmSource: utmSource,
      couponId,
      subscribeScreenFrom,
      dispatch,
    });

    dispatch(uiActions.setModal('subscriptionSuccess'));
    trackSubscriptionStartSuccess({
      couponId: couponId || '',
      planType: getIsYearlyPlan(plan)
        ? PlanTypes.Yearly
        : getPlanTypeFromDisplayInterval(plan.displayInterval),
      isStripeCheckout: false,
    });
    onPaymentStatusChange('success');
    dispatch(membershipActions.fetchMembership());

    trackPurchase({ value: plan.price, userId, planName: plan.title });
    dispatch(membershipActions.receivePromotion(null));
    if (onPaymentComplete) {
      onPaymentComplete();
    }
  } catch (error) {
    trackSubscriptionStartError({
      errorMessage: getErrorMessage(error),
    });
    onPaymentStatusChange('error');
    dispatch(
      analyticsActions.logEventWithProperties({
        event: 'subscription_purchase_error',
        props: {
          subscribe_screen_from: subscribeScreenFrom,
          plan_type: plan.title,
        },
      }),
    );
  } finally {
    setTimeout(() => {
      onPaymentStatusChange('idle');
    }, 1000);
  }
};
