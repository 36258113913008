export function getUiErrorMessage(error: unknown, fallback?: string): string {
  if (error instanceof Error && 'message' in error) {
    return error.message;
  }

  if (typeof error === 'string') {
    return error;
  }

  return fallback || 'Unknown error.';
}
