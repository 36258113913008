import { useMutation } from '@tanstack/react-query';

import { getUiErrorMessage } from '../../../utils/getUiErrorMessage';
import { isOfflineError } from '../../../utils/isOfflineError';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { TResendVerificationEmailResponse } from './types';
import { useRequestHandler } from './useRequestHandler';

type Props = {
  callback?: () => void;
};
export const useResendVerificationEmail = (props: Props) => {
  const { callback } = props;
  const postResendVerificationEmail = useRequestHandler();

  return useMutation<TResendVerificationEmailResponse, Error>(
    Queries.resendVerificationEmail,
    () => postResendVerificationEmail(),
    {
      onError: e => {
        if (isOfflineError(e)) return;
        Logger.error(new Error(getUiErrorMessage(e, 'Error sending verification email')));
      },
      onSuccess: data => {
        if (data.status !== 200) return;
        if (callback) {
          callback();
        }
      },
      cacheTime: 0,
    },
  );
};
