import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 1rem;
  margin-right: 0.25rem;
  cursor: pointer;
`;

//To prevent background bleeding into border
export const InnerContainer = styled.div`
  position: relative;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
  padding: 1rem;

  &:hover {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.08) 100%
    );
  }
`;

export const TextContainer = styled.div`
  width: 75%;
`;

export const Title = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 1.25rem;
  font-weight: 700;
`;

export const Description = styled.div`
  margin-top: 4px;
  color: rgba(255, 255, 255, 0.75);
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 0.875rem;
  font-weight: 400;
`;

export const DescriptionBold = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-weight: 500;
  text-transform: capitalize;
`;

export const Image = styled.img`
  position: absolute;
  top: 50%;
  right: -14.5rem;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    right: -20rem;
  }
`;
