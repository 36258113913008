import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as uiActions from '../../../../actions/ui';
import { RootReducerType } from '../../../../reducers';
import { SuccessModalDisplay } from './SuccessModal.display';

let successModalPortal = document.getElementById('success-modal');
if (!successModalPortal) {
  successModalPortal = document.createElement('div');
  successModalPortal.setAttribute('id', 'success-modal');
  document.body.appendChild(successModalPortal);
}

const Modal: React.FC<{ children: ReactNode | undefined }> = ({ children }) => {
  return createPortal(children, successModalPortal!);
};

export const SuccessModal = () => {
  const { successModal } = useSelector((state: RootReducerType) => state.ui);
  const dispatch = useDispatch();

  useEffect(() => {
    if (successModal.isDismissable) {
      const handleKeyPress = (evt: KeyboardEvent) => {
        if (evt.key === 'Escape') {
          evt.stopPropagation();
          dispatch(uiActions.successModalClose());
        }
      };

      window.addEventListener('keyup', handleKeyPress);

      return () => window.removeEventListener('keyup', handleKeyPress);
    }
  }, [successModal?.isDismissable]);

  return (
    <Modal>
      <SuccessModalDisplay
        actions={successModal.actions}
        description={successModal.description}
        iconSrc={successModal.iconSrc}
        iconType={successModal.iconType}
        isDismissable={successModal?.isDismissable}
        isOpen={successModal.isOpen}
        title={successModal.title}
      />
    </Modal>
  );
};
