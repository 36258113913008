import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setModal } from '../../../../actions/ui';
import { useStreakMilestone } from '../../../../domains/Music/components/AudioControlBar/components/StreaksWidgetModal/hooks/useStreakMilestone';
import { RootReducerType } from '../../../../reducers';
import { StreakMilestone, STREAK_MILESTONES } from '../../../../types';
import { getNextAchievement } from '../../../../utils/getNextAchievement';
import { StreakMilestoneModalDisplay } from './StreakMilestoneModal.display';

export function StreakMilestoneModal() {
  const modalType = useSelector((state: RootReducerType) => state.ui.modalType);
  const streakMilestone = useStreakMilestone();
  const nextAchievement = getNextAchievement<StreakMilestone>(streakMilestone, STREAK_MILESTONES);
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(setModal(null));
  }, [dispatch, setModal]);

  const onCtaClick = useCallback(() => {
    dispatch(setModal('streaksInfoAnimated'));
  }, [dispatch, setModal]);

  const isOpen = modalType === 'streakMilestone';

  return (
    <StreakMilestoneModalDisplay
      isOpen={isOpen}
      nextAchievement={nextAchievement}
      primaryButtonAction={onCtaClick}
      primaryButtonText="CLAIM MY BADGE"
      streakMilestone={streakMilestone}
      onClose={onClose}
    />
  );
}
