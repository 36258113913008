import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { AuthStateType } from '../types';

const initialState: AuthStateType = {
  profileId: null,
  thirdPartyToken: null,
  token: null,
  type: null,

  login: {
    inProgress: false,
    emailLoading: false,
    facebookLoading: false,
    appleLoading: false,
    googleLoading: false,
    error: undefined,
  },

  signup: {
    inProgress: false,
    emailLoading: false,
    facebookLoading: false,
    appleLoading: false,
    googleLoading: false,
    error: undefined,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      return state;
    },

    emailLogin: state => {
      state.login.inProgress = true;
      state.login.emailLoading = true;
      state.login.error = undefined;
      return state;
    },

    appleLogin: state => {
      state.login.inProgress = true;
      state.login.appleLoading = true;
      state.login.error = undefined;
      return state;
    },

    facebookLogin: state => {
      state.login.inProgress = true;
      state.login.facebookLoading = true;
      state.login.error = undefined;
      return state;
    },

    googleLogin: state => {
      state.login.inProgress = true;
      state.login.googleLoading = true;
      state.login.error = undefined;
      return state;
    },

    loginSuccess: (state, action: PayloadAction<Partial<AuthStateType>>) => {
      return {
        ...state,
        login: initialState.login,
        ...action.payload,
      };
    },

    loginFailed: (state, action) => {
      state.login = {
        ...initialState.login,
        error: action.payload,
      };
      return state;
    },

    emailSignup: state => {
      state.signup.inProgress = true;
      state.signup.emailLoading = true;
      state.signup.error = undefined;
      return state;
    },

    appleSignup: state => {
      state.signup.inProgress = true;
      state.signup.appleLoading = true;
      state.signup.error = undefined;
      return state;
    },

    facebookSignup: state => {
      state.signup.inProgress = true;
      state.signup.facebookLoading = true;
      state.signup.error = undefined;
      return state;
    },

    googleSignup: state => {
      state.signup.inProgress = true;
      state.signup.googleLoading = true;
      state.signup.error = undefined;
      return state;
    },

    signupSuccess: (state, action: PayloadAction<Partial<AuthStateType>>) => {
      return {
        ...state,
        signup: initialState.signup,
        ...action.payload,
      };
    },

    signupFailed: (state, action) => {
      state.signup = {
        ...initialState.signup,
        error: action.payload,
      };
      return state;
    },

    clearErrors: state => {
      state.login.error = undefined;
      state.signup.error = undefined;
      return state;
    },

    clearAuthState: () => {
      return initialState;
    },
  },
});

const persistConfig = {
  key: 'auth',
  storage,
  blacklist: ['login', 'signup'],
};

export const authSliceActions = authSlice.actions;
export default persistReducer(persistConfig, authSlice.reducer);
