import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 15px 0;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

export const InformationContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const NeuralEffectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.75rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    margin-bottom: 0.25rem;
  }
`;

export const NeuralEffectLevelIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 0.6rem;
  margin-right: 0.5rem;
`;

export const EffectBar = styled.div<{ isActive: boolean }>`
  background: white;
  border-radius: 3px;
  height: 3px;
  margin-bottom: 2px;
  opacity: ${props => (props.isActive ? '1' : '0.4')};
`;

export const NeuralEffectText = styled.div`
  color: white;
  font-family: TTNormsPro-Regular;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 0.75rem;
  }
`;

export const PillContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    margin-bottom: 0.25rem;
  }
`;

export const GenrePill = styled.div`
  display: flex;
  height: 1.25rem;
  padding: 0.3125rem 0.75rem 0.1875rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.1);
  margin-right: 0.5rem;
`;

export const PillText = styled.div`
  color: rgba(255, 255, 255, 0.75);
  font-family: TTNormsPro-Regular;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const Title = styled.div`
  align-items: center;
  color: white;
  display: flex;
  font-family: TTNormsPro-Bold;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
  transform-origin: center;
  transition: transform 0.15s ease-in-out;
  width: fit-content;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin-right: 24px;
  height: 96px;
  width: 96px;
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    border-radius: 1rem;
    height: 64px;
    width: 64px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.4);
  }
`;

export const Image = styled.img`
  z-index: 10;
  border-radius: 12px;
  height: 96px;
  width: 96px;
  transition: transform 0.15s ease-in-out;
  transform-origin: center;
  object-fit: cover;
  aspect-ratio: 1 / 1;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    border-radius: 1rem;
    height: 64px;
    width: 64px;
  }
`;

export const PlayButton = styled.img`
  width: 2.2rem;
  height: 2.2rem;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &:hover {
    transform: translate(-50%, -50%) scale(1.05);
  }
`;
