import styled, { css } from 'styled-components';
import theme from '../../../../styles/theme';

import { HEADER_HEIGHT, HEADER_PADDING } from './constants';

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${HEADER_HEIGHT + HEADER_PADDING * 2}rem;
  display: flex;
  padding: ${HEADER_PADDING}rem;
  gap: 16px;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const RightContainer = styled.div`
  height: 100%;
  display: flex;
  gap: 1rem;
  ${({ theme }) => theme.mediaQuery.maxWidth.xs} {
    gap: 0;
  }
  justify-content: flex-end;
  align-items: center;
`;

export const LogoContainer = styled.div`
  display: block;
  cursor: pointer;
  transition: transform 0.25s ease-in-out;
  margin-right: 1.5rem;

  &:hover {
    transform: scale(1.05);
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    display: none;
  }
`;

export const BackButtonContainer = styled.div`
  display: block;
  cursor: pointer;
  transition: transform 0.25s ease-in-out;
  margin-right: 1.5rem;

  &:hover {
    transform: scale(1.05);
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    display: none;
  }
`;

export const MobileLogoContainer = styled(LogoContainer)`
  display: none;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    display: block;
  }
`;

export const CallToActionWidgetContainer = styled.div<{
  isMobileView: boolean;
  isSideDeckOpen: boolean;
}>`
  margin-right: 1rem;

  // Custom query for it's in mobile view/ bento view with and without side deck
  ${({ theme, isMobileView, isSideDeckOpen }) => css`
    ${isMobileView && theme.mediaQuery.maxWidth.md} {
      display: none;
    }

    ${!isMobileView && isSideDeckOpen && theme.mediaQuery.maxWidth.xl} {
      display: none;
    }

    ${!isMobileView &&
    !isSideDeckOpen &&
    css`
      @media (max-width: 840px) {
        display: none;
      }
    `}
  `}
`;
export const HeaderIconLabel = styled.span`
  position: absolute;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  bottom: -14px;
  opacity: 0;
  transform: scale(0.4);
  transition: all 0.3s ease-in-out;
`;

export const HeaderIconContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover > ${HeaderIconLabel} {
    bottom: -20px;
    opacity: 1;
    transform: scale(1);
  }
  padding: 8px;
  border-radius: 100%;
  transition: background 0.2s ease-in-out;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.1);
  }
`;
