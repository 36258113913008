import { FALLBACK_TRACK_IMAGE } from '@Globals';
import { EffectLevels } from '@Model';
import * as S from './DemoTrack.styles';
import PlayIcon from './assets/play_circle.svg';
import StopIcon from './assets/stop_circle.svg';

type Props = {
  activity: string;
  imageUrl: string;
  neuralEffectLevel: EffectLevels;
  genre: string;
  title: string;
  isPlaying: boolean;
  onClick: () => void;
};

export const DemoTrackDisplay = (props: Props) => {
  const { activity, isPlaying, imageUrl, neuralEffectLevel, genre, title, onClick } = props;

  return (
    <S.Container>
      <S.ImageWrapper>
        <S.Image
          src={imageUrl}
          onError={evt => {
            evt.currentTarget.src = FALLBACK_TRACK_IMAGE;
          }}
        />
        <S.PlayButton
          alt={isPlaying ? 'Stop' : 'Play'}
          src={isPlaying ? StopIcon : PlayIcon}
          onClick={onClick}
        />
      </S.ImageWrapper>
      <S.InformationContainer>
        <S.Title data-testid="currentTrackTitle">{title}</S.Title>

        <S.NeuralEffectContainer>
          <S.NeuralEffectLevelIconWrapper>
            <S.EffectBar isActive={neuralEffectLevel === EffectLevels.High} />
            <S.EffectBar isActive={neuralEffectLevel !== EffectLevels.Low} />
            <S.EffectBar isActive={true} />
          </S.NeuralEffectLevelIconWrapper>
          <S.NeuralEffectText>{`${neuralEffectLevel} Neural Effect`}</S.NeuralEffectText>
        </S.NeuralEffectContainer>
        <S.PillContainer>
          <S.GenrePill>
            <S.PillText data-testid="trackGenre">{genre}</S.PillText>
          </S.GenrePill>
          <S.GenrePill>
            <S.PillText data-testid="trackGenre">{activity}</S.PillText>
          </S.GenrePill>
        </S.PillContainer>
      </S.InformationContainer>
    </S.Container>
  );
};
