import React from 'react';

import * as S from './styles';
import InfiniteIconSVG from '../../assets/infinite.svg';
import TimerIconSVG from '../../assets/timer.svg';
import IntervalIconSVG from '../../assets/interval.svg';
import { RecentSessionType } from '../../types';

type Props = {
  onClick: () => void;
  recentSession: RecentSessionType;
};

export const RecentSession = ({ onClick, recentSession }: Props) => {
  const sessionTimer = recentSession.timerSpecification;
  let Icon = InfiniteIconSVG;
  let timerDisplay = 'Infinity';

  if (sessionTimer.type === 'countdown') {
    Icon = TimerIconSVG;
    timerDisplay = sessionTimer.durations[0].displayValue;
  } else if (sessionTimer.type === 'interval') {
    Icon = IntervalIconSVG;
    timerDisplay = sessionTimer.durations.map(duration => duration.displayValue).join(', ');
  }

  return (
    <S.Container onClick={onClick}>
      <S.InnerContainer>
        <S.TimerContainer>
          <S.TimerIcon src={Icon} />
          <S.TimerText>{timerDisplay}</S.TimerText>
        </S.TimerContainer>

        <S.Title>{recentSession.dynamicActivity.displayValue}</S.Title>
        <S.Image src={recentSession.dynamicActivity.mentalState.playImageUrl} />
      </S.InnerContainer>
    </S.Container>
  );
};
