import { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';

import { ButtonProps } from '../buttons/ButtonBase';

const ActionButton = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  text-transform: lowercase;
`;

const OrangeLink = styled.a`
  color: ${({ theme }) => theme.colors.brandSecondaryDark};
  cursor: pointer;
  text-transform: lowercase;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

interface FormActionTextProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  /** Text describing action */
  text: string;
  /** label to click for action */
  actionText: string;
}

export const FormActionText: React.FC<FormActionTextProps & ButtonProps> = ({
  analyticsEvent,
  text,
  actionText,
  ...rest
}) => {
  return (
    <ActionButton data-analytics-event={analyticsEvent}>
      {text} <OrangeLink {...rest}>{actionText}</OrangeLink>
    </ActionButton>
  );
};
