import { useIsTrialUser } from '@Memberships';
import BrainFMIcon from '../../../assets/images/brand_logo.svg';
import AccountIcon from '../assets/account_icon.svg';
import PaymentMethodIcon from '../assets/payment_method_icon.svg';
import SettingsIcon from '../assets/settings_icon.svg';
import SubscriptionIcon from '../assets/subscription_icon.svg';
import ReferIcon from '../assets/refer_icon.svg';
import GiftIcon from '../assets/gift_icon.svg';
import { ProfileItemActionType, ProfileItemType } from '../Profile.types';
import { ProfileSection } from '../../../types';
import { useIsDesktopAnnouncementBannerExperimentEnabled } from '../../../domains/Utils/useAmplitudeExperiments';

export enum AccountMenuItems {
  Account = 'Account Information',
  ContactSupport = 'Contact Support',
  PaymentMethod = 'Payment Method',
  Subscription = 'Subscription',
  Gift = 'Gift Brain.fm',
  Download = 'Brain.fm for macOS',
  Refer = 'Refer a friend',
  Settings = 'Settings',
}

export function useAccountItems(): ProfileItemType[] {
  const isSubscriber = !useIsTrialUser();
  const isDesktopAnnouncementBannerExperimentEnabled =
    useIsDesktopAnnouncementBannerExperimentEnabled();

  return [
    {
      type: ProfileItemActionType.Screen,
      screen: ProfileSection.Account,
      label: AccountMenuItems.Account,
      iconSrc: AccountIcon,
      testid: 'account',
    },
    ...includeIfTrue(
      {
        type: ProfileItemActionType.Screen,
        screen: ProfileSection.PaymentMethod,
        label: AccountMenuItems.PaymentMethod,
        iconSrc: PaymentMethodIcon,
        testid: 'paymentMethod',
      },
      isSubscriber,
    ),
    {
      type: ProfileItemActionType.Screen,
      screen: ProfileSection.Subscription,
      label: AccountMenuItems.Subscription,
      iconSrc: SubscriptionIcon,
      testid: 'subscription',
    },
    {
      type: ProfileItemActionType.Screen,
      screen: ProfileSection.Settings,
      label: AccountMenuItems.Settings,
      iconSrc: SettingsIcon,
      testid: 'settings',
    },
    ...includeIfTrue(
      {
        type: ProfileItemActionType.Screen,
        screen: ProfileSection.Refer,
        label: AccountMenuItems.Refer,
        iconSrc: ReferIcon,
        testid: 'refer',
      },
      isSubscriber,
    ),
    ...includeIfTrue(
      {
        type: ProfileItemActionType.Linkout,
        label: AccountMenuItems.Download,
        iconSrc: BrainFMIcon,
        testid: 'download',
        href: 'https://www.brain.fm/download',
      },
      isDesktopAnnouncementBannerExperimentEnabled,
    ),
    ...includeIfTrue(
      {
        type: ProfileItemActionType.Linkout,
        label: AccountMenuItems.Gift,
        iconSrc: GiftIcon,
        testid: 'gift',
        href: `https://giftup.app/place-order/e382fe5e-19a6-4be5-936e-1bda244f95aa?platform=hosted`,
      },
      isSubscriber,
    ),
  ].filter(Boolean);
}

export function includeIfTrue<Item>(item: Item, isTrue: boolean): Item[] {
  if (isTrue) {
    return [item];
  }

  return [];
}
