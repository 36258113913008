import { Button, ButtonVariants, ButtonVariantStates } from '@Cortex';
import { NOOP } from '@Globals';
import React from 'react';
import { ModalContainer } from '../../../shared/ModalContainer';
import CardSVG from '../UpdateStripePaymentMethodPanel/assets/card_pulse.svg';

import * as S from './UpgradeStripeSubscriptionPanel.styles';

export type Props = {
  price?: string;
  isLoading: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
};

export function UpgradeStripeSubscriptionPanel({
  isLoading,
  onClose = NOOP,
  onSubmit = NOOP,
  price = '',
}: Props) {
  return (
    <S.Overlay>
      <ModalContainer
        description={`Seize the opportunity now to upgrade to an annual plan for just ${price}! We'll instantly apply a credit for any unused time in your current billing cycle.`}
        descriptionStyles={{
          fontSize: '0.8rem',
        }}
        headerImage={CardSVG}
        headerStyles={{
          marginTop: '2rem',
        }}
        title="Upgrade and Save!"
        onClose={onClose}
      >
        <>
          <S.SubmitButton>
            <Button
              data-testid="confirmUpgradeButton"
              isFullWidth={true}
              keepTextCase
              style={{ fontSize: '0.75rem' }}
              type="button"
              variant={ButtonVariants.Secondary}
              variantState={isLoading ? ButtonVariantStates.Loading : ButtonVariantStates.Idle}
              onClick={onSubmit}
            >
              UPGRADE MY SUBSCRIPTION
            </Button>
          </S.SubmitButton>
          <Button
            keepTextCase
            style={{ fontSize: '0.75rem' }}
            type="button"
            variant={ButtonVariants.Base}
            variantState={isLoading ? ButtonVariantStates.Loading : ButtonVariantStates.Idle}
            onClick={onClose}
          >
            GO BACK
          </Button>
        </>
      </ModalContainer>
    </S.Overlay>
  );
}
