import { FontColors } from '@Cortex';
import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: background 0.3s ease-in-out;
`;

export const InnerContainer = styled.div`
  display: flex;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1rem;

  &:hover {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.08) 100%
    );
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
`;

export const Image = styled.img`
  width: calc(2.25rem - 0.5rem - 0.5rem);
  height: calc(2.25rem - 0.5rem - 0.5rem);
  object-fit: contain;
`;
