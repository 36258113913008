import { OnboardingQuizQuestion } from '../types';

export const filterQuestionsByUtmSource = (
  questions: OnboardingQuizQuestion[],
): OnboardingQuizQuestion[] => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get('utm_source');

  if (utmSource) {
    return questions.filter(question => question.title !== 'How did you find us?');
  }

  return questions;
};
