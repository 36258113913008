import { useIsTrialUser } from '@Memberships';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../../reducers';
import { useIsExpired } from '../../../lenses/isExpired';

export const useShouldRenderReferAFriend = () => {
  const isTrial = useIsTrialUser();
  const isExpired = useIsExpired();

  const hasActiveSubscription = !isTrial && !isExpired;
  const isDismissed = useSelector((state: RootReducerType) => state.ui.isReferAFriendDismissed);

  return !isDismissed && hasActiveSubscription;
};
