import { generatePath } from 'react-router-dom';
import { DA_PREFIXES, DYNAMIC_PLAYER_ACTIVITY_PATH } from '../constants';

export const getDynamicMoodActivityPlayerPath = (params: {
  mood: string;
  dynamicMentalStateId: string;
}) => {
  return generatePath(DYNAMIC_PLAYER_ACTIVITY_PATH, {
    activityId: `${DA_PREFIXES.MOOD}${params.dynamicMentalStateId}_${params.mood}`,
  });
};
