import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { logEventWithProperties } from '../actions/analytics';
import { AnalyticsEvents, AnalyticsEventsProperties } from '../types';

export function useAnalyticsPageView(
  page: AnalyticsEvents,
  props: Partial<AnalyticsEventsProperties> = {},
) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logEventWithProperties({ event: page, props }));
  }, []);
}
