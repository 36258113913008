import { MembershipState, MembershipSources } from '@Memberships';

export enum StripeMembershipCycle {
  FreeTrialRenewing = 'freeTrialRenewing',
  FreeTrialNotRenewingActive = 'freeTrialNotRenewingActive',
  FreeTrialNotRenewingExpired = 'freeTrialNotRenewingExpired',
  PaidRenewing = 'paidRenewing',
  PaidNotRenewingActive = 'paidNotRenewingActive',
  PaidNotRenewingExpired = 'paidNotRenewingExpired',
  PermanentlyCancelledActive = 'permanentlyCancelledActive',
  PermanentlyCancelledExpired = 'permanentlyCancelledExpired',
  Unknown = 'unknown',
}

// this file provides Stripe membership state/cycle, not based on our db's data.
// some of the cases will never happen, but they are here for completeness because currentPeriodEndTimestamp always correlates with trialEnd (with current stripe settings)
export const getStripeMembershipCycle = ({
  isOnTrial,
  autoRenewActive,
  currentPeriodEndTimestamp,
  source,
  isPermanentlyCancelled,
}: Omit<MembershipState, 'promotion'>) => {
  if (
    source !== MembershipSources.Stripe ||
    isOnTrial === null ||
    autoRenewActive === null ||
    currentPeriodEndTimestamp === null ||
    isPermanentlyCancelled === null
  ) {
    return StripeMembershipCycle.Unknown;
  }

  const isExpired = currentPeriodEndTimestamp < Math.floor(Date.now() / 1000);

  if (isPermanentlyCancelled && !isExpired) {
    return StripeMembershipCycle.PermanentlyCancelledActive;
  }

  if (isPermanentlyCancelled && isExpired) {
    return StripeMembershipCycle.PermanentlyCancelledExpired;
  }

  if (isOnTrial && autoRenewActive) {
    return StripeMembershipCycle.FreeTrialRenewing;
  }

  if (isOnTrial && !autoRenewActive && !isExpired) {
    return StripeMembershipCycle.FreeTrialNotRenewingActive;
  }

  if (isOnTrial && !autoRenewActive && isExpired) {
    return StripeMembershipCycle.FreeTrialNotRenewingExpired;
  }

  if (!isOnTrial && autoRenewActive) {
    return StripeMembershipCycle.PaidRenewing;
  }

  if (!isOnTrial && !autoRenewActive && !isExpired) {
    return StripeMembershipCycle.PaidNotRenewingActive;
  }

  if (!isOnTrial && !autoRenewActive && isExpired) {
    return StripeMembershipCycle.PaidNotRenewingExpired;
  }

  return StripeMembershipCycle.Unknown;
};
