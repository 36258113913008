import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
`;

//To prevent background bleeding into border
export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
  padding: 1rem;
  align-items: center;
  min-height: 64px;

  &:hover {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.08) 100%
    );
  }
`;

export const Title = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 0.875;
  font-weight: 500;
  text-transform: none;
  width: 70%;
`;
export const Image = styled.img`
  height: 156px;
  width: 156px;
  position: absolute;
  top: 50%;
  right: -8rem;
  transform: translate(-50%, -50%);
`;
