import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as uiActions from '../../../actions/ui';
import { Assets } from '../../../utils/assets';

export const useStripeCheckoutSuccessModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentSuccess = queryParams.get('payment_success');

    if (paymentSuccess === 'true') {
      dispatch(uiActions.setModal('subscriptionSuccess'));
    }
    if (paymentSuccess === 'false') {
      dispatch(
        uiActions.successModalOpen({
          title: 'Payment Canceled',
          description:
            'It looks like your payment didn’t go through. No worries, you can try again.',
          actions: [{ type: 'primary', text: 'CLOSE', action: uiActions.successModalClose() }],
          iconType: 'info',
          iconSrc: Assets.icons.infoBlack.url,
        }),
      );
    }
  }, [location, dispatch]);
};
