import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { usePost } from '../../client/client';
import { Endpoints } from '../../common/endpoints';
import { TJoinTeamRequestBody, TJoinTeamResponse } from './types';

export const useRequestHandler = () => {
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);
  const post = usePost<TJoinTeamRequestBody, TJoinTeamResponse>();

  return useCallback(
    async (teamId: string) => {
      return await post({
        path: Endpoints.postJoinTeam(userId!, teamId!),
        body: {},
        options: { apiVersion: 3 },
      });
    },
    [post],
  );
};
