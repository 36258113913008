import { audioPlayerActions, useIsAudioPlayerRepeatEnabled } from '@AudioPlayer';
import { useDispatch, useSelector } from 'react-redux';

import { usePlayerAnalyticsParams } from '../../hooks/usePlayerAnalyticsParams';
import { trackLoopTrackEvent } from '../Analytics/coreAnalytics';

export function useToggleAudioPlayerRepeat() {
  const dispatch = useDispatch();
  const isAudioPlayerRepeatEnabled = useIsAudioPlayerRepeatEnabled();
  const {
    activity,
    trackGenre,
    filterGenre,
    filterNel,
    trackNel,
    mentalState,
    complexity,
    trackName,
  } = usePlayerAnalyticsParams();

  return function toggleAudioPlayerRepeat() {
    if (isAudioPlayerRepeatEnabled) {
      dispatch(audioPlayerActions.setPlayStyleToDefault());
    } else {
      dispatch(audioPlayerActions.setPlayStyleToRepeat());
      trackLoopTrackEvent({
        activity,
        complexity,
        filterGenre,
        trackGenre,
        mentalState,
        filterNel,
        trackNel,
        trackName,
      });
    }
  };
}
