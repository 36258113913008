import { musicActions } from '@Music';
import { useDispatch, useSelector } from 'react-redux';

import { RootReducerType } from '../../../../../reducers';

export function useGenreFilterHandler(allGenres?: {
  baseGenreNames: string[];
  natureGenreNames: string[];
}) {
  const dispatch = useDispatch();
  const selectedGenreFilters = useSelector((state: RootReducerType) => state.music.genreFilters);
  const sessionMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );

  function handleToggleSelectGenre(selectedGenre: string) {
    if (selectedGenreFilters.includes(selectedGenre)) {
      dispatch(
        musicActions.setGenreFilters(
          selectedGenreFilters.filter(activity => activity !== selectedGenre),
        ),
      );
      return;
    }
    dispatch(musicActions.setGenreFilters([...selectedGenreFilters, selectedGenre]));
  }

  return {
    title: 'Genres',
    selectedItems: selectedGenreFilters,
    items: [...(allGenres?.baseGenreNames || []), ...(allGenres?.natureGenreNames || [])],
    onItemClick: handleToggleSelectGenre,
  };
}
