import { useCallback } from 'react';
import { MentalStateSelectorDisplay } from './MentalStateSelector.display';
import { useDynamicMentalStates } from '../../../../api/modules/DynamicMentalStates';
import { useDispatch, useSelector } from 'react-redux';
import { uiSliceActions } from '../../../../reducers/uiReducer';
import { RootReducerType } from '../../../../reducers';
import { DynamicMentalState } from '@Model';

export enum MentalStateSelectorTab {
  Library,
  Explore,
}

interface Props {
  tab: MentalStateSelectorTab;
}

export const MentalStateSelector = ({ tab }: Props) => {
  const dispatch = useDispatch();
  const { data: dynamicMentalStates } = useDynamicMentalStates();
  const libraryMentalState = useSelector(
    (state: RootReducerType) => state.ui.sideDeck.librarySelectedMentalState,
  );
  const exploreMentalState = useSelector(
    (state: RootReducerType) => state.ui.sideDeck.exploreSelectedMentalState,
  );

  const selectedMentalState = useCallback(() => {
    if (tab === MentalStateSelectorTab.Library) {
      return libraryMentalState;
    }
    if (tab === MentalStateSelectorTab.Explore) {
      return exploreMentalState;
    }
  }, [tab, exploreMentalState, libraryMentalState]);

  const handleSelectMenuItem = useCallback(
    (mentalState: DynamicMentalState) => {
      if (tab === MentalStateSelectorTab.Library) {
        dispatch(uiSliceActions.setLibraryMentalState(mentalState));
      }
      if (tab === MentalStateSelectorTab.Explore) {
        dispatch(uiSliceActions.setExploreMentalState(mentalState));
      }
    },
    [tab, dispatch],
  );

  return (
    <MentalStateSelectorDisplay
      mentalStates={dynamicMentalStates}
      selectedMentalState={selectedMentalState()}
      onSelect={handleSelectMenuItem}
    />
  );
};
