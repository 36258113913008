import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubmitButton = styled.div`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;

export const InputLabel = styled.label`
  color: rgba(255, 255, 255, 0.75);
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin-left: 8px;
  margin-bottom: 8px;
`;

export const ErrorText = styled.span`
  bottom: 4px;
  left: 0;
  right: 0;
  padding-left: 1rem;
  color: red;
  font-size: 12px;
  position: absolute;
  text-transform: lowercase;
`;
