import { TimerStatuses } from '@Timer';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { timerActions } from '../reducer';

export function useUpdateTimerEverySecond(params: { isTimerReconciled: boolean }) {
  let timeoutRef = useRef<number | null>(null);
  const dispatch = useDispatch();
  const timerStatus = useSelector<RootReducerType, RootReducerType['timer']['status']>(
    state => state.timer.status,
  );

  function updateTimer() {
    dispatch(timerActions.update());
    timeoutRef.current = window.setTimeout(updateTimer, 1000);
  }

  useEffect(() => {
    if (params.isTimerReconciled && timerStatus === TimerStatuses.Running) {
      updateTimer();
    }

    return function stopUpdates() {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, [params.isTimerReconciled, timerStatus]);
}
