import { useDispatch } from 'react-redux';

import { timerActions } from './reducer';

export function useStartTimer() {
  const dispatch = useDispatch();
  return function startTimer() {
    dispatch(timerActions.start());
  };
}
