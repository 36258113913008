import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';

export function isLifetimeUser(membership: RootReducerType['user']['membership']): boolean {
  return membership?.title === 'Lifetime';
}

export function useIsLifetimeUser() {
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);

  return isLifetimeUser(legacyMembership);
}
