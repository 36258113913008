import { MenuItem, MenuItemIcons, Button, ButtonVariants, ButtonVariantSizes } from '@Cortex';
import { Assets } from '../../../../utils/assets';
import * as S from './ReferAFriendWidget.styles';

export type Props = {
  icon?: MenuItemIcons;
  onClickButton: () => void;
  onClickIcon?: () => void;
};
export const ReferAFriendWidgetPresentation = (props: Props) => {
  const { icon, onClickButton, onClickIcon, ...otherProps } = props;

  return (
    <S.CallToActionWidget>
      <S.Body data-testid="referAFriendWidgetBody">
        <MenuItem
          icon={props.icon || MenuItemIcons.Alert}
          isActive={true}
          onClickIcon={props.onClickIcon}
        />
        Friends get 30 days <S.Highlight>FREE</S.Highlight>
      </S.Body>
      <Button
        data-testid="referAFriendWidgetDesktop"
        keepTextCase={true}
        size={ButtonVariantSizes.Small}
        style={{
          color: 'white',
          letterSpacing: '1.2px',
          fontSize: '0.75rem',
          background:
            'linear-gradient(117deg, rgba(67,82,133,1) 0%, rgba(67,62,89,1) 32%, rgba(67,62,89,1) 59%, rgba(122,74,108,1) 100%)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          boxShadow: 'none',
          backgroundOrigin: 'border-box',
        }}
        variant={ButtonVariants.Secondary}
        onClick={onClickButton}
      >
        <S.ButtonContent>
          <S.Icon src={Assets.icons.referBold.url} />
          REFER A FRIEND
        </S.ButtonContent>
      </Button>
    </S.CallToActionWidget>
  );
};
