import styled, { css } from 'styled-components';

import CheckmarkSVG from './assets/checkmark.svg?react';

export const MainContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const Container = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const Title = styled.div`
  color: #d4d2ea;
  font-family: TTNormsPro-Bold;
  font-weight: 700;
  font-size: 0.875rem;
  letter-spacing: 0.2rem;
  margin-bottom: 1.5rem;
  z-index: 1;
`;

export const Checkmark = styled(CheckmarkSVG)`
  margin-right: 1rem;
`;

export const Benefits = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  z-index: 1;
`;

export const BenefitsTitle = styled.div`
  font-family: TTNormsPro-Bold;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.25rem;
`;

export const BenefitsSubtitle = styled.div`
  color: #d4d2ea;
  font-family: TTNormsPro-Regular;
  font-weight: 400;
  font-size: 0.875rem;
`;

export const Image = styled.img<{ isMobileView: boolean }>`
  display: none;

  ${({ isMobileView }) =>
    isMobileView &&
    css`
      display: block;
      position: absolute;
      right: 0;
      top: -7.4rem;
      width: 25rem;
      height: auto;
      z-index: 0;

      ${({ theme }) => theme.mediaQuery.maxWidth.md} {
        display: none;
      }
    `}
`;

export const PrimaryButtonWrapper = styled.div`
  z-index: 1;
`;
