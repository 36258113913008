import { MembershipSources } from '@Memberships';
import { useFeatureFlag, FeatureFlags } from '@Utils';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../../reducers';

export const useIsEligibleForDiscount = () => {
  const isMonthlySubscriptionCancellationDiscountEnabled = useFeatureFlag(
    FeatureFlags.MonthlySubscriptionCancellationDiscount,
  );
  const membership = useSelector((state: Pick<RootReducerType, 'membership'>) => state.membership);
  const membershipTitle = useSelector((state: RootReducerType) => state.user.membership?.title);
  const isMonthly = membership.source === MembershipSources.Stripe && membershipTitle === 'Monthly';

  return Boolean(isMonthly) && isMonthlySubscriptionCancellationDiscountEnabled;
};
