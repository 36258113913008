import { trackSignInWithMagicLink } from '@Analytics';
import { useToken } from '@Authentication';
import { API_BASE_URL } from '@Globals';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '../../hooks';
import { authSliceActions } from '../../reducers/authentication';
import { Logger } from '../../utils/logger';

export const useMagicLinkToken = () => {
  const [isCheckingMagicLink, setCheckingMagicLinkToken] = useState(true);

  const authToken = useToken();
  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    const magicLinkToken = query.get('magicLink');
    const campaignName = query.get('name') || '';

    if (magicLinkToken) {
      query.delete('magicLink');
      navigate(
        {
          search: query.toString(),
        },
        { replace: true },
      );
    }

    if (!authToken && magicLinkToken) {
      fetch(`${API_BASE_URL}/auth/magic-link/${magicLinkToken}`)
        .then(res => {
          return res.json();
        })
        .then(res => {
          if (res && res.status === 200 && res.result !== null) {
            trackSignInWithMagicLink({ campaignName });
            dispatch(authSliceActions.loginSuccess({ token: res.result, type: 'magic_link' }));
          }
        })
        .catch(err => {
          Logger.error(err);
        })
        .finally(() => {
          setCheckingMagicLinkToken(false);
        });
    } else {
      setCheckingMagicLinkToken(false);
    }
  }, []);

  return { isCheckingMagicLink };
};
