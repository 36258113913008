import { BROWSER_ENVIRONMENT } from '@Globals';
import * as Sentry from '@sentry/react';
import { getErrorMessage } from '../getErrorMessage';

import { isGenericError } from './isGenericError';
import { isOfflineError } from '../isOfflineError';

const DEV = BROWSER_ENVIRONMENT === 'development';

export const Logger = (() => {
  // Logs messages as info
  function info(message: string, extra: any = undefined) {
    try {
      if (DEV) {
        console.log(message, extra);
      } else {
        Sentry.captureMessage(message, {
          level: 'info',
          extra,
        });
      }
    } catch (e) {}
  }

  // Logs errors to Sentry
  function error(err: unknown, extra?: { [key: string]: any }) {
    const extraDataError = extra?.reason;
    if (isGenericError(err) || isOfflineError(err) || isOfflineError(extraDataError)) return; // filter out generic errors and offline errors (made for user display only)

    try {
      if (DEV) {
        console.log(err);
      } else {
        if (isExpiredMembershipError(err)) {
          Sentry.captureMessage(getErrorMessage(err), {
            level: 'info',
            extra,
          });
        } else {
          Sentry.captureException(err, extra ? { extra } : undefined);
        }
      }
    } catch (e) {}
  }

  return {
    info,
    error,
  };
})();

function isExpiredMembershipError(error: unknown) {
  return (
    error instanceof Error &&
    (error.message === 'Your membership is expired' ||
      error.message === 'Your subscription is expired' ||
      error.message === 'Could not validate your request.' ||
      error.message === 'invalid token')
  );
}
