import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';

export function isTrialUser(membership: RootReducerType['user']['membership']): boolean {
  return membership?.type.includes('trial') || false;
}

export function useIsTrialUser() {
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);
  return useMemo(() => {
    return isTrialUser(legacyMembership);
  }, [legacyMembership?.type]);
}
