import styled from 'styled-components';

export const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: flex-end;
  max-width: 100%;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
`;

export const NoTracksText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.medium};
  color: ${({ theme }) => theme.colors.white700};
  margin: 0 0 0.5rem 0;
  padding: 0.5rem;
`;
