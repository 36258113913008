import { BROWSER_TRACK_URL } from '@Globals';
import { Serving, Track } from '@Model';

import { isServing } from './isServing';

export const getTrackVariationUrl = (trackOrServing?: Track | Serving | null): string => {
  if (isServing(trackOrServing)) {
    return trackOrServing.trackVariation.tokenedUrl;
  }

  const tokenedUrl = trackOrServing?.serving.trackVariation.tokenedUrl;
  if (tokenedUrl) {
    return tokenedUrl;
  }

  return `${BROWSER_TRACK_URL}${trackOrServing?.url}`;
};
