import { ReactNode } from 'react';

import CloseIcon from '../../../assets/images/close_icon.svg';
import ArrowLeftIcon from '../../../assets/images/arrow_left_icon.svg';
import * as S from './ProfileFormContainer.styles';

interface Props {
  title?: string;
  description?: string;
  headerImage?: string;
  onBack?: () => void;
  onClose?: () => void;
  errorMessage?: string | null;
  keepTextCase?: boolean;
  maxWidth?: string;
  children: ReactNode | undefined;
}

export const ProfileFormContainer: React.FC<Props> = ({
  title,
  description,
  headerImage,
  onBack,
  onClose,
  children,
  errorMessage = null,
  keepTextCase,
  maxWidth,
}) => {
  return (
    <S.Container maxWidth={maxWidth}>
      <S.Header>
        {onBack && (
          <S.BackButton type="button" onClick={onBack}>
            <S.Back src={ArrowLeftIcon} />
          </S.BackButton>
        )}
        {onClose && (
          <S.ImageButton type="button" onClick={onClose}>
            <S.Close src={CloseIcon} />
          </S.ImageButton>
        )}

        {headerImage && <S.Image src={headerImage} />}

        <S.TextContainer>
          {title && <S.Title keepTextCase={keepTextCase}>{title}</S.Title>}

          {description ? <S.Description>{description}</S.Description> : null}
        </S.TextContainer>
      </S.Header>
      {children}
      <S.ErrorBox>
        <S.ErrorText>{errorMessage}</S.ErrorText>
      </S.ErrorBox>
    </S.Container>
  );
};
