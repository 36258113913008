import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../reducers';

import TimerIconSVG from '../assets/timer.svg';
import IntervalIconSVG from '../assets/interval.svg';
import InfiniteIconSVG from '../assets/infinite.svg';
import { useIsPomodoroActive } from '@Timer';
import { getTimerMode } from '../../../utils/getTimerMode';
import { TimerMode } from '../../../domains/Session/components/TimerSettings/hooks/useTrackTimerModeChange';

export function useTimerTypeIcon() {
  const { sessionPlayType } = useSelector((state: RootReducerType) => state.sessionManager);
  const isPomodoro = useIsPomodoroActive();
  const timerMode = getTimerMode(sessionPlayType, isPomodoro);

  switch (timerMode) {
    case TimerMode.Infinite:
      return InfiniteIconSVG;
    case TimerMode.Custom:
      return TimerIconSVG;
    case TimerMode.Interval:
      return IntervalIconSVG;
    default:
      return InfiniteIconSVG;
  }
}
