export const OTHER_REASON = 'Other';
export const BUDGET_REASON = 'Budget concerns';

export const PAID_SUBSCRIBER_SURVERY = [
  'I’m bored of the music',
  'I like it but want to turn off auto-renewal',
  'I lost the habit of using it regularly',
  'Used it briefly but it didn’t stick',
  'I no longer need it',
  'I found a free alternative',
  'I ran into technical issues/bugs',
  BUDGET_REASON,
  OTHER_REASON,
];

export const TRIAL_SUBSCRIPBER_SURVERY = [
  'I don’t feel the effects',
  'Not enough music variety',
  'I couldn’t find music I enjoy',
  'It’s confusing to use',
  'I forgot to use it',
  'I ran into technical issues/bugs',
  OTHER_REASON,
];
