import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface ToggleState extends HTMLAttributes<HTMLDivElement> {
  isActive: boolean;
  size?: 'small' | 'large' | 'xlarge';
}

const getToggleSize = (size: ToggleState['size']) => {
  if (size === 'large') return 32;
  if (size === 'xlarge') return 44;

  return 22;
};

const ToggleContainer = styled.div<ToggleState>`
  cursor: pointer;
  border-radius: ${({ size }) => getToggleSize(size)}px;
  width: ${({ size }) => getToggleSize(size)}px;
  height: ${({ size }) => getToggleSize(size) / 1.6}px;
  position: relative;
  transition:
    background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out,
    border 0.2s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.75);

  ${({ isActive }) =>
    isActive
      ? css`
          background: rgba(255, 255, 255, 0.2);
        `
      : css`
          background: transparent;
        `};
`;

const Knob = styled.div<ToggleState>`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  width: ${({ size }) => getToggleSize(size) / 1.6 - 4}px;
  height: ${({ size }) => getToggleSize(size) / 1.6 - 4}px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: ${({ isActive, size }) =>
    isActive ? `calc(100% - ${getToggleSize(size) / 1.6 - 4}px - 2px)` : '1px'};
  transform: translateY(-50%);
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
`;

export const Switch: React.FC<ToggleState> = ({ isActive, size = 'small', ...divProps }) => {
  return (
    <ToggleContainer isActive={isActive} size={size} {...divProps}>
      <Knob isActive={isActive} size={size} />
    </ToggleContainer>
  );
};
