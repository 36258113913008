import { CloseButton, Paper } from '@Cortex';
import { NOOP } from '@Globals';
import React, { ReactNode } from 'react';

import * as S from './CardWrapper.styles';

export type Props = {
  onClickClose?: () => void;
  children: ReactNode;
};

export const CardWrapper = ({ onClickClose = NOOP, children }: Props) => {
  return (
    <Paper isScrollable={true} padding="4rem 2rem" style={{ maxHeight: '90vh' }}>
      <S.CloseButton>
        <CloseButton data-testid="onboardingCardCloseButton" onClick={onClickClose} />
      </S.CloseButton>
      <S.Wrapper>{children}</S.Wrapper>
    </Paper>
  );
};
