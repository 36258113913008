import styled, { keyframes } from 'styled-components';
import { Text } from '@Cortex';

const FadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  align-items: center;
  animation: ${FadeInAnimation} 0.3s ease;
  border-radius: 80px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding: 12px 32px;
  position: relative;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const Pulse = styled.div`
  position: absolute;
  right: 16px;
  top: -16px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const SText = styled(Text)`
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: 0.1em;
  text-align: left;
  margin: 0;
  padding: 0;
`;
