import { FontColors } from '@Cortex';
import styled from 'styled-components';

export const Container = styled.div<{ isActive: boolean }>`
  display: flex;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
  background-color: ${({ isActive }) => (isActive ? 'white' : 'transparent')};
  margin-bottom: 8px;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
`;

export const Text = styled.div<{ isActive: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  color: ${({ isActive }) => (isActive ? 'black' : 'white')};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  transition: color 0.3s ease-in-out;
`;
