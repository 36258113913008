import { HTMLAttributes, useState } from 'react';
import * as S from './OnboardingMentalStates.styles';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  imageOne: { url: string; alt: string };
  imageTwo: { url: string; alt: string };
}

export const MentalStateCard = ({ text, imageOne, imageTwo, ...props }: CardProps) => {
  const [hovered, setHovered] = useState(false);

  return (
    <S.CardContainer
      {...props}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <S.CardImageArea>
        <S.CardImage active={!hovered} alt={imageOne.alt} src={imageOne.url} />
        <S.CardImage active={hovered} alt={imageTwo.alt} src={imageTwo.url} />
      </S.CardImageArea>
      <S.CardText>{text}</S.CardText>
    </S.CardContainer>
  );
};
