import { Serving, Track } from '@Model';

import { isServing } from './isServing';

export function getTrackImageUrl(trackOrServing?: Track | Serving | null): string {
  if (isServing(trackOrServing)) {
    return trackOrServing.track.imageUrl;
  }

  return trackOrServing?.image || '';
}
