import { Button, ButtonVariants, ButtonVariantStates, ProfileFormInput } from '@Cortex';
import { Formik } from 'formik';
import * as yup from 'yup';

import { ProfileFormContainer } from '../../../shared/ProfileFormContainer';
import * as S from './EditProfile.styles';
import EditProfileHeader from './assets/edit_profile_header.svg';

const validationSchema = yup.object({
  email: yup.string().email().required('Email field is required'),
  name: yup.string(),
});

export interface Props {
  email: string;
  errorMessage?: string;
  isLoading?: boolean;
  isRemoveAccountAvailable: boolean;
  name: string;
  onClose: () => void;
  onFormSubmit: (values: { name: string; email: string }) => void;
  onDeleteAccount: () => void;
  submitButtonTitle?: string;
  submitButtonVariantState?: ButtonVariantStates;
}

export const EditProfileDisplay = ({
  email,
  errorMessage,
  isLoading = false,
  isRemoveAccountAvailable,
  name,
  onClose,
  onDeleteAccount,
  onFormSubmit,
  submitButtonTitle = 'SAVE',
}: Props) => {
  return (
    <Formik
      initialValues={{ name, email }}
      validationSchema={validationSchema}
      onSubmit={values => onFormSubmit(values)}
    >
      {({ handleChange, handleSubmit, values, touched, errors }) => {
        return (
          <S.Form data-testid="editProfileForm" onSubmit={handleSubmit}>
            <ProfileFormContainer
              errorMessage={errorMessage}
              headerImage={EditProfileHeader}
              keepTextCase
              title="Edit Profile"
              onClose={onClose}
            >
              <S.Container>
                <ProfileFormInput
                  autoComplete="name"
                  errorMessage={touched.name && errors.name ? errors.name : ''}
                  label="Name"
                  name="name"
                  type="text"
                  value={values.name}
                  variant="first"
                  onChange={handleChange}
                />

                <ProfileFormInput
                  autoComplete="email"
                  errorMessage={touched.email && errors.email ? errors.email : ''}
                  label="Email"
                  name="email"
                  value={values.email}
                  variant="last"
                  onChange={handleChange}
                />
                {isRemoveAccountAvailable && (
                  <S.DeleteButton>
                    <Button
                      data-testid="deleteAccount"
                      isFullWidth
                      keepTextCase
                      type="button"
                      variant={ButtonVariants.Destructive}
                      onClick={onDeleteAccount}
                    >
                      Delete My Account
                    </Button>
                  </S.DeleteButton>
                )}
                <S.SubmitButton>
                  <Button
                    data-testid="editProfileSubmitButton"
                    isFullWidth
                    keepTextCase
                    type="submit"
                    variant={ButtonVariants.Secondary}
                    variantState={
                      isLoading ? ButtonVariantStates.Loading : ButtonVariantStates.Idle
                    }
                  >
                    {submitButtonTitle}
                  </Button>
                </S.SubmitButton>
                <S.CancelButton>
                  <Button
                    isFullWidth
                    keepTextCase
                    type="button"
                    variant={ButtonVariants.Base}
                    onClick={onClose}
                  >
                    CANCEL
                  </Button>
                </S.CancelButton>
              </S.Container>
            </ProfileFormContainer>
          </S.Form>
        );
      }}
    </Formik>
  );
};
