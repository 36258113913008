import { EnhancedMembershipPlanType } from '../../../../../types';

export const getCreateStripeCheckoutSubscriptionPath = (
  plan: EnhancedMembershipPlanType,
): string => {
  if (plan.trialLength) {
    return `/payments/create-${plan.trialLength}-day-trial-stripe-checkout-subscription`;
  }
  // no trial
  return '/payments/create-stripe-checkout-subscription';
};
