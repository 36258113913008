import { Track } from '@Model';
import { createAction } from '@reduxjs/toolkit';

import {
  SessionManagerStateType,
  ReplaceTracksWithUpdatedPreferencesActivity,
  FetchMoreTracksActivity,
  CreateDynamicSessionFromJumpBackIn,
} from '../types/sessionManager';
import {
  CreateSession,
  CreateDynamicSession,
  ChangeSessionActivity,
  ChangeSessionDynamicActivity,
} from '../types/sessionManager';

// Audioplayer is here for some reason?

export const dynamicReplaceTracksWithUpdatedPreferences =
  createAction<ReplaceTracksWithUpdatedPreferencesActivity>(
    'DYNAMIC_REPLACE_TRACKS_WITH_UPDATED_PREFERENCES',
  );

export const DYNAMIC_fetchMoreTracks = createAction<FetchMoreTracksActivity>(
  'DYNAMIC_AUDIO_PLAYER_FETCH_TRACKS',
);
export const appendTracks = createAction<Track[]>('AUDIO_PLAYER_APPEND_TRACKS');
export const initSession = createAction('AUDIO_PLAYER_INIT_SESSION');
export const startSession = createAction('AUDIO_PLAYER_START_SESSION');
export const endSession = createAction('AUDIO_PLAYER_END_SESSION');

export const resumeTrack = createAction<number>('AUDIO_PLAYER_RESUME');
export const pauseTrack = createAction<number>('AUDIO_PLAYER_PAUSE');
export const previousTrack = createAction('AUDIO_PLAYER_PREVIOUS');
export const skipTrack = createAction<{
  timestampAtSkip: number;
  shouldHonorRepeat?: boolean;
  shouldTrackSkip?: boolean;
}>('AUDIO_PLAYER_SKIP');
export const trackEnded = createAction('AUDIO_PLAYER_TRACK_ENDED');

// Session Handling

export const setCurrentTrackTimeStamp = createAction<number>('CURRENT_TRACK_TIMESTAMP');

export const setSessionType = createAction<string>('SET_SESSION_TYPE');
export const setSessionTimerLengthDisplayValue = createAction<string>(
  'SET_SESSION_TIMER_LENGTH_DISPLAY_VALUE',
);

export const sessionTimerChanged = createAction('SESSION_TIMER_CHANGED');

export const setSessionTimer =
  createAction<
    Pick<SessionManagerStateType, 'sessionPlayType' | 'timerLength' | 'timerLengthDisplayValue'>
  >('SET_SESSION_TIMER');
export const extendSesionTimer = createAction<number>('EXTEND_SESSION_TIMER');

export const setSessionInterval =
  createAction<
    Pick<SessionManagerStateType, 'sessionPlayType' | 'intervalFocus' | 'intervalRelax'>
  >('SET_SESSION_INTERVAL');

// Load new tracks
export const trackCardLoad = createAction<{
  isUsingDynamicActivities?: boolean;
  loadWithoutPlaying?: boolean;
  trackId: string;
  trackVariationId?: string;
}>('SESSION_TRACK_CARD_LOAD');

// Create Sessions

export const createDynamicSession = createAction<CreateDynamicSession>(
  'STANDARD_SESSION_CREATE_DYNAMIC_SESSION',
);

export const createDynamicSessionFromJumpBackIn = createAction<CreateDynamicSessionFromJumpBackIn>(
  'CREATE_DYNAMIC_SESSION_FROM_JUMP_BACK_IN',
);

export const changeSessionDynamicActivity = createAction<ChangeSessionDynamicActivity>(
  'CHANGE_SESSION_DYNAMIC_ACTIVITY',
);

export const clearSessionManager = createAction('CLEAR_SESSION_MANAGER');

export const timerFinished = createAction('TIMER_FINISHED');

export const timerFinishedRestart = createAction('TIMER_FINISHED_RESTART');

export const timerFinishedKeepGoing = createAction('TIMER_FINISHED_KEEP_GOING');
