import styled from 'styled-components';

export const Container = styled.div<{ isExpanded: boolean }>`
  position: relative;
  height: 2.625rem;
  width: ${({ isExpanded }) => (isExpanded ? '100%' : '40px')};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.6s ease-in-out;
`;

export const Input = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  color: #fff;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(34px);
  -webkit-backdrop-filter: blur(34px);
  border-radius: 0.75rem;

  &::placeholder {
    color: rgba(255, 255, 255, 0.75);
  }
`;

export const Icon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  object-fit: contain;
`;

export const IconButton = styled.button<{ isExpanded: boolean }>`
  border: none;
  background: transparent;
  cursor: pointer;
  object-fit: contain;
  position: absolute;
  left: 2rem;
  top: 25%;
  z-index: 2;
  transform: ${({ isExpanded }) => (isExpanded ? 'translateX(-20px)' : 'translateX(-75%)')};
  transition: transform 0.3s ease-in-out;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    left: 2.5rem;
  }
`;
