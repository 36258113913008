import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Accordion = styled.div`
  width: 100%;
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

//To prevent background bleeding into border
export const InnerAccordion = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
`;

export const Anchor = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.08) 100%
    );
  }
`;

export const Chevron = styled.div<{ isDown?: boolean }>`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  transform: rotate(${({ isDown = false }) => (isDown ? '180deg' : '0deg')});
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: rotate(${({ isDown = false }) => (isDown ? '180deg' : '0deg')}) scale(1.2);
  }
`;

export const Dropdown = styled.div<{ animationState: TransitionStatus }>`
  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0.2;
      case 'entered':
        return 1;
      case 'exiting':
        return 0.2;
      case 'exited':
        return 0;
    }
  }};
  transform: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return `translateY(-5px)`;
      case 'entered':
        return `translateY(0)`;
      case 'exiting':
        return `translateY(0)`;
      case 'exited':
        return `translateY(-5px)`;
    }
  }};
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
`;
