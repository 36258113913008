import { TextBold, FontFamilies, TextSemiBold } from '@Cortex';
import styled from 'styled-components';

export const HeaderSpacer = styled.div`
  height: 48px;
  width: 48px;
`;

export const CloseIconContainer = styled.div`
  backdrop-filter: blur(34px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 48px;
  width: 48px;
  transition: box-shadow 0.2s ease-in-out;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 0.5);
  }
`;

export const CloseIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    max-width: 750px;
    margin: 0 auto;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  margin: 8px 8px 0 0;
`;

export const HeaderIconContainer = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    display: block;
  }
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1250px;
  justify-content: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    flex-direction: column;
  }
`;

export const SubscriptionInfoText = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: ${FontFamilies.Regular};
  color: rgba(255, 255, 255, 0.5);
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
  width: 100%;
`;

export const LeftBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 45%;
  position: relative;
  z-index: 9999;
  isolation: isolate;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    width: 100%;
  }
`;

export const LeftBoxHeaderIconContainer = styled.div`
  display: block;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    display: none;
  }
`;

export const RightBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  width: 50%;
  justify-content: center;
  align-items: center;
  gap: 20px;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    width: 100%;
  }
`;

export const Title = styled(TextBold)`
  font-size: 3rem;
  margin-top: 2rem;
`;
export const Checkmarks = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const CouponContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  display: flex;
  flex-direction: column;
`;

export const CouponGiftCardContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const CouponSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

export const CouponSectionInputWrapper = styled.div`
  flex: 3;
`;

export const CouponTextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const CouponText = styled(TextSemiBold)`
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.75);
`;

export const CouponButton = styled.button`
  border: none;
  outline: none;
  background: none;
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  margin-left: 8px;
  font-family: ${FontFamilies.Regular};
`;
