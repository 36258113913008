import { Button, ButtonVariants, Panel } from '@Cortex';
import ReactModal from 'react-modal';

import { baseStyles, CLOSE_TIMEOUT_MS } from '../../../../modals/modalStyles';
import { Assets } from '../../../../utils/assets';
import { VariantStates } from '../../../Cortex/components/Button/Button';
import * as S from './RenewSubscriptionModal.styles';

export type Props = {
  description: string;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  primaryButtonAction: () => void;
  title: string;
};

export function RenewSubscriptionModalPresentation(props: Props) {
  const { title, description, primaryButtonAction, onClose, isLoading } = props;
  return (
    <ReactModal
      closeTimeoutMS={CLOSE_TIMEOUT_MS}
      isOpen={props.isOpen}
      style={baseStyles}
      onRequestClose={props.onClose}
    >
      <S.Overlay>
        <S.ModalWrapper>
          <Panel
            padding="2rem 0"
            style={{
              background:
                'linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              overflow: 'visible',
            }}
          >
            <S.ModalContent>
              <S.CloseButtonWrapper type="button" onClick={onClose}>
                <S.Close src={Assets.icons.close_white.url} />
              </S.CloseButtonWrapper>
              <S.Image alt={Assets.images.keepAccess.alt} src={Assets.images.keepAccess.url} />
              <S.AuraImage
                alt={Assets.images.keepAccessAura.alt}
                src={Assets.images.keepAccessAura.url}
              />
              <S.TextContainer>
                {title && <S.Title>{title}</S.Title>}
                {description && <S.Description>{description}</S.Description>}
              </S.TextContainer>
              <S.MembershipInformationContainer>
                <S.MembershipInformation>
                  <S.Icon alt={Assets.icons.checkmark.alt} src={Assets.icons.checkmark.url} />
                  <S.MembershipTextContainer>
                    <S.MembershipHeaderText>
                      Keep your favorites and preferences
                    </S.MembershipHeaderText>
                  </S.MembershipTextContainer>
                </S.MembershipInformation>
                <S.MembershipInformation>
                  <S.Icon alt={Assets.icons.checkmark.alt} src={Assets.icons.checkmark.url} />
                  <S.MembershipTextContainer>
                    <S.MembershipHeaderText>$0.00 today</S.MembershipHeaderText>
                    <S.MembershipInfoText>
                      You won’t be charged until your renewal date.
                    </S.MembershipInfoText>
                  </S.MembershipTextContainer>
                </S.MembershipInformation>
              </S.MembershipInformationContainer>
              <S.ButtonWrapper>
                <Button
                  data-testid="successButtonPrimary"
                  isFullWidth
                  keepTextCase
                  style={{ fontSize: '0.75rem', letterSpacing: '1.2px' }}
                  variant={ButtonVariants.Secondary}
                  variantState={isLoading ? VariantStates.Loading : VariantStates.Idle}
                  onClick={primaryButtonAction}
                >
                  KEEP ACCESS
                </Button>
              </S.ButtonWrapper>
            </S.ModalContent>
          </Panel>
        </S.ModalWrapper>
      </S.Overlay>
    </ReactModal>
  );
}
