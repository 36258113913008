import { post } from '../../../../../api/client/client';

type Props = {
  token: string | null;
  email?: string;
};
export const getStripeCustomerId = async ({ token, email }: Props) => {
  if (!token || !email) return null;

  const { result } = await post<{ email?: string }, { result: string }>({
    path: '/payments/create-customer',
    body: { email },
    token,
  });

  return result;
};
