import styled from 'styled-components';

export const Container = styled.div``;

export const GenresContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 8px;
  margin-bottom: 2rem;
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 0.75rem;
  text-transform: capitalize;
`;

export const GenreWrapper = styled.div`
  margin: 0.25rem 0;
  height: fit-content;
`;

export const Loading = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  margin: 0.75rem 0 0.25rem;
  justify-content: center;
  align-items: center;

  &:after {
    animation: rotation 0.8s linear infinite;
    border-radius: 100%;
    border-right: 3px solid transparent;
    border-top: 3px solid rgba(255, 255, 255, 0.8);
    content: '';
    height: 2rem;
    position: relative;
    width: 2rem;
    display: inline-block;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;
