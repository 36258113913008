import React from 'react';
import { Assets } from '../../utils/assets';
import * as S from './ActivationSuccess.styles';

export const ActivationSuccessPresentation = () => {
  return (
    <S.Wrapper>
      <S.ImageContainer>
        <S.Image src={Assets.icons.checkmarkBlack.url} />
      </S.ImageContainer>

      <S.TextContainer>
        <S.Title>Success!</S.Title>
        <S.Description>
          Your account has been activated. You can now close this window and return to the app on
          your desktop.
        </S.Description>
      </S.TextContainer>
    </S.Wrapper>
  );
};
