import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
`;

export const ImageContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
`;

export const Image = styled.img`
  object-fit: contain;
  max-width: 320px;
`;

export const TextWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

export const HeadingContainer = styled.div`
  text-align: center;
  margin-top: 1.25rem;
  position: absolute;
  z-index: 2;
  isolation: isolate;
  top: 250px;
  padding: 0 1.5rem;
`;
