import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaymentsStateType, PaymentTransactionState, PaymentsStatus } from '../types/payments';

const initialState: PaymentsStateType = {
  transaction: {
    status: '',
    clientSecret: '',
    subscriptionId: '',
  },
  status: 'idle',
  error: undefined,
  customerId: undefined,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    transactionStart: state => {
      state.status = 'loading';
      state.error = undefined;
      state.transaction = initialState.transaction;
    },

    transactionSuccess: (state, action: PayloadAction<PaymentTransactionState>) => {
      state.transaction = {
        ...state.transaction,
        ...action.payload,
      };
      state.status = 'idle';
      state.error = undefined;
    },

    transactionFailed: (state, action: PayloadAction<string>) => {
      state.transaction = initialState.transaction;
      state.status = 'idle';
      state.error = action.payload;
    },

    setCustomerId: (state, action: PayloadAction<string>) => {
      state.customerId = action.payload;
    },

    setStatus: (state, action: PayloadAction<PaymentsStatus>) => {
      state.status = action.payload;
    },

    clearTransactionState: state => {
      state.transaction = initialState.transaction;
    },
  },
});

export const paymentSliceActions = paymentSlice.actions;
export default paymentSlice.reducer;
