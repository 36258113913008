import { Serving, Track } from '@Model';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';

export function useGetPreviousTrack(): Track | Serving | null {
  return useSelector(getPreviousTrack);
}

export function getPreviousTrack(state: RootReducerType): Track | Serving | null {
  return state.music.queueHistory[0] || null;
}
