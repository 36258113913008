import { forwardRef, useState } from 'react';
import * as S from './ProfileAccountInfo.styles';
import { Transition } from 'react-transition-group';
import { DeleteAccount, EditProfile, ProfileInfo, UpdatePassword } from '@User';

enum AccountInfoScreen {
  EditProfile,
  UpdatePassword,
  DeleteAccount,
}

export const ProfileAccountInfo = forwardRef<HTMLDivElement>((_, ref) => {
  const [accountInfoScreen, setAccountInfoScreen] = useState<AccountInfoScreen | null>(null);

  return (
    <S.Container ref={ref}>
      <ProfileInfo
        onEditProfile={() => setAccountInfoScreen(AccountInfoScreen.EditProfile)}
        onPasswordChange={() => setAccountInfoScreen(AccountInfoScreen.UpdatePassword)}
      />

      <Transition
        in={accountInfoScreen === AccountInfoScreen.EditProfile}
        mountOnEnter
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <S.FloatingWindow animationState={state}>
              <EditProfile
                onClose={() => setAccountInfoScreen(null)}
                onDeleteAccount={() => setAccountInfoScreen(AccountInfoScreen.DeleteAccount)}
              />
            </S.FloatingWindow>
          );
        }}
      </Transition>

      <Transition
        in={accountInfoScreen === AccountInfoScreen.UpdatePassword}
        mountOnEnter
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <S.FloatingWindow animationState={state}>
              <UpdatePassword onClose={() => setAccountInfoScreen(null)} />
            </S.FloatingWindow>
          );
        }}
      </Transition>

      <Transition
        in={accountInfoScreen === AccountInfoScreen.DeleteAccount}
        mountOnEnter
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <S.FloatingWindow animationState={state}>
              <DeleteAccount onClose={() => setAccountInfoScreen(null)} />
            </S.FloatingWindow>
          );
        }}
      </Transition>
    </S.Container>
  );
});
