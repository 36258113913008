import { FontColors } from '@Cortex';
import styled from 'styled-components';
import { Assets } from '../../utils/assets';

export const Wrapper = styled.div`
  background-image: url(${Assets.images.activationBg.url});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
  border-radius: 100%;
  background: #6efe96;
  box-shadow:
    0px 0px 8px 0px rgba(110, 254, 150, 0.5),
    0px 0px 32px 0px rgba(110, 254, 150, 0.5);
`;

export const Image = styled.img`
  width: 2rem;
  height: 2rem;
  object-fit: contain;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  max-width: 400px;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1.25rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0.5rem;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
  color: ${FontColors.WhiteTransparent};
  text-align: center;
  width: 90%;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
