import { styled } from 'styled-components';

export const Panel = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.01) 100%);
  padding: 8px 12px 8px 8px;
  cursor: pointer;
  width: 100%;
`;

export const Image = styled.img`
  border-radius: 6px;
  height: 48px;
  width: 48px;
  transition: transform 0.15s ease-in-out;
  transform-origin: center;
  object-fit: cover;
  aspect-ratio: 1 / 1;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  gap: 4px;
  padding: 6px 8px;
  border-radius: 6px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

export const PausePauseWrapper = styled.div`
  transition: transform 0.15s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;
