import React, { useEffect, useState } from 'react';
import { Fit, Layout, useRive, EventType } from '@rive-app/react-canvas';
import CrossIcon from '../../../../assets/images/close_icon.svg';

import { Panel } from '@Cortex';
// @ts-ignore
import File from './assets/turbomode.riv';
import * as S from './SessionNELTip.styles';

interface SessionNELTipProps {
  onClose: () => void;
  onTurbochargeMeClick: () => void;
}

export const SessionNELTip: React.FC<SessionNELTipProps> = ({ onClose, onTurbochargeMeClick }) => {
  const [buttonIsHovered, setButtonIsHovered] = useState(false);
  const { rive, RiveComponent } = useRive({
    src: File,
    artboard: 'Brain.fm - Turbo Mode',
    stateMachines: 'State Machine 1',
    autoplay: false,
    animations: 'Lines_Loop',
    layout: new Layout({
      fit: Fit.Cover,
    }),
  });

  useEffect(() => {
    if (rive) {
      rive.play();

      rive.on(EventType.StateChange, e => {
        if (e.data && Array.isArray(e.data)) {
          if (e.data[0] === 'Button_Hover-1') {
            setButtonIsHovered(true);
          }
          if (e.data[0] === 'Button_Hover-0') {
            setButtonIsHovered(false);
          }

          if (e.data[0] === 'Button_Done_100') {
            onClose();
          }

          if (e.data.length >= 2) {
            if (e.data[0] === 'Button_Done' && e.data[1] === 'Text2') {
              onTurbochargeMeClick();
            }
          }
        }
      });

      return () => {
        rive.removeAllRiveEventListeners();
      };
    }
  }, [rive]);

  return (
    <Panel
      style={{
        padding: '0px',
        width: '311px',
        height: '432px',
        backdropFilter: 'blur(25px)',
        WebkitBackdropFilter: 'blur(25px)',
        boxShadow: `0px -8px 24px 0px rgba(0, 0, 0, 0.50)`,
      }}
    >
      <S.CloseButtonContainer>
        <S.CloseButton data-testid="closeButton" src={CrossIcon} onClick={onClose} />
      </S.CloseButtonContainer>
      <RiveComponent style={{ cursor: buttonIsHovered ? 'pointer' : 'default' }} />
    </Panel>
  );
};
