type Params = {
  currentPreferences: {
    genreNames: string[];
    neuralEffectLevels: string[];
  };
  newPreferences: {
    genreNames: string[] | null;
    neuralEffectLevels: string[] | null;
  };
};

type ReturnType = {
  genreNamesToAdd: string[];
  neuralEffectLevelsToAdd: string[];
  genreNamesToRemove: string[];
  neuralEffectLevelsToRemove: string[];
};

export function getPreferenceAdditionsAndRemovals(params: Params): ReturnType {
  const genrePreferences: IndividualParams = {
    currentPreferences: params.currentPreferences.genreNames,
    newPreferences: params.newPreferences.genreNames,
  };
  const neuralEffectPreferences: IndividualParams = {
    currentPreferences: params.currentPreferences.neuralEffectLevels,
    newPreferences: params.newPreferences.neuralEffectLevels,
  };

  return {
    genreNamesToAdd: getAdditions(genrePreferences),
    neuralEffectLevelsToAdd: getAdditions(neuralEffectPreferences),
    genreNamesToRemove: getRemovals(genrePreferences),
    neuralEffectLevelsToRemove: getRemovals(neuralEffectPreferences),
  };
}

type IndividualParams = {
  currentPreferences: string[];
  newPreferences: string[] | null;
};

function getAdditions(params: IndividualParams): string[] {
  return (
    params.newPreferences?.filter(
      newPreference => !params.currentPreferences.includes(newPreference),
    ) || []
  );
}

function getRemovals(params: IndividualParams): string[] {
  const shouldEvaluateRemovals = params.newPreferences !== null;
  return shouldEvaluateRemovals
    ? params.currentPreferences.filter(
        currentPreference => !params.newPreferences?.includes(currentPreference),
      )
    : [];
}
