import { InputHTMLAttributes, useState } from 'react';

import { Text } from '../Text';
import * as S from './FormInput.styles';
import EyeIcon from '../../../../assets/images/eye.svg';
import EyeSlashIcon from '../../../../assets/images/eye_slash.svg';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  errorMessage?: string;
  rightSideContent?: string | (() => JSX.Element);
}

export const FormInput = ({ name, label, errorMessage = undefined, type, ...props }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputStyles = {
    backgroundPosition: errorMessage ? '97%' : '120%',
    borderColor: errorMessage ? '#F34675' : 'initial',
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <S.InputContainer>
      <S.InputLabel htmlFor={name} style={{ color: errorMessage ? '#F34675' : '#fff' }}>
        {label}
      </S.InputLabel>

      <S.InputField
        id={name}
        name={name}
        style={inputStyles}
        type={type === 'password' && showPassword ? 'text' : type}
        {...props}
      />

      {props.rightSideContent ? (
        <S.RightSideContent>
          {typeof props.rightSideContent === 'string' ? (
            <Text style={{ marginRight: '1rem' }}>{props.rightSideContent}</Text>
          ) : (
            props.rightSideContent()
          )}
        </S.RightSideContent>
      ) : null}

      {errorMessage ? <S.ErrorText>{errorMessage}</S.ErrorText> : null}

      {type === 'password' ? (
        <S.Image
          src={showPassword ? EyeSlashIcon : EyeIcon}
          style={{ right: !!errorMessage ? '2.5rem' : '1rem' }}
          onClick={handleShowPassword}
        />
      ) : null}
    </S.InputContainer>
  );
};
