import { MembershipStates, MembershipSources } from '@Memberships';
import { useIsPaymentProcessing } from '@Payment';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as uiActions from '../../../../../../actions/ui';
import { useEnableSubscriptionAutoRenew } from '../../../../../../api/modules/EnableSubscriptionAutoRenew';
import { useGetStripeMembershipCycle } from '../../../../../../hooks/useGetStripeMembershipCycle';
import { RootReducerType } from '../../../../../../reducers';
import { StripeMembershipCycle } from '../../../../../../utils/getStripeMembershipCycle';
import { useMembershipPeriodTimeLeft } from '../../hooks/useMembershipPeriodTimeLeft';
import { MembershipPanelWrapper } from '../MembershipPanelWrapper/MembershipPanelWrapper';
import { useOpenUpgradeStripeSubscriptionModal } from './hooks/useOpenUpgradeStripeSubscriptionModal';
import { SubscriptionInformationPresentation } from './SubscriptionInformation.presentation';
import { getCtaText } from './utils/getCtaText';

export const SubscriptionInformation = () => {
  const openUpgradeStripeSubscriptionModal = useOpenUpgradeStripeSubscriptionModal();
  const dispatch = useDispatch();
  const stripeMembershipCycle = useGetStripeMembershipCycle();
  const { mutate: handleEnableAutoRenew, isLoading: isEnablingSubscriptionAutoRenew } =
    useEnableSubscriptionAutoRenew();
  const navigate = useNavigate();
  const membershipTitle = useSelector((state: RootReducerType) => state.user.membership?.title);
  const membership = useSelector((state: Pick<RootReducerType, 'membership'>) => state.membership);
  const isPaymentProcessing = useIsPaymentProcessing();
  const membershipSource = useSelector(
    (state: Pick<RootReducerType, 'membership'>) => state.membership.source,
  );
  const isLoading =
    membershipSource === MembershipSources.Loading ||
    isPaymentProcessing ||
    isEnablingSubscriptionAutoRenew;

  const isCancelled = membership.state === MembershipStates.Cancelled;
  const subtitle = `${membershipTitle} Plan`;
  const ctaText = getCtaText({ membership, membershipTitle, stripeMembershipCycle });
  const { expirationDate } = useMembershipPeriodTimeLeft();

  const isEligibleForRenewal = [
    StripeMembershipCycle.FreeTrialNotRenewingActive,
    StripeMembershipCycle.PaidNotRenewingActive,
  ].includes(stripeMembershipCycle);

  const onClickCallToAction = useCallback(() => {
    if (isEligibleForRenewal) {
      handleEnableAutoRenew();
      return;
    }

    if (membership.state === MembershipStates.Cancelled) {
      dispatch(uiActions.setModal('fastSubscription'));
      return;
    }

    openUpgradeStripeSubscriptionModal();
  }, [
    dispatch,
    navigate,
    openUpgradeStripeSubscriptionModal,
    membership.state,
    isEligibleForRenewal,
    handleEnableAutoRenew,
  ]);

  return (
    <MembershipPanelWrapper isLoading={isLoading} subtitle={subtitle} title="PRO">
      <SubscriptionInformationPresentation
        callToActionText={ctaText}
        currentPeriodEndDate={membership.currentPeriodEndDate}
        expirationDate={expirationDate}
        isCancelled={isCancelled}
        isEligibleForRenewal={isEligibleForRenewal}
        renewalPrice={membership.renewalPrice}
        subscriptionSource={MembershipSources[membership.source!]}
        onClickCallToAction={onClickCallToAction}
      />
    </MembershipPanelWrapper>
  );
};
