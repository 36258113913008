import {
  StripeCouponMembershipPromotion,
  useGetAvailableStripeCouponPromotion,
} from '@Memberships';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../../../reducers';
import { EnhancedMembershipPlanType } from '../../../../../types';
import { useIsExtendedPromoAvailable } from '../../PaywallModal/hooks/useIsExtendedPromoAvailable';
import { enhanceMembershipInfo } from '../lib/enhanceMembershipInfo';
import { getAvailablePlans } from '../lib/getAvailablePlans';
import { useGetTrialLengths } from './useGetTrialLengths';

export function useGetMembershipPlans(): EnhancedMembershipPlanType[] {
  const isExtendedPromoAvailable = useIsExtendedPromoAvailable();
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const trialLengths = useGetTrialLengths();
  const membershipPlans = useSelector((state: RootReducerType) => state.membershipPlans.plans);
  const enhancedPlans = enhanceMembershipInfo({
    plans: Object.values(membershipPlans.results),
    trialLengths,
  }).sort((a, b) => b.price - a.price);

  const availablePlans = getAvailablePlans({ plans: enhancedPlans, isExtendedPromoAvailable });
  return availablePromotion
    ? replacePlansWithPromotions({ availablePromotion, plans: availablePlans })
    : availablePlans;
}

function replacePlansWithPromotions(params: {
  availablePromotion: StripeCouponMembershipPromotion;
  plans: EnhancedMembershipPlanType[];
}): EnhancedMembershipPlanType[] {
  const promotionPlan = params.plans.find(plan => {
    return plan.title === params.availablePromotion.targetPlan;
  });

  if (!promotionPlan) return params.plans;

  return [
    {
      ...promotionPlan,
      couponId: params.availablePromotion.couponId,
      description: params.availablePromotion.description,
      displayCost: params.availablePromotion.displayCost,
      displayInterval: params.availablePromotion.targetPlan === 'Monthly' ? 'mo' : 'yr',
      originalCost: promotionPlan.price.toString(),
      promotionCode: params.availablePromotion.promotionCode,
      title: params.availablePromotion.name,
    },
  ];
}
