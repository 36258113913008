import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 10px;
`;

export const VerifyButtonWrapper = styled.div`
  margin-top: 8px;
`;

export const Text = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;

  align-items: center;
  color: #ffffff;
`;
export const ButtonText = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1rem;

  align-items: center;
  color: #ffffff;
`;

export const SecondaryText = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.8rem;

  align-items: center;
  color: #ffffff;
`;
export const AdditionalInfo = styled.div`
  margin-top: -8px;
  z-index: 1;
  margin-bottom: 25px;
`;

export const InlineButton = styled.span<{ disabled: boolean }>`
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;
