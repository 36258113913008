import styled from 'styled-components';

export const Container = styled.div<{ maxWidth?: string }>`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1.5rem;
  max-width: ${props => props.maxWidth || '30.625rem'};
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.5);
  /* backdrop-filter: blur(50px); */
  background: linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1);
  border-radius: 1.25rem;

  @supports (backdrop-filter: blur(1rem)) {
    backdrop-filter: blur(1rem);
    background: linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 2rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
`;

export const TextContainer = styled.div`
  margin-top: 7.5rem;
  flex-direction: column;
`;

export const Title = styled.h1<{ keepTextCase?: boolean }>`
  color: #fff;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1.25rem;
  margin: 0 0 1rem 0;
  width: 100%;
  text-align: center;
  text-transform: ${props => (props.keepTextCase ? 'unset' : 'lowercase')};
`;

export const Description = styled.h1`
  color: rgba(255, 255, 255, 0.75);
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

export const Close = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

export const Image = styled.img`
  position: absolute;
  top: 0rem;
  height: 13.5rem;
`;

export const ImageButton = styled.button`
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  cursor: pointer;
  top: 1rem;
  right: 1rem;
  z-index: 5;
`;

export const Back = styled.img`
  width: 1rem;
  height: 1rem;
  object-fit: contain;
`;

export const BackButton = styled.button`
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 1rem;
  height: 1rem;
  position: absolute;
  cursor: pointer;
  top: 1.2rem;
  left: 1.2rem;
  z-index: 5;
`;

export const ErrorBox = styled.div`
  position: absolute;
  width: 100%;
  padding: 0.25rem;
  left: 0;
  right: 0;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorText = styled.span`
  color: #f34675;
  font-size: ${({ theme }) => theme.fontSize.extraSmall};
`;
