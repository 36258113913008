import styled from 'styled-components';

export const Streak = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.white};
`;

export const TopPart = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  color: rgba(255, 255, 255, 0.75);
  font-size: 0.75rem;
`;

export const BottomPart = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  text-align: center;
  font-size: 4rem;
  line-height: 3rem;
  margin-top: 1rem;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
`;
