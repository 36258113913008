import * as S from './styles';
import { RootReducerType } from '../../../../../../reducers';
import { useSelector } from 'react-redux';
import { useDynamicMentalStates } from '../../../../../../api/modules/DynamicMentalStates';

export type Props = {
  genreType: string;
  onClick: () => void;
};

export const TAGS_PLAY_ALL_TEST_ID = 'tags-play-all';

export const TagsPlayAllCard = ({ genreType, onClick }: Props) => {
  const { data: dynamicMentalStates } = useDynamicMentalStates();
  const dynamicMentalStateId = useSelector(
    (state: RootReducerType) => state.ui.sideDeck.exploreSelectedMentalState?.id,
  );
  const mentalState = dynamicMentalStates?.find(
    mentalState => mentalState.id === dynamicMentalStateId,
  );

  return (
    <S.Container data-testid={TAGS_PLAY_ALL_TEST_ID} onClick={onClick}>
      <S.InnerContainer>
        <S.TextContainer>
          <S.Title>Play All</S.Title>
          <S.Description>
            Start a listening session that will shuffle all{' '}
            <S.DescriptionBold>{genreType}</S.DescriptionBold>{' '}
            <S.DescriptionBold>{mentalState?.displayValue}</S.DescriptionBold> tracks
          </S.Description>
          <S.Image src={mentalState?.playImageUrl} />
        </S.TextContainer>
      </S.InnerContainer>
    </S.Container>
  );
};
