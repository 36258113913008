import { ImgHTMLAttributes, ReactNode, CSSProperties } from 'react';
import * as S from './styles';
import CloseIcon from '../../../assets/images/close_icon.svg';

type Props = {
  onClose: () => void;
  title: string;
  description?: string;
  headerImage: ImgHTMLAttributes<HTMLImageElement>['src'];
  children: ReactNode;
  headerStyles?: CSSProperties;
  descriptionStyles?: CSSProperties;
};

export const ModalContainer = ({
  onClose,
  title,
  description,
  descriptionStyles,
  headerImage,
  children,
  headerStyles,
}: Props) => {
  return (
    <S.Container>
      <S.Header style={headerStyles}>
        <S.ImageButton type="button" onClick={onClose}>
          <S.Close src={CloseIcon} />
        </S.ImageButton>

        <S.Image src={headerImage} />

        <div>
          <S.Title>{title}</S.Title>
          {description ? (
            <S.Description style={descriptionStyles}>{description}</S.Description>
          ) : null}
        </div>
      </S.Header>
      {children}
    </S.Container>
  );
};
