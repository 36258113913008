import { RootReducerType } from '../../../reducers';

/**
 * Gets users teams list and verification data and determines if the user is allowed to play a session with teams subscription.
 * @param teams
 * @param verification
 * @returns {boolean}
 */
export function isAllowedToPlaySessionWithTeamSubscription(
  teams: RootReducerType['teams'],
  verification: RootReducerType['user']['verification'],
): boolean {
  return hasTeamAccess(teams) && isEmailVerified(verification);
}

export function hasTeamAccess(teams: RootReducerType['teams']): boolean {
  return (teams.list || []).some(t => t.hasPlatformAccess);
}

export function isEmailVerified(verification: RootReducerType['user']['verification']): boolean {
  return Boolean(verification?.isVerified);
}
