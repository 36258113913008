import { useSelector } from 'react-redux';

import { RootReducerType } from '../../reducers';
import { TimerDisplayTypes } from './constants';

export function useIsPomodoroActive() {
  const displayType = useSelector((state: RootReducerType) => state.timer.displayType);

  return displayType === TimerDisplayTypes.Pomodoro;
}
