import * as S from './styles';
import { TimerRadioSelector } from '../TimerRadioSelector';

import { TimerBreakSoundTypes } from '../../types';

type Props = {
  intervalBreakTypes: TimerBreakSoundTypes[];
  selected: TimerBreakSoundTypes['type'];
  onSelect: (selected: TimerBreakSoundTypes['type']) => void;
};

export const IntervalTimerSettings = ({ intervalBreakTypes, selected, onSelect }: Props) => {
  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title>Select Interval Sound</S.Title>
      </S.TitleContainer>
      <S.SelectionContainer>
        {intervalBreakTypes.map(interval => (
          <TimerRadioSelector
            key={interval.type}
            audioUrl={interval.audioUrl}
            isSelected={selected === interval.type}
            title={interval.title}
            onClick={() => onSelect(interval.type)}
          />
        ))}
      </S.SelectionContainer>
    </S.Container>
  );
};
