import { Button, ButtonVariants } from '@Cortex';

import * as S from './HeroCard.styles';
import BrandLogo from '../../../../assets/images/brand_logo.svg';
import { useEffect } from 'react';
import { CoreAnalytics } from '@Analytics';

export type Props = {
  activeStepIndex: number;
  body?: string;
  ctaButtonText?: string;
  desktopBackgroundImage?: string;
  mobileBackgroundImage?: string;
  numberOfSteps: number;
  onClickNext?: () => void;
  title?: string;
  stepText?: string;
};

export function HeroCard(props: Props) {
  useEffect(() => {
    CoreAnalytics.trackOnboardingCardImpressionEvent({
      title: props.title ?? '',
      body: props.body ?? '',
      step: props.stepText ?? '',
    });
  }, []);

  return (
    <S.Wrapper>
      <S.Header>
        <S.Logo src={BrandLogo} />
        {props.activeStepIndex !== null &&
        props.numberOfSteps !== null &&
        props.numberOfSteps !== 1 ? (
          <S.StepSection>
            <S.StepText>{props.stepText || 'Onboarding'}</S.StepText>
            <S.StepPills>
              {[...Array(props.numberOfSteps)].map((_, index) => (
                <S.StepPill
                  key={index}
                  isActive={index === props.activeStepIndex}
                  isCompleted={index <= props.activeStepIndex}
                />
              ))}
            </S.StepPills>
          </S.StepSection>
        ) : null}
        <S.LogoPlaceholder src={BrandLogo} />
      </S.Header>
      <S.Content>
        <S.OnboardingCardTextColumn>
          {props.title ? <S.Title>{props.title}</S.Title> : null}
          {props.body ? <S.Body>{props.body}</S.Body> : null}
        </S.OnboardingCardTextColumn>
        {props.desktopBackgroundImage ? (
          <S.BackgroundImage
            desktopSrc={props.desktopBackgroundImage}
            mobileSrc={props.mobileBackgroundImage}
          />
        ) : null}
        <S.Buttons>
          {props.onClickNext && (
            <S.ButtonContainer>
              <Button
                isFullWidth
                keepTextCase
                variant={ButtonVariants.Secondary}
                onClick={props.onClickNext}
              >
                {props.ctaButtonText || 'Continue'}
              </Button>
            </S.ButtonContainer>
          )}
        </S.Buttons>
      </S.Content>
    </S.Wrapper>
  );
}
