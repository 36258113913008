import { EffectLevels, Track } from '@Model';
import { MusicState, initialState } from '@Music';

import { Logger } from '../../../../utils/logger';

export type PersistedV0MusicState = {
  currentTrack: V0Track | null;
  queue: V0Track[];
};

export type V0Track = {
  activityId: string;
  genreName: string;
  hasMultipleNELs: boolean;
  id: string;
  image: string;
  instrumentations: string[];
  isNewlyCreated: boolean;
  lengthInSeconds: number;
  mentalStateId: string;
  moods: string[];
  name: string;
  complexityDisplayValue: EffectLevels;
  neuralEffectLevelDisplayValue: EffectLevels;
  url: string;
};

type V1MusicState = Pick<MusicState, 'queue' | 'currentTrack'>;

export function migrate(state: PersistedV0MusicState): V1MusicState {
  Logger.info('Migrating music state from v0 to v1');

  return {
    ...state,
    currentTrack: null,
    queue: [],
  };
}
