import { Serving, Track } from '@Model';

import { getTrackIsNewlyCreated, getTrackName } from '../lenses';

export function sortByTrackName(trackOne: Track | Serving, trackTwo: Track | Serving) {
  const result = getTrackName(trackOne).localeCompare(getTrackName(trackTwo));

  if (result < 0) return -1;
  if (result > 0) return 1;

  return 0;
}

export function sortByTrackIsNewlyRelease(trackOne: Track | Serving, trackTwo: Track | Serving) {
  if (getTrackIsNewlyCreated(trackOne) && !getTrackIsNewlyCreated(trackTwo)) return -1;
  if (!getTrackIsNewlyCreated(trackOne) && getTrackIsNewlyCreated(trackTwo)) return 1;

  const result = getTrackName(trackOne).localeCompare(getTrackName(trackTwo));

  if (result < 0) return -1;
  if (result > 0) return 1;

  return 0;
}
