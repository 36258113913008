import { useMemo } from 'react';

import { useNewlyReleasedTracks } from '../../../../../../api/modules/NewlyReleasedTracks';
import { useRecommendedTrack } from '../../../../../../api/modules/RecommendedTrack';
import { NewTracks } from '../NewTracks';
import * as S from './Featured.styles';
import { Serving, Track } from '@Model';

type Props = {
  onTrackClickMore: (track: Track | Serving) => void;
};

export const Featured = (props: Props) => {
  const {
    error: newTracksError,
    data: newTracksData,
    isFetchedAfterMount: isNewTracksFetched,
    isRefetching: isRefetchingNewTracks,
  } = useNewlyReleasedTracks();

  const isDataReady = isNewTracksFetched && !isRefetchingNewTracks;

  const newTracks = useMemo(() => newTracksData || [], [newTracksData]);

  if (!isDataReady) {
    return <S.Loading />;
  }

  return (
    <NewTracks
      error={newTracksError}
      tracks={newTracks}
      onTrackClickMore={props.onTrackClickMore}
    />
  );
};
