import { membershipActions } from '@Memberships';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import * as uiActions from '../../../actions/ui';
import * as userActions from '../../../actions/user';
import { getUiErrorMessage } from '../../../utils/getUiErrorMessage';
import { isOfflineError } from '../../../utils/isOfflineError';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { CreateFastSubscriptionResponseType } from './types';
import { useRequestHandler } from './useRequestHandler';

export const useCreateFastSubscription = () => {
  const createFastSubscription = useRequestHandler();
  const dispatch = useDispatch();

  return useMutation<CreateFastSubscriptionResponseType, Error>(
    Queries.postCreateFastSubscription,
    () => createFastSubscription(),
    {
      onError: e => {
        if (isOfflineError(e)) return;
        Logger.error(new Error(getUiErrorMessage(e, 'Error enabling subscription auto renew')));
      },
      onSuccess: data => {
        if (data.status !== 200) return;
        dispatch(membershipActions.fetchMembership());
        dispatch(userActions.getInfo());
        dispatch(
          uiActions.successModalOpen({
            title: 'Success!',
            description: 'Your subscription has successfully created',
            actions: [{ type: 'primary', text: 'CLOSE', action: uiActions.successModalClose() }],
          }),
        );
      },
      cacheTime: 0,
    },
  );
};
