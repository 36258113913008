import { EnhancedMembershipPlanType } from '../../../../../types';
import { getIsYearlyPlan } from './getIsYearlyPlan';

type Args = {
  plans: EnhancedMembershipPlanType[];
  isExtendedPromoAvailable: boolean;
};

export function getAvailablePlans({
  plans,
  isExtendedPromoAvailable,
}: Args): EnhancedMembershipPlanType[] {
  if (isExtendedPromoAvailable) {
    // only yearly plans for extended
    return plans.filter(getIsYearlyPlan);
  }

  return plans;
}
