import styled from 'styled-components';

export const Container = styled.div<{ hasSelected?: boolean; backgroundUrl?: string }>`
  background-image: ${props => (props.backgroundUrl ? `url(${props.backgroundUrl})` : 'unset')};
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  border: ${props => (props.hasSelected ? '2px' : '1px')} solid
    ${props => (props.hasSelected ? '#d4d2ea' : 'rgba(255, 255, 255, 0.2)')};
  border-radius: 0.75rem;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: space-between;
  margin: 1rem 0 0;
  padding: 1rem;
  transition: background 0.2s ease-in-out;
  width: 100%;
  max-height: 24rem;
  min-height: 20rem;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  transform: ${props => (props.hasSelected ? 'scale(1.05)' : 'scale(1)')};

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: ${props => (props.hasSelected ? '#d4d2ea' : 'rgba(255, 255, 255, 0.5)')};
    transform: scale(1.05);
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    max-height: unset;
    min-height: unset;
  }
`;

export const ImageBackground = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PlayButton = styled.img`
  width: 3.83rem;
  height: 3.83rem;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  &:hover {
    transform: translate(-50%, -50%) scale(1.05);
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 2.5rem;
    height: 2.5rem;
    left: unset;
    right: 0;
    top: 50%;
  }
`;
