import { DynamicMentalState } from '@Model';
import * as S from './styles';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  mentalState: DynamicMentalState;
  isActive: boolean;
}

export const MentalState = ({ mentalState, isActive, ...rest }: Props) => {
  return (
    <S.Container {...rest}>
      <S.Body>
        <S.Title>{mentalState.displayValue}</S.Title>
      </S.Body>
      <S.ImageContainer>
        <S.Image src={mentalState.imageUrl} />
        <S.AuraImage src={mentalState.imageUrlSecondary} />
      </S.ImageContainer>
    </S.Container>
  );
};
