import { Text, Icon } from '@Cortex';
import ChevronNavigateIcon from '../../assets/chevron_navigate_icon.svg';
import LinkOutIcon from '../../assets/link_out_icon.svg';

import * as S from './ProfileItem.styles';
import { ProfileItemActionType, ProfileItemType } from '../../Profile.types';

type Props = {
  item: ProfileItemType;
  testId?: string;
  onClick: (item: ProfileItemType) => void;
};

export function ProfileItem({ item, testId, onClick }: Props) {
  return (
    <S.Container key={item.label} data-testid={testId} onClick={() => onClick(item)}>
      <S.LeftContainer>
        <S.LeftIcon>
          <Icon size={24} src={item.iconSrc} />
        </S.LeftIcon>
        <Text>{item.label}</Text>
      </S.LeftContainer>
      <S.RightContainer>
        {item.type === ProfileItemActionType.Screen && (
          <S.RightIcon>
            <Icon size={16} src={ChevronNavigateIcon} />
          </S.RightIcon>
        )}
        {item.type === ProfileItemActionType.Linkout && (
          <S.RightIcon>
            <Icon size={16} src={LinkOutIcon} />
          </S.RightIcon>
        )}
      </S.RightContainer>
    </S.Container>
  );
}
