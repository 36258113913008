export enum TimerSettingsPanes {
  Infinite = 'Infinite',
  Timer = 'Timer',
  Intervals = 'Intervals',
}

export type TimerPomodorIntervals = {
  focus: TimerState;
  break: TimerState;
};

export type TimerState = {
  label: string;
  value: string;
};

export type TimerBreakSoundTypes = {
  type: 'chime' | 'voice';
  title: string;
  description?: string;
  audioUrl: string;
};
