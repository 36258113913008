import { Button, ButtonVariants } from '@Cortex';
import { NOOP } from '@Globals';
import React, { forwardRef } from 'react';
import { TransitionStatus } from 'react-transition-group';

import * as S from './DiscountOffer.styles';
import { Assets } from '../../../../../../utils/assets';
import { ProfileFormContainer } from '../../../../../shared/ProfileFormContainer';

export interface Props {
  onClose?: () => void;
  onContinue?: () => void;
  onBack?: () => void;
  onCancelSubscription?: () => void;
  animationState: TransitionStatus;
}

export const DiscountOffer = forwardRef<HTMLDivElement, Props>(
  (
    {
      onClose = NOOP,
      onContinue = NOOP,
      onBack = NOOP,
      onCancelSubscription = NOOP,
      animationState,
    },
    ref,
  ) => {
    return (
      <S.Container ref={ref} animationState={animationState}>
        <ProfileFormContainer
          description={
            'You are eligible for a special discount. Would you like to explore this offer?'
          }
          keepTextCase
          maxWidth="33rem"
          title="We’d love for you to stay with us."
          onBack={onBack}
          onClose={onClose}
        >
          <S.Image alt={Assets.images.specialOffer.alt} src={Assets.images.specialOffer.url} />
          <S.AuraImage alt={Assets.images.purpleAura.alt} src={Assets.images.purpleAura.url} />
          <S.Footer>
            <S.ButtonWrapper>
              <Button
                isFullWidth
                keepTextCase
                variant={ButtonVariants.Secondary}
                onClick={onContinue}
              >
                VIEW OFFER
              </Button>
            </S.ButtonWrapper>
            <S.ButtonWrapper>
              <Button
                data-testid="confirmCancelSubscriptionButton"
                isFullWidth
                keepTextCase
                variant={ButtonVariants.Base}
                onClick={onCancelSubscription}
              >
                PROCEED WITH CANCELLATION
              </Button>
            </S.ButtonWrapper>
          </S.Footer>
        </ProfileFormContainer>
      </S.Container>
    );
  },
);
