import { useState, useRef, useCallback, useEffect } from 'react';
import { Analytics } from '../../../../../../utils/analytics';
import { Events } from '../../../../../../utils/analytics/events';
import { DEMO_TRACK } from './constants';
import { DemoTrackDisplay } from './DemoTrack.display';

export const DemoTrack = () => {
  const audioRef = useRef(new Audio(DEMO_TRACK.url));
  const [isPlaying, setIsPlaying] = useState(false);
  const onClick = useCallback(() => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
      Analytics.logEventWithProperties(Events.test_subscription_track_play, {});
    }
    setIsPlaying(!isPlaying);
  }, [audioRef.current, isPlaying, setIsPlaying]);

  useEffect(
    () => () => {
      audioRef.current.pause();
    },
    [audioRef.current],
  );

  useEffect(() => {
    const handleEnded = () => {
      setIsPlaying(false);
    };

    const currentAudio = audioRef.current;
    currentAudio.addEventListener('ended', handleEnded);

    return () => {
      currentAudio.removeEventListener('ended', handleEnded);
    };
  }, [audioRef, setIsPlaying]);

  return (
    <DemoTrackDisplay
      activity={DEMO_TRACK.activity}
      genre={DEMO_TRACK.genre}
      imageUrl={DEMO_TRACK.imageUrl}
      isPlaying={isPlaying}
      neuralEffectLevel={DEMO_TRACK.neuralEffectLevel}
      title={DEMO_TRACK.title}
      onClick={onClick}
    />
  );
};
