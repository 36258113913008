import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { fetchFavoritesAndDislikes } from '../actions';

export function useFetchFavoritesAndDislikesOnUserChange() {
  const dispatch = useDispatch();
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);

  useEffect(() => {
    if (userId) {
      dispatch(fetchFavoritesAndDislikes());
    }
  }, [userId]);
}
