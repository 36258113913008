import { FontColors } from '@Cortex';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0.5rem 0;
  padding: 0 2px;
`;

export const SelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
`;

export const Title = styled.span`
  width: 100%;
  display: flex;
  gap: 1rem;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${FontColors.White};
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
`;
