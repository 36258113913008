import { TimerMode } from '../domains/Session/components/TimerSettings/hooks/useTrackTimerModeChange';
import { SessionPlayType } from '../types';

export const getTimerMode = (sessionPlayType: SessionPlayType, isPomodoro: boolean) => {
  if (sessionPlayType === 'NORMAL' && isPomodoro) return TimerMode.Interval;
  if (sessionPlayType === 'NORMAL') return TimerMode.Infinite;
  switch (sessionPlayType) {
    case 'TIMER':
      return TimerMode.Custom;
    case 'QUOTES':
    default:
      return TimerMode.Quotes;
  }
};
