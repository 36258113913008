import { TransitionStatus } from 'react-transition-group';
import { Assets } from '../../../../utils/assets';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 32px 24px 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const HeadingContainer = styled.div`
  margin-bottom: 0.5rem;
  text-transform: capitalize;
`;

export const TabBarContainer = styled.div`
  margin-bottom: 1.25rem;
`;

export const FadeInWrapper = styled.div<{ animationState: TransitionStatus }>`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  animation: fadeInDown 0.3s ease-in-out;
  visibility: ${({ animationState }) => (animationState === 'entered' ? 'visible' : 'hidden')};

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;
