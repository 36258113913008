import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;
  background: linear-gradient(
    30deg,
    rgba(60, 80, 120, 1) 0%,
    rgba(60, 80, 120, 1) 45%,
    rgba(126, 77, 140, 1) 100%
  );
  border-radius: 76px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  display: flex;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  justify-content: space-between;
  padding: 0.2rem 0.2rem 0.2rem 0.8rem;
  margin-bottom: 8px;
`;

export const Body = styled.div`
  align-items: center;
  display: flex;
`;

export const Image = styled.img`
  margin-left: 0.5rem;
  height: 2rem;
`;

export const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

export const ButtonWrapper = styled.a`
  margin-left: 1rem;
  text-decoration: none;
`;
