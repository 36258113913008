import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { timerTimes } from '../Session/components/TimerSettings/constants';

import {
  TimerState,
  TimerStatuses,
  InitialTimer,
  TimerDisplayTypes,
  PomodoroIntervals,
  DefaultPomodoroIntervals,
  CustomTimerMaxValues,
} from './constants';

export const initialState: InitialTimer = {
  currentTime: Date.now(),
  displayType: TimerDisplayTypes.Countup,
  startTime: null,
  status: TimerStatuses.Initial,
  timeSpentPaused: null,
  pomodoroSettings: {
    intervals: DefaultPomodoroIntervals,
  },
  customTimerOptions: [...timerTimes],
};

const { actions: timerActions, reducer: timerReducer } = createSlice({
  name: 'Timer',
  initialState: initialState as TimerState,
  reducers: {
    reset(state, action: PayloadAction<undefined | { shouldPreserveDisplayType?: boolean }>) {
      if (!action.payload?.shouldPreserveDisplayType) {
        return {
          ...initialState,
          pomodoroSettings: state.pomodoroSettings,
          customTimerOptions: state.customTimerOptions,
        };
      }
      return {
        ...initialState,
        displayType: state.displayType,
        pomodoroSettings: state.pomodoroSettings,
        customTimerOptions: state.customTimerOptions,
      };
    },

    start(state) {
      if (state.status === TimerStatuses.Running) return state;
      const currentTime = Date.now();

      if (state.status === TimerStatuses.Paused) {
        const timeSinceLastUpdate = currentTime - state.currentTime;
        return {
          ...state,
          currentTime,
          timeSpentPaused: state.timeSpentPaused + timeSinceLastUpdate,
          status: TimerStatuses.Running,
        };
      }

      return {
        ...state,
        currentTime,
        startTime: currentTime,
        timeSpentPaused: 0,
        status: TimerStatuses.Running,
      };
    },

    turnOnPomodoroMode(state) {
      return {
        ...state,
        displayType: TimerDisplayTypes.Pomodoro,
      };
    },

    pause(state, action: PayloadAction<{ skipTimeUpdate: boolean } | undefined>) {
      if (state.status !== TimerStatuses.Running) return state;
      const currentTime = Date.now();

      return {
        ...state,
        currentTime: action.payload?.skipTimeUpdate ? state.currentTime : currentTime,
        status: TimerStatuses.Paused,
      };
    },

    update(state) {
      const currentTime = Date.now();
      const timeSinceLastUpdate = currentTime - state.currentTime;

      if (state.status === TimerStatuses.Initial) {
        return {
          ...state,
          currentTime,
        };
      }

      if (state.status === TimerStatuses.Paused) {
        return {
          ...state,
          currentTime,
          timeSpentPaused: state.timeSpentPaused + timeSinceLastUpdate,
        };
      }

      return {
        ...state,
        currentTime,
      };
    },

    setPomodoroIntervals(state, action: PayloadAction<Partial<PomodoroIntervals>>) {
      return {
        ...state,
        pomodoroSettings: {
          ...state.pomodoroSettings,
          intervals: {
            ...state.pomodoroSettings.intervals,
            ...action.payload,
          },
        },
      };
    },

    addCustomTimerValue(state, action: PayloadAction<{ label: string; value: string }>) {
      return {
        ...state,
        customTimerOptions: [
          action.payload,
          ...state.customTimerOptions.filter(({ value }) => value !== action.payload.value),
        ].slice(0, CustomTimerMaxValues),
      };
    },
  },
});

const persistedTimerReducer = persistReducer(
  {
    key: 'domains/Timer/reducer',
    storage,
    whitelist: ['displayType', 'pomodoroSettings', 'customTimerOptions'],
  },
  timerReducer,
);

export { timerActions, timerReducer, persistedTimerReducer };
