import { OnboardingSpecification } from '../types';
import { filterQuestionsByUtmSource } from './filterQuestionsByUtmSource';

export const processOnboardingSpecification = (
  onboardingSpecification: OnboardingSpecification,
): OnboardingSpecification => {
  const { quizSpecification } = onboardingSpecification;
  if (!quizSpecification) {
    return onboardingSpecification;
  }

  return {
    ...onboardingSpecification,
    quizSpecification: {
      ...quizSpecification,
      questions: filterQuestionsByUtmSource(quizSpecification.questions),
    },
  };
};
