import { NOOP } from '@Globals';
import { CardElement } from '@stripe/react-stripe-js';
import { useFeatureFlag, FeatureFlags } from '@Utils';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DEFAULT_BENEFITS, REVIEWS } from '../../constants';
import { useCommonPaywallData } from '../../hooks/useCommonPaywallData';
import { useDynamicFontSize } from '../../hooks/useDynamicFontSize';
import { getDefaultCardElementStyles } from '../../lib/getDefaultCardElementStyles';
import { SingleScreenPaywallDisplay } from './SingleScreenPaywall.display';
import { OnboardingTestimonials } from '../../../../../Onboarding/types';
import { useIsExtendedPromoAvailable } from '../../../PaywallModal/hooks/useIsExtendedPromoAvailable';

interface Props {
  paywallBenefits?: string[];
  paywallTestimonials?: OnboardingTestimonials[];
  onPaymentComplete?: () => void;
  onClose?: () => void;
}

export const SingleScreenPaywall = ({
  onPaymentComplete = NOOP,
  onClose = NOOP,
  paywallBenefits = DEFAULT_BENEFITS,
  paywallTestimonials = REVIEWS,
}: Props) => {
  const location = useLocation();
  const fontSize = useDynamicFontSize();
  const isExtendedPromoAvailable = useIsExtendedPromoAvailable();
  const isStripeCheckoutEnabled = useFeatureFlag(FeatureFlags.StripeCheckout);
  const isFromOnboarding = location.state?.from === 'Onboarding';

  const isCloseButtonAvailable = !isFromOnboarding;

  const {
    errorMessage,
    selectedPlan,
    setSelectedPlan,
    plans,
    handlePayment,
    handleStripeCheckout,
    paymentStatus,
    defaultBillingName,
  } = useCommonPaywallData({ onPaymentComplete });

  useEffect(() => {
    if (isStripeCheckoutEnabled) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      const message = 'Are you sure you want to leave?';
      event.returnValue = message; // This message is not actually returned, but for chrome to show dialog
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isStripeCheckoutEnabled]);

  return (
    <SingleScreenPaywallDisplay
      billingName={defaultBillingName}
      errorMessage={errorMessage}
      isCloseButtonAvailable={isCloseButtonAvailable}
      isExtendedPromo={isExtendedPromoAvailable}
      isStripeCheckoutEnabled={isStripeCheckoutEnabled}
      paywallBenefits={paywallBenefits}
      paywallTestimonials={paywallTestimonials}
      plans={plans}
      selectedPlan={selectedPlan}
      transactionStatus={paymentStatus}
      onClose={onClose}
      onPlanSelect={setSelectedPlan}
      onSubmit={handlePayment}
      onSubmitStripeCheckout={handleStripeCheckout}
    >
      <CardElement options={{ ...getDefaultCardElementStyles(fontSize) }} />
    </SingleScreenPaywallDisplay>
  );
};
