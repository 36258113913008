import styled, { css } from 'styled-components';

export const Container = styled.div<{ variant: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  ${props =>
    props.variant === 'first' &&
    css`
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    `}
  ${props =>
    props.variant === 'last' &&
    css`
      margin-top: 1px;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    `}
  ${props =>
    props.variant === 'default' &&
    css`
      margin-top: 1px;
    `}
`;

export const Radio = styled.div`
  align-items: center;
  align-self: center;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
  justify-content: center;
  margin-left: 1rem;
`;

export const RadioSelected = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  background-color: white;
`;

export const RadioButtonLabel = styled.label`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
`;
