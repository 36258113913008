import { useCallback } from 'react';

import { useGet } from '../../client/client';
import { Endpoints } from '../../common/endpoints';
import { TPromotionResponse } from './types';

export const useRequestHandler = () => {
  const get = useGet<TPromotionResponse>();

  return useCallback(async () => {
    const response = await get({ path: Endpoints.getPromotions(), options: { apiVersion: 3 } });
    return response.result;
  }, [get]);
};
