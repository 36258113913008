import * as S from './styles';
import { useEffect, useState } from 'react';
import { SLIDES } from '../../libs/carouselSlides';

export const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovered, setHovered] = useState(false);

  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(() => {
        setActiveIndex(prevIndex => (prevIndex + 1) % SLIDES.length);
      }, 6000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isHovered]);

  const handleNextSlide = () => {
    setActiveIndex(prevIndex => (prevIndex + 1) % SLIDES.length);
  };

  const handlePrevSlide = () => {
    setActiveIndex(prevIndex => (prevIndex - 1 + SLIDES.length) % SLIDES.length);
  };

  return (
    <S.Carousel>
      <S.CarouselItem onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <S.ImageContainer onClick={handleNextSlide}>
          {SLIDES.map((slide, index) => (
            <S.Image key={index} isActive={index === activeIndex} src={slide.images.md} />
          ))}
        </S.ImageContainer>

        <S.TextContainer>
          {SLIDES.map((slide, index) => (
            <S.TextWrapper key={index} isActive={index === activeIndex}>
              <S.Title>{slide.title}</S.Title>
              <S.Description>{slide.description}</S.Description>
            </S.TextWrapper>
          ))}
        </S.TextContainer>

        <S.CarouselControl>
          <S.ArrowContainer onClick={handlePrevSlide}>
            <S.ArrowLeft src="https://cdn.brain.fm/images/arrow.png" />
          </S.ArrowContainer>

          <S.SliderContainer>
            {SLIDES.map((slide, index) => (
              <S.CarouselControlButton
                key={index}
                active={activeIndex === index}
                onClick={() => setActiveIndex(index)}
              />
            ))}
          </S.SliderContainer>

          <S.ArrowContainer onClick={handleNextSlide}>
            <S.ArrowRight src="https://cdn.brain.fm/images/arrow.png" />
          </S.ArrowContainer>
        </S.CarouselControl>
      </S.CarouselItem>
    </S.Carousel>
  );
};
