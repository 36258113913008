import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../../../../reducers';
import { STREAK_MILESTONES, StreakMilestone } from '../../../../../../../types';
import { getMaxAchievement } from '../../../../../../../utils/getMaxAchievement';

export const useStreakMilestone = () => {
  const longestCount = useSelector(
    (state: RootReducerType) => state.user.streaks.data.weekly.longestCount,
  );

  return getMaxAchievement<StreakMilestone>(longestCount, STREAK_MILESTONES);
};
