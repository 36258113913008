import styled from 'styled-components';

const HeaderContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.extraSmall};
  text-align: center;
  margin: 0.5rem;
  line-height: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 2.25rem;
  height: 2.25rem;
  object-fit: contain;
`;

const HeaderTitle = styled.h1`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
  font-family: ${({ theme }) => theme.font.family.default.medium};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  margin: 1rem 0.5rem;
  line-height: 1.2;
`;

const HeaderDescription = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.small};
  opacity: 0.8;
  margin: 0.25rem;
`;

export interface FormHeaderProps {
  /** Mail text */
  title: string;
  /** Text appearing right under the Mail text */
  description?: string;
  /** Image source that appears on the left of Main text */
  imageSource?: { url: string; alt: string };
}

export const FormHeader: React.FC<FormHeaderProps> = ({ title, description, imageSource }) => {
  return (
    <HeaderContainer>
      <Header>
        {imageSource && <Image alt={imageSource.alt} src={imageSource.url} />}
        <HeaderTitle>{title}</HeaderTitle>
      </Header>
      {description && <HeaderDescription>{description}</HeaderDescription>}
    </HeaderContainer>
  );
};
