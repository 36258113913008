import styled from 'styled-components';
import { TransitionStatus } from 'react-transition-group';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    width: fit-content;
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  overflow-y: auto;
  padding: 1rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    align-items: flex-start;
  }
`;

export const ContentWrapper = styled.div<{ animationState: TransitionStatus }>`
  display: flex;
  flex-direction: column;
  position: relative;
  top: -5%;
  transition: opacity 0.3s ease-in-out;

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0;
      case 'entered':
        return 1;
      case 'exiting':
        return 0;
      case 'exited':
        return 0;
    }
  }};

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    top: 0;
    width: 100%;
    max-width: 768px;
  }
`;

export const CardImageArea = styled.div`
  position: relative;
  width: 16.25rem;
  height: 16.25rem;
  transition: transform 0.3s ease-in-out;

  ${({ theme }) => theme.mediaQuery.maxWidth.xxl} {
    width: 15.25rem;
    height: 15.25rem;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    width: 12.25rem;
    height: 12.25rem;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 11.25rem;
    height: 11.25rem;
    top: -0.5rem;
    right: 0;
    position: absolute;
    isolation: isolate;
    z-index: -1;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.01) 100%);
  border-radius: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin: 0.75rem;
  padding: 0px 2.1rem 2.5rem 2.1rem;
  position: relative;
  overflow: hidden;

  transition:
    transform 0.3s ease-in-out,
    box-shadow 0.6s ease-in-out,
    background 0.6s ease-in-out;

  &:hover {
    transform: translateY(-4px);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.04) 100%
    );
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
  }

  &:hover ${CardImageArea} {
    transform: scale(1.05);
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: row-reverse;
    align-items: center;
    padding: 1rem;
    width: 100%;
    justify-content: flex-end;
    margin: 0.5rem 1rem;
  }
`;

export const CardText = styled.div`
  color: #fff;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: clamp(1.5rem, 8vw - 3rem, 2rem);
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-align: center;
  margin-top: 1.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    margin: 2rem;
  }
`;

export const CardImage = styled.img<{ active: boolean }>`
  position: absolute;
  width: 100%;
  height: auto;
  object-fit: contain;
  transition:
    opacity 0.6s ease-in-out,
    transform 0.6s ease-in-out;
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

export const Header = styled.div`
  color: #fff;
  text-align: center;
  font-size: clamp(2rem, 8vw - 2rem, 3.5rem);
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 2rem;
  }
`;

export const Subheader = styled.div`
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-size: clamp(1.125rem, 8vw - 2rem, 2rem);
  margin-top: clamp(1rem, 8vw - 3rem, 2rem);
  margin-bottom: clamp(1rem, 8vw - 3rem, 2rem);
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-weight: ${({ theme }) => theme.font.weight.medium};

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 1.125rem;
  }
`;

export const MentalStates = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
  }
`;
