import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as uiActions from '../../../../actions/ui';
import { put } from '../../../../api/client/client';
import { RootReducerType } from '../../../../reducers';
import { userSliceActions } from '../../../../reducers/user';
import { UserInfoType } from '../../../../types';
import { UpdatePasswordDisplay, FormSubmitArgs } from './UpdatePassword.display';

type UpdatePasswordStatus = 'idle' | 'loading' | 'success' | 'error';

interface UpdatePasswordProps {
  onClose: () => void;
}

export const UpdatePassword = ({ onClose }: UpdatePasswordProps) => {
  const [formStatus, setFormStatus] = useState<UpdatePasswordStatus>('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const { info } = useSelector((state: RootReducerType) => state.user);
  const { token } = useSelector((state: RootReducerType) => state.auth);

  const dispatch = useDispatch();
  const handlePasswordChange = async ({ currentPassword, newPassword }: FormSubmitArgs) => {
    try {
      setErrorMessage('');
      setFormStatus('loading');

      const { status, result, messages } = await put<
        { currentPassword: string; newPassword: string },
        {
          status: number;
          result?: UserInfoType;
          messages: string[];
        }
      >({
        path: `/users/${info?.id}/update-password`,
        body: { currentPassword, newPassword },
        token,
      });

      if (status === 200 && result) {
        dispatch(userSliceActions.setUserInfo(result));
        onClose();

        dispatch(
          uiActions.successModalOpen({
            title: 'Success!',
            description: 'Your password was changed successfully.',
            actions: [{ type: 'primary', text: 'CLOSE', action: uiActions.successModalClose() }],
          }),
        );
      } else {
        setFormStatus('error');
        setErrorMessage(messages.join(' '));
      }
    } catch (error) {
      setFormStatus('error');
    } finally {
      setTimeout(() => {
        setFormStatus('idle');
      }, 2000);
    }
  };

  return (
    <UpdatePasswordDisplay
      errorMessage={errorMessage}
      isLoading={formStatus === 'loading'}
      submitButtonTitle="Save"
      onClose={onClose}
      onFormSubmit={handlePasswordChange}
    />
  );
};
