import { DynamicActivity } from '@Model';
import { takeEvery, select, put, call } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';

import * as recommendedActivitiesActions from '../actions/recommendedActivities';
import { RootReducerType } from '../reducers';

import { requestSaga } from './httpRequest';
import { RequestMethods } from '../api/client/types';
import { recommendedActivitiesSliceActions } from '../reducers/recommendedActivities';
import { Logger } from '../utils/logger';

function* fetchRecommendedActivitiesSaga(action: Action) {
  if (recommendedActivitiesActions.fetchRecommendedActivities.match(action)) {
    yield put(recommendedActivitiesSliceActions.fetchRecommendedActivities());

    try {
      const userInfo: RootReducerType['user']['info'] = yield select(
        (state: RootReducerType) => state.user.info,
      );

      const recentSession = (yield call(
        requestSaga,
        RequestMethods.POST,
        `/users/${userInfo?.id}/activities/recommended?platform=web`,
        {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        3,
      )) as {
        result: DynamicActivity[];
      };

      yield put(
        recommendedActivitiesSliceActions.fetchRecommendedActivitiesSuccess(recentSession.result),
      );
    } catch (error) {
      yield put(recommendedActivitiesSliceActions.fetchRecommendedActivitiesSuccess([]));
      Logger.error(error);
    }
  }
}

export function* watchRecommendedActivitiesSaga() {
  yield takeEvery(
    recommendedActivitiesActions.fetchRecommendedActivities.type,
    fetchRecommendedActivitiesSaga,
  );
}
