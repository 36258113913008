import { Text } from '@Cortex';
import styled from 'styled-components';

export const Carousel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-right: 2rem;
`;

export const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TextContainer = styled.div`
  flex: 1;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 68px;
`;

export const TextWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 0.3s;
`;

export const ImageContainer = styled.div`
  height: 400px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

export const Image = styled.img<{ isActive: boolean }>`
  object-fit: contain;
  width: 478px;
  height: 360px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 0.3s;
  position: absolute;
`;

export const Title = styled(Text)`
  font-size: 2rem;
  color: #fff;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  margin-bottom: 0.5rem;
  text-align: center;
`;

export const Description = styled(Text)`
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.75);
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  text-align: center;
`;

export const CarouselControl = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 40px;
  overflow: hidden;
  justify-content: center;
  margin-right: 1rem;
`;

export const CarouselControlButton = styled.div<{ active: boolean }>`
  flex: 1;
  height: 4px;
  border-radius: 5px;
  background-color: ${({ active }) => (active ? '#fff' : 'rgba(255, 255, 255, 0.2)')};
  margin: 1rem 3px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

export const SliderContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: 40%;
  align-items: center;
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const ArrowImage = styled.img`
  width: 24px;
  height: 24px;
  opacity: 0.5;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

export const ArrowLeft = styled(ArrowImage)`
  transform: rotate(0deg);
  margin-right: 1.5rem;
`;

export const ArrowRight = styled(ArrowImage)`
  transform: rotate(180deg);
  margin-left: 1.5rem;
`;
