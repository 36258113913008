import { DynamicActivity } from '@Model';
import { CSSProperties } from 'react';
import * as S from './ActivityCard.styles';

export type Props = {
  dynamicActivity: DynamicActivity;
  onClick: () => void;
  style?: CSSProperties;
};

export const ActivityCard = ({ dynamicActivity, onClick, style }: Props) => {
  return (
    <S.Container style={style} onClick={onClick}>
      <S.InnerContainer>
        <S.Title>{dynamicActivity.displayValue}</S.Title>
        <S.Image src={dynamicActivity.mentalState.playImageUrl} />
      </S.InnerContainer>
    </S.Container>
  );
};
