import { TabBar, TextBold } from '@Cortex';
import { musicActions } from '@Music';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';

import * as S from './Library.styles';
import { Favorites } from './components/Favorites';
import { Recent } from './components/Recent';
import { RootReducerType } from '../../../../reducers';
import { LibraryPanelTab } from '../../../../types';
import { Serving, Track } from '@Model';
import { TrackInfoModal } from '../TrackInfoModal/TrackInfoModal';
import {
  MentalStateSelector,
  MentalStateSelectorTab,
} from '../MentalStateSelector/MentalStateSelector.container';

const TAB_ITEMS = Object.values(LibraryPanelTab);

export function Library() {
  const preSelectedTab = useSelector((state: RootReducerType) => state.ui.libraryPanelTab);
  const [currentPane, setCurrentPane] = useState<LibraryPanelTab>(LibraryPanelTab.Favorites);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const favoritesRef = useRef<HTMLDivElement>(null);
  const recentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [isMoreInfoOpened, setIsMoreInfoOpened] = useState(false);
  const [previewTrack, setPreviewTrack] = useState<Track | Serving | null>(null);

  useEffect(() => {
    if (preSelectedTab) {
      const activeIndex = TAB_ITEMS.findIndex(tabItem => tabItem === preSelectedTab);

      if (activeIndex >= 0) {
        setActiveTabIndex(activeIndex);
      }
      setCurrentPane(preSelectedTab);
    }

    return () => {
      dispatch(musicActions.setGenreFilters([]));
      dispatch(musicActions.setActivityFilters([]));
      dispatch(musicActions.setNeuralEffectFilters([]));
    };
  }, []);

  const handleTabItemClick = (pane: LibraryPanelTab) => {
    const activeIndex = TAB_ITEMS.findIndex(tabItem => tabItem === pane);

    if (activeIndex >= 0) {
      setActiveTabIndex(activeIndex);
    }

    setCurrentPane(pane);
  };

  const handleTrackClickMore = (track: Track | Serving) => {
    setIsMoreInfoOpened(true);
    setPreviewTrack(track);
  };

  return (
    <>
      <S.Container data-testid="Library">
        <S.HeadingContainer>
          <TextBold size={32}>{'Library'}</TextBold>
        </S.HeadingContainer>

        <S.TabBarContainer>
          <TabBar activeTabIndex={activeTabIndex} items={TAB_ITEMS} onSelect={handleTabItemClick} />
        </S.TabBarContainer>

        <S.TabBarContainer>
          <MentalStateSelector tab={MentalStateSelectorTab.Library} />
        </S.TabBarContainer>

        <Transition
          in={currentPane === LibraryPanelTab.Favorites}
          mountOnEnter
          nodeRef={favoritesRef}
          timeout={0}
          unmountOnExit
        >
          {animationState => (
            <S.FadeInWrapper ref={favoritesRef} animationState={animationState}>
              <Favorites onTrackClickMore={handleTrackClickMore} />
            </S.FadeInWrapper>
          )}
        </Transition>

        <Transition
          in={currentPane === LibraryPanelTab.Recent}
          mountOnEnter
          nodeRef={recentRef}
          timeout={0}
          unmountOnExit
        >
          {animationState => (
            <S.FadeInWrapper ref={recentRef} animationState={animationState}>
              <Recent onTrackClickMore={handleTrackClickMore} />
            </S.FadeInWrapper>
          )}
        </Transition>
      </S.Container>
      <TrackInfoModal
        isOpen={isMoreInfoOpened}
        track={previewTrack}
        onClose={() => setIsMoreInfoOpened(false)}
      />
    </>
  );
}
