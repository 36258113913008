import React, { useState } from 'react';
import * as S from './styles';
import { NOOP } from '@Globals';
import EyeIcon from '../../../../assets/images/eye.svg';
import EyeSlashIcon from '../../../../assets/images/eye_slash.svg';
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'first' | 'last' | 'middle';
}

export const Input = ({
  placeholder,
  variant = 'middle',
  onFocus = NOOP,
  onBlur = NOOP,
  type,
  ...rest
}: Props) => {
  const [isFocused, setFocused] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <S.Container isFocused={isFocused === placeholder} variant={variant}>
      <S.Label isFocused={isFocused === placeholder}>{placeholder}</S.Label>

      <S.Input
        isFocused={isFocused === placeholder}
        type={type === 'password' && showPassword ? 'text' : type}
        onBlur={e => {
          if (!e.target.value) {
            setFocused('');
          }
          onBlur(e);
        }}
        onFocus={e => {
          setFocused(placeholder || '');
          onFocus(e);
        }}
        {...rest}
      />

      {type === 'password' ? (
        <S.PasswordInputEyeIcon
          src={showPassword ? EyeSlashIcon : EyeIcon}
          onClick={handleShowPassword}
        />
      ) : null}
    </S.Container>
  );
};
