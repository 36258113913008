import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootReducerType } from '../../../../reducers';
import { getTrackDynamicMentalStateId } from '../../../Music/lenses/getTrackDynamicMentalStateId';
import { FavoriteModalDisplay } from './FavoriteModal.display';
import { Logger } from '../../../../utils/logger';
import { uiSliceActions } from '../../../../reducers/uiReducer';
import { UNIVERSAL_LINK_ACTIONS } from '../../../../types/universalLinks';
import { getTrackId } from '@Music';

export function FavoriteModal() {
  const dispatch = useDispatch();
  const currentTrack = useSelector((state: RootReducerType) => state.music.currentTrack);
  const showModal = useSelector((state: RootReducerType) => state.ui.shouldShowFavoriteModal);

  const [isCopied, setIsCopied] = useState(false);

  const onClose = useCallback(() => {
    dispatch(uiSliceActions.setShouldShowFavoriteModal(false));
  }, [dispatch]);

  const handleShare = useCallback(async () => {
    try {
      if (window.navigator?.clipboard) {
        if (!currentTrack) return;
        const id = getTrackId(currentTrack);
        const mentalStateId = getTrackDynamicMentalStateId(currentTrack);
        const link = `${window.location.origin}/intentions/share?intention_action=${UNIVERSAL_LINK_ACTIONS.PlayTrack}&intention_value=${id}&mental_state=${mentalStateId}`;
        await window.navigator.clipboard.writeText(
          `Check out my new favorite track on ${link} and see what the right music can do for you!`,
        );
        setIsCopied(true);
      } else {
        Logger.error(new Error('Clipboard API not supported'));
      }
    } catch (error) {
      Logger.error(new Error('Failed to copy share text to clipboard'), { reason: error });
    } finally {
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
  }, [currentTrack, setIsCopied]);

  return (
    <FavoriteModalDisplay
      isCopied={isCopied}
      isOpen={showModal}
      title="You have a new favorite."
      onClose={onClose}
      onShare={handleShare}
    />
  );
}
