import { DynamicActivity } from '@Model';
import * as S from './MoreTrackInfoDetails.styles';
import { ActivityCard } from '../../../../../../ActivityCard/ActivityCard';

export interface Props {
  mentalState: string;
  activity?: string;
  complexity: string;
  brightness?: string;
  NEL: string;
  mood: string;
  instrumentation: string;
  onActivityClick: (activityId: string) => void;
}

export const MoreTrackInfoDetails = (props: Props) => {
  return (
    <S.Container>
      <S.TrackInfoBodySection>
        <S.Row>
          <S.Column>
            <S.SectionTitle>Mental State</S.SectionTitle>
            <S.SectionDescription>{props.mentalState}</S.SectionDescription>
          </S.Column>
          {props.activity && (
            <S.Column>
              <S.SectionTitle>Activity</S.SectionTitle>
              <S.SectionDescription>{props.activity}</S.SectionDescription>
            </S.Column>
          )}
        </S.Row>

        <S.Row>
          <S.Column>
            <S.SectionTitle>Moods</S.SectionTitle>
            <S.SectionDescription>{props.mood}</S.SectionDescription>
          </S.Column>
        </S.Row>

        <S.Row>
          <S.Column>
            <S.SectionTitle>Instrumentation</S.SectionTitle>
            <S.SectionDescription>{props.instrumentation}</S.SectionDescription>
          </S.Column>
        </S.Row>

        <S.Row>
          <S.Column>
            <S.FrequencyTitleSection>
              <S.SectionTitle>Complexity</S.SectionTitle>
              <S.FrequencyLevelDescription>{props.complexity}</S.FrequencyLevelDescription>
            </S.FrequencyTitleSection>
            <S.FrequencyLevelContainer>
              <S.FrequencyLevelBar level={props.complexity} />
            </S.FrequencyLevelContainer>
          </S.Column>
        </S.Row>

        {props.brightness ? (
          <S.Row>
            <S.Column>
              <S.FrequencyTitleSection>
                <S.SectionTitle>Brightness</S.SectionTitle>
                <S.FrequencyLevelDescription>{props.brightness}</S.FrequencyLevelDescription>
              </S.FrequencyTitleSection>
              <S.FrequencyLevelContainer>
                <S.FrequencyLevelBar level={props.brightness} />
              </S.FrequencyLevelContainer>
            </S.Column>
          </S.Row>
        ) : null}
      </S.TrackInfoBodySection>
    </S.Container>
  );
};
