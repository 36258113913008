import { useQuery } from '@tanstack/react-query';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { TeamDataResponseType } from './types';
import { useRequestHandler } from './useRequestHandler';

export const useTeamDataById = (teamId: string) => {
  const getTeamDataById = useRequestHandler();

  return useQuery<TeamDataResponseType['result'], Error>(
    Queries.getTeamDataById(teamId!),
    () => getTeamDataById(teamId!),
    {
      onError: () => {
        Logger.error(new Error('Error getting Team Data'));
      },
      enabled: Boolean(teamId),
    },
  );
};
