import React from 'react';
import * as S from './FirstSessionModal.styles';
import { Button, ButtonVariants, FontColors, Text, TextBold, TextSemiBold } from '@Cortex';
import BrandLogo from '../../../assets/images/brand_logo.svg';
import { Assets } from '../../../utils/assets';
import ReactModal from 'react-modal';

export type Props = {
  isOpen: boolean;
  isPaymentSaved: boolean;
  onClose: () => void;
};

export const FirstSessionModalDisplay = ({ isOpen, isPaymentSaved, onClose }: Props) => {
  return (
    <ReactModal isOpen={isOpen}>
      <S.Container>
        <S.Logo src={BrandLogo} />
        <S.HeaderText>Welcome to your first session</S.HeaderText>

        <S.TipsContainer>
          <S.TipContainer>
            <S.StepImage
              alt={Assets.images.headphoneEarbud.alt}
              src={Assets.images.headphoneEarbud.url}
            />
            <S.Description>
              <strong>Wear headphones</strong> or earbuds
            </S.Description>
          </S.TipContainer>

          <S.TipContainer>
            <S.StepImage alt={Assets.images.threeClocks.alt} src={Assets.images.threeClocks.url} />
            <S.Description>
              Listen for at least <strong>60 minutes</strong>
            </S.Description>
          </S.TipContainer>

          <S.TipContainer>
            <S.StepImage
              alt={Assets.images.favoriteDislike.alt}
              src={Assets.images.favoriteDislike.url}
            />
            <S.Description>
              <strong>Favorite or Dislike tracks to</strong> help us learn
            </S.Description>
          </S.TipContainer>
        </S.TipsContainer>

        <S.ButtonContainer>
          <Button isFullWidth keepTextCase variant={ButtonVariants.Secondary} onClick={onClose}>
            I’m ready, let’s do this
          </Button>
        </S.ButtonContainer>
        {isPaymentSaved ? (
          <S.PaymentContainer>
            <S.CheckIcon
              alt={Assets.icons.greenCircleCheck.alt}
              src={Assets.icons.greenCircleCheck.url}
            />
            <Text size="0.875rem">Your payment information was saved.</Text>
          </S.PaymentContainer>
        ) : null}
      </S.Container>
    </ReactModal>
  );
};
