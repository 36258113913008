import { HelmetProps } from 'react-helmet';
import { createGlobalStyle, css } from 'styled-components';

import { Assets } from '../utils/assets';

const styles = css`
  html {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    font-size: clamp(12px, 8vw - 2rem, 16px);
    font-family: Poppins, TTNormsPro-Regular, sans-serif;
    background-color: #0d0c11;
    background-image: url(${Assets.images.blurBg.url});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;

    // older versions of android browsers dont support clamp yet
    @supports not (font-size: clamp(12px, 8vw - 2rem, 16px)) {
      font-size: 12px;
    }

    @media (min-width: ${({ theme }) => theme.breakPoints.sm}) {
      @supports not (font-size: clamp(12px, 8vw - 2rem, 16px)) {
        font-size: 16px;
      }
    }
  }

  body {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  #root {
    height: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
  }

  img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .Toastify__toast {
    min-height: 48px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.5);
  }
  .Toastify__toast-container {
    width: 280px;
  }

  .custom-toast {
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    border-radius: 2rem;
    border: rgba(255, 255, 255, 0.75);
    padding: 0.5rem 1rem;
    color: #ffffff;
    font-family: TTNormsPro-Regular;

    font-size: 14px;
    background: linear-gradient(
      90deg,
      rgba(206, 70, 143, 0.1) 0%,
      rgba(44, 106, 162, 0.1) 35%,
      rgba(117, 17, 125, 0.1) 100%
    );
  }

  .ReactModal__Overlay {
    opacity: 0;
    transform: translateY(30px);
    transition:
      transform 500ms ease-in-out,
      opacity 500ms ease-in-out;
    z-index: 10;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateY(0px);
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateY(-30px);
  }

  @keyframes modalEnter {
    from {
      // transform: translate(-50%, 0%);
      opacity: 0;
    }
    to {
      // transform: translate(-50%, -50%);
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeInAndUp {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @keyframes rotateFull {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @-webkit-keyframes autofill {
    to {
      color: white;
      background: transparent;
    }
  }
`;

const GlobalStyles = createGlobalStyle`
  ${styles}
`;

export const GlobalFontStyles = `
  @font-face {
    font-family: TTNormsPro-Regular;
    src: url('/fonts/TTNormsPro-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: TTNormsPro-Medium;
    src: url('/fonts/TTNormsPro-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: TTNormsPro-Bold;
    src: url('/fonts/TTNormsPro-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }
`;

export const HelmetFontPreload: HelmetProps['link'] = [
  {
    rel: 'preload',
    href: './fonts/TTNormsPro-Regular.ttf',
    as: 'font',
    type: 'font/ttf',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'preload',
    href: './fonts/TTNormsPro-Medium.ttf',
    as: 'font',
    type: 'font/ttf',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'preload',
    href: './fonts/TTNormsPro-Bold.ttf',
    as: 'font',
    type: 'font/ttf',
    crossOrigin: 'anonymous',
  },
];

export default GlobalStyles;
