import styled from 'styled-components';

export const GreenPulse = styled.div`
  position: absolute;
  top: -2.75rem;
  left: -3.5rem;
  width: 8rem;
  height: auto;
  aspect-ratio: 1/1;
`;
