import { useDispatch } from 'react-redux';

import { timerActions } from './reducer';

export function usePauseTimer(params?: { skipTimeUpdate: boolean }) {
  const dispatch = useDispatch();
  return function pauseTimer() {
    dispatch(timerActions.pause(params));
  };
}
