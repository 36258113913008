import styled from 'styled-components';
import { FontColors } from '../Text';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 2.5rem 2.5rem 2.5rem;
  width: 30.625rem;
  margin: auto;
  background: linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 0.75rem;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
`;

export const ImageContainer = styled.div<{ type?: 'success' | 'info' }>`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
  border-radius: 100%;
  ${props =>
    props.type === 'success'
      ? `
      background: #6efe96;
      box-shadow:
        0px 0px 8px 0px rgba(110, 254, 150, 0.5),
        0px 0px 32px 0px rgba(110, 254, 150, 0.5);
    `
      : ''};

  ${props =>
    props.type === 'info'
      ? `
      background: #6e87fe;
      box-shadow:
        0px 0px 8px 0px rgb(152 110 254 / 50%),
        0px 0px 32px 0px rgb(174 110 254 / 50%);
    `
      : ''};
`;

export const Image = styled.img`
  width: 2rem;
  height: 2rem;
  object-fit: contain;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1.25rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0.5rem;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
  color: ${FontColors.WhiteTransparent};
  text-align: center;
  width: 90%;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
