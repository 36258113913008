import { TrackActionOrigins } from '@Analytics';
import { Panel } from '@Cortex';
import { NOOP } from '@Globals';
import { Serving, Track } from '@Model';
import {
  addToDislikes,
  addToFavorites,
  getTrackComplexity,
  getTrackGenreName,
  getTrackId,
  getTrackImageUrl,
  getTrackInstrumentations,
  getTrackIsNewlyCreated,
  getTrackMoods,
  getTrackName,
  getTrackNeuralEffectLevel,
  getTrackVariationId,
  NeuralEffectLevelIcon,
  removeFromDislikes,
  removeFromFavorites,
} from '@Music';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useShareTrack } from '../../../../../../../../hooks/useShareTrack';
import { RootReducerType } from '../../../../../../../../reducers';
import { getTrackDynamicMentalStateDisplayValue } from '../../../../../../lenses/getTrackDynamicMentalStateDisplayValue';
import { getTrackBeatsPerMinute } from '../../../../../../lenses/getTrackBeatsPerMinute';
import { isTrackVariationInFavorites } from '../../../../../../lenses/isTrackVariationInFavorites';
import { getTrackBrightness } from '../../../../../../lenses/getTrackBrightness';
import { getTrackRelatedActivities } from '../../../../../../lenses/getTrackRelatedActivities';
import { SimilarTracks } from '../SimilarTracks';
import * as S from './MoreTrackInfo.styles';
import CloseIcon from './assets/close.svg';
import { HeartIcon } from '../../../../../../../../components/icons/HeartIcon';
import { ShareIcon } from '../../../../../../../../components/icons/ShareIcon';
import { ThumbsDownIcon } from '../../../../../../../../components/icons/ThumbsDownIcon';
import { MoreTrackInfoDetails } from './component/MoreTrackInfoDetails';
import { OutsideClickDetector } from '@Utils';
import { useNavigate } from 'react-router-dom';
import {
  TrackInfoModal,
  TrackInfoModalVariant,
} from '../../../../../../components/TrackInfoModal/TrackInfoModal';
import { ActivityCard } from '../../../../../ActivityCard/ActivityCard';

enum TabItems {
  SimilarTracks = 'Similar Tracks',
  Activities = 'Activities',
}

const TAB_ITEMS = Object.values(TabItems);

interface Props {
  track: Track | Serving;
  fade?: boolean;
  onClose?: () => void;
}

export const MoreTrackInfo = ({ track, onClose = NOOP }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionDynamicActivity = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity,
  );
  const { handleGetShareLink } = useShareTrack();
  const [selectedTab, setSelectedTab] = useState(TabItems.SimilarTracks);
  const dislikedTrackIds = useSelector((state: RootReducerType) => state.music.dislikedTrackIds);
  const favorites = useSelector((state: RootReducerType) => state.music.favorites);
  const relatedActivities = getTrackRelatedActivities(track);
  const [isMoreInfoOpened, setIsMoreInfoOpened] = useState(false);
  const [previewTrack, setPreviewTrack] = useState<Track | Serving | null>(null);
  const similarTrackRef = useRef<HTMLDivElement | null>(null);
  const activitiesRef = useRef<HTMLDivElement>(null);

  // sets up a dictionary for efficient lookup of favorite track
  const favoritesDictionary = favorites.reduce(
    (acc, track) => {
      acc[getTrackId(track)] = track;
      return acc;
    },
    {} as { [key: string]: Track | Serving },
  );

  const handleDislikeClick = () => {
    if (dislikedTrackIds.includes(getTrackId(track))) {
      dispatch(removeFromDislikes({ trackId: getTrackId(track) }));
    } else {
      dispatch(addToDislikes({ track }));
      window.setTimeout(() => window.dispatchEvent(new Event('skip-keep-play-state')), 1000);
    }
  };

  const isCurrentTrackVariationInFavorites = useSelector(
    isTrackVariationInFavorites({
      trackVariationId: getTrackVariationId(track),
    }),
  );

  const handleFavoriteClick = () => {
    if (isCurrentTrackVariationInFavorites) {
      dispatch(removeFromFavorites({ track }));
    } else {
      dispatch(addToFavorites({ origin: TrackActionOrigins.TrackPlayer, track }));
    }
  };

  const handleShareTrack = () => {
    handleGetShareLink(track);
  };

  const handleActivityClickPlay = (activityId: string) => {
    navigate(`/player/${activityId}`);
  };

  const handleClickMore = (track: Track | Serving) => {
    setIsMoreInfoOpened(true);
    setPreviewTrack(track);
  };

  const mentalStateDisplayValue = getTrackDynamicMentalStateDisplayValue(track);

  const activity = sessionDynamicActivity?.displayValue;

  return (
    <S.Container data-testid="moreTrackInfo">
      <OutsideClickDetector onOutsideClick={onClose}>
        <Panel isBlurred isScrollable style={S.GradientContainerStyleTransparent}>
          <S.CloseButton data-testid="trackInfoCloseButton" onClick={onClose}>
            <S.Image src={CloseIcon} />
          </S.CloseButton>

          <S.ContentContainer>
            <S.TrackInfoContainer>
              <S.TrackInfo>
                <S.TrackImageContainer>
                  <S.TrackImage src={getTrackImageUrl(track)} />
                  {getTrackIsNewlyCreated(track) && <S.NewTag>NEW</S.NewTag>}
                </S.TrackImageContainer>
                <S.TrackDetails>
                  <S.TrackDetailsTitle>{getTrackName(track)}</S.TrackDetailsTitle>
                  <S.TrackDetailsNeuralEffect>
                    <NeuralEffectLevelIcon effectLevel={getTrackNeuralEffectLevel(track)} />
                    {getTrackNeuralEffectLevel(track)} Neural Effect
                  </S.TrackDetailsNeuralEffect>
                  <S.TrackDetailsGenreAndBPM>
                    {getTrackGenreName(track)}
                    {` • `}
                    {getTrackBeatsPerMinute(track)} BPM
                  </S.TrackDetailsGenreAndBPM>
                </S.TrackDetails>
              </S.TrackInfo>
              <S.TrackActions>
                <S.TrackAction>
                  <S.TrackActionButton onClick={handleDislikeClick}>
                    <ThumbsDownIcon
                      fillOpacity={dislikedTrackIds.includes(getTrackId(track)) ? 1 : 0}
                    />
                  </S.TrackActionButton>
                  Dislike
                </S.TrackAction>
                <S.TrackAction>
                  <S.TrackActionButton
                    data-testid={'trackCardAddToFavoritesButton'}
                    onClick={handleFavoriteClick}
                  >
                    <HeartIcon fillOpacity={isCurrentTrackVariationInFavorites ? 1 : 0} />
                  </S.TrackActionButton>
                  Favorite
                </S.TrackAction>
                <S.TrackAction>
                  <S.TrackActionButton onClick={handleShareTrack}>
                    <ShareIcon />
                  </S.TrackActionButton>
                  Share
                </S.TrackAction>
              </S.TrackActions>
              <S.Divider />
              <MoreTrackInfoDetails
                NEL={getTrackNeuralEffectLevel(track)}
                activity={activity}
                brightness={getTrackBrightness(track)}
                complexity={getTrackComplexity(track)}
                instrumentation={getTrackInstrumentations(track).join(', ')}
                mentalState={mentalStateDisplayValue || ''}
                mood={getTrackMoods(track).join(', ')}
                onActivityClick={handleActivityClickPlay}
              />
            </S.TrackInfoContainer>
            <S.TabContentContainer>
              <S.TabBarContainer>
                <S.TabItem
                  isActive={selectedTab === TabItems.SimilarTracks}
                  onClick={() => setSelectedTab(TabItems.SimilarTracks)}
                >
                  Similar tracks
                </S.TabItem>
                <S.TabItem
                  isActive={selectedTab === TabItems.Activities}
                  onClick={() => setSelectedTab(TabItems.Activities)}
                >
                  Activities
                </S.TabItem>
              </S.TabBarContainer>

              <S.FadeInWrapper
                ref={similarTrackRef}
                isActive={selectedTab === TabItems.SimilarTracks}
              >
                <S.SimilarTracks>
                  <SimilarTracks
                    favoritesDictionary={favoritesDictionary}
                    onClickMore={handleClickMore}
                  />
                </S.SimilarTracks>
              </S.FadeInWrapper>

              <S.FadeInWrapper ref={activitiesRef} isActive={selectedTab === TabItems.Activities}>
                <S.ActivitiesSection>
                  {relatedActivities &&
                    relatedActivities.map(activity => (
                      <ActivityCard
                        key={activity.id}
                        dynamicActivity={activity}
                        onClick={() => handleActivityClickPlay(activity.id)}
                      />
                    ))}
                </S.ActivitiesSection>
              </S.FadeInWrapper>
            </S.TabContentContainer>
          </S.ContentContainer>
          <TrackInfoModal
            isOpen={isMoreInfoOpened}
            track={previewTrack}
            variant={TrackInfoModalVariant.MoreInfo}
            width={similarTrackRef.current?.offsetWidth}
            onClose={() => setIsMoreInfoOpened(false)}
          />
        </Panel>
      </OutsideClickDetector>
    </S.Container>
  );
};
