import { Icon } from '@Cortex';

import Logo from '../../../assets/images/brand_logo.svg';
import * as S from './HeaderWithLogoAndClose.styles';
import CloseIcon from '../../../assets/images/close_icon.svg';

interface Props {
  onPaymentComplete?: () => void;
  onClose?: () => void;
}

export const HeaderWithLogoAndClose = ({ onClose }: Props) => {
  return (
    <S.Container>
      <S.LogoContainer>
        <Icon size={54} src={Logo} />
      </S.LogoContainer>
      {onClose && (
        <S.CloseIconContainer onClick={onClose}>
          <S.CloseIcon src={CloseIcon} />
        </S.CloseIconContainer>
      )}
    </S.Container>
  );
};
