import { membershipActions } from '@Memberships';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import * as analyticsActions from '../../../actions/analytics';

import * as uiActions from '../../../actions/ui';
import * as userActions from '../../../actions/user';
import { SubscriptionEvents } from '../../../utils/analytics/events';
import { getUiErrorMessage } from '../../../utils/getUiErrorMessage';
import { isOfflineError } from '../../../utils/isOfflineError';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { TEnableSubscriptionAutoRenewResponse } from './types';
import { useRequestHandler } from './useRequestHandler';

export const useEnableSubscriptionAutoRenew = () => {
  const enableSubscriptionAutoRenew = useRequestHandler();
  const dispatch = useDispatch();

  return useMutation<TEnableSubscriptionAutoRenewResponse, Error>(
    Queries.postEnableSubscriptionAutoRenew,
    () => enableSubscriptionAutoRenew(),
    {
      onMutate: () => {
        dispatch(
          analyticsActions.subscriptionLogEvent(SubscriptionEvents.core_renewal_enable_attempt),
        );
      },
      onError: e => {
        dispatch(
          analyticsActions.subscriptionLogEvent(SubscriptionEvents.core_renewal_enable_error),
        );
        if (isOfflineError(e)) return;
        Logger.error(new Error(getUiErrorMessage(e, 'Error enabling subscription auto renew')));
      },
      onSuccess: data => {
        if (data.status !== 200) return;
        dispatch(membershipActions.fetchMembership());
        dispatch(userActions.getInfo());
        dispatch(
          uiActions.successModalOpen({
            title: 'Success!',
            description: 'Your subscription has been reactivated.',
            actions: [{ type: 'primary', text: 'CLOSE', action: uiActions.successModalClose() }],
          }),
        );
        dispatch(
          analyticsActions.subscriptionLogEvent(SubscriptionEvents.core_renewal_enable_success),
        );
      },
      cacheTime: 0,
    },
  );
};
