import { Button, ButtonVariants, Icon } from '@Cortex';
import { NOOP } from '@Globals';
import React from 'react';
import CalendarIcon from '../../../../../../assets/images/calendar_icon.svg';

import * as S from './SubscriptionInformation.styles';

export type Props = {
  callToActionText?: string | null;
  currentPeriodEndDate: string | null;
  expirationDate?: Date | null;
  isCancelled?: boolean;
  isEligibleForRenewal?: boolean;
  onClickCallToAction?: () => void;
  renewalPrice: string | null;
  subscriptionSource: string;
};

export function SubscriptionInformationPresentation({
  callToActionText = null,
  currentPeriodEndDate,
  expirationDate,
  isCancelled = false,
  onClickCallToAction = NOOP,
  isEligibleForRenewal = false,
  renewalPrice,
  subscriptionSource,
}: Props) {
  const isGiftSubscription = subscriptionSource === 'GiftUp';

  if (currentPeriodEndDate && isCancelled && isEligibleForRenewal) {
    return (
      <>
        <S.PaymentInfo>
          <Icon size={30} src={CalendarIcon} />
          <S.PaymentText>
            Your membership renewal is off.
            <S.PaymentInfoBold> You will lose access on {currentPeriodEndDate}</S.PaymentInfoBold>.
          </S.PaymentText>
        </S.PaymentInfo>
        {callToActionText ? (
          <S.CtaWrapper>
            <Button
              data-testid="subscriptionButton"
              keepTextCase={true}
              style={{ fontSize: '0.75rem' }}
              variant={ButtonVariants.Secondary}
              onClick={onClickCallToAction}
            >
              {callToActionText}
            </Button>
          </S.CtaWrapper>
        ) : null}
      </>
    );
  }

  if (currentPeriodEndDate && isCancelled) {
    return (
      <>
        <S.PaymentInfo>
          <Icon size={30} src={CalendarIcon} />
          <S.PaymentText>
            Your membership has been cancelled. You can continue to use brain.fm until{' '}
            <S.PaymentInfoBold>{currentPeriodEndDate}</S.PaymentInfoBold>.
          </S.PaymentText>
        </S.PaymentInfo>
        {callToActionText ? (
          <S.CtaWrapper>
            <Button
              data-testid="subscriptionButton"
              keepTextCase={true}
              style={{ fontSize: '0.75rem' }}
              variant={ButtonVariants.Secondary}
              onClick={onClickCallToAction}
            >
              {callToActionText}
            </Button>
          </S.CtaWrapper>
        ) : null}
      </>
    );
  }

  if (isGiftSubscription && expirationDate) {
    return (
      <S.PaymentInfo>
        <Icon size={30} src={CalendarIcon} />
        <S.PaymentText>
          Your gift subscription is valid until
          <S.PaymentInfoBold>
            {` ${expirationDate.toLocaleString('en-us', {
              month: 'long',
            })} ${expirationDate.getDate()}, ${expirationDate.getFullYear()}`}
          </S.PaymentInfoBold>
          .
        </S.PaymentText>
      </S.PaymentInfo>
    );
  }

  if (renewalPrice && currentPeriodEndDate) {
    return (
      <>
        <S.PaymentInfo>
          <Icon size={30} src={CalendarIcon} />
          <S.PaymentText>
            Your next payment is <S.PaymentInfoBold>{renewalPrice}</S.PaymentInfoBold> which will be
            charged automatically on <S.PaymentInfoBold>{currentPeriodEndDate}</S.PaymentInfoBold>.
          </S.PaymentText>
        </S.PaymentInfo>
        {callToActionText ? (
          <S.CtaWrapper>
            <Button
              data-testid="subscriptionButton"
              keepTextCase={true}
              style={{ fontSize: '0.75rem' }}
              variant={ButtonVariants.Secondary}
              onClick={onClickCallToAction}
            >
              {callToActionText}
            </Button>
          </S.CtaWrapper>
        ) : null}
      </>
    );
  }

  return (
    <>
      <S.PaymentInfo>
        <S.PaymentText>
          Your subscription is managed by{' '}
          <S.PaymentInfoBold>{subscriptionSource}</S.PaymentInfoBold>.
        </S.PaymentText>
      </S.PaymentInfo>
      {callToActionText ? (
        <S.CtaWrapper>
          <Button
            data-testid="subscriptionButton"
            keepTextCase={true}
            style={{ fontSize: '0.75rem' }}
            variant={ButtonVariants.Secondary}
            onClick={onClickCallToAction}
          >
            {callToActionText}
          </Button>
        </S.CtaWrapper>
      ) : null}
    </>
  );
}
