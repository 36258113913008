import theme from '../../../../../styles/theme';

export const getDefaultCardElementStyles = (fontSize: string | number) => ({
  style: {
    base: {
      color: '#fff',
      fontSize: `${fontSize}px`,
      fontFamily: theme.font.family.poppins.regular,
      fontWeight: theme.font.weight.regular,
      ':-webkit-autofill': { color: theme.colors.brandPrimary },
      '::placeholder': {
        color: '#fff',
      },
    },

    invalid: { color: 'red' },
  },
});
