export type MentalState = {
  id: MentalStateIds;
  type: MentalStateTypes;
  displayValue: MentalStateDisplayValues;
};

type MentalStateIds = 1 | 2 | 3 | 4;

export enum MentalStateTypes {
  Focus = 'focus',
  Meditate = 'meditate',
  Relax = 'relax',
  Sleep = 'sleep',
}

export enum MentalStateDisplayValues {
  Focus = 'Focus',
  Meditate = 'Meditate',
  Relax = 'Relax',
  Sleep = 'Sleep',
}
