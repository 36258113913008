import { Navigate } from 'react-router-dom';
import { ProfileSection, SideDeckTab } from '../../types';

type Props = {
  section: ProfileSection;
};

export const ProfileRedirect = ({ section }: Props) => {
  return (
    <Navigate
      replace={true}
      state={{ sideDeckTab: SideDeckTab.Profile, profileSection: section }}
      to={'/'}
    />
  );
};
