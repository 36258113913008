import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { TextSemiBold } from '@Cortex';
import { Serving, Track } from '@Model';
import {
  addToFavorites,
  removeFromFavorites,
  getTrackId,
  getTrackName,
  getTrackGenreName,
  getTrackNeuralEffectLevel,
  getTrackIsNewlyCreated,
  getTrackImageUrl,
  getTrackHasMultipleNELs,
  TrackInformationCard,
} from '@Music';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSimilarTracks } from '../../../../../../../../api/modules/SimilarTracks';
import { getDynamicSimilarActivityPlayerPath } from '../../../../../../../../utils/getDynamicSimilarActivityPlayerPath';
import * as S from './SimilarTracks.styles';
import { getTrackRelatedActivities } from '../../../../../../lenses/getTrackRelatedActivities';

interface Props {
  favoritesDictionary: { [key: string]: Track | Serving };
  onClickMore: (track: Track | Serving) => void;
}

export const SimilarTracks = ({ favoritesDictionary, onClickMore }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, data, error } = useSimilarTracks();

  if (isLoading) {
    return (
      <S.Container>
        <S.Loading />
      </S.Container>
    );
  }

  if (error) {
    return (
      <S.Container>
        <TextSemiBold>No Similar tracks were found.</TextSemiBold>
      </S.Container>
    );
  }

  const handleFavoriteClick = (track: Track | Serving) => {
    if (favoritesDictionary[getTrackId(track)]) {
      dispatch(removeFromFavorites({ track }));
    } else {
      dispatch(addToFavorites({ origin: TrackActionOrigins.SimilarTracks, track }));
    }
  };

  const handleTrackCardClick = (serving: Track | Serving) => () => {
    navigate(getDynamicSimilarActivityPlayerPath(getTrackId(serving)));
  };

  return (
    <S.Container>
      {data?.map(serving => (
        <S.TrackWrapper key={getTrackId(serving)}>
          <TrackInformationCard
            hasMultipleNELs={getTrackHasMultipleNELs(serving)}
            imageUrl={getTrackImageUrl(serving)}
            isFavorited={!!favoritesDictionary[getTrackId(serving)]}
            isNewlyCreated={getTrackIsNewlyCreated(serving)}
            moreIconTestId={`trackCardMoreInfo__${getTrackId(serving)}`}
            neuralEffectLevel={getTrackNeuralEffectLevel(serving)}
            relatedActivities={getTrackRelatedActivities(serving)}
            subtitle={getTrackGenreName(serving)}
            title={getTrackName(serving)}
            onAddToFavorites={() => handleFavoriteClick(serving)}
            onClick={handleTrackCardClick(serving)}
            onClickMore={() => onClickMore(serving)}
            onRemoveFromFavorites={() => handleFavoriteClick(serving)}
          />
        </S.TrackWrapper>
      ))}
    </S.Container>
  );
};
