import { getErrorName } from '../../../utils/getErrorName';
import { Logger } from '../../../utils/logger';

type Props = {
  error: unknown;
  message: string;
  level?: 'info' | 'error';
  extra?: any;
};

export const logAudioPlayerError = ({ error, message, level = 'error', extra }: Props) => {
  const data = extra || { reason: error };
  const msg = `AudioPlayer [${getErrorName(error)}]: ${message}`;

  if (level === 'info') {
    Logger.info(msg, data);
    return;
  }

  Logger.error(new Error(msg), data);
};
