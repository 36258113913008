import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem 3rem 3rem 3rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: fit-content;
    align-self: center;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 2.5rem;
`;

export const Logo = styled.img`
  height: 3.5rem;
  width: 3.5rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 4rem;
  justify-content: center;
  max-width: 50rem;
`;

export const Title = styled.div`
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 130%;
  text-align: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    font-size: 32px;
    line-height: 120%;
    margin-bottom: 2rem;
  }
`;

export const FunBarsWrapper = styled.div`
  display: flex;
  width: 50rem;
  flex-direction: column;
  padding: 0 4rem;
  margin-bottom: 3rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 100%;
    max-width: 35rem;
    padding: 0;
  }
`;

export const FunBarWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  justify-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

export const FunBarHeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.5rem;
`;

export const FunBarLeftWrapper = styled.div`
  display: flex;
  margin-right: 1.5rem;
  align-items: center;
`;

export const FunBarRightWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const CompletionIcon = styled.img<{ isCompleted: boolean }>`
  animation: ${({ isCompleted }) => (isCompleted ? fadeIn : undefined)} 3s ease;
  height: 3rem;
  width: 3rem;
`;

export const FunBarName = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
`;

export const FunBarProgressWrapper = styled.div`
  position: relative;
  height: 0.75rem;
  width: 100%;
`;

export const FunBarProgressBase = styled.div`
  background-color: #1d192f;
  border-radius: 0.75rem;
  height: 0.75rem;
  position: absolute;
  width: 100%;
`;

export const FunBarProgress = styled.div<{ progress: number }>`
  background: white;
  background-size: 100% 1rem;
  border-radius: 0.75rem;
  height: 0.75rem;
  position: absolute;
  transition: width 0.3s ease-in-out;
  width: ${props => props.progress}%;
`;
