import { Text, TextBold } from '@Cortex';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const HeaderText = styled(TextBold)`
  font-size: 3rem;
  margin-bottom: 3rem;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex: 1;
  max-width: 55%;
  padding: 2rem;
  min-width: 15.5rem;
  z-index: 2;
`;
export const Highlighted = styled.span`
  color: white;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
`;

export const Right = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  max-width: 70%;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;
export const ButtonWrapper = styled.div`
  margin: 1rem 0 0;
`;

export const Padding = styled.div`
  height: 200px;
  pointer-events: none;
`;

export const TitleText = styled(Text)`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1.3rem;
  margin-bottom: 1rem;
`;

export const SubtitleText = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.75);
`;

export const ButtonImage = styled.img`
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
`;
