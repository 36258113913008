import { EffectLevels, Serving, Track } from '@Model';

import { getNelDisplayValue } from '../lib/getNeuralEffectLevelDisplayValue';
import { isServing } from './isServing';

export function getTrackComplexity(trackOrServing?: Track | Serving | null): EffectLevels {
  if (isServing(trackOrServing)) {
    return getNelDisplayValue(trackOrServing.track.complexityLevel || 0.5);
  }

  return trackOrServing?.complexityDisplayValue || EffectLevels.Medium;
}
