import styled, { css } from 'styled-components';

const SharedButtonStyles = styled.button<{ state?: 'error' | 'success' | 'loading' | 'idle' }>`
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 14px;
  outline: none;
  width: 100%;
  height: 48px;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ButtonWhite = styled(SharedButtonStyles)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 100px;
  box-shadow: 0 2.5px 11.5px rgba(147, 35, 102, 0.4);
  transition:
    background 0.2s ease-in-out,
    color 0.15s ease-in-out;
  width: 100%;
  font-size: 14px;
  letter-spacing: 1px;

  background: ${props => {
    switch (props.state) {
      case 'success':
        return '#4CD867';
      case 'error':
        return 'red';
      default:
        return '#FFFFFF';
    }
  }};

  color: ${props => {
    switch (props.state) {
      case 'error':
        return '#FFFFFF';
      case 'loading':
        return 'transparent';
      default:
        return '#191736';
    }
  }};

  ${({ state }) => {
    return state === 'loading'
      ? css`
          &:after {
            animation: rotation 0.8s linear infinite;
            border-radius: 100%;
            border-right: 3px solid transparent;
            border-top: 3px solid rgba(0, 0, 0, 0.8);
            content: '';
            height: 2rem;
            position: absolute;
            width: 2rem;

            @keyframes rotation {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          }
        `
      : null;
  }}
`;
