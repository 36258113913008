import React, { HTMLAttributes } from 'react';

import * as S from './JumpBackInButton.styles';
import { Icon, Text } from '@Cortex';
import ChevronIcon from '../assets/chevron_icon.svg';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  isOpen: boolean;
}

export const JumpBackInButton = ({ isOpen, ...props }: Props) => {
  return (
    <S.Container isOpen={isOpen} {...props}>
      <S.ButtonText>JUMP BACK IN</S.ButtonText>
      <S.IconContainer isOpen={isOpen}>
        <Icon isSquare size={16} src={ChevronIcon} />
      </S.IconContainer>
    </S.Container>
  );
};
