import * as S from './UserProperty.styles';

interface Props {
  label: string;
  value: string;
  valueTestId?: string;
  icon?: string;
  iconTitle?: string;
}

export const UserProperty = ({ label, value, valueTestId, icon, iconTitle }: Props) => {
  return (
    <S.Container>
      <S.Label>{label}</S.Label>
      <S.ValueWrapper>
        <S.Text data-testid={valueTestId}>{value}</S.Text>
        {icon && <S.Icon src={icon} title={iconTitle} />}
      </S.ValueWrapper>
    </S.Container>
  );
};
