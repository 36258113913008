import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  CurrentSessionDynamicType,
  CurrentSessionStateType,
  CurrentSessionType,
  PlayedActivity,
} from '../types';

const initialState: CurrentSessionStateType = {
  session: {
    activityId: '',
    mentalStateId: '',
    playDurationSeconds: 0,
    playedActivities: [],
    startedAt: null,
    startedFrom: '',
    type: '',
  },
};

const currentSessionSlice = createSlice({
  name: 'currentSession',
  initialState,
  reducers: {
    setSession(state, action: PayloadAction<CurrentSessionType>) {
      state.session = action.payload;
      return state;
    },

    updateSession(state, action: PayloadAction<Partial<CurrentSessionType>>) {
      state.session = { ...state.session, ...action.payload };
      return state;
    },

    updateDynamicSession(state, action: PayloadAction<Partial<CurrentSessionDynamicType>>) {
      state.session = { ...state.session, ...action.payload };
      return state;
    },

    addPlayedActivity(state, action: PayloadAction<PlayedActivity>) {
      state.session.playedActivities.push(action.payload);
      return state;
    },

    updateLastPlayedActivityDuration(state, action: PayloadAction<number>) {
      const lastElement = state.session.playedActivities.length - 1;
      if (lastElement >= 0) {
        state.session.playedActivities[lastElement].duration = action.payload;
      }
      return state;
    },

    clearSession(state) {
      state.session = initialState.session;
      return state;
    },

    clearState() {
      return initialState;
    },
  },
});

const persistConfig = {
  key: 'currentSession',
  storage,
};

export const currentSessionSliceActions = currentSessionSlice.actions;
export default persistReducer(persistConfig, currentSessionSlice.reducer);
