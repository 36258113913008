import { Text, TextBold } from '@Cortex';
import { useDynamicMentalStateMoods } from '../../../../../../api/modules/DynamicMentalStateMoods';
import { TrackTag } from '../TrackTag';
import * as S from './Moods.styles';

type Props = {
  onMoodSelect: (mood: string) => void;
};

export const Moods = ({ onMoodSelect }: Props) => {
  const { isLoading, error, data } = useDynamicMentalStateMoods();

  if (isLoading) {
    return <S.Loading />;
  }

  if (error) {
    return <Text>{error.message}</Text>;
  }

  return (
    <>
      <S.Title>
        <TextBold size="1.25rem">Moods</TextBold>
      </S.Title>

      <S.Container>
        {data.map((mood, i) => {
          return (
            <S.MoodWrapper key={`${mood}_${i}`}>
              <TrackTag icon="" name={mood} onClick={() => onMoodSelect(mood)} />
            </S.MoodWrapper>
          );
        })}
      </S.Container>
    </>
  );
};
