import { FormInput, Button, ButtonVariants, ButtonVariantSizes } from '@Cortex';
import React, { useState } from 'react';

import { GiftCardType } from '../../../../api/modules/GiftCardInfo/types';
import { VariantStates } from '../../../Cortex/components/Button/Button';
import * as S from './GiftCard.styles';

type Props = {
  isRedeeming: boolean;
  isLoadingCardData: boolean;
  cardErrorMessage?: string;
  redeemErrorMessage?: string;
  cardCode?: string;
  cardDetails: GiftCardType | null;
  onFetchCard: () => void;
  onChangeCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFetchCardOnEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onRedeemCard: () => void;
  layout: 'modern' | 'classic';
};

export const GiftCardPresentation = (props: Props) => {
  const {
    isLoadingCardData,
    isRedeeming,
    cardErrorMessage,
    redeemErrorMessage,
    cardCode,
    cardDetails,
    onChangeCode,
    onFetchCardOnEnter,
    onFetchCard,
    onRedeemCard,
    layout,
  } = props;
  const [isInputFocused, setInputFocused] = useState(false);

  const isLoading = isLoadingCardData || isRedeeming;

  if (layout === 'modern') {
    const inputFocused = isInputFocused || Boolean(cardCode && cardCode.length > 0);

    return (
      <S.ModernContainer isFocused={isInputFocused}>
        {redeemErrorMessage || cardErrorMessage ? (
          <S.ErrorText>{redeemErrorMessage || cardErrorMessage}</S.ErrorText>
        ) : null}
        <S.ModernForm>
          <S.ModernFormInput
            data-testid="giftCard"
            placeholder="Enter gift card number"
            value={cardCode}
            onBlur={() => setInputFocused(false)}
            onChange={onChangeCode}
            onFocus={() => setInputFocused(true)}
            onKeyDown={onFetchCardOnEnter}
          />

          <S.Button isFocused={inputFocused} type="button" onClick={onFetchCard}>
            Apply
          </S.Button>
        </S.ModernForm>
        {cardDetails && (
          <S.CardDetails>
            <S.CardTitle>
              {cardDetails.title} {!cardDetails.canBeRedeemed && ` (inactive)`}
            </S.CardTitle>
            {cardDetails.canBeRedeemed && (
              <Button
                keepTextCase={true}
                size={ButtonVariantSizes.Medium}
                style={{ fontSize: '0.875rem' }}
                variant={ButtonVariants.Secondary}
                variantState={isLoading ? VariantStates.Loading : VariantStates.Idle}
                onClick={onRedeemCard}
              >
                REDEEM
              </Button>
            )}
            {redeemErrorMessage ? <S.ErrorText>{redeemErrorMessage}</S.ErrorText> : null}
          </S.CardDetails>
        )}
      </S.ModernContainer>
    );
  }

  return (
    <S.Container>
      <S.Form>
        <FormInput
          data-testid="giftCard"
          errorMessage={cardErrorMessage}
          label="Have a gift card?"
          value={cardCode}
          onChange={onChangeCode}
          onKeyDown={onFetchCardOnEnter}
        />
        <S.ButtonWrapper>
          <Button
            variant={ButtonVariants.Outline}
            variantState={isLoading ? VariantStates.Loading : VariantStates.Idle}
            onClick={onFetchCard}
          >
            Apply
          </Button>
        </S.ButtonWrapper>
      </S.Form>
      {cardDetails && (
        <S.CardDetails>
          <S.CardTitle>
            {cardDetails.title} {!cardDetails.canBeRedeemed && ` (inactive)`}
          </S.CardTitle>
          {cardDetails.canBeRedeemed && (
            <Button
              variant={ButtonVariants.Secondary}
              variantState={isLoading ? VariantStates.Loading : VariantStates.Idle}
              onClick={onRedeemCard}
            >
              Redeem
            </Button>
          )}
          {redeemErrorMessage ? <S.ErrorText>{redeemErrorMessage}</S.ErrorText> : null}
        </S.CardDetails>
      )}
    </S.Container>
  );
};
