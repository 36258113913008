import { FontColors, Paper, Text } from '@Cortex';
import { OutsideClickDetector } from '@Utils';
import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';

import ChevronIcon from '../../../../assets/images/chevron_up_icon.svg';
import SortIcon from './assets/sort.svg';
import * as S from './SortSelect.styles';
import { SortSelectBy } from './SortSelect.types';
import { ListGroup } from '../ListGroup';

type Props = {
  sortByList: SortSelectBy[];
  isMenuExpanded: boolean;
  selectedSort: string;
  setMenuExpanded: (value: boolean) => void;
  onSortingSelect: (sortBy: string) => void;
};

export const SortSelectDisplay = (props: Props) => {
  const dropDownRef = useRef<HTMLDivElement>(null);

  function handleClick(e: React.SyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();
    props.setMenuExpanded(!props.isMenuExpanded);
  }

  return (
    <OutsideClickDetector onOutsideClick={() => props.setMenuExpanded(false)}>
      <S.Container>
        <S.Button hasBorder={false} onClick={handleClick}>
          <S.SortTextContainer>
            <S.SortIcon src={SortIcon} />
            <S.Header>
              <Text color={FontColors.WhiteTransparent}>{props.selectedSort}</Text>
            </S.Header>
          </S.SortTextContainer>
          <S.ImageIcon isUp={props.isMenuExpanded} src={ChevronIcon} />
        </S.Button>
        <Transition
          in={props.isMenuExpanded}
          mountOnEnter
          nodeRef={dropDownRef}
          timeout={0}
          unmountOnExit
        >
          {animationState => (
            <S.FilterContainer ref={dropDownRef} animationState={animationState}>
              <S.FilterContent>
                <ListGroup
                  checkedItems={[props.selectedSort]}
                  items={props.sortByList}
                  onCheck={props.onSortingSelect}
                />
              </S.FilterContent>
            </S.FilterContainer>
          )}
        </Transition>
      </S.Container>
    </OutsideClickDetector>
  );
};
