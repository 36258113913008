import React from 'react';
import { Assets } from '../../../../../../utils/assets';
import * as S from './LifetimePanel.styles';

export const LifetimeMembershipPanel = () => {
  return (
    <S.LifetimeContainer>
      <S.Header>
        <S.HeaderIcon>
          <S.Logo src={Assets.images.logoFull.url} />
        </S.HeaderIcon>
        <S.HeaderText>
          <S.HeaderBadge>PRO</S.HeaderBadge>
          <S.HeaderTextSubtitle>Lifetime Plan</S.HeaderTextSubtitle>
        </S.HeaderText>
      </S.Header>
      <S.Children>Thank you for being a lifetime member!</S.Children>
    </S.LifetimeContainer>
  );
};
