import React, { InputHTMLAttributes } from 'react';

import * as S from './TimerFormInput.styles';
import { Text } from '@Cortex';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMessage?: string;
  rightSideContent?: string | (() => JSX.Element);
  style?: React.CSSProperties;
}

export const TimerFormInput = ({
  name,
  label,
  errorMessage = undefined,
  style,
  ...props
}: Props) => {
  const inputStyles = {
    backgroundPosition: errorMessage ? '97%' : '120%',
    borderColor: errorMessage ? '#F34675' : undefined,
  };

  return (
    <S.InputContainer>
      <S.InputLabel htmlFor={name} style={{ color: errorMessage ? '#F34675' : '#fff' }}>
        {label}
      </S.InputLabel>
      <S.InputField id={name} name={name} style={{ ...inputStyles, ...style }} {...props} />
      {props.rightSideContent ? (
        <S.RightSideContent>
          {typeof props.rightSideContent === 'string' ? (
            <S.Text>{props.rightSideContent}</S.Text>
          ) : (
            props.rightSideContent()
          )}
        </S.RightSideContent>
      ) : null}
      {errorMessage ? <S.ErrorText>{errorMessage}</S.ErrorText> : null}
    </S.InputContainer>
  );
};
