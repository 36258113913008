import { usePauseTimer } from '@Timer';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { useResetTimer } from '../useResetTimer';

const MILLISECONDS_BEFORE_RESET = 1000 * 60 * 60; // one hour

export function useResetOrPauseTimerBetweenUsages() {
  const [isTimerReconciled, setIsTimerReconciled] = useState(false);
  const timeSinceLastTimerUpdate = useTimeSinceLastTimerUpdate();
  const resetTimer = useResetTimer({ shouldPreserveDisplayType: true });
  const pauseTimer = usePauseTimer({ skipTimeUpdate: true });

  useEffect(() => {
    if (timeSinceLastTimerUpdate >= MILLISECONDS_BEFORE_RESET) {
      resetTimer();
    } else {
      pauseTimer();
    }

    setIsTimerReconciled(true);
  }, []);

  return { isTimerReconciled };
}

function useTimeSinceLastTimerUpdate() {
  const lastCurrentTime = useSelector<RootReducerType, RootReducerType['timer']['currentTime']>(
    state => state.timer.currentTime,
  );

  return Date.now() - lastCurrentTime;
}
