import { Serving, Track } from '@Model';
import { getTrackImageUrl } from '@Music';

export const getBackgroundImage = (data: Track | Serving) => {
  const imageUrl = new URL(getTrackImageUrl(data));

  imageUrl.search = '';
  imageUrl.searchParams.set('w', '1728');
  imageUrl.searchParams.set('q', '80');
  imageUrl.searchParams.set('auto', 'format');
  imageUrl.searchParams.set('fit', 'crop');
  imageUrl.searchParams.set('ar', '16:9');

  return imageUrl.toString();
};
