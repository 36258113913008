import { useDispatch } from 'react-redux';

import { timerActions } from './reducer';

export function useStoreCustomTimerOption() {
  const dispatch = useDispatch();

  return function storeCustomTimerOption(option: { label: string; value: string }) {
    dispatch(timerActions.addCustomTimerValue(option));
  };
}
