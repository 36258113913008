import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';
import { AMPLITUDE_KEY } from '../../globals';
import { Logger } from '../../utils/logger';
import { useEffect, useMemo, useState } from 'react';
import { DESKTOP_ANNOUNCEMENT_EXPERIMENT_ID } from '../Memberships/components/DesktopAnnouncementBanner/DesktopAnnouncementBanner.constants';

enum AmplitudeExperimentClientStatuses {
  Unititialized = 'Unititialized',
  Initializing = 'Initializing',
  Initialized = 'Initialized',
  Errored = 'Errored',
}

const AMPLITUDE_EXPERIMENT_CLIENT_REF: {
  client: ExperimentClient | null;
  status: AmplitudeExperimentClientStatuses;
} = { client: null, status: AmplitudeExperimentClientStatuses.Unititialized };

export function useInitializeAmplitudeExperimentsOnMount(): void {
  useEffect(() => {
    initializeAmplitudeExperiments();
  }, []);
}

export function clearAmplitudeExperiments(): void {
  if (AMPLITUDE_EXPERIMENT_CLIENT_REF.client) {
    AMPLITUDE_EXPERIMENT_CLIENT_REF.client.clear();
    AMPLITUDE_EXPERIMENT_CLIENT_REF.client.stop();
    AMPLITUDE_EXPERIMENT_CLIENT_REF.client = null;
    AMPLITUDE_EXPERIMENT_CLIENT_REF.status = AmplitudeExperimentClientStatuses.Unititialized;
  }
}

// EXPERIMENT IDS
const TIME_OF_DAY_RECOMMENDATIONS_EXPERIMENT_ID = 'tod-recommended-activities';
const SIDE_DECK_OPENED_EXPERIMENT_ID = 'side-deck-opened';

export function useTimeOfDayExperiment(): {
  isEnabled: boolean;
  title: string | null;
  subtitle: string | null;
} {
  const isEnabled = useBooleanAmplitudeExperiment(TIME_OF_DAY_RECOMMENDATIONS_EXPERIMENT_ID);

  if (!isEnabled) {
    return { isEnabled, title: null, subtitle: null };
  }

  return {
    isEnabled,
    title:
      AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(TIME_OF_DAY_RECOMMENDATIONS_EXPERIMENT_ID)
        .payload?.title || null,
    subtitle:
      AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(TIME_OF_DAY_RECOMMENDATIONS_EXPERIMENT_ID)
        .payload?.subtitle || null,
  };
}
export function trackTimeOfDayExperimentExposure(): void {
  AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.exposure(TIME_OF_DAY_RECOMMENDATIONS_EXPERIMENT_ID);
}

export function useIsDesktopAnnouncementBannerExperimentEnabled(): boolean {
  return useBooleanAmplitudeExperiment(DESKTOP_ANNOUNCEMENT_EXPERIMENT_ID);
}

export function trackDesktopAnnouncementBannerExperimentExposure(): void {
  AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.exposure(DESKTOP_ANNOUNCEMENT_EXPERIMENT_ID);
}

export function useIsSideDeckOpenedEnabled(): boolean {
  return useBooleanAmplitudeExperiment(SIDE_DECK_OPENED_EXPERIMENT_ID);
}

async function initializeAmplitudeExperiments(): Promise<void> {
  if (AMPLITUDE_EXPERIMENT_CLIENT_REF.status !== AmplitudeExperimentClientStatuses.Unititialized) {
    return;
  }

  AMPLITUDE_EXPERIMENT_CLIENT_REF.status = AmplitudeExperimentClientStatuses.Initializing;

  try {
    const client = Experiment.initializeWithAmplitudeAnalytics(AMPLITUDE_KEY);
    await client.start();
    AMPLITUDE_EXPERIMENT_CLIENT_REF.client = client;
    AMPLITUDE_EXPERIMENT_CLIENT_REF.status = AmplitudeExperimentClientStatuses.Initialized;
  } catch (error) {
    AMPLITUDE_EXPERIMENT_CLIENT_REF.status = AmplitudeExperimentClientStatuses.Errored;
    Logger.error('Failed to initialize Amplitude Experiment client', { reason: error });
  }
}

function useBooleanAmplitudeExperiment(experimentName: string) {
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    fetchAmplitudeExperiment();

    async function fetchAmplitudeExperiment() {
      try {
        await AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.fetch();
        const experimentValue: string | undefined =
          AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(experimentName).value;

        setIsEnabled(Boolean(experimentValue && experimentValue !== 'control'));
      } catch (error) {
        Logger.error('Failed to fetch Amplitude Experiments', {
          experimentName,
          reason: error,
        });
      }
    }
  }, []);

  return isEnabled;
}
