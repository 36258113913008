import { Action } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import { RootReducerType } from 'src/reducers';
import * as analyticsActions from '../../../actions/analytics';

import { RequestMethods } from '../../../api/client/types';
import { requestSaga, SuccessResponseType } from '../../../sagas/httpRequest';
import { SubscriptionEvents } from '../../../utils/analytics/events';
import { Logger } from '../../../utils/logger';
import { membershipActions } from '../../Memberships/reducer';
import { cancelStripeSubscription } from '../actions';
import { paymentActions } from '../reducer/reducer';

export function* cancelStripeSubscriptionSaga(action: Action) {
  if (!cancelStripeSubscription.match(action)) return;
  yield put(analyticsActions.subscriptionLogEvent(SubscriptionEvents.core_renewal_disable_attempt));

  yield put(paymentActions.receiveCancellationProcessing());

  try {
    const { info }: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
    const response: SuccessResponseType = yield requestSaga(
      RequestMethods.POST,
      '/payments/disable-subscription-auto-renew',
      {
        userId: info?.id,
        reason: action.payload.reason,
      },
    );

    if (response.status === 200) {
      yield put(membershipActions.fetchMembership());
      yield put(paymentActions.resetState());
      yield put(
        analyticsActions.subscriptionLogEvent(SubscriptionEvents.core_renewal_disable_success),
      );
      return;
    }
  } catch (error) {
    Logger.error(new Error('cancelStripeSubscriptionSaga(): unable to cancel subscription'), {
      reason: error,
    });
    yield put(analyticsActions.subscriptionLogEvent(SubscriptionEvents.core_renewal_disable_error));
  }

  yield put(paymentActions.receiveCancellationError());
}
