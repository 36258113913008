import styled from 'styled-components';
import { FontColors, TextBold } from '@Cortex';
import { Assets } from '../../../utils/assets';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  background-color: #0d0c11;
  background-image: url(${Assets.images.blurBg.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  overflow-y: auto;
`;

export const HeaderText = styled(TextBold)`
  font-size: 3.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 2rem;
  }
`;

export const Logo = styled.img`
  height: 3.5rem;
  width: 3.5rem;
  margin-bottom: 3rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    margin-bottom: 2rem;
  }
`;

export const TipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  gap: 4rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    margin-top: 2rem;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const TipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 19.5rem;
  gap: 1rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: row-reverse;
    width: 100%;
    gap: 1.5rem;
  }
`;

export const StepCircle = styled.div`
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.1);
`;

export const StepImage = styled.img`
  width: 100%;
  height: 15rem;
  object-fit: contain;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    height: 88px;
    width: 126px;
  }
`;

export const Description = styled.div`
  width: 82.5%;
  color: ${FontColors.White};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;

  & strong {
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-weight: 700;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    text-align: left;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 4rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

export const PaymentContainer = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: inline-flex;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);
  animation: fade-up 1s ease-in-out;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    left: 2rem;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const CheckIcon = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  object-fit: contain;
`;
