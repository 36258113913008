import styled from 'styled-components';

export const OuterCircle = styled.div`
  position: relative;
  width: 9.68rem;
  height: 9.68rem;
  border-radius: 100%;
  filter: blur(2rem);
  opacity: 0.5;
  isolation: isolate;
`;

export const InnerCircle = styled.div`
  position: absolute;
  width: 83%;
  height: 83%;
  top: -5%;
  right: -5%;
  border-radius: 100%;

  mix-blend-mode: color-dodge;
  filter: blur(2rem);
`;
