import { useGetAvailableStripeCouponPromotion, MembershipSources } from '@Memberships';
import { useIsPaymentProcessing } from '@Payment';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../../../reducers';
import { useMembershipPeriodTimeLeft } from '../../hooks/useMembershipPeriodTimeLeft';
import { MembershipPanelWrapper } from '../MembershipPanelWrapper/MembershipPanelWrapper';
import { GracePeriodCallToActionPresentation } from './GracePeriodCallToAction.presentation';

type Props = {
  onClickUpdatePaymentDetails?: () => void;
};

export const GracePeriodCallToAction = (props: Props) => {
  const { gracePeriodTimeLeftInDays, gracePeriodTimeLeftInHours, gracePeriodTimeLeftInMinutes } =
    useMembershipPeriodTimeLeft();
  const membershipTitle = useSelector((state: RootReducerType) => state.user.membership?.title);
  const isPaymentProcessing = useIsPaymentProcessing();
  const membershipSource = useSelector(
    (state: Pick<RootReducerType, 'membership'>) => state.membership.source,
  );
  const isLoading = membershipSource === MembershipSources.Loading || isPaymentProcessing;
  const subtitle = `${membershipTitle} Plan`;

  return (
    <MembershipPanelWrapper
      isLoading={isLoading}
      subtitle={subtitle}
      tagTitle="EXPIRED"
      title="PRO"
    >
      <GracePeriodCallToActionPresentation
        daysRemaining={gracePeriodTimeLeftInDays}
        hoursRemaining={gracePeriodTimeLeftInHours % 24}
        minutesRemaining={gracePeriodTimeLeftInMinutes % 60}
        onClickUpdatePaymentDetails={props.onClickUpdatePaymentDetails}
      />
    </MembershipPanelWrapper>
  );
};
