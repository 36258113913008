import * as S from './CloseButton.styles';

type Props = {
  'data-testid'?: string;
  hasHoverEffect?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export function CloseButton(props: Props) {
  return (
    <S.Wrapper
      data-testid={props['data-testid']}
      hasHoverEffect={props.hasHoverEffect}
      style={{ display: 'flex', alignItems: 'center' }}
      onClick={props.onClick}
    >
      <S.CloseIcon />
    </S.Wrapper>
  );
}
