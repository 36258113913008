import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;
`;

export const BackgroundImage = styled.div`
  background-image: url('https://cdn.brain.fm/images/reset_password-background.webp');
  background-size: cover;
  background-position: center;
  filter: blur(18px);
  -webkit-filter: blur(18px);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

export const SignInContainerLG = styled.div`
  display: flex;
  padding: 12px 1rem;
  justify-content: center;
  position: absolute;
  top: 48px;
  right: 48px;
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    display: none;
  }
`;

export const SignInContainerSM = styled.div`
  display: block;

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    display: none;
  }
`;
