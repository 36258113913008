import { useSelector } from 'react-redux';
import { usePromotions } from '../../../api/modules/Promotions';

import { RootReducerType } from '../../../reducers';
import { useIsExtendedPromoAvailable } from '../../Payment/components/PaywallModal/hooks/useIsExtendedPromoAvailable';
import {
  MembershipPromotion,
  MembershipPromotionTypes,
  MembershipState,
  StripeCouponMembershipPromotion,
} from '../reducer';

export function getAvailableStripeCouponPromotion(
  state: MembershipState,
): MembershipPromotion | null {
  return state.promotion;
}

export function useGetAvailableStripeCouponPromotion(): StripeCouponMembershipPromotion | null {
  const userState = useSelector((state: RootReducerType) => state.user);
  const membershipState = useSelector((state: RootReducerType) => state.membership);
  const availablePromotion = getAvailableStripeCouponPromotion(membershipState);
  const { data: promotionsList, isFetched: promotionsFetched } = usePromotions();
  const isExtendedPromoAvailable = useIsExtendedPromoAvailable();

  const expirationDate = userState.membership?.expirationDate;
  const isExpired = expirationDate && new Date(expirationDate) < new Date();

  const shouldShowGlobalPromotion =
    process.env.NODE_ENV !== 'test' &&
    (membershipState.source === 'Trial' || membershipState.source === null || isExpired) &&
    !isExtendedPromoAvailable &&
    promotionsFetched &&
    Array.isArray(promotionsList) &&
    promotionsList.length;

  const globalPromotion = shouldShowGlobalPromotion ? promotionsList[0] : null;

  if (availablePromotion && availablePromotion.type !== MembershipPromotionTypes.StripeCoupon) {
    return null;
  }

  return availablePromotion || globalPromotion;
}
