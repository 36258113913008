import styled from 'styled-components';

export const MenuItem = styled.div<{ isActive: boolean }>`
  align-items: center;
  color: white;
  cursor: ${props => (props.isActive ? 'auto' : 'pointer')};
  display: flex;
  flex-direction: row;
  font-family: TTNormsPro-Regular;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 8px;
  opacity: ${props => (props.isActive ? '1' : '0.7')};
  transition: opacity 0.15s ease-in-out;
  padding: 0.8rem;
  border: 1px solid transparent;
  ${props =>
    props.isActive
      ? `
        border-color: white;
        background-color: rgba(255, 255, 255, 0.1);
      `
      : ''};
  &:hover,
  &:active {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.1);
  }

  & > a {
    color: white;
    text-decoration: none;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  margin: 0.5rem 0;
`;

export const Icon = styled.div<{ isActive: boolean; isClickable: boolean }>`
  align-items: center;
  cursor: ${props => (props.isClickable ? 'pointer' : 'default')};
  display: flex;
  margin-right: 1rem;
`;
