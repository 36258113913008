import { Icon, TextBold } from '@Cortex';
import { musicActions, Sort } from '@Music';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as S from './ExploreMusic.styles';
import { Serving, Track } from '@Model';
import { TrackInfoModal } from '../TrackInfoModal/TrackInfoModal';
import { SearchBar, SearchResults } from '../SearchBar';
import { useGenreNamesByDynamicMentalState } from '../../../../api/modules/GenreNamesByDynamicMentalState';
import { useGenreFilterHandler, useNeuralEffectFilterHandler } from '../Filter/hooks';
import { MentalStateSelector, MentalStateSelectorTab } from '../MentalStateSelector';
import { Filter } from '../Filter';
import { Transition } from 'react-transition-group';
import { Featured } from './components/Featured';
import { Genres } from './components/Genres';
import { Moods } from './components/Moods/Moods';
import { TracksByGenre } from './components/TracksByGenre';
import { TracksByMood } from './components/TracksByMood';
import ChevronLeftIcon from '../../../../assets/images/chevron_left_icon.svg';

export function ExploreMusic() {
  const dispatch = useDispatch();
  const [isMoreInfoOpened, setIsMoreInfoOpened] = useState(false);
  const [previewTrack, setPreviewTrack] = useState<Track | Serving | null>(null);
  const [selectedGenre, setSelectedGenre] = useState<string | null>(null);
  const [selectedMood, setSelectedMood] = useState<string | null>(null);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const { data: allGenres, isLoading } = useGenreNamesByDynamicMentalState();

  const neuralEffectFilter = useNeuralEffectFilterHandler();
  const genreFilter = useGenreFilterHandler(allGenres);

  const handleGoBack = () => {
    setSelectedGenre(null);
    setSelectedMood(null);
  };

  const onClearSearch = () => {
    dispatch(musicActions.setSearchState({ results: null }));
  };

  const shouldSlide = selectedGenre !== null || selectedMood !== null;
  const filters = [neuralEffectFilter, ...(searchEnabled ? [genreFilter] : [])];

  useEffect(() => {
    return () => {
      dispatch(musicActions.setGenreFilters([]));
      dispatch(musicActions.setActivityFilters([]));
      dispatch(musicActions.setNeuralEffectFilters([]));
    };
  }, []);

  const handleTrackClickMore = (track: Track | Serving) => {
    setIsMoreInfoOpened(true);
    setPreviewTrack(track);
  };

  return (
    <>
      <S.Container data-testid="ExploreMusic">
        <S.ContentContainer>
          {!selectedGenre && !selectedMood ? (
            <S.HeadingContainer>
              <TextBold size={32}>{'Explore'}</TextBold>
            </S.HeadingContainer>
          ) : null}

          {selectedGenre || selectedMood ? (
            <S.TagHeadingContainer>
              <S.BackIcon>
                <Icon size={24} src={ChevronLeftIcon} onClick={handleGoBack} />
              </S.BackIcon>
              <TextBold size={20}>{selectedGenre || selectedMood}</TextBold>
              <S.FakeIcon>
                <Icon size={24} src={ChevronLeftIcon} onClick={handleGoBack} />
              </S.FakeIcon>
            </S.TagHeadingContainer>
          ) : null}

          {!selectedGenre && !selectedMood ? (
            <>
              <S.TopItemContainer>
                <SearchBar onClearSearch={onClearSearch} onSelect={setSearchEnabled} />
              </S.TopItemContainer>
              <S.TabBarContainer>
                <MentalStateSelector tab={MentalStateSelectorTab.Explore} />
              </S.TabBarContainer>
            </>
          ) : null}

          {selectedGenre || selectedMood || searchEnabled ? (
            <S.FiltersContainer>
              <S.FilterWrapper>
                <Filter filters={filters} />
              </S.FilterWrapper>
              <S.FilterWrapper>
                <Sort />
              </S.FilterWrapper>
            </S.FiltersContainer>
          ) : null}

          <Transition in={searchEnabled} mountOnEnter nodeRef={searchRef} timeout={0} unmountOnExit>
            {animationState => (
              <S.FadeInWrapper ref={searchRef} animationState={animationState}>
                <SearchResults onTrackClickMore={handleTrackClickMore} />
              </S.FadeInWrapper>
            )}
          </Transition>

          <Transition
            in={!searchEnabled}
            mountOnEnter
            nodeRef={searchRef}
            timeout={0}
            unmountOnExit
          >
            {animationState => (
              <S.FadeInWrapper ref={searchRef} animationState={animationState}>
                <S.GenreContainer slide={shouldSlide}>
                  <S.Page fadeIn={!shouldSlide}>
                    <S.ContentContainer>
                      {!selectedGenre && !selectedMood && (
                        <Featured onTrackClickMore={handleTrackClickMore} />
                      )}
                      <Genres
                        genres={allGenres}
                        isLoading={isLoading}
                        onGenreSelect={setSelectedGenre}
                      />

                      <Moods onMoodSelect={mood => setSelectedMood(mood)} />
                    </S.ContentContainer>
                  </S.Page>

                  <S.Page fadeIn={shouldSlide}>
                    {selectedGenre ? (
                      <TracksByGenre
                        title={selectedGenre}
                        onTrackClickMore={handleTrackClickMore}
                      />
                    ) : null}
                    {selectedMood ? (
                      <TracksByMood title={selectedMood} onTrackClickMore={handleTrackClickMore} />
                    ) : null}
                  </S.Page>
                </S.GenreContainer>
              </S.FadeInWrapper>
            )}
          </Transition>
        </S.ContentContainer>
      </S.Container>
      <TrackInfoModal
        isOpen={isMoreInfoOpened}
        track={previewTrack}
        onClose={() => setIsMoreInfoOpened(false)}
      />
    </>
  );
}
