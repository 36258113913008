import { DynamicActivity } from '@Model';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface RecommendedActivitiesReducerType {
  status: 'loading' | 'idle';
  results: DynamicActivity[];
}

const initialState: RecommendedActivitiesReducerType = {
  status: 'idle',
  results: [],
};

const recommendedActivitiesSlice = createSlice({
  name: 'recommendedActivities',
  initialState,
  reducers: {
    fetchRecommendedActivities: state => {
      state.status = 'loading';
      return state;
    },
    fetchRecommendedActivitiesSuccess: (state, action: PayloadAction<DynamicActivity[]>) => {
      return { ...state, status: 'idle', results: action.payload };
    },
    clearState: () => initialState,
  },
});

export const recommendedActivitiesSliceActions = recommendedActivitiesSlice.actions;
export const recommendedActivitiesReducer = recommendedActivitiesSlice.reducer;
