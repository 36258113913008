import { AMPLITUDE_KEY } from '@Globals';
import Amplitude from 'amplitude-js';

import { Logger } from '../../utils/logger';

export function initializeAmplitudeSDK(params?: {
  amplitudeSDK?: typeof Amplitude;
  amplitudeKey?: string;
}) {
  const { amplitudeSDK = Amplitude, amplitudeKey = AMPLITUDE_KEY } = params || {};

  try {
    amplitudeSDK.getInstance().init(amplitudeKey, undefined, { includeUtm: true });
  } catch (e) {
    Logger.error(new Error('Failed to initialize amplitude sdk.'), { reason: e });
  }
}
