import styled from 'styled-components';

import ShareIconSVG from './ShareIcon.svg?react';

export const ShareIcon = styled(ShareIconSVG)`
  stroke-width: 1.5;
  transition:
    transform 0.15s ease-in-out,
    fill-opacity 0.15s ease-in-out,
    stroke-width 0.15s ease-in-out;

  width: 1.3rem;
  height: auto;

  &:hover {
    stroke-width: 2;
    transform: scale(1.03);
  }
`;
