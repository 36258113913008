import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from '../User';
import { ActivationPresentation } from './Activation.presentation';
import { ActivationSuccessPresentation } from './ActivationSuccess.presentation';
import { getActivationPin, getActivationStatus } from './lenses';
import { ActivationStatuses, activationActions } from './reducer';

export function Activation() {
  const dispatch = useDispatch();
  const activationStatus = useSelector(getActivationStatus);
  const pin = useSelector(getActivationPin);
  const userId = useSelector(getUserId);

  function handleSubmit() {
    dispatch(activationActions.submitPin({ userId, pin }));
  }

  function handlePinChange(code: string) {
    dispatch(activationActions.setPin(code));
  }

  const isLoading = activationStatus === ActivationStatuses.Submitting;
  const isErrored = activationStatus === ActivationStatuses.Errored;
  switch (activationStatus) {
    case ActivationStatuses.Accepted:
      return <ActivationSuccessPresentation />;
    case ActivationStatuses.Idle:
    case ActivationStatuses.Submitting:
    case ActivationStatuses.Errored:
    default:
      return (
        <ActivationPresentation
          isErrored={isErrored}
          isLoading={isLoading}
          pin={pin}
          onPinChange={handlePinChange}
          onSubmit={handleSubmit}
        />
      );
  }
}
