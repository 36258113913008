import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1.25rem;
`;

export const ImageContainer = styled.div`
  width: 3.875rem;
  height: 3.875rem;
  background: linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  padding: 1rem;
`;

export const Image = styled.img`
  object-fit: contain;
`;

export const TextWrapper = styled.div`
  margin-bottom: 0.5rem;
  text-transform: none;
`;

export const HeadingContainer = styled.div`
  text-align: center;
  margin-top: 1.25rem;
`;
