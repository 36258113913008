import { Assets } from '../../../../utils/assets';
import {
  Button,
  ButtonVariantSizes,
  ButtonVariants,
  FontColors,
  MenuItem,
  MenuItemIcons,
  Text,
  TextBold,
} from '../../../Cortex';
import * as S from './DesktopAnnouncementBanner.styles';

type Props = {
  onClickClose: () => void;
  onClickDownload: () => void;
};

export function DesktopAnnouncementBannerDisplay(props: Props) {
  return (
    <S.Wrapper>
      <S.Body>
        <MenuItem icon={MenuItemIcons.Close} isActive={true} onClickIcon={props.onClickClose} />
        <S.Image
          alt={Assets.images.macAppBannerIcon.alt}
          src={Assets.images.macAppBannerIcon.url}
        />
        <S.CopyWrapper>
          <TextBold size={14}>Introducing the Brain.fm Mac App</TextBold>
          <Text color={FontColors.WhiteTransparent} size={11}>
            Stay in the zone without the need to search through tabs.
          </Text>
        </S.CopyWrapper>
      </S.Body>
      <S.Body>
        <S.ButtonWrapper href="https://www.brain.fm/download" target="_blank">
          <Button
            keepTextCase={true}
            size={ButtonVariantSizes.Small}
            style={{ fontSize: '0.75rem', letterSpacing: '1.2px' }}
            variant={ButtonVariants.Secondary}
            onClick={props.onClickDownload}
          >
            DOWNLOAD NOW
          </Button>
        </S.ButtonWrapper>
      </S.Body>
    </S.Wrapper>
  );
}
