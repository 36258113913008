import { LoadingText } from '@Cortex';
import React from 'react';
import { Assets } from '../../../../../../utils/assets';

import * as S from './MembershipPanelWrapper.styles';
import { MembershipPanelAura } from '../MembershipPanelAura';

export type Props = {
  children?: React.ReactNode;
  iconSrc?: string;
  isLoading?: boolean;
  subtitle: string;
  tagTitle?: string | null;
  title: string;
};

export function MembershipPanelWrapper({
  children,
  iconSrc = Assets.images.logoFull.url,
  isLoading = false,
  subtitle,
  tagTitle = null,
  title,
}: Props) {
  return (
    <S.Container>
      <S.Header>
        <S.HeaderIcon>
          <S.Logo src={iconSrc} />
          <S.HeaderIconAura>
            <MembershipPanelAura
              innerBackground={tagTitle ? S.redAura.inner : S.normalAura.inner}
              outerBackground={tagTitle ? S.redAura.outer : S.normalAura.outer}
            />
          </S.HeaderIconAura>
        </S.HeaderIcon>
        <S.HeaderText>
          <S.HeaderMainInfo>
            {isLoading ? <LoadingText /> : title && <S.HeaderBadge>{title}</S.HeaderBadge>}
          </S.HeaderMainInfo>
          <S.HeaderAdditionalInfo>
            {subtitle && (
              <S.HeaderTextSubtitle>
                {isLoading ? <LoadingText width="100%" /> : subtitle}
              </S.HeaderTextSubtitle>
            )}
            {tagTitle ? (
              <div>
                <S.Tag size="0.75rem">{tagTitle}</S.Tag>
              </div>
            ) : null}
          </S.HeaderAdditionalInfo>
        </S.HeaderText>
      </S.Header>
      <S.Children>{isLoading ? <LoadingText /> : children}</S.Children>
    </S.Container>
  );
}
