import { generatePath } from 'react-router-dom';
import { DA_PREFIXES, DYNAMIC_PLAYER_ACTIVITY_PATH } from '../constants';

export const getDynamicGenresActivityPlayerPath = (params: {
  genreName: string;
  dynamicMentalStateId: string;
}) => {
  return generatePath(DYNAMIC_PLAYER_ACTIVITY_PATH, {
    activityId: `${DA_PREFIXES.GENRE_NAMES}${params.dynamicMentalStateId}_${params.genreName}`,
  });
};
