import { useCallback } from 'react';
import { usePost } from '../../client/client';
import { Endpoints } from '../../common/endpoints';
import { CreateFastSubscriptionResponseType } from './types';

export const useRequestHandler = () => {
  const post = usePost<{}, CreateFastSubscriptionResponseType>();

  return useCallback(async () => {
    return await post({
      path: Endpoints.postCreateFastSubscription(),
      body: {},
      options: { apiVersion: 2 },
    });
  }, [post]);
};
