import styled from 'styled-components';

export const Display = styled.div`
  cursor: pointer;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
`;
