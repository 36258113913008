import { call, put, select } from 'redux-saga/effects';

import { get, deleteCall, put as putCall, post } from '../api/client/client';
import { ApiVersions, RequestMethods } from '../api/client/types';
import { DEFAULT_API_VERSION } from '../api/common/constants';
import { generateErrorResponse } from '../api/common/lib/generateErrorResponse';
import { RootReducerType } from '../reducers';
import { authSliceActions } from '../reducers/authentication';
import { signOut } from '../actions/authentication';
import { Logger } from '../utils/logger';

export type ErrorResponseType = {
  status: number;
  messages: string[];
};

export type SuccessResponseType<T = {}> = T & {
  status: number;
  token?: string;
};

export type ResponseType<T = {}> = ErrorResponseType | SuccessResponseType<T>;

const isError = (response: ResponseType): response is ErrorResponseType => {
  return 'messages' in response;
};

/**
 *
 * @param {string} method GET | POST | PUT | PATCH | DELETE
 * @param {string} url Api routes. (e.g. /users/123)
 * @param {Object} body Proper object to be part of the request body.
 * @param apiVersion
 */
export function* requestSaga<T extends ResponseType>(
  method: RequestMethods,
  url: string,
  body?: any,
  apiVersion?: ApiVersions,
) {
  const { token: userToken }: RootReducerType['auth'] = yield select(
    (state: RootReducerType) => state.auth,
  );
  const debugParams = { url, method, body };

  // Default case is GET request.
  switch (method) {
    case RequestMethods.POST: {
      const response: T = yield call(post, {
        path: url,
        body,
        token: userToken || '',
        options: { apiVersion: apiVersion || DEFAULT_API_VERSION },
      });
      logEmptyResponseForDebugging(response, debugParams);

      const { status } = response;

      if ('token' in response && response.token) {
        yield put(authSliceActions.setToken(response.token));
      }

      if (status === 200) return response;

      if (status === 401) {
        // yield put(actions.createUnauthenticatedError(response));
      }
      const errorMessages = isError(response) ? response.messages : undefined;
      return generateErrorResponse(errorMessages, status);
    }

    case RequestMethods.PUT: {
      const response: T = yield call(putCall, {
        path: url,
        body,
        token: userToken || '',
        options: { apiVersion: apiVersion || DEFAULT_API_VERSION },
      });
      logEmptyResponseForDebugging(response, debugParams);

      const { status } = response;

      if ('token' in response && response.token) {
        yield put(authSliceActions.setToken(response.token));
      }

      if (status === 200) return response;

      if (status === 401) {
        // yield put(actions.createUnauthenticatedError(response));
      }

      const errorMessages = isError(response) ? response.messages : undefined;
      return generateErrorResponse(errorMessages, status);
    }

    case RequestMethods.DELETE: {
      const response: T = yield call(deleteCall, {
        path: url,
        body,
        token: userToken || '',
        options: { apiVersion: apiVersion || DEFAULT_API_VERSION },
      });
      logEmptyResponseForDebugging(response, debugParams);

      const { status } = response;

      if ('token' in response && response.token) {
        yield put(authSliceActions.setToken(response.token));
      }

      if (status === 200) return response;

      if (status === 401) {
        // yield put(actions.createUnauthenticatedError(response));
      }

      const errorMessages = isError(response) ? response.messages : undefined;
      return generateErrorResponse(errorMessages, status);
    }

    default: {
      const response: T = yield call(get, {
        path: url,
        token: userToken || '',
        options: { apiVersion: apiVersion || DEFAULT_API_VERSION },
      });
      logEmptyResponseForDebugging(response, debugParams);

      const { status } = response;

      if ('token' in response && response.token) {
        yield put(authSliceActions.setToken(response.token));
      }

      if (response.status === 200 || !response.hasOwnProperty('status')) return response; // v3 endpoints may not have status property

      const errorMessages = isError(response) ? response.messages : undefined;

      if (status === 401) {
        // yield put(actions.createUnauthenticatedError(response));

        //Log user out when refresh token expires
        if (errorMessages?.includes('Could not validate your request.')) {
          yield put(signOut({ shouldSkipConfirmation: true }));
        }
      }

      return generateErrorResponse(errorMessages, status);
    }
  }
}

function logEmptyResponseForDebugging(
  response: any,
  params: { url: string; method: string; body: any },
): void {
  if (!response) {
    Logger.info('No response from server', params);
  }
}
