import { membershipActions } from '@Memberships';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as uiActions from '../../../actions/ui';
import * as userActions from '../../../actions/user';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { TRedeemGiftCardResponse } from './types';
import { useRequestHandler } from './useRequestHandler';

export const useRedeemGiftCard = (cardCode: string) => {
  const postRedeemGiftCard = useRequestHandler();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useMutation<TRedeemGiftCardResponse, Error>(
    Queries.postRedeemGiftCard(cardCode),
    () => postRedeemGiftCard(cardCode),
    {
      onError: () => {
        Logger.error(new Error('Error redeeming Gift Card'));
      },
      onSuccess: data => {
        if (data.status !== 200) return;
        dispatch(membershipActions.fetchMembership());
        dispatch(userActions.getInfo());
        navigate('/', { replace: true });
        dispatch(uiActions.setModal('subscriptionSuccess'));
      },
      cacheTime: 0,
    },
  );
};
