import { useQuery } from '@tanstack/react-query';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { PublicTeamDataResponseType } from './types';
import { useRequestHandler } from './useRequestHandler';

export const usePublicTeamData = (teamId: string | null) => {
  const getPublicTeamData = useRequestHandler();

  return useQuery<PublicTeamDataResponseType['result'], Error>(
    Queries.getPublicTeamData(teamId!),
    () => getPublicTeamData(teamId!),
    {
      onError: () => {
        Logger.error(new Error('Error getting Public Team Data'));
      },
      enabled: Boolean(teamId),
    },
  );
};
