import { Button, ButtonVariants, LoadingText, MenuItem } from '@Cortex';
import React from 'react';

import * as S from './BillingPanel.styles';

export type Props = {
  cardInformation: string;
  isLoading?: boolean;
  onClickCancel?: (() => void) | null;
  onClickUpdatePaymentMethod?: (() => void) | null;
  updatePaymentButtonText?: string;
};

export function BillingPanel({
  cardInformation,
  isLoading = false,
  onClickCancel = null,
  onClickUpdatePaymentMethod = null,
  updatePaymentButtonText = 'UPDATE PAYMENT METHOD',
}: Props) {
  return (
    <S.Container>
      <S.PaymentMethod>
        <S.Title>
          {isLoading ? <LoadingText>CURRENT PAYMENT METHOD</LoadingText> : 'CURRENT PAYMENT METHOD'}
        </S.Title>
        <S.Body>
          {isLoading ? <LoadingText>{cardInformation}</LoadingText> : cardInformation}
        </S.Body>
      </S.PaymentMethod>
      {onClickUpdatePaymentMethod || onClickCancel ? (
        <>
          {onClickUpdatePaymentMethod ? (
            <S.PaymentButton hasBottomMargin={Boolean(onClickCancel)}>
              <Button
                data-testid="updatePaymentButton"
                isFullWidth={true}
                keepTextCase={true}
                style={{ fontSize: '0.75rem' }}
                variant={ButtonVariants.Secondary}
                onClick={onClickUpdatePaymentMethod}
              >
                {updatePaymentButtonText}
              </Button>
            </S.PaymentButton>
          ) : null}
          {onClickCancel ? (
            <S.CancelWrapper>
              <MenuItem testid="cancelSubscriptionButton" onClick={onClickCancel}>
                Cancel Renewal
              </MenuItem>
            </S.CancelWrapper>
          ) : null}
        </>
      ) : null}
    </S.Container>
  );
}
