import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
`;

export const Section = styled.section<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props => (props.isVisible ? 'translateY(0)' : 'translateY(-100%)')};
`;

export const ChevronButton = styled.button`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

export const PlanSelection = styled.div`
  padding: 20px;
  height: 100%;
  background-color: #f0f0f0;
`;

export const PaymentSection = styled.div`
  padding: 20px;
  height: 100%;
  background-color: #d0f0d0;
`;
