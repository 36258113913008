import { Serving, Track } from '@Model';

import { isServing } from './isServing';

export function getTrackIsNewlyCreated(trackOrServing?: Track | Serving | null): boolean {
  if (isServing(trackOrServing)) {
    return trackOrServing.track.isNewlyCreated;
  }

  return trackOrServing?.serving.track.isNewlyCreated || false;
}
