import { Text, TextBold } from '@Cortex';

import * as S from './NoData.styles';
import Icon from './assets/headphones.svg';

export const NoData = () => {
  return (
    <S.Container>
      <S.ImageContainer>
        <S.Image src={Icon} />
      </S.ImageContainer>
      <S.HeadingContainer>
        <S.TextWrapper>
          <TextBold>Start listening!</TextBold>
        </S.TextWrapper>
        <S.TextWrapper>
          <Text>Recently played sessions and tracks will appear here for quick access.</Text>
        </S.TextWrapper>
      </S.HeadingContainer>
    </S.Container>
  );
};
