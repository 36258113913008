import { Button, ButtonVariants, CloseButton, Paper } from '@Cortex';
import { NOOP } from '@Globals';
import React from 'react';

import { Assets } from '../../../../utils/assets';
import * as S from './OnboardingCard.styles';

export type Props = {
  activeStepIndex: number;
  body: React.ReactNode;
  image: Images;
  numberOfSteps: number;
  onClickClose?: () => void;
  onClickNext?: () => void;
  onClickSkip?: () => void;
  subtitle: string | null;
  title: string;
};

export enum Images {
  Study = 'Study',
  PerformanceGraph = 'PerformanceGraph',
  Timer = 'Timer',
}

export function OnboardingCard({
  onClickClose = NOOP,
  onClickSkip = NOOP,
  onClickNext = NOOP,
  subtitle = null,
  ...props
}: Props) {
  return (
    <Paper isScrollable={true} padding="4rem 2rem">
      <S.CloseButton>
        <CloseButton data-testid="onboardingCardCloseButton" onClick={onClickClose} />
      </S.CloseButton>
      <S.OnboardingCard>
        <S.OnboardingCardImageColumn>{renderImage(props)}</S.OnboardingCardImageColumn>
        <S.OnboardingCardTextColumn>
          {subtitle ? <S.Subtitle>{subtitle}</S.Subtitle> : null}
          <S.Title>{props.title}</S.Title>
          <S.Body>{props.body}</S.Body>
          {props.activeStepIndex !== null && props.numberOfSteps !== null ? (
            <S.StepPills>
              {[...Array(props.numberOfSteps)].map((_, index) => (
                <S.StepPill key={index} isActive={index === props.activeStepIndex} />
              ))}
            </S.StepPills>
          ) : null}
        </S.OnboardingCardTextColumn>
        <S.Buttons>
          <S.SkipButton>
            <Button onClick={onClickSkip}>Skip</Button>
          </S.SkipButton>
          <Button variant={ButtonVariants.Primary} onClick={onClickNext}>
            Next
          </Button>
        </S.Buttons>
      </S.OnboardingCard>
    </Paper>
  );
}

function renderImage(props: Pick<Props, 'image'>) {
  switch (props.image) {
    case Images.Study:
      return (
        <S.FixedHeight>
          <picture>
            <source srcSet={Assets.images.focusMentalStateBgWebp.url} type="image/webp" />
            <S.StudyImage
              alt={Assets.images.focusMentalStateBgPng.alt}
              src={Assets.images.focusMentalStateBgPng.url}
            />
          </picture>
        </S.FixedHeight>
      );
    case Images.PerformanceGraph:
      return (
        <picture>
          <S.GraphImage>
            <S.Image
              alt={Assets.images.onboardingGraph.alt}
              src={Assets.images.onboardingGraph.url}
              width="100%"
            />
          </S.GraphImage>
        </picture>
      );
    case Images.Timer:
      return (
        <picture>
          <S.TimerImage>
            <S.Image
              alt={Assets.images.onboardingCountdown.alt}
              src={Assets.images.onboardingCountdown.url}
            />
          </S.TimerImage>
        </picture>
      );
  }
}
