import { useState, useCallback, ChangeEvent, useEffect } from 'react';

import { useResendVerificationEmail } from '../../../../../../api/modules/ResendVerificationEmail';
import { useVerifyEmail } from '../../../../../../api/modules/VerifyEmail';
import { VerificationFormPresentation } from './VerificationForm.presentation';

export const VerificationForm = () => {
  const [code, setCode] = useState('');
  const [isResent, setIsResent] = useState(false);
  const [verificationErrorMessage, setVerificationErrorMessage] = useState('');
  const handleResent = useCallback(() => {
    setIsResent(true);
  }, [setIsResent]);
  const { mutateAsync: handleVerifyRequest, isLoading, data } = useVerifyEmail();
  const { mutateAsync: handleResendEmailRequest, isLoading: isLoadingResendEmail } =
    useResendVerificationEmail({ callback: handleResent });

  const handleChangeCode = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCode(e.target.value);
    },
    [setCode],
  );
  const handleVerify = useCallback(() => {
    if (!code) return;
    handleVerifyRequest(code);
  }, [handleVerifyRequest, code]);
  const handleResendEmail = useCallback(() => {
    handleResendEmailRequest();
  }, [handleResendEmailRequest]);

  useEffect(() => {
    setVerificationErrorMessage(data?.messages?.[0] || '');
  }, [data, setVerificationErrorMessage]);

  return (
    <VerificationFormPresentation
      code={code}
      isResending={isLoadingResendEmail}
      isResent={isResent}
      isVerifying={isLoading}
      verificationErrorMessage={verificationErrorMessage}
      onChangeCode={handleChangeCode}
      onResendEmail={handleResendEmail}
      onVerify={handleVerify}
    />
  );
};
