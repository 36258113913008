import { ExpiredMembershipCallAction } from './components/ExpiredMembershipCallToAction';
import { GracePeriodCallToAction } from './components/GracePeriodCallToAction';
import { LifetimeMembershipPanel } from './components/LifetimePanel';
import { SubscriptionInformation } from './components/SubscriptionInformation';
import { TrialCallToAction } from './components/TrialCallToAction';
import { useMembershipPanelType, MembershipPanelType } from './hooks/useMembershipPanelType';

type Props = {
  onClickUpdatePaymentDetails?: () => void;
};

export function MembershipPanelContainer(props: Props) {
  const membershipType = useMembershipPanelType();

  if (membershipType === MembershipPanelType.Lifetime) {
    return <LifetimeMembershipPanel />;
  }

  if (membershipType === MembershipPanelType.Trial) {
    return <TrialCallToAction />;
  }

  if (membershipType === MembershipPanelType.ExpiredSubscriptionNotInGracePeriod) {
    return <ExpiredMembershipCallAction />;
  }

  if (membershipType === MembershipPanelType.ExpiredSubscriptionInGracePeriod) {
    return (
      <GracePeriodCallToAction onClickUpdatePaymentDetails={props.onClickUpdatePaymentDetails} />
    );
  }

  if (membershipType === MembershipPanelType.ActiveSubscription) {
    return <SubscriptionInformation />;
  }

  return null;
}
