import { useEffect } from 'react';

import * as S from './Confetti.styles';
import lottie from 'lottie-web';
import confetti from '../assets/confetti.json';

export const Confetti = () => {
  useEffect(() => {
    const animationContainer = document.getElementById('confetti') as HTMLElement;
    const animation = lottie.loadAnimation({
      container: animationContainer,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: confetti,
    });

    const onComplete = () => {
      animationContainer.parentNode?.removeChild(animationContainer);
    };

    animation.addEventListener('complete', onComplete);

    return () => {
      animation.removeEventListener('complete', onComplete);
      animation.destroy();
    };
  }, []);

  return <S.Confetti id="confetti" />;
};
