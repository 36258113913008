import * as S from './StreakInfo.styles';

type Props = {
  title: string;
  value: number;
};

export const StreakInfo = ({ title, value }: Props) => {
  return (
    <S.Streak>
      <S.TopPart>{title}</S.TopPart>
      <S.BottomPart>{value}</S.BottomPart>
    </S.Streak>
  );
};
