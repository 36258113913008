import { createMigrate } from 'redux-persist';

import { migrate as v0ToV1Migrate } from './1_CreateServingBasis';

const migrations = {
  1: v0ToV1Migrate,
};

// @ts-expect-error
export const migrate = createMigrate(migrations, { debug: false });
