import { useCallback, useEffect, useState } from 'react';

import { Assets } from '../../utils/assets';
import { Logger } from '../../utils/logger';
import { PomodoroPhases } from './constants';
import { usePomodoroPhase } from './usePomodoroPhase';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerType } from '../../reducers';
import { usePomodoroDisplayValues } from './useTimerDisplayValue';
import { useIsPomodoroActive } from './useIsPomodoroActive';

const CHIME = new Audio(Assets.sounds.chime.url);
const THREE_CHIMES = new Audio(Assets.sounds.threeChimes.url);
const VOICE_WORK_END = new Audio(Assets.sounds.voiceWorkEnd.url);
const VOICE_BREAK_END = new Audio(Assets.sounds.voiceBreakEnd.url);

export function useChimeOnPomodoroPhaseChange() {
  const dispatch = useDispatch();
  const [hasMounted, setHasMounted] = useState(false);
  const intervalTimerBreakType = useSelector(
    (state: RootReducerType) => state.userV2.preferences.intervalTimerBreakType,
  );
  const playerVolume = useSelector((state: RootReducerType) => state.ui.playerVolume);
  const isPomodoro = useIsPomodoroActive();
  const pomodoroPhase = usePomodoroPhase();
  const { fadeOutPhase } = usePomodoroDisplayValues();
  let IntervalAudio: HTMLAudioElement | null = null;

  const emmitEvent = (name: string) => {
    window.dispatchEvent(new Event(name));
  };

  const handleFadeIn = useCallback(() => {
    emmitEvent('fade-in');
  }, []);

  useEffect(() => {
    if (!fadeOutPhase || !isPomodoro) {
      return;
    }
    emmitEvent('fade-out-partial');
  }, [fadeOutPhase, isPomodoro]);

  useEffect(() => {
    if (!hasMounted) {
      // don't play sound on mount
      setHasMounted(true);
      return;
    }
    if (!pomodoroPhase) return;

    try {
      if (pomodoroPhase === PomodoroPhases.BreakEnding) {
        if (intervalTimerBreakType === 'voice') {
          IntervalAudio = VOICE_BREAK_END;
        } else {
          IntervalAudio = THREE_CHIMES;
        }
      }

      if (pomodoroPhase === PomodoroPhases.Break) {
        if (intervalTimerBreakType === 'voice') {
          IntervalAudio = VOICE_WORK_END;
        } else {
          IntervalAudio = CHIME;
        }
      }

      if (IntervalAudio) {
        IntervalAudio.volume = playerVolume;
        IntervalAudio.play().catch(e => {
          Logger.info('Custom Timer: chime play() request was interrupted', {
            reason: e,
          });
        });
        IntervalAudio.onended = handleFadeIn;
      }
    } catch (error) {
      Logger.error(new Error('useChimeOnPomodoroPhaseChange(): unable to play sound'), {
        reason: error,
      });
    }
  }, [pomodoroPhase, dispatch]);
}
