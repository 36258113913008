import { Button, ButtonVariants, Panel } from '@Cortex';
import React from 'react';
import ReactModal from 'react-modal';
import LoadingIcon from '../../../../components/icons/LoadingIcon';

import { baseStyles, CLOSE_TIMEOUT_MS } from '../../../../modals/modalStyles';
import { Assets } from '../../../../utils/assets';
import * as S from './SubscriptionSuccessModal.styles';
import { Confetti } from './components/Confetti';

export type Props = {
  description: string;
  isOpen: boolean;
  isLoading: boolean;
  isTrialInfoVisible: boolean;
  onClose: () => void;
  primaryButtonAction: () => void;
  primaryButtonText: string;
  subscriptionInformation: string;
  title: string;
  trialInformation: string;
};

export function SubscriptionSuccessModalDisplay(props: Props) {
  const {
    title,
    isTrialInfoVisible,
    isLoading,
    description,
    trialInformation,
    subscriptionInformation,
    primaryButtonText,
    primaryButtonAction,
  } = props;
  return (
    <ReactModal
      closeTimeoutMS={CLOSE_TIMEOUT_MS}
      isOpen={props.isOpen}
      style={baseStyles}
      onRequestClose={props.onClose}
    >
      <S.Overlay>
        <S.ModalWrapper>
          <Panel
            padding="2rem 0"
            style={{
              background:
                'linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              overflow: 'visible',
            }}
          >
            <S.ModalContent>
              <Confetti />
              <S.Image
                alt={Assets.images.congratulations.alt}
                src={Assets.images.congratulations.url}
              />
              <S.AuraImage alt={Assets.images.purpleAura.alt} src={Assets.images.purpleAura.url} />
              {isLoading ? (
                <S.LoadingWrapper>
                  <LoadingIcon />
                </S.LoadingWrapper>
              ) : (
                <>
                  <S.TextContainer>
                    {title && <S.Title>{title}</S.Title>}
                    {description && <S.Description>{description}</S.Description>}
                  </S.TextContainer>
                  {isTrialInfoVisible && (
                    <S.MembershipInformationContainer>
                      <S.MembershipInformation>
                        <S.Icon alt={Assets.icons.clock.alt} src={Assets.icons.clock.url} />
                        <S.MembershipTextContainer>
                          <S.MembershipHeaderText>FREE TRIAL DURATION</S.MembershipHeaderText>
                          <S.MembershipInfoText>{trialInformation}</S.MembershipInfoText>
                        </S.MembershipTextContainer>
                      </S.MembershipInformation>
                      <S.MembershipInformation>
                        <S.Icon alt={Assets.icons.calendar.alt} src={Assets.icons.calendar.url} />
                        <S.MembershipTextContainer>
                          <S.MembershipHeaderText>UPCOMING SUBSCRIPTION</S.MembershipHeaderText>
                          <S.MembershipInfoText>{subscriptionInformation}</S.MembershipInfoText>
                        </S.MembershipTextContainer>
                      </S.MembershipInformation>
                    </S.MembershipInformationContainer>
                  )}
                </>
              )}
              <S.Description>
                Dive in and make the most of it. Discover, learn, and enjoy!
              </S.Description>
              <S.ButtonWrapper>
                <Button
                  data-testid="successButtonPrimary"
                  isFullWidth
                  keepTextCase
                  style={{ fontSize: '0.75rem', letterSpacing: '1.2px' }}
                  variant={ButtonVariants.Secondary}
                  onClick={primaryButtonAction}
                >
                  {primaryButtonText}
                </Button>
              </S.ButtonWrapper>
            </S.ModalContent>
          </Panel>
        </S.ModalWrapper>
      </S.Overlay>
    </ReactModal>
  );
}
