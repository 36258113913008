import { useQuery } from '@tanstack/react-query';

import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { GiftCardType, TGiftCardInfoResponse } from './types';
import { useRequestHandler } from './useRequestHandler';

export const useGiftCardInfo = (cardCode: string) => {
  const getGiftCardInfo = useRequestHandler();

  return useQuery<TGiftCardInfoResponse, Error>(
    Queries.getGiftCardInfo(cardCode),
    () => getGiftCardInfo(cardCode),
    {
      onError: () => {
        Logger.error(new Error('Error getting Gift Card Info'));
      },
      enabled: false, // only manual trigger when needed
      cacheTime: 0,
    },
  );
};
