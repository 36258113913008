import { Serving, Track } from '@Model';

import { isServing } from './isServing';

export function getTrackId(trackOrServing?: Track | Serving | null): string {
  if (isServing(trackOrServing)) {
    return trackOrServing.track.id;
  }

  return trackOrServing?.serving.track.id || '';
}
