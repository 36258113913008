import { RootReducerType } from '../../../reducers';

type Params = {
  id: string;
};

export function isTrackInDislikes(params: Params) {
  return function selectIsTrackInDislikes(state: Pick<RootReducerType, 'music'>) {
    return state.music.dislikedTrackIds.some(id => id === params.id);
  };
}
