import { musicActions } from '@Music';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootReducerType } from '../../../../reducers';
import { Analytics } from '../../../../utils/analytics';
import { ExploreEvents } from '../../../../utils/analytics/events';
import { SortDisplay } from './Sort.display';

export enum SortBy {
  Title = 'Title',
}

export const Sort = () => {
  const [isMenuExpanded, setMenuExpanded] = useState(false);
  const sortByFilters = useSelector((state: RootReducerType) => state.music.sortByFilters);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(musicActions.setSortByFilters([]));
    };
  }, []);

  function handleSortingSelect(selectedActivity: string) {
    if (sortByFilters.includes(selectedActivity)) {
      dispatch(
        musicActions.setSortByFilters(
          sortByFilters.filter(activity => activity !== selectedActivity),
        ),
      );
    } else {
      dispatch(musicActions.setSortByFilters([...sortByFilters, selectedActivity]));
    }
  }

  function handleSetMenuExpanded(value: boolean) {
    setMenuExpanded(state => {
      if (state !== value) {
        Analytics.logEventWithProperties(ExploreEvents.toggle_sortby_expansion__explore, {
          type: value ? 'close' : 'open',
        } as any);
        return value;
      }

      return state;
    });
  }

  return (
    <SortDisplay
      isMenuExpanded={isMenuExpanded}
      selectedSortings={sortByFilters}
      setMenuExpanded={handleSetMenuExpanded}
      sortByList={Object.values(SortBy)}
      onSortingSelect={handleSortingSelect}
    />
  );
};
