import styled from 'styled-components';

export const Logo = styled.img`
  height: 3.5rem;
  width: 3.5rem;
`;

export const LogoPlaceholder = styled(Logo)`
  opacity: 0;
  display: block;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    display: none;
  }
`;

export const OnboardingCardVideoColumn = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const OnboardingCardImageColumn = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: auto;
    height: 100%;
  }
`;

export const Image = styled.img`
  max-height: 500px;
  object-fit: contain;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2rem 3rem 0px 3rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContentWrapper = styled.div`
  z-index: 1;
  max-width: 79rem;
  position: relative;
  align-self: center;
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    margin-bottom: 0.5rem;
    width: 100%;
    overflow-y: auto;
  }
`;
export const Content = styled.div<{ isBlurred: boolean }>`
  display: flex;
  flex-direction: row;

  gap: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  ${({ isBlurred }) =>
    isBlurred &&
    `
    filter: blur(20px);
    pointer-events: none;
  `}
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
  }
`;

export const OnboardingCardTextColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    padding: 1rem;
    text-align: center;
  }
`;

export const FaqItems = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    align-items: center;
  }
`;

export const FaqItem = styled.div`
  background-color: #0d0c11ff;
  border: 1px solid #ffffff1a;
  border-radius: 8px;
  color: #ffffff;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  margin-bottom: 0.75rem;
  white-space: pre-line;
  padding: 12px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  &:hover {
    background-color: #0d0c1180;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 100%;
  }
`;

export const Modal = styled.div`
  border-radius: 20px;
  padding: 2rem 4rem;
  width: 100%;
  max-width: min(840px, 90vw);
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);
`;

export const ModalLink = styled.a`
  color: white;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  text-decoration: none;
  align-self: center;
  margin-top: 1rem;
  &:hover {
    opacity: 1;
  }
`;

export const Close = styled.img`
  width: 1.2rem;
  height: 1.2rem;
  object-fit: contain;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }
`;

export const ModalImage = styled.img`
  max-height: 85px;
  align-self: center;
  margin-bottom: 1rem;
`;

export const ModalTitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  margin-bottom: 1rem;
  font-size: 1.875rem;
  text-align: center;
  white-space: pre-line;
`;

export const ModalListItem = styled.li`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  margin-bottom: 0.75rem;
`;

export const Title = styled.div<{ isCentered: boolean }>`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 2rem;
  white-space: pre-line;
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 32px;
    line-height: auto;
    margin-bottom: 1.5rem;
    text-align: center;
  }
`;

export const Body = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1.8rem;
  white-space: pre-wrap;
  line-height: 3rem;
  text-align: left;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 20px;
    line-height: 2rem;
    margin-bottom: 1rem;
    opacity: 1;
    text-align: center;
  }
`;

export const BodyTextBold = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    padding: 1rem;
    align-items: center;
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    background-color: #000000;
    padding: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  width: 20.75rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    width: 100%;
  }
`;

export const StepSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  gap: 0.5rem;
  justify-self: center;
`;
export const StepText = styled.div`
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.75);
`;

export const StepPills = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StepPill = styled.div<{
  isActive: boolean;
  isCompleted: boolean;
}>`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, isActive, isCompleted }) =>
    isActive || isCompleted ? theme.colors.white : `rgba(255, 255, 255, 0.2)`};

  border-radius: 0.5rem;
  height: 0.5rem;
  margin-right: 0.25rem;
  width: 4rem;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background: ${({ theme, isActive, isCompleted }) =>
      isActive || isCompleted ? theme.colors.white : `rgba(255, 255, 255, 0.2)`};
    transform: ${({ isActive }) => (isActive ? `translateX(-50%)` : `translateX(0)`)};
    transition: transform 0.3s ease-in;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex: 1;
    height: 8px;
    margin: 0px 3px;
    max-width: 64px;
  }
`;
