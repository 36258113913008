import { Stripe } from '@stripe/stripe-js';
import { post } from '../../../../../api/client/client';
import { PaymentTransactionState } from '../../../../../types';

type Props = {
  paymentMethodId: string;
  planId: number;
  customerId: string;
  transaction: PaymentTransactionState;
  stripe: Stripe | null;
  token: string | null;
  userId?: string;
  onError: (msg: string | null) => void;
};

export const confirmCardPayment = async ({
  paymentMethodId,
  planId,
  customerId,
  transaction,
  stripe,
  token,
  userId,
  onError,
}: Props) => {
  if (!stripe || !token) return;

  const { error, paymentIntent } = await stripe.confirmCardPayment(transaction.clientSecret, {
    payment_method: paymentMethodId,
  });

  if (error) {
    onError(error?.message || null);
    throw error;
  }

  if (paymentIntent?.status === 'succeeded') {
    return post({
      path: '/payments/confirm-subscription',
      body: {
        subscriptionId: transaction.subscriptionId,
        planId,
        customerId,
        userId,
      },
      token,
    });
  }
};
