import { useToken } from '@Authentication';
import { userActions } from '@User';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { post } from '../../../api/client/client';
import { RootReducerType } from '../../../reducers';
import {
  OnboardingSpecification,
  OnboardingNeurotypeMatrixItem,
  OnboardingCardType,
} from '../types';

export const useGetResultsCards = (
  onboardingSpecification: OnboardingSpecification,
  quizResultIndexes: number[],
) => {
  const token = useToken();
  const dispatch = useDispatch();
  const userId = useSelector((state: RootReducerType) => {
    return state.user.info?.id;
  });
  const [id, setId] = useState<string>('');
  const [cards, setCards] = useState<OnboardingCardType[]>([]);
  const [onboardingMusicUrl, setOnboardingMusicUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  const handleRequest = useCallback(
    async (onboardingSpecification: OnboardingSpecification, quizResultIndexes: number[]) => {
      setIsLoading(true);
      const { result } = await post<
        {
          onboardingSpecification: OnboardingSpecification;
          quizResultIndexes: number[];
        },
        { result: OnboardingNeurotypeMatrixItem; status: number }
      >({
        path: `/users/${userId}/onboardingSpecification/quiz`,
        body: {
          onboardingSpecification,
          quizResultIndexes,
        },
        token,
        options: { apiVersion: 3 },
      });
      setIsLoading(false);
      return result;
    },
    [userId, setIsLoading],
  );
  useEffect(() => {
    handleRequest(onboardingSpecification, quizResultIndexes).then(res => {
      setCards(res.resultCards);
      setId(res.id);
      setOnboardingMusicUrl(res.onboardingMusicUrl ?? '');
      dispatch(userActions.getSessionPreferences());
    });
  }, [setCards, handleRequest, dispatch, userActions, setId]);

  return {
    id,
    cards,
    onboardingMusicUrl,
    isLoading,
  };
};
