import { Quotes } from '@AudioPlayer';
import React, { useState } from 'react';

import * as S from './QuotesContainer.styles';

type Props = {
  onClick?: (e: React.MouseEvent) => void;
};

export const QuotesContainer = (props: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <S.TimerContainer
      data-testid="centerTimer"
      onClick={props.onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <S.SessionTypeTextContainer>
        <S.SessionTypeText isVisible={isHovered}>CLICK TO TOGGLE TIMER SETTINGS</S.SessionTypeText>
      </S.SessionTypeTextContainer>
      <Quotes />
    </S.TimerContainer>
  );
};
