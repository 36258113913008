import { EnhancedError } from '../EnhancedError';

export const generateErrorResponse = (
  errorMessages?: string[],
  errorCode?: number,
): EnhancedError => {
  const err: EnhancedError = new EnhancedError();

  let message = (errorMessages || []).join('. ');

  // It is likely that we will get long sql errors
  if (message.length > 500) {
    message = 'Something went wrong on our end. Please try again later.';
  }

  err.message = message;
  err.status = errorCode;

  throw err;
};
