import { useIsTrialUser } from '@Memberships';
import { useNavigate } from 'react-router-dom';
import { useTeamSubscriptionInfo } from '../../../../../hooks/useHasTeamSubscription';
import { useIsExpired } from '../../../../Memberships/lenses/isExpired';

export const usePaywallGateway = () => {
  const isTrial = useIsTrialUser();
  const isExpired = useIsExpired();
  const navigate = useNavigate();
  const { belongsToTeam, isDataReady, hasTeamSubscription } = useTeamSubscriptionInfo();
  const hasActiveTeamAccess = belongsToTeam && isDataReady && hasTeamSubscription;
  if (hasActiveTeamAccess) {
    // if has active team access, redirect no matter if trial or not
    navigate('/');
  }

  if (isTrial || isExpired) {
    return;
  }

  navigate('/');
};
