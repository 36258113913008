import { TextBold } from '@Cortex';
import styled from 'styled-components';

export const Image = styled.img`
  position: absolute;
  width: 80%;
  min-width: 324px;
  max-width: 600px;
  top: -10%;
  right: -10%;

  object-fit: cover;
  transition: all 0.3s ease-in-out;
  transform-origin: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    min-width: 224px;
    top: -20%;
    right: 0%;
    width: 30%;
  }

  @container (width < 200px) {
    top: -10%;
    right: -40%;
  }
`;

export const AuraImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
  width: 130%;
  min-width: 324px;
  max-width: 800px;
  transition: all 0.3s ease-in-out;
  transform-origin: center;
  /* height: 338px; */
`;

export const Container = styled.div<{ isSmall: boolean }>`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 4px 0px #000000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${props => (props.isSmall ? '220px' : '325px')};
  overflow: hidden;
  padding: 32px;
  position: relative;
  transition: background 0.6s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.08);
  }

  &:hover ${Image} {
    transform: scale(1.05);
  }

  &:hover ${AuraImage} {
    transform: translate(35%, -35%) scale(1.2);
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  container-type: inline-size;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
`;

export const Title = styled(TextBold)`
  font-size: clamp(1.5rem, 2.7vw + 0.3rem, 2rem);
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;
  z-index: 99;
`;

export const Subtitle = styled.span`
  font-size: 12px;
  height: 30px;
  line-height: 20px;
  text-align: left;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.75);
  z-index: 99;
`;
