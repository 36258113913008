import { TextBold, Text } from '@Cortex';
import styled from 'styled-components';

export const ForgotPasswordForm = styled.div`
  align-items: center;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;
  color: #fff;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  height: 100dvh;
  overflow-y: scroll;
  width: 100%;
  position: relative;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    overflow-y: inherit;
  }
`;

export const FormBlurView = styled.div`
  backdrop-filter: blur(10px);
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.01));
  border-radius: 1.5rem;
  padding: 2rem;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    padding: 24px 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const IconContainer = styled.div``;

export const FormWrapper = styled.div`
  max-width: 545px;
  width: 100%;
`;

export const Form = styled.div``;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
`;

const Link = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const LinkLast = styled(Link)`
  margin-bottom: 0;
`;

export const HeaderText = styled(TextBold)`
  margin-bottom: 1rem;
  text-align: center;
`;

export const IconImage = styled.img`
  height: 32px;
  width: auto;
  object-fit: contain;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SigninText = styled(Text)`
  color: rgba(255, 255, 255, 0.75);
  font-size: 1rem;
  margin-right: 0.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    display: none;
  }
`;

export const SigninButton = styled.button`
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  letter-spacing: 1px;
  padding: 13px 24px;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const LargeText = styled.h1`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const TopAuraImage = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: -1;
  pointer-events: none;
`;

export const BottomAuraImage = styled.img`
  pointer-events: none;
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);

  z-index: -1;
`;
