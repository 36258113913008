import { Action } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import * as membershipPlansActions from '../actions/membershipPlans';
import { RequestMethods } from '../api/client/types';
import { membershipPlansSliceActions } from '../reducers/membershipPlans';
import { MembershipPlanType, MembershipPlansNormalized } from '../types/membershipPlans';
import { getErrorMessage } from '../utils/getErrorMessage';
import { Logger } from '../utils/logger';
import { requestSaga } from './httpRequest';

function* getMembershipPlansSaga(action: Action) {
  try {
    // set the loading state
    yield put(membershipPlansSliceActions.getPlans());

    if (membershipPlansActions.getMembershipPlans.match(action)) {
      const { result } = yield requestSaga(RequestMethods.GET, '/membership-plans?platform=web');

      const normalizedData: MembershipPlansNormalized = {
        ids: [],
        results: {},
      };

      // sort plans by price to make sure Yearly is first in the array.
      // It will be shown as a first choice in the UI
      const normalizedPlans = (result as MembershipPlanType[])
        .sort((a, b) => (a.price > b.price ? -1 : 1))
        .reduce((newObject: MembershipPlansNormalized, plan: MembershipPlanType) => {
          newObject.ids.push(plan.id);
          newObject.results[plan.id] = plan;

          return newObject;
        }, normalizedData);

      yield put(membershipPlansSliceActions.getPlansSuccess(normalizedPlans));
      yield put(membershipPlansActions.membershipPlansInitiallyFetched());
    }
  } catch (error) {
    yield put(membershipPlansSliceActions.getPlansFailed(getErrorMessage(error)));
    Logger.error(error);
  }
}

export default function* watchMembershipPlansSaga() {
  yield takeLatest(membershipPlansActions.getMembershipPlans.type, getMembershipPlansSaga);
}
