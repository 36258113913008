import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '../../../hooks';
import { Metric } from '../../../types';
import { get } from '../../../api/client/client';
import { membershipActions, MembershipPromotion, MembershipPromotionTypes } from '../reducer';
import {
  BROWSER_ENVIRONMENT,
  REFER_A_FRIEND_LEGACY_PROMO_DEVELOPMENT,
  REFER_A_FRIEND_LEGACY_PROMO_PRODUCTION,
  REFER_A_FRIEND_PROMO_DEVELOPMENT,
  REFER_A_FRIEND_PROMO_PRODUCTION,
} from '@Globals';

export function useIngestPromotionOnMount(params: { isReady?: boolean } = { isReady: true }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  useEffect(() => {
    async function handleIngestPromotionOnMount() {
      if (!params.isReady) return;

      const couponId = query.get('couponId') || undefined;
      const expirationDate = query.get('expirationDate');
      const description = query.get('description');
      // TODO: delete this if we verify evething is working after release
      const displayCost = query.get('displayCost') || query.get('yearlyPrice');

      const name = query.get('name');
      const targetPlan = query.get('targetPlan') || 'Yearly';
      const promotionCode = query.get('promotionCode') || undefined;
      const redirectToPath = query.get('redirectToPath') || undefined;

      query.delete('couponId');
      query.delete('description');
      query.delete('expirationDate');

      // TODO: delete this if we verify evething is working after release
      query.delete('yearlyPrice');

      query.delete('displayCost');
      query.delete('name');
      query.delete('targetPlan');
      query.delete('promotionCode');
      query.delete('redirectToPath');

      // TODO: we only need this because of race conditions, find a better way in the future
      if (query.get('magicLink')) {
        query.delete('magicLink');
      }

      navigate(
        {
          search: query.toString(),
        },
        { replace: true },
      );

      if (isCouponExpired(expirationDate)) return;

      if ((couponId || promotionCode) && description && displayCost && name) {
        if (couponId) {
          const { result } = await get<{ status: number; result: MembershipPromotion | null }>({
            path: `/membership/promotions/${couponId || promotionCode}`,
            token: null,
          });
          if (!result) return;

          dispatch(
            membershipActions.receivePromotion({
              ...result,
              expirationDate: getExpirationDateAsMetric(expirationDate),
              type: MembershipPromotionTypes.StripeCoupon,
            } as MembershipPromotion),
          );
        } else if (promotionCode) {
          dispatch(
            membershipActions.receivePromotion({
              couponId,
              description,
              displayCost,
              expirationDate: getExpirationDateAsMetric(expirationDate),
              name,
              promotionCode: replacePromotionCodeIfOutdated(promotionCode),
              redirectToPath,
              targetPlan,
              type: MembershipPromotionTypes.StripeCoupon,
            }),
          );
        }
      }
    }

    handleIngestPromotionOnMount();
  }, [params.isReady]);
}

function getExpirationDateAsMetric(expirationDate?: string | null): Metric | undefined {
  if (!expirationDate) return;

  try {
    const expirationDateAsUnixTimestampWithMs = parseInt(expirationDate, 10) * 1000;

    return {
      displayValue: new Date(expirationDateAsUnixTimestampWithMs).toLocaleString(undefined, {
        month: 'long',
        day: 'numeric',
      }),
      value: expirationDateAsUnixTimestampWithMs,
    };
  } catch (e) {
    return;
  }
}

function isCouponExpired(expirationDate?: string | null): boolean {
  if (!expirationDate) return false;

  try {
    const timestamp = parseInt(expirationDate, 10) * 1000;
    return timestamp < Date.now();
  } catch (e) {
    return false;
  }
}

function replacePromotionCodeIfOutdated(promotionCode: string): string {
  const legacyPromotionCode =
    BROWSER_ENVIRONMENT === 'production'
      ? REFER_A_FRIEND_LEGACY_PROMO_PRODUCTION
      : REFER_A_FRIEND_LEGACY_PROMO_DEVELOPMENT;
  const updatedPromotionCode =
    BROWSER_ENVIRONMENT === 'production'
      ? REFER_A_FRIEND_PROMO_PRODUCTION
      : REFER_A_FRIEND_PROMO_DEVELOPMENT;

  if (promotionCode === legacyPromotionCode) {
    return updatedPromotionCode;
  }

  return promotionCode;
}
