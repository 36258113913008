const colors = {
  white: 'rgba(255,255,255,1)',
  white300: 'rgba(255,255,255,0.3)',
  white500: 'rgba(255,255,255,0.5)',
  white600: 'rgba(255,255,255,0.6)',
  white700: 'rgba(255,255,255,0.75)',
  white900: 'rgba(255,255,255,0.9)',
  black: 'rgba(0,0,0,1)',
  black200: 'rgba(0,0,0,0.2)',
  black300: 'rgba(0,0,0,0.3)',
  black400: 'rgba(0,0,0,0.4)',
  black500: 'rgba(0,0,0,0.5)',
  black600: 'rgba(0,0,0,0.6)',
  black700: 'rgba(0,0,0,0.75)',
  black800: 'rgba(0,0,0,0.8)',
  black900: 'rgba(0,0,0,0.9)',
  // brandPrimary: '#3F17F0',
  brandPrimary: '#0a1333',
  brandPrimary300: 'rgba(10, 19, 51, 0.3)',
  brandPrimary500: 'rgba(10, 19, 51, 0.5)',
  brandPrimaryDark: '#310F92',
  brandPrimaryLight: '#4915f8',
  brandSecondary: '#F69B14',
  brandSecondaryDark: '#cd327d',
  extraDarkNavy: '#242153',
  darkNavy: '#14124B',
  navy: '#1F2E61',
  brightPurple: '#A51CC6',
  lightCrimson: '#ff496b',
  lightPurple: '#5411ff',
  darkPurple: '#15134e',
};

export type ColorsType = typeof colors;

export default colors;
