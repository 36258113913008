import React, { useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const POINTS = [0, 45, 90, 135, 180, 225, 270, 315];

const Svg = styled.svg`
  animation: ${spin} 0.75s steps(8, end) infinite;
`;

const Polyline = styled.polyline`
  stroke-width: 3px;
  stroke-linecap: round;

  &:nth-child(8n + 1) {
    stroke-opacity: 0.125;
  }
  &:nth-child(8n + 2) {
    stroke-opacity: 0.25;
  }
  &:nth-child(8n + 3) {
    stroke-opacity: 0.375;
  }
  &:nth-child(8n + 4) {
    stroke-opacity: 0.5;
  }
  &:nth-child(8n + 5) {
    stroke-opacity: 0.625;
  }
  &:nth-child(8n + 6) {
    stroke-opacity: 0.75;
  }
  &:nth-child(8n + 7) {
    stroke-opacity: 0.875;
  }
  &:nth-child(8n + 8) {
    stroke-opacity: 1;
  }
`;

export const LoadingIconRotatingLines = (): React.ReactElement => {
  const lines = useCallback(
    () =>
      POINTS.map(point => (
        <Polyline key={point} points="24,12 24,4" transform={`rotate(${point}, 24, 24)`} />
      )),
    [],
  );

  return (
    <Svg stroke="white" viewBox="0 0 48 48" width="40px" xmlns="http://www.w3.org/2000/svg">
      {lines()}
    </Svg>
  );
};
