import * as S from './ListGroup.styles';
import { ListItem } from './components/ListItem';

interface Props {
  checkedItems: string[];
  items: string[];
  onCheck: (item: string) => void;
}

export const ListGroup = ({ checkedItems, onCheck, items }: Props) => {
  return (
    <S.Display>
      <S.Content>
        {items.map((item, i) => (
          <ListItem
            key={`${item}_${i}`}
            isChecked={checkedItems.includes(item)}
            item={item}
            onCheck={() => onCheck(item)}
          />
        ))}
      </S.Content>
    </S.Display>
  );
};
