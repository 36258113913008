import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

import CheckmarkIcon from '../../../Session/components/TimerSettings/assets/checkmark.svg';

export const Container = styled.div`
  position: relative;
  isolation: isolate;
  text-transform: lowercase;
  z-index: 1;
`;

export const Header = styled.div`
  text-transform: capitalize;
`;

export const Button = styled.button<{ hasBorder: boolean }>`
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.75rem;
  border: ${props => (props.hasBorder ? '2px solid white' : '2px solid transparent')};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  width: 100%;
  transition: 0.3s border ease-in-out;

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.5);
  }
`;

export const SortTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SortIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  margin-right: 0.75rem;
`;

export const ImageIcon = styled.img<{ isUp: boolean }>`
  width: 1rem;
  height: 1rem;
  object-fit: contain;
  margin-left: 0.5rem;
  transform: ${({ isUp }) => (isUp ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 0.2s ease;
`;

export const FilterContainer = styled.div<{ animationState: TransitionStatus }>`
  padding-top: 0.75rem;
  position: absolute;
  z-index: 1;
  left: 0;
  /* top: 0; */
  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0.2;
      case 'entered':
        return 1;
      case 'exiting':
        return 0.2;
      case 'exited':
        return 0;
    }
  }};
  transform: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return `translateY(-5px)`;
      case 'entered':
        return `translateY(0)`;
      case 'exiting':
        return `translateY(0)`;
      case 'exited':
        return `translateY(-5px)`;
    }
  }};
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  width: 75%;
`;

export const FilterContent = styled.div`
  min-width: 13rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 0.75rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
`;
