import { useQuery } from '@tanstack/react-query';
import { isOfflineError } from '../../../utils/isOfflineError';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { FastSubscriptionInfo } from './types';
import { useRequestHandler } from './useRequestHandler';

export const useFastSubscriptionInfo = (isEnabled: boolean) => {
  const getFastSubscriptionInfo = useRequestHandler();

  return useQuery<FastSubscriptionInfo>(
    Queries.getFastSubscriptionInfo,
    () => getFastSubscriptionInfo(),
    {
      onError: err => {
        if (isOfflineError(err)) return;
        Logger.error(new Error('Error getting fast subscription info'), {
          err,
        });
      },
      enabled: isEnabled,
    },
  );
};
