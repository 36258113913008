import { FontColors, Text } from '@Cortex';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';

import ChevronIcon from '../../../../../../assets/images/chevron_up_icon.svg';
import { ListGroup } from '../../../ListGroup';
import * as S from './FilterItem.styles';

export type FilterItemProps = {
  title: string;
  onClick: () => void;
  isExpanded?: boolean;
  'data-testid'?: string;
  items: string[];
  selectedItems: string[];
  isLastItem: boolean;
  onItemClick: (value: any) => void;
};

export function FilterItem({
  title,
  isExpanded = false,
  items,
  selectedItems,
  isLastItem,
  onClick,
  onItemClick,
  ...props
}: FilterItemProps) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <S.Container>
      <S.Text data-testid={props['data-testid']}>{title}</S.Text>
      <ListGroup checkedItems={selectedItems} items={items} onCheck={onItemClick} />
      {!isLastItem ? <S.Divider /> : null}
    </S.Container>
  );
}
