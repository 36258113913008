import { Transition } from 'react-transition-group';
import { FaqCard } from '../../components/FaqCard';
import { HeroCard } from '../../components/HeroCard';
import { InfoCard } from '../../components/InfoCard';
import { InfoCardTwo } from '../../components/InfoCardTwo';

import { QuestionCard } from '../../components/QuestionCard';
import { VideoCard } from '../../components/VideoCard';
import { OnboardingQuizQuestion, OnboardingCardType, OnboardingQuizAnswer } from '../../types';
import { isFaqCard } from '../../utils/isFaqCard';
import { isHeroCard } from '../../utils/isHeroCard';
import { isInfoCard } from '../../utils/isInfoCard';
import { isInfoCardTwo } from '../../utils/isInfoCardTwo';
import { isVideoCard } from '../../utils/isVideoCard';
import * as S from './QuizCards.styles';

type Props = {
  isLoading: boolean;
  isResponseCardsMode: boolean;
  activeStepIndex: number;
  currentCardIndex: number;
  currentResponseCardIndex: number;
  mountedCardIndex: number;
  mountedResponseCardIndex: number;
  numberOfSteps: number;
  cards: OnboardingQuizQuestion[];
  responseCards: OnboardingCardType[];
  onHandleMountCard: () => void;
  onHandleMountResponseCard: () => void;
  onClickBack?: () => void;
  onClickContinue: (
    questionIndex: number,
    answerIndex: number,
    responseCards?: OnboardingQuizAnswer['responseCards'],
    testimonials?: OnboardingQuizAnswer['testimonials'],
    benefits?: OnboardingQuizAnswer['benefits'],
    dynamicActivityId?: OnboardingQuizAnswer['dynamicActivityId'],
    userPropertyToSet?: OnboardingQuizAnswer['userPropertyToSet'],
  ) => () => void;
  onHandleNextResponseCard: () => void;
  onHandleSkipResponseCard: () => void;
};

export const QuizCardsPresentation = (props: Props) => {
  const {
    onHandleMountCard,
    onHandleMountResponseCard,
    activeStepIndex,
    cards,
    responseCards,
    currentCardIndex,
    currentResponseCardIndex,
    mountedCardIndex,
    mountedResponseCardIndex,
    numberOfSteps,
    isLoading,
    isResponseCardsMode,
    onClickBack,
    onClickContinue,
    onHandleNextResponseCard,
    onHandleSkipResponseCard,
  } = props;

  return (
    <S.Wrapper>
      {isLoading && <S.Loading />}
      {cards.map((card, index) => (
        <Transition
          key={`answer-${index}`}
          in={currentCardIndex === index && mountedCardIndex === index && !isResponseCardsMode}
          mountOnEnter
          timeout={100}
          unmountOnExit
          onExited={onHandleMountCard}
        >
          {state => (
            <S.AnimationContainer animationState={state}>
              <S.Content>
                <QuestionCard
                  activeStepIndex={activeStepIndex}
                  answers={card.answers}
                  description={card.description}
                  hasPrivacyNotice={card.hasPrivacyNotice}
                  image={card.image}
                  numberOfSteps={numberOfSteps}
                  questionIndex={index}
                  stepText={card.stepText}
                  title={card.title}
                  onClickBack={onClickBack}
                  onClickContinue={onClickContinue}
                />
              </S.Content>
            </S.AnimationContainer>
          )}
        </Transition>
      ))}
      {responseCards.map((card, index) => (
        <Transition
          key={`response-${index}`}
          in={
            currentResponseCardIndex === index &&
            mountedResponseCardIndex === index &&
            isResponseCardsMode
          }
          mountOnEnter
          timeout={100}
          unmountOnExit
          onExited={onHandleMountResponseCard}
        >
          {state => (
            <S.AnimationContainer animationState={state}>
              <S.Content>
                {isInfoCard(card) && (
                  <InfoCard
                    activeStepIndex={activeStepIndex}
                    body={card.body}
                    ctaButtonText={index === responseCards.length - 1 ? 'Continue' : 'Next'}
                    heading={card.heading}
                    image={card.image}
                    numberOfSteps={numberOfSteps}
                    title={card.title}
                    onClickNext={onHandleNextResponseCard}
                    onClickSkip={onHandleSkipResponseCard}
                  />
                )}
                {isInfoCardTwo(card) && (
                  <InfoCardTwo
                    activeStepIndex={activeStepIndex}
                    belowBodyImage={card.belowBodyImage}
                    body={card.body}
                    ctaButtonText={card.ctaButtonText}
                    heading={card.heading}
                    image={card.image}
                    isSkippable={card.isSkippable}
                    numberOfSteps={numberOfSteps}
                    stepText={card.stepText}
                    title={card.title}
                    onClickNext={onHandleNextResponseCard}
                    onClickSkip={onHandleSkipResponseCard}
                  />
                )}
                {isHeroCard(card) && (
                  <HeroCard
                    activeStepIndex={currentCardIndex}
                    body={card.body}
                    ctaButtonText={card.ctaButtonText}
                    desktopBackgroundImage={card.desktopBackgroundImage}
                    mobileBackgroundImage={card.mobileBackgroundImage}
                    numberOfSteps={numberOfSteps}
                    stepText={card.stepText}
                    title={card.title}
                    onClickNext={onHandleNextResponseCard}
                  />
                )}
                {isVideoCard(card) && (
                  <VideoCard
                    activeStepIndex={activeStepIndex}
                    ctaButtonText={index === responseCards.length - 1 ? 'Get Started' : 'Next'}
                    heading={card.heading}
                    image={card.image}
                    numberOfSteps={numberOfSteps}
                    title={card.title}
                    youtubeVideoId={card.youtubeVideoId}
                    onClickNext={onHandleNextResponseCard}
                    onClickSkip={onHandleSkipResponseCard}
                  />
                )}
                {isFaqCard(card) && (
                  <FaqCard
                    activeStepIndex={activeStepIndex}
                    body={card.body}
                    ctaButtonText={card.ctaButtonText || 'Continue'}
                    image={card.image}
                    items={card.items}
                    numberOfSteps={numberOfSteps}
                    stepText={card.stepText}
                    title={card.title}
                    onClickNext={onHandleNextResponseCard}
                  />
                )}
              </S.Content>
            </S.AnimationContainer>
          )}
        </Transition>
      ))}
    </S.Wrapper>
  );
};
