import { DEFAULT_OFFLINE_ERROR_MESSAGE } from '../constants';

export function isOfflineError(error: unknown) {
  return isReactQueryOfflineError(error) || isOurInternalOfflineError(error);
}

function isReactQueryOfflineError(error: unknown) {
  const isNotTypeError = !(error instanceof TypeError);

  if (isNotTypeError) {
    return false;
  }

  return error.message === 'Failed to fetch' || error.message === 'Load failed';
}

function isOurInternalOfflineError(error: unknown) {
  const isNotError = !(error instanceof Error);

  if (isNotError) {
    return false;
  }
  return error.message === DEFAULT_OFFLINE_ERROR_MESSAGE;
}
