import React from 'react';
import * as S from './NELInfo.styles';
import { TextBold, Text, FontColors, Button, ButtonVariants } from '@Cortex';

type Props = {
  onClick: () => void;
};

export const NELInfo = ({ onClick }: Props) => {
  return (
    <S.Container>
      <S.DescriptionText>
        The strength of neuromodulation embedded in the music. Low is best if you are overstimulated
        or anxious. High is best for ADHD brains, or if you need an extra boost of stimulation!
      </S.DescriptionText>
      <S.LinkWrapper onClick={onClick}>
        <S.LinkText>Read the Latest Science</S.LinkText>
        <S.LinkOutIcon />
      </S.LinkWrapper>
    </S.Container>
  );
};
