import { useTeams } from './useTeams';

export const useTeamSubscriptionInfo = () => {
  const { list, isFetched } = useTeams();

  return {
    hasTeamSubscription: list.some(s => s.hasPlatformAccess),
    belongsToTeam: Boolean(list.length),
    isDataReady: isFetched,
  };
};
