import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { usePost } from '../../client/client';
import { Endpoints } from '../../common/endpoints';
import { TEnableSubscriptionAutoRenewResponse } from './types';

export const useRequestHandler = () => {
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);
  const post = usePost<{}, TEnableSubscriptionAutoRenewResponse>();

  return useCallback(async () => {
    return await post({
      path: Endpoints.enableSubscriptionAutoRenew(),
      body: {
        userId: userId!,
      },
      options: { apiVersion: 2 },
    });
  }, [post]);
};
