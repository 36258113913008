import { FontColors } from '@Cortex';
import styled, { css, keyframes } from 'styled-components';

const WIDTH_OF_CURRENT_ACTIVITY_NAME = '12rem';
const HEIGHT_OF_CURRENT_ACTIVITY_NAME = '3.625rem';

export const Header = styled.span`
  color: ${FontColors.WhiteTransparent};
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 0.5rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
`;

export const Text = styled.span`
  color: white;
  flex: 1;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  margin-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const FadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ChevronIconContainer = styled.div<{ isDown?: boolean }>`
  transition: transform 0.3s ease-in-out;
  transform: rotate(${({ isDown = false }) => (isDown ? '180deg' : '0deg')});
  animation: ${FadeInAnimation} 0.2s ease-in-out;
`;

export const LoaderIconContainer = styled.div`
  opacity: 0.5;
  animation:
    rotate 1.2s infinite linear,
    ${FadeInAnimation} 0.2s ease-in-out;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  margin-top: 0.15rem;
  transition: opacity 0.2s ease-in-out;
`;

export const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  height: 100%;
  width: 100%;
  min-width: ${WIDTH_OF_CURRENT_ACTIVITY_NAME};
  min-height: ${HEIGHT_OF_CURRENT_ACTIVITY_NAME};
  overflow: hidden;
  border-radius: inherit;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    min-width: auto;
  }
`;

export const Selection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: ${WIDTH_OF_CURRENT_ACTIVITY_NAME};
`;

export const Skeleton = styled.div<{ isLoading?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 12px 16px 8px 16px;
  position: relative;
  overflow: hidden;

  ${({ isLoading }) => {
    if (isLoading) {
      return css`
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transform: translateX(-100%);
          background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.2) 20%,
            rgba(255, 255, 255, 0.3) 60%,
            rgba(255, 255, 255, 0)
          );
          animation: shimmer 2s infinite;
          content: '';
        }

        @keyframes shimmer {
          100% {
            transform: translateX(100%);
          }
        }
      `;
    }
  }}
`;
