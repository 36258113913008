import { useEffect } from 'react';
import { Analytics } from '../../../../../utils/analytics';
import { useShouldRenderReferAFriend } from './useShouldRenderReferAFriend';

export const useTrackImpressions = () => {
  const shouldRender = useShouldRenderReferAFriend();
  useEffect(() => {
    if (shouldRender) {
      Analytics.logEvent('player_refer_a_friend_banner_impression');
    }
  }, []);
};
