import * as S from './MembershipPanelAura.styles';

interface Props {
  outerBackground: string;
  innerBackground: string;
}

export const MembershipPanelAura = ({ outerBackground, innerBackground }: Props) => {
  return (
    <S.OuterCircle style={{ background: outerBackground }}>
      <S.InnerCircle style={{ background: innerBackground }} />
    </S.OuterCircle>
  );
};
