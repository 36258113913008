import { useCallback, useEffect, useState } from 'react';

export const useDynamicFontSize = () => {
  const [fontSize, setFontSize] = useState(16);

  const onResize = useCallback(() => {
    if (window.innerWidth < 576 && fontSize !== 12) {
      setFontSize(12);
    } else if (window.innerWidth >= 576 && fontSize !== 16) {
      setFontSize(16);
    }
  }, [fontSize]);

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return fontSize;
};
