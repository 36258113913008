import { Serving } from '@Model';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { useRequestHandler } from './useRequestHandler';

export const useTracksByMood = (mood: string) => {
  const dynamicMentalStateId = useSelector(
    (state: RootReducerType) => state.ui.sideDeck.exploreSelectedMentalState?.id,
  );

  const args = {
    dynamicMentalStateId: dynamicMentalStateId as string,
    mentalStateId: undefined,
  };
  const getTracksByMood = useRequestHandler();

  return useQuery<Serving[], Error>(
    Queries.getTracksByMood(mood, dynamicMentalStateId!),
    () => getTracksByMood(mood, args),
    {
      onError: () => {
        Logger.error(new Error('Error getting Tracks by Mood'));
      },
      enabled: Boolean(mood) && Boolean(dynamicMentalStateId),
    },
  );
};
