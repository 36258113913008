import { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

interface IconProps extends ImgHTMLAttributes<HTMLImageElement> {
  size: number;
  isSquare?: boolean;
}

const IconContainer = styled.div<IconProps>`
  width: ${({ size }) => `clamp(${size - 5}px, 8vw - 2rem, ${size}px)`};
  height: ${({ isSquare, size }) =>
    isSquare ? `clamp(${size - 5}px, 8vw - 2rem, ${size}px)` : 'auto'};
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const Icon: React.FC<IconProps> = ({ size = 16, isSquare = false, ...props }) => {
  return (
    <IconContainer isSquare={isSquare} size={size}>
      <Image {...props} />
    </IconContainer>
  );
};

export default Icon;
