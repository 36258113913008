import { Button, ButtonVariants } from '@Cortex';
import { NOOP } from '@Globals';
import React, { forwardRef } from 'react';
import { TransitionStatus } from 'react-transition-group';
import DiscountBadgeImage from '../../../../../../assets/images/discount_badge.svg';
import * as S from './DiscountOfferDetails.styles';
import { Assets } from '../../../../../../utils/assets';
import { ProfileFormContainer } from '../../../../../shared/ProfileFormContainer';

export interface Props {
  onClose?: () => void;
  onBack?: () => void;
  onContinue?: () => void;
  onCancelSubscription?: () => void;
  animationState: TransitionStatus;
}

export const DiscountOfferDetails = forwardRef<HTMLDivElement, Props>(
  (
    {
      onClose = NOOP,
      onContinue = NOOP,
      onBack = NOOP,
      onCancelSubscription = NOOP,
      animationState,
    },
    ref,
  ) => {
    return (
      <S.Container ref={ref} animationState={animationState}>
        <ProfileFormContainer onBack={onBack} onClose={onClose}>
          <S.Image alt={Assets.images.specialOffer.alt} src={Assets.images.specialOffer.url} />
          <S.AuraImage alt={Assets.images.purpleAura.alt} src={Assets.images.purpleAura.url} />
          <S.Title>
            Stay and get 50% off for 3 months!
            <br />
            Keep enjoying the power of Brain.fm.
          </S.Title>
          <S.Badge>
            <S.DiscountImage src={DiscountBadgeImage} />
            <S.AccentText>50% OFF</S.AccentText> for 3 months
          </S.Badge>
          <S.Footer>
            <S.ButtonWrapper>
              <Button
                isFullWidth
                keepTextCase
                variant={ButtonVariants.Secondary}
                onClick={onContinue}
              >
                ACCEPT THIS OFFER
              </Button>
            </S.ButtonWrapper>
            <S.ButtonWrapper>
              <Button
                data-testid="confirmCancelSubscriptionButton"
                isFullWidth
                keepTextCase
                variant={ButtonVariants.Base}
                onClick={onCancelSubscription}
              >
                PROCEED WITH CANCELLATION
              </Button>
            </S.ButtonWrapper>
          </S.Footer>
        </ProfileFormContainer>
      </S.Container>
    );
  },
);
