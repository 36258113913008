import { getIsImmediateCharge } from './getIsImmediateCharge';

export const getPaymentData = (currentPeriodEnd?: number) => {
  const date = new Date((currentPeriodEnd || 0) * 1000);

  if (!getIsImmediateCharge(currentPeriodEnd)) {
    return `renewal payment on ${date.toLocaleString('en-us', {
      month: 'long',
    })} ${date.getDate()}, ${date.getFullYear()}`;
  }

  return 'starts now';
};
