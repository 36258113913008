import { Button, ButtonVariants, Icon } from '@Cortex';
import * as S from './TrackInfoModal.styles';
import {
  getTrackBrightness,
  getTrackComplexity,
  getTrackGenreName,
  getTrackId,
  getTrackImageUrl,
  getTrackInstrumentations,
  getTrackIsNewlyCreated,
  getTrackMoods,
  getTrackName,
  getTrackNeuralEffectLevel,
  getTrackVariationId,
} from '../../lenses';
import { NeuralEffectLevelIcon } from '../NeuralEffectLevelIcon';
import { getTrackBeatsPerMinute } from '../../lenses/getTrackBeatsPerMinute';
import { HeartIcon } from '../../../../components/icons/HeartIcon';
import { ShareIcon } from '../../../../components/icons/ShareIcon';
import { ThumbsDownIcon } from '../../../../components/icons/ThumbsDownIcon';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerType } from '../../../../reducers';
import {
  addToDislikes,
  addToFavorites,
  removeFromDislikes,
  removeFromFavorites,
} from '../../actions';
import { isTrackVariationInFavorites } from '../../lenses/isTrackVariationInFavorites';
import { TrackActionOrigins } from '@Analytics';
import { useShareTrack } from '../../../../hooks/useShareTrack';
import { ActivityCard } from '../ActivityCard/ActivityCard';
import { getTrackRelatedActivities } from '../../lenses/getTrackRelatedActivities';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '../../../../assets/images/chevron_right_icon.svg';
import ChevronLeftIcon from '../../../../assets/images/chevron_left_icon.svg';
import { Transition } from 'react-transition-group';
import { useRef, useState } from 'react';
import { Serving, Track } from '@Model';

export type Props = {
  isMobileFixed?: boolean;
  isOpen: boolean;
  track: Track | Serving | null;
  variant?: TrackInfoModalVariant;
  width?: number;
  onClose: () => void;
};

export enum TrackInfoModalVariant {
  Default = 'Default',
  MoreInfo = 'MoreInfo',
}

export enum TrackInfoModalSection {
  Main = 'Main',
  Activities = 'Activities',
}

export function TrackInfoModal(props: Props) {
  const {
    isMobileFixed = false,
    width,
    isOpen,
    track,
    variant = TrackInfoModalVariant.Default,
    onClose,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleGetShareLink } = useShareTrack();
  const dislikedTrackIds = useSelector((state: RootReducerType) => state.music.dislikedTrackIds);
  const relatedActivities = getTrackRelatedActivities(track);
  const [currentPane, setCurrentPane] = useState<TrackInfoModalSection>(TrackInfoModalSection.Main);
  const mainRef = useRef<HTMLDivElement>(null);
  const activitiesRef = useRef<HTMLDivElement>(null);
  const trackId = getTrackId(track);
  const trackComplexity = getTrackComplexity(track);
  const trackBrightness = getTrackBrightness(track);

  const handleDislikeClick = () => {
    if (!track) return;
    if (dislikedTrackIds.includes(getTrackId(track))) {
      dispatch(removeFromDislikes({ trackId: getTrackId(track) }));
    } else {
      dispatch(addToDislikes({ track }));
    }
  };

  const isCurrentTrackVariationInFavorites = useSelector(
    isTrackVariationInFavorites({
      trackVariationId: getTrackVariationId(track),
    }),
  );

  const handleFavoriteClick = () => {
    if (!track) return;
    if (isCurrentTrackVariationInFavorites) {
      dispatch(removeFromFavorites({ track }));
    } else {
      //TODO - update origin to track info modal or something
      dispatch(addToFavorites({ origin: TrackActionOrigins.TrackPlayer, track }));
    }
  };

  const handleShareTrack = () => {
    if (!track) return;
    handleGetShareLink(track);
  };

  const handleActivityClickPlay = (activityId: string) => {
    navigate(`/player/${activityId}`);
  };

  return (
    <>
      {isOpen ? (
        <S.Overlay isMobileFixed={isMobileFixed} variant={variant} onClick={onClose}>
          <S.ContentWrapper
            isOpen={isOpen}
            variant={variant}
            width={width}
            onClick={e => e.stopPropagation()}
          >
            <Transition
              in={currentPane === TrackInfoModalSection.Main}
              mountOnEnter
              nodeRef={mainRef}
              timeout={0}
              unmountOnExit
            >
              {animationState => (
                <S.FadeInWrapper ref={mainRef} animationState={animationState}>
                  <S.TrackInfo>
                    <S.TrackDetails>
                      <S.TrackImageContainer>
                        <S.TrackImage src={getTrackImageUrl(track)} />
                      </S.TrackImageContainer>
                      <S.TrackInfoDetail>
                        <S.TrackDetailsTitleContainer>
                          <S.TrackDetailsTitle>{getTrackName(track)}</S.TrackDetailsTitle>
                          {getTrackIsNewlyCreated(track) && <S.NewTag>NEW</S.NewTag>}
                        </S.TrackDetailsTitleContainer>

                        <S.TrackDetailsNeuralEffect>
                          <NeuralEffectLevelIcon effectLevel={getTrackNeuralEffectLevel(track)} />
                          {getTrackNeuralEffectLevel(track)} Neural Effect
                        </S.TrackDetailsNeuralEffect>
                        <S.TrackDetailsGenreAndBPM>
                          {getTrackGenreName(track)}
                          {` • `}
                          {getTrackBeatsPerMinute(track)} BPM
                        </S.TrackDetailsGenreAndBPM>
                      </S.TrackInfoDetail>
                    </S.TrackDetails>
                    <S.TrackActions>
                      <S.TrackAction>
                        <S.TrackActionButton
                          data-testid={
                            dislikedTrackIds.includes(trackId)
                              ? `removeFromDislikes__${trackId}`
                              : `addToDislikes__${trackId}`
                          }
                          onClick={handleDislikeClick}
                        >
                          <ThumbsDownIcon
                            fillOpacity={dislikedTrackIds.includes(trackId) ? 1 : 0}
                          />
                        </S.TrackActionButton>
                        Dislike
                      </S.TrackAction>
                      <S.TrackAction>
                        <S.TrackActionButton
                          data-testid={
                            isCurrentTrackVariationInFavorites
                              ? `removeFromFavorites__${trackId}`
                              : `addToFavorites__${trackId}`
                          }
                          onClick={handleFavoriteClick}
                        >
                          <HeartIcon fillOpacity={isCurrentTrackVariationInFavorites ? 1 : 0} />
                        </S.TrackActionButton>
                        Favorite
                      </S.TrackAction>
                      <S.TrackAction>
                        <S.TrackActionButton onClick={handleShareTrack}>
                          <ShareIcon />
                        </S.TrackActionButton>
                        Share
                      </S.TrackAction>
                    </S.TrackActions>
                    <S.TrackAttributes>
                      <S.Row>
                        <S.Column>
                          <S.SectionTitle>Moods</S.SectionTitle>
                          <S.SectionDescription>
                            {getTrackMoods(track).join(', ')}
                          </S.SectionDescription>
                        </S.Column>

                        <S.Column>
                          <S.SectionTitle>Instrumentation</S.SectionTitle>
                          <S.SectionDescription>
                            {getTrackInstrumentations(track).join(', ')}
                          </S.SectionDescription>
                        </S.Column>
                      </S.Row>

                      <S.Row>
                        <S.Column>
                          <S.FrequencyTitleSection>
                            <S.SectionTitle>Complexity</S.SectionTitle>
                            <S.FrequencyLevelDescription>
                              {trackComplexity}
                            </S.FrequencyLevelDescription>
                          </S.FrequencyTitleSection>
                          <S.FrequencyLevelContainer>
                            <S.FrequencyLevelBar level={trackComplexity} />
                          </S.FrequencyLevelContainer>
                        </S.Column>
                        {trackBrightness ? (
                          <S.Column>
                            <S.FrequencyTitleSection>
                              <S.SectionTitle>Brightness</S.SectionTitle>
                              <S.FrequencyLevelDescription>
                                {trackBrightness}
                              </S.FrequencyLevelDescription>
                            </S.FrequencyTitleSection>
                            <S.FrequencyLevelContainer>
                              <S.FrequencyLevelBar level={trackBrightness} />
                            </S.FrequencyLevelContainer>
                          </S.Column>
                        ) : null}
                      </S.Row>
                    </S.TrackAttributes>
                  </S.TrackInfo>
                  {relatedActivities && relatedActivities.length > 0 ? (
                    <S.ActivityContainer>
                      <S.SectionTitle>Activities</S.SectionTitle>
                      <S.ActivitiesSection>
                        {relatedActivities.map(activity => (
                          <ActivityCard
                            key={activity.id}
                            dynamicActivity={activity}
                            onClick={() => handleActivityClickPlay(activity.id)}
                          />
                        ))}
                      </S.ActivitiesSection>
                      {relatedActivities.length > 4 ? (
                        <S.ActivityViewMoreContainer
                          onClick={() => setCurrentPane(TrackInfoModalSection.Activities)}
                        >
                          <S.ActivityViewMoreInnerContainer>
                            <S.ViewMoreText>View more</S.ViewMoreText>
                            <Icon size={20} src={ChevronRightIcon} />
                          </S.ActivityViewMoreInnerContainer>
                        </S.ActivityViewMoreContainer>
                      ) : null}
                    </S.ActivityContainer>
                  ) : null}
                </S.FadeInWrapper>
              )}
            </Transition>

            <Transition
              in={currentPane === TrackInfoModalSection.Activities}
              mountOnEnter
              nodeRef={activitiesRef}
              timeout={0}
              unmountOnExit
            >
              {animationState => (
                <S.FadeInWrapper ref={activitiesRef} animationState={animationState}>
                  <S.ActivityHeader>
                    <Icon
                      size={24}
                      src={ChevronLeftIcon}
                      onClick={() => setCurrentPane(TrackInfoModalSection.Main)}
                    />
                    <S.ActivityHeaderTitle>
                      Activities for "{getTrackName(track)}"
                    </S.ActivityHeaderTitle>
                    <S.EmptyIcon>
                      <Icon size={24} src={ChevronLeftIcon} />
                    </S.EmptyIcon>
                  </S.ActivityHeader>
                  {relatedActivities && relatedActivities.length > 0 ? (
                    <S.ActivitiesSection>
                      {relatedActivities.map(activity => (
                        <ActivityCard
                          key={activity.id}
                          dynamicActivity={activity}
                          onClick={() => handleActivityClickPlay(activity.id)}
                        />
                      ))}
                    </S.ActivitiesSection>
                  ) : null}
                </S.FadeInWrapper>
              )}
            </Transition>

            <S.ButtonWrapper>
              <Button
                data-testid="trackCardMoreInfoCloseButton"
                isFullWidth
                keepTextCase
                style={{ fontSize: '0.75rem' }}
                type="button"
                variant={ButtonVariants.Secondary}
                onClick={onClose}
              >
                CLOSE
              </Button>
            </S.ButtonWrapper>
          </S.ContentWrapper>
        </S.Overlay>
      ) : null}
    </>
  );
}
