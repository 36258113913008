import { Text } from '@Cortex';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  height: 4.75rem;
`;

export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
  padding: 1rem;
  flex-direction: column;
  transition: background-image 0.2s ease-in-out;
  justify-content: center;

  &:hover {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.08) 100%
    );
  }
`;

export const Title = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 0.875;
  font-weight: 500;
  text-transform: none;
  width: 70%;
`;

export const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.75;
  margin-bottom: 2px;
`;

export const TimerText = styled(Text)`
  font-size: 0.75rem;
`;

export const Image = styled.img`
  height: 156px;
  width: 156px;
  position: absolute;
  top: 50%;
  right: -8rem;
  transform: translate(-50%, -50%);
`;

export const TimerIcon = styled.img`
  height: 1rem;
  width: 1rem;
  margin-right: 4px;
`;
