import { InputField } from '@Cortex';
import styled, { css } from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const CurrentPaymentMethod = styled.div`
  color: #d4d2ea;
  font-size: 0.95rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const CardInformation = styled.span`
  color: white;
`;

export const CardInputContainer = styled.div`
  backdrop-filter: blur(34px);
  background: rgba(25, 23, 54, 0.75);
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
`;

export const SubmitButton = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const NameInputContainer = styled.div`
  border-radius: 0.75rem;
  margin-bottom: 0.5rem;
  position: relative;
`;

export const NameInputLabel = styled.label<{ isError: boolean }>`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.75rem;
  color: ${({ isError }) => (isError ? 'red' : '#fff')};
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const NameInput = styled(InputField)`
  border: none;
  background: none;
  width: 100%;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  text-transform: capitalize;

  &:focus {
    border: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    font-size: 1rem;
  }
  &::placeholder {
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.5);
    font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const InputContainer = styled.div<{ variant: 'default' | 'first' | 'last' | 'individual' }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.75rem 1rem;
  height: 4.125rem;
  justify-content: center;
  ${props =>
    (props.variant === 'first' || props.variant === 'individual') &&
    css`
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    `}
  ${props =>
    (props.variant === 'last' || props.variant === 'individual') &&
    css`
      margin-top: 1px;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    `}
  ${props =>
    props.variant === 'default' &&
    css`
      margin-top: 1px;
    `}
`;

export const ErrorBox = styled.div`
  position: relative;
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorText = styled.span`
  position: absolute;
  top: 0;
  color: #f34675;
  font-size: ${({ theme }) => theme.fontSize.extraSmall};
`;
