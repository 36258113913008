import { Serving } from '@Model';
import { getTrackId } from '@Music';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { useRequestHandler } from './useRequestHandler';

export const useSimilarTracks = () => {
  const currentTrack = useSelector((state: RootReducerType) => state.music.currentTrack);
  const getGenreNames = useRequestHandler();
  const currentTrackId = getTrackId(currentTrack);

  return useQuery<Serving[]>(
    Queries.getSimilarTracks(currentTrackId),
    () => getGenreNames(currentTrackId),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        Logger.error(new Error('Error getting Similar Tracks'));
      },
      enabled: Boolean(currentTrackId),
    },
  );
};
