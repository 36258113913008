export const DYNAMIC_PLAYER_BASE_PATH = '/player';
export const DYNAMIC_PLAYER_ACTIVITY_PATH = `${DYNAMIC_PLAYER_BASE_PATH}/:activityId`;
export const DA_PREFIXES = {
  SIMILAR: 'similar_',
  FAVORITES: 'favorites_',
  GENRE_NAMES: 'genre_name_',
  MOOD: 'mood_',
};
export const STORAGE_REDIRECT_PATH_KEY = 'redirectPath';

export const DEFAULT_ERROR_MESSAGE =
  'There might be a server issue. Please try again in a little bit.';
export const DEFAULT_OFFLINE_ERROR_MESSAGE =
  'It looks like you may be offline! Please connect to the Internet and try again.';
