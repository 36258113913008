import {
  Button,
  ButtonVariants,
  ButtonVariantStates,
  FontColors,
  Icon,
  Text,
  TextSemiBold,
} from '@Cortex';
import { CouponCode } from '@Payment';
import React, { useState } from 'react';
import Logo from '../../../../../../../../assets/images/brand_logo.svg';

import { EnhancedMembershipPlanType } from '../../../../../../../../types';
import { GiftCard } from '../../../../../GiftCard';
import { Testimonials } from '../Testimonials/Testimonials';
import * as S from './SingleScreenSlideUpPaywallPlanSelection.styles';
import { Checkmark } from '../Checkmark/Checkmark';
import CloseIcon from '../../../../../../../../assets/images/close_icon.svg';
import { OnboardingTestimonials } from '../../../../../../../Onboarding/types';
import { PlanCard } from '../PlanCard/PlanCard';
import { useLocation, useNavigate } from 'react-router-dom';

export interface Props {
  billingName?: string;
  billingInterval?: string;
  buttonSubtitle?: string;
  discountPercentage: number;
  hasDiscount: boolean;
  isExtendedPromo?: boolean;
  isCloseButtonAvailable: boolean;
  isStripeCheckoutEnabled: boolean;
  onClose: () => void;
  onPlanSelect: (plan: EnhancedMembershipPlanType) => void;
  onSubmit: () => void;
  onSubmitStripeCheckout: (value: string) => void;
  paywallBenefits: string[];
  paywallTestimonials: OnboardingTestimonials[];
  plans: EnhancedMembershipPlanType[];
  selectedPlan: EnhancedMembershipPlanType | null;
  variantState: ButtonVariantStates;
}

export const SingleScreenSlideUpPaywallPlanSelectionDisplay: React.FC<Props> = ({
  billingName,
  buttonSubtitle,
  discountPercentage,
  hasDiscount,
  isExtendedPromo = false,
  isCloseButtonAvailable,
  isStripeCheckoutEnabled,
  onClose,
  onPlanSelect,
  onSubmit,
  onSubmitStripeCheckout,
  paywallBenefits,
  paywallTestimonials,
  plans,
  selectedPlan,
  variantState,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [couponSectionVisible, setCouponSectionVisible] = useState(false);

  const shouldRedirectBack = Boolean(location.state?.redirectBack);

  return (
    <S.Container>
      <S.HeaderWrapper>
        <S.HeaderSpacer />
        <S.HeaderIconContainer>
          <Icon size={56} src={Logo} />
        </S.HeaderIconContainer>
        {!isCloseButtonAvailable ? null : (
          <S.CloseIconContainer
            onClick={shouldRedirectBack ? () => navigate(location.state?.redirectBack) : onClose}
          >
            <S.CloseIcon src={CloseIcon} />
          </S.CloseIconContainer>
        )}
      </S.HeaderWrapper>

      <S.PaymentContainer>
        <S.LeftBoxContainer>
          <S.LeftBoxHeaderIconContainer>
            <Icon size={56} src={Logo} />
          </S.LeftBoxHeaderIconContainer>
          <S.Title size="3rem">
            {selectedPlan?.trialLength ? 'Start listening free' : 'Stay in the zone'}
          </S.Title>
          <S.Checkmarks>
            {paywallBenefits.map(benefit => (
              <Checkmark key={benefit}>{benefit}</Checkmark>
            ))}
          </S.Checkmarks>

          <Testimonials paywallTestimonials={paywallTestimonials} />
        </S.LeftBoxContainer>

        <S.RightBoxContainer>
          <S.CardHeader>
            {plans.map(plan => (
              <PlanCard
                key={plan.id}
                discountPercentage={discountPercentage}
                hasDiscount={hasDiscount}
                isExtendedPromo={isExtendedPromo}
                isSelected={selectedPlan?.id === plan.id}
                plan={plan}
                onClick={() => onPlanSelect(plan)}
              />
            ))}
          </S.CardHeader>
          <S.ButtonContainer>
            <Button
              data-testid="handlePurchase"
              isFullWidth
              keepTextCase
              type="button"
              variant={ButtonVariants.Secondary}
              variantState={variantState}
              onClick={
                isStripeCheckoutEnabled ? () => onSubmitStripeCheckout(billingName || '') : onSubmit
              }
            >
              {isExtendedPromo && selectedPlan?.trialLength
                ? `Get ${selectedPlan.trialLength} Days Free`
                : selectedPlan?.trialLength
                  ? 'Try for free'
                  : 'Select Plan'}
            </Button>
          </S.ButtonContainer>
          {buttonSubtitle ? <Text color={FontColors.WhiteSecondary}>{buttonSubtitle}</Text> : null}
          <S.CouponContainer>
            {!couponSectionVisible ? (
              <S.CouponText>
                Have a gift card or coupon code?
                <S.CouponButton
                  data-testid="displayCouponForm"
                  type="button"
                  onClick={() => setCouponSectionVisible(true)}
                >
                  Click here
                </S.CouponButton>
              </S.CouponText>
            ) : null}

            {couponSectionVisible ? (
              <S.CouponGiftCardContainer>
                <S.CouponSection>
                  <S.CouponTextWrapper>
                    <TextSemiBold>Coupon Code</TextSemiBold>
                  </S.CouponTextWrapper>
                  <S.CouponSectionInputWrapper>
                    <CouponCode layout="modern" />
                  </S.CouponSectionInputWrapper>
                </S.CouponSection>

                <S.CouponSection>
                  <S.CouponTextWrapper>
                    <TextSemiBold>Gift Card</TextSemiBold>
                  </S.CouponTextWrapper>
                  <S.CouponSectionInputWrapper>
                    <GiftCard layout="modern" />
                  </S.CouponSectionInputWrapper>
                </S.CouponSection>
              </S.CouponGiftCardContainer>
            ) : null}
          </S.CouponContainer>
        </S.RightBoxContainer>
      </S.PaymentContainer>
    </S.Container>
  );
};
