import { Icon } from '@Cortex';

import CheckMarkSvg from '../../../../../assets/images/checkmark_icon.svg';
import * as S from './Checkmark.styles';

interface Props {
  title: string;
  description: string;
}

export const Checkmark = ({ title, description }: Props) => {
  return (
    <S.Container>
      <S.CheckMarkIconContainer>
        <S.IconContainer>
          <Icon isSquare size={18} src={CheckMarkSvg} />
        </S.IconContainer>
      </S.CheckMarkIconContainer>
      <S.TextContainer>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.TextContainer>
    </S.Container>
  );
};
