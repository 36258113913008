import { NOOP } from '@Globals';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../../reducers';
import { VerificationStatus } from '../../../../types';
import { ProfileInfoDisplay, Props } from './ProfileInfo.display';

type ProfileInfoProps = Pick<Props, 'onEditProfile' | 'onPasswordChange'>;

export const ProfileInfo = ({
  onEditProfile = NOOP,
  onPasswordChange = NOOP,
}: ProfileInfoProps) => {
  const { info, verification } = useSelector((state: RootReducerType) => state.user);
  const authType = useSelector((state: RootReducerType) => state.auth.type);
  const isVisibleVerificationData =
    Boolean(verification) && verification?.status !== VerificationStatus.Excluded;

  return (
    <ProfileInfoDisplay
      authType={authType || 'email'}
      email={info?.email || ''}
      isVerified={Boolean(verification?.isVerified)}
      isVisibleVerificationData={isVisibleVerificationData}
      name={info?.firstName || ''}
      onEditProfile={onEditProfile}
      onPasswordChange={onPasswordChange}
    />
  );
};
