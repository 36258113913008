import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MembershipPlansStateType, MembershipPlansNormalized } from '../types/membershipPlans';

const initialState: MembershipPlansStateType = {
  status: 'idle',
  plans: {
    ids: [],
    results: {},
  },
  error: null,
};

const membershipPlansSlice = createSlice({
  name: 'membershipPlans',
  initialState,
  reducers: {
    getPlans: state => {
      state.status = 'loading';
      state.error = null;
      return state;
    },

    getPlansSuccess: (state, action: PayloadAction<MembershipPlansNormalized>) => {
      state.plans = action.payload;
      state.error = null;
      state.status = 'idle';
      return state;
    },

    getPlansFailed: (state, action: PayloadAction<string>) => {
      state.status = 'error';
      state.error = action.payload;
      return state;
    },
  },
});

export const membershipPlansSliceActions = membershipPlansSlice.actions;
export default membershipPlansSlice.reducer;
