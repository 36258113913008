import { Serving, Track } from '@Model';
import {
  getTrackActivityId,
  getTrackGenreName,
  getTrackHasMultipleNELs,
  getTrackName,
  getTrackNeuralEffectLevel,
  SortBy,
  sortByTrackIsNewlyRelease,
  sortByTrackName,
  useActivityFilters,
} from '@Music';
import { useSelector } from 'react-redux';
import { RootReducerType } from 'src/reducers';
import { SortSelectBy } from '../components/SortSelect/SortSelect.types';

export function useApplyFiltersToTracks(tracks?: (Track | Serving)[]) {
  const selectedActivityIds = useActivityFilters().map(x => x._id);
  const selectedNeuralEffectLevels = useSelector(
    (state: RootReducerType) => state.music.neuralEffectFilters,
  );
  const sortByFilters = useSelector((state: RootReducerType) => state.music.sortByFilters);
  const genreFilters = useSelector((state: RootReducerType) => state.music.genreFilters);

  let filteredData = [...(tracks || [])];

  if (selectedActivityIds.length > 0) {
    filteredData = filteredData.filter(x => selectedActivityIds.includes(getTrackActivityId(x)));
  }

  if (selectedNeuralEffectLevels.length > 0) {
    filteredData = filteredData.filter(
      x =>
        getTrackHasMultipleNELs(x) ||
        selectedNeuralEffectLevels.includes(getTrackNeuralEffectLevel(x)),
    );
  }

  if (genreFilters.length > 0) {
    filteredData = filteredData.filter(x => genreFilters.includes(getTrackGenreName(x)));
  }

  if (sortByFilters.includes(SortBy.Title) || sortByFilters.includes(SortSelectBy.Title)) {
    filteredData.sort(sortByTrackName);
  }

  if (sortByFilters.includes(SortSelectBy.RecentlyReleased)) {
    filteredData.sort(sortByTrackIsNewlyRelease);
  }

  return filteredData;
}
