import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { FontColors } from '@Cortex';
import InfiniteIconSVG from './assets/infinite.svg?react';
import TimerIconSVG from './assets/timer.svg?react';
import IntervalIconSVG from './assets/interval.svg?react';
import InformationIconSVG from './assets/information.svg?react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  width: 100%;
  height: 100%;
  padding: 2rem;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 30rem;
  max-width: 480px;
  justify-items: center;
`;

export const HeadingContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  padding: 10px 0;
`;

export const TimerOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

export const TimerOption = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 1rem;
  border: 2px solid transparent;
  transition: color 0.2s ease-in-out;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: 0.625rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${FontColors.WhiteTransparent};
  cursor: pointer;

  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        border: 2px solid ${FontColors.White};
        border-radius: 12px;
        color: ${FontColors.White};
      `;
    }
  }}
`;

const BaseIcon = styled.svg<{ active: boolean }>`
  fill-opacity: ${({ active }) => (active ? 1 : 0.5)};
  fill: white;
  transition: fill-opacity 0.15s ease-in-out;
`;

export const InformationIcon = styled(InformationIconSVG)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const InfiniteIcon = styled(BaseIcon).attrs({ as: InfiniteIconSVG })``;

export const TimerIcon = styled(BaseIcon).attrs({ as: TimerIconSVG })``;

export const IntervalIcon = styled(BaseIcon).attrs({ as: IntervalIconSVG })``;

export const FadeInWrapper = styled.div<{ animationState: TransitionStatus }>`
  overflow: auto;
  animation: fadeInDown 0.3s ease-in-out;
  visibility: ${({ animationState }) => (animationState === 'entered' ? 'visible' : 'hidden')};
  padding-right: 0.25rem;
  padding-bottom: 12px;
  margin-bottom: 60px;

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
`;

export const DescriptionText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${FontColors.WhiteTransparent};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
`;

export const SwitchSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const TimerTimeTab = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`;

export const TimerTimeOption = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  padding-bottom: 0.75rem;
  border-bottom: ${({ isSelected }) => (isSelected ? '2px solid white' : 'none')};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: ${({ isSelected }) => (isSelected ? '500' : '400')};
  font-size: 1rem;
  line-height: 24px;
  color: ${({ isSelected }) => (isSelected ? `${FontColors.White}` : FontColors.WhiteTransparent)};
  transition:
    color 0.2s ease-in-out,
    font-weight 0.2s ease-in-out;
  cursor: pointer;
`;

export const IntervalContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 15px;
`;

export const ChimeSettingsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 15px;

  justify-content: space-between;
  color: white;
`;

export const IntervalSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const IntervalText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${FontColors.White};
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 1rem;
`;

export const ButtonsContainer = styled.div`
  position: sticky;
  width: 100%;
  height: 9rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px 40px 32px 40px;
  justify-content: center;
  gap: 8px;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 65.54%, #000000 100%);
`;
