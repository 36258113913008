import { useSearchParams, createSearchParams } from 'react-router-dom';
import { useTeamSubscriptionInfo } from '../../../hooks/useHasTeamSubscription';
import { useSelector } from 'react-redux';
import { RootReducerType } from 'src/reducers';
import { MembershipStates, useIsTrialUser } from '@Memberships';

export const usePostOnboardingPath = () => {
  const { hasTeamSubscription } = useTeamSubscriptionInfo();
  const membershipState = useSelector((state: RootReducerType) => state.membership.state);
  const isTrialUser = useIsTrialUser();
  const isActiveNonTrialUser = membershipState === MembershipStates.Active && !isTrialUser;
  const [searchParams] = useSearchParams();
  const queryParams = createSearchParams(searchParams).toString();

  return hasTeamSubscription || isActiveNonTrialUser
    ? '/'
    : `/payment${queryParams ? `?${queryParams}` : ''}`;
};
