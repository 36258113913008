import * as S from './MultiNelIcon.styles';

export const MultiNelIcon = () => {
  return (
    <S.Container data-testid="multiNelIcon">
      <S.TopBar />
      <S.MiddleBar />
      <S.BottomBar />
    </S.Container>
  );
};
