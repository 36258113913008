import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1.5rem;
  max-width: 31rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.5);
  background: linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1);
  border-radius: 1.25rem;

  @supports (backdrop-filter: blur(1rem)) {
    backdrop-filter: blur(1rem);
    background: linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1);
  }
`;

export const Header = styled.div`
  display: flex;
  height: 10rem;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 1.75rem;
`;

export const ImageButton = styled.button`
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
`;

export const Close = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

export const Image = styled.img`
  position: absolute;
  top: 0rem;
  height: 13.5rem;
`;

export const Title = styled.h1`
  color: #fff;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: center;
`;

export const Description = styled.p`
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  font-size: 0.875rem;
  line-height: 1.25rem;
`;
