import { ProfileSection } from '../../types';

export type ProfileItemType = {
  type: ProfileItemActionType;
  screen?: ProfileSection;
  iconSrc: string;
  testid?: string;
  urlPath?: string | null;
  label: string;
  href?: string;
};

export enum ProfileItemActionType {
  Screen = 'Screen',
  Linkout = 'Linkout',
  email = 'email',
}
