type Word = 'days' | 'hours' | 'hrs';

const SINGULAR: { [key in Word]: string } = {
  days: 'day',
  hours: 'hour',
  hrs: 'hr',
};

export function pluralize(word: Word, count: number, shouldIncludeCount: boolean = true) {
  if (shouldIncludeCount) {
    return count === 1 ? `1 ${SINGULAR[word]}` : `${count} ${word}`;
  }

  return count === 1 ? SINGULAR[word] : word;
}
