import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../../reducers';
import { MentalStates } from '../../../../types';
import { useDynamicSession } from '../../lenses/useDynamicSession';
import { WelcomeTexts } from './constants';
import { SessionProTipDisplay } from './SessionProTip.display';

interface Props {
  onClose?: () => void;
}

export const SessionProTip = (props: Props) => {
  const name = useSelector((state: RootReducerType) => state.user?.info?.firstName);
  const welcomeText = useWelcomeText();
  return <SessionProTipDisplay name={name} welcomeText={welcomeText} onClose={props.onClose} />;
};

function useWelcomeText() {
  const dynamicSession = useDynamicSession();

  const mentalStateId = dynamicSession?.mentalState.id;

  switch (mentalStateId) {
    case MentalStates.Sleep:
      return WelcomeTexts[MentalStates.Sleep];
    case MentalStates.Relax:
      return WelcomeTexts[MentalStates.Relax];
    case MentalStates.Meditate:
      return WelcomeTexts[MentalStates.Meditate];
    case MentalStates.Focus:
      return WelcomeTexts[MentalStates.Focus];
    case MentalStates.Workout:
      return WelcomeTexts[MentalStates.Workout];
    default:
      return 'Loading...';
  }
}
