import { Text } from '@Cortex';
import styled from 'styled-components';

import { FORM_PADDING } from '../../constants';

export const SignUpForm = styled.div`
  align-items: center;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 100dvh;
  overflow-y: scroll;
  width: 100%;
  position: relative;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    overflow-y: inherit;
  }
`;

export const TopAuraImage = styled.img`
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: translate(-50%, -50%);
  z-index: -1;
`;

export const BottomAuraImage = styled.img`
  bottom: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  transform: translate(50%, 50%);
  z-index: -1;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Button = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-basis: 48%;
`;

export const IconContainer = styled.div`
  /* flex: 1; */
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SigninText = styled(Text)`
  color: rgba(255, 255, 255, 0.75);
  font-size: 1rem;
  margin-right: 0.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    display: none;
  }
`;

export const SignInButton = styled.button`
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  letter-spacing: 1px;
  padding: 13px 24px;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const IconImage = styled.img`
  height: 32px;
  width: auto;
  object-fit: contain;
`;

export const FormWrapper = styled.div`
  width: 100%;
`;

export const Form = styled.div`
  margin-bottom: 1.5rem;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
`;

const Link = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const LinkLast = styled(Link)`
  margin-bottom: 0;
`;

export const SignUpWithWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1.5rem;
`;

export const HR = styled.hr`
  color: rgba(255, 255, 255, 1);
  opacity: 0.2;
  width: 100%;
`;

export const SignUpWithText = styled(Text)`
  flex-shrink: 0;
  padding: 0 1rem;
  text-align: center;
  opacity: 0.75;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
`;

export const FormHeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  gap: 8px;
`;

export const LargeText = styled.h1`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
`;

export const DescriptionText = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white700};
  text-align: center;
`;

export const Content = styled.div`
  display: flex;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    flex-direction: column;
  }
`;

export const ContentColumn = styled.div`
  display: block;
  width: 50%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    width: 100%;
  }
`;

export const ContentColumnCarousel = styled(ContentColumn)`
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    display: none;
  }
`;

export const FormBlurView = styled.div`
  backdrop-filter: blur(10px);
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.01));
  border-radius: 1.5rem;
  padding: 2rem;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    padding: 24px 16px;
  }
`;

export const VerticalSeparator = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 0 ${FORM_PADDING};

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    /* surprise! it's now a horizontal separator */
    margin: 0 0 2rem;
  }
`;

export const WidthContainer = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 2.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    padding: 1rem;
  }
`;

export const DisclaimerWrapper = styled.div`
  padding-bottom: 2rem;
`;
