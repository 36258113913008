import { useCallback } from 'react';

import { useGet } from '../../client/client';
import { Endpoints } from '../../common/endpoints';
import { TDynamicMentalStateMoods } from './types';

export const useRequestHandler = () => {
  const get = useGet<TDynamicMentalStateMoods>();

  return useCallback(
    async (dynamicMentalStateId: string) => {
      const data = await get({
        path: Endpoints.getMoodsByDynamicMentalState(dynamicMentalStateId),
        options: { apiVersion: 3 },
      });

      return data.result;
    },
    [get],
  );
};
