import styled from 'styled-components';

import AlertIcon from '../../../../assets/images/alert_icon.svg';

export const InputLabel = styled.label`
  color: #fff;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  text-transform: lowercase;
  margin-bottom: 0.5rem;
`;

export const InputField = styled.input`
  padding: 0.75rem 1rem;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 0.875rem;
  color: #d4d2ea;
  border: 1px solid #373e5b;
  backdrop-filter: blur(34px);
  border-radius: 0.75rem;
  height: 2.75rem;
  background: none;
  outline: none;
  transition:
    border 0.2s ease-in-out,
    background 0.3s ease-in-out;

  background-image: url(${AlertIcon});
  background-repeat: no-repeat;
  background-size: 1.25rem;

  &:focus {
    border: 1px solid #fff;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    box-shadow: 0 0 0 300px ${({ theme }) => theme.colors.brandPrimary} inset;
    animation-name: autofill;
    animation-fill-mode: both;
    border-radius: 0.75rem;
    font-size: 0.875rem;
    -webkit-text-fill-color: #fff;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
    opacity: 0.8;
    text-transform: lowercase;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 1.5rem;
  position: relative;
`;

export const ErrorText = styled.span`
  bottom: 0;
  color: #f34675;
  font-size: ${({ theme }) => theme.fontSize.extraSmall};
  left: 0.5rem;
  position: absolute;
  text-transform: capitalize;
`;

export const RightSideContent = styled.div`
  bottom: 2.2rem;
  position: absolute;
  right: 2px;
  bottom: 0;
  height: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  color: white;
  position: absolute;
  right: 1rem;
  bottom: 0.75rem;
  height: 1.25rem;
  width: 1.25rem;
  cursor: pointer;
  z-index: 1;
  opacity: 0.75;
  transition:
    opacity 0.3s ease-in-out,
    right 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;
