import styled, { css, keyframes } from 'styled-components';

import AlertIcon from '../../../../assets/images/alert_icon.svg';

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const InputLabel = styled.label<{ inputIsFocused: boolean }>`
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  position: absolute;
  animation: ${FadeIn} 0.3s ease-in;
  transition:
    top 0.3s ease-in-out,
    color 0.3s ease-in-out,
    font-size 0.3s ease-in-out;

  ${({ inputIsFocused }) => {
    if (inputIsFocused) {
      return css`
        color: rgba(255, 255, 255, 0.75);
        top: 12px;
        font-size: 12px;
      `;
    }
  }}
`;

export const InputWrapper = styled.div`
  margin-top: 12px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InputField = styled.input`
  flex: 1;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  color: #ffffff;
  border: none;
  background: none;
  outline: none;
  transition:
    border 0.2s ease-in-out,
    background 0.3s ease-in-out;

  background-image: url(${AlertIcon});
  background-repeat: no-repeat;
  background-size: 1.25rem;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    box-shadow: 0 0 0 300px rgba(0, 0, 0, 0.2) inset;
    animation-name: autofill;
    animation-fill-mode: both;
    border-radius: 0.75rem;
    font-size: 1rem;
    -webkit-text-fill-color: #fff;
  }

  &::placeholder {
    font-size: 1rem;
    color: #ffffff;
    font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
    opacity: 0.5;
    text-transform: lowercase;
  }
`;

export const InputContainer = styled.div<{
  hasRoundedTop: boolean;
  hasRoundedBottom: boolean;
}>`
  @supports (not (-webkit-appearance: none)) and (stroke-color: transparent) {
    backdrop-filter: blur(34px);
  }

  background: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  display: flex;
  height: 66px;
  outline: none;
  padding: 12px 1rem;
  position: relative;
  text-align: left;
  width: 100%;

  /* -webkit-backdrop-filter: blur(34px); */

  border-top-left-radius: ${({ hasRoundedTop }) => (hasRoundedTop ? '1rem' : '0')};
  border-top-right-radius: ${({ hasRoundedTop }) => (hasRoundedTop ? '1rem' : '0')};
  border-bottom-left-radius: ${({ hasRoundedBottom }) => (hasRoundedBottom ? '1rem' : '0')};
  border-bottom-right-radius: ${({ hasRoundedBottom }) => (hasRoundedBottom ? '1rem' : '0')};
  margin-bottom: ${({ hasRoundedTop, hasRoundedBottom }) =>
    !(hasRoundedBottom && hasRoundedTop) ? '1px' : '0'};
  border-bottom-width: 2px;
  border-color: transparent;
  border-style: solid;

  transition: background 0.2s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    opacity: 1;
    cursor: text;
  }

  &:focus-within {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const ErrorText = styled.span`
  top: 12px;
  color: #f34675;
  font-size: ${({ theme }) => theme.fontSize.extraSmall};
  position: absolute;
  text-transform: capitalize;
  animation: ${FadeIn} 0.3s ease-in;
`;

export const Image = styled.img`
  color: white;
  position: absolute;
  right: 1rem;
  height: 1.25rem;
  width: 1.25rem;
  cursor: pointer;
  z-index: 1;
  opacity: 0.75;
  transition:
    opacity 0.3s ease-in-out,
    right 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;
