import { getTrackImageUrl, getTrackName, useGetCurrentTrack } from '@Music';
import { useEffect } from 'react';

// TODO: find a way to get typescript working correctly with mediaSession API.

export function useIntegrateWithMediaSessionAPI() {
  useAttachHandlersToMediaSessionAPI();
  useUpdateMediaSessionMetadataOnTrackChange();
}

function useAttachHandlersToMediaSessionAPI() {
  useEffect(() => {
    // @ts-ignore
    if (navigator.mediaSession) {
      // @ts-ignore
      navigator.mediaSession.setActionHandler('play', emitPlayEvent);
      // @ts-ignore
      navigator.mediaSession.setActionHandler('pause', emitPauseEvent);
    }
  }, []);
}

function useUpdateMediaSessionMetadataOnTrackChange() {
  const currentTrack = useGetCurrentTrack();

  useEffect(() => {
    // @ts-ignore
    if (!currentTrack || !navigator.mediaSession) return;

    // @ts-ignore
    navigator.mediaSession.metadata = new MediaMetadata({
      album: 'brain.fm',
      artist: 'brain.fm',
      artwork: [
        {
          src: getTrackImageUrl(currentTrack),
          type: 'image/png',
        },
      ],
      title: getTrackName(currentTrack).toLowerCase(),
    });
  }, [currentTrack]);
}

function emitPauseEvent() {
  emitEvent('pause');
}

function emitPlayEvent() {
  emitEvent('resume');
}

function emitEvent(eventName: string) {
  window.dispatchEvent(new Event(eventName));
}
