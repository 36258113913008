import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const ContainerWithIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 40rem;
  max-width: 55rem;
  margin: auto;
  padding: 1rem;
`;
