import { EffectLevels } from '@Model';
import { NeuralEffectLevelIcon } from '@Music';
import { HTMLAttributes } from 'react';

import * as S from './NEL.styles';
import CheckmarkIcon from './assets/checkmark_icon.svg';

interface Props extends HTMLAttributes<HTMLDivElement> {
  icon?: EffectLevels;
  title: string;
  description: string;
  isSelected?: boolean;
  hasRoundedBottom?: boolean;
  hasRoundedTop?: boolean;
  'data-testid'?: string;
  onClick?: () => void;
}

export const NEL = ({
  icon,
  title,
  description,
  isSelected = false,
  'data-testid': testId,
  hasRoundedBottom = false,
  hasRoundedTop = false,
  onClick,
}: Props) => {
  return (
    <S.Container
      data-testid={testId}
      hasRoundedBottom={hasRoundedBottom}
      hasRoundedTop={hasRoundedTop}
      isSelected={isSelected}
      onClick={onClick}
    >
      <S.TextContainer>
        <S.Title>
          {icon ? (
            <S.NeuralEffectLevelIcon>
              <NeuralEffectLevelIcon effectLevel={icon} />
            </S.NeuralEffectLevelIcon>
          ) : null}
          {title}
        </S.Title>
        <S.Description>{description}</S.Description>
      </S.TextContainer>
      <S.RadioContainer>
        <S.CheckmarkIcon isSelected={isSelected} src={CheckmarkIcon} />
      </S.RadioContainer>
    </S.Container>
  );
};
