import { useGenreNamesByDynamicActivity } from '../../../../api/modules/GenreNamesByDynamicActivity';
import { useDynamicSession } from '../../../Session/lenses/useDynamicSession';
import * as S from './GenresDynamic.styles';
import { GenreButton } from './components';

type Props = {
  selected: string[];
  onSelect: (value: string) => void;
  activityId?: string;
};

export const Genres = ({ activityId, onSelect, selected }: Props) => {
  const { data, isLoading } = useGenreNamesByDynamicActivity(activityId);

  const session = useDynamicSession();

  const handleClick = (genreName: string) => () => {
    onSelect(genreName);
  };

  if (isLoading) return <S.Loading />;

  return (
    <S.Container>
      <S.Section>
        <S.SectionTitle>Music</S.SectionTitle>

        <S.GenresContainer>
          {!isLoading &&
            data &&
            data.baseGenres.map((item, i) => {
              const isActive = Boolean(session && selected.includes(item.name));
              const isFirst = i === 0;
              const isLast = i === data.baseGenres.length - 1;

              return (
                <S.GenreContainer key={item.name}>
                  <GenreButton
                    genre={item}
                    hasRoundedBottom={isLast}
                    hasRoundedTop={isFirst}
                    isActive={isActive}
                    onClick={handleClick(item.name)}
                  />
                </S.GenreContainer>
              );
            })}
        </S.GenresContainer>
      </S.Section>
      {data && data.natureGenres.length ? (
        <S.Section>
          <S.SectionTitle>Nature</S.SectionTitle>
          <S.GenresContainer>
            {!isLoading &&
              data &&
              data.natureGenres.map((item, i) => {
                const isActive = Boolean(session && selected.includes(item.name));
                const isFirst = i === 0;
                const isLast = i === data.natureGenres.length - 1;

                return (
                  <S.GenreContainer key={item.name}>
                    <GenreButton
                      genre={item}
                      hasRoundedBottom={isLast}
                      hasRoundedTop={isFirst}
                      isActive={isActive}
                      onClick={handleClick(item.name)}
                    />
                  </S.GenreContainer>
                );
              })}
          </S.GenresContainer>
        </S.Section>
      ) : null}
    </S.Container>
  );
};
