import { GOOGLE_CLIENT_ID } from '@Globals';
import { CredentialResponse } from 'google-one-tap';
import { useEffect } from 'react';

type Props = {
  callback: (credentialResponse: CredentialResponse) => void;
  clickListener?: () => void;
  context?: 'signin' | 'signup' | 'use';
};
export const useInitializeGoogleAuth = (props: Props) => {
  const { callback, clickListener, context } = props;

  useEffect(() => {
    if (typeof google === 'undefined') return;
    const googleBtn = document.getElementById('google-button');
    google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID!,
      callback,
      context: context || 'signin',
      use_fedcm_for_prompt: true,
    });

    if (googleBtn) {
      google.accounts.id.renderButton(googleBtn, {
        theme: 'filled_black',
        shape: 'pill',
        size: 'large',
        logo_alignment: 'left',
        // @ts-ignore typing is incorrect
        click_listener: clickListener,
      });
    }
    google.accounts.id.prompt();
  }, [typeof google]);
};
