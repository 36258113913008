import { Serving, TagTypes, Track } from '@Model';

import { isServing } from './isServing';

export function getTrackMoods(trackOrServing?: Track | Serving | null): string[] {
  if (isServing(trackOrServing)) {
    return trackOrServing.track.tags
      .filter(tag => tag.type === TagTypes.Mood)
      .map(tag => tag.value);
  }

  return trackOrServing?.moods || [];
}
