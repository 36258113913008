import styled from 'styled-components';

export const Container = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderTextSubtitle = styled.div`
  color: #d4d2ea;
  font-family: TTNormsPro-Regular;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

export const HeaderTextTitle = styled.div`
  font-family: TTNormsPro-Bold;
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 1rem;
`;

export const TimeRemaining = styled.div`
  background-color: #1d183b;
  border-radius: 0.75rem;
  display: flex;
  margin-bottom: 1rem;
  padding: 1.5rem;
  width: 100%;
`;

export const TimeValue = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 20%;
`;

export const TimeValueBig = styled.div`
  font-family: TTNormsPro-Bold;
  font-weight: 700;
  font-size: 2rem;
`;

export const TimeValueSmall = styled.div`
  color: #d4d2ea;
  font-family: TTNormsPro-Regular;
  font-weight: 400;
  font-size: 1rem;
`;

export const TimeColon = styled.div`
  font-family: TTNormsPro-Bold;
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
  width: 20%;
`;
