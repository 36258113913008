import { FontColors, FontFamilies, Paper, Text } from '@Cortex';
import { NOOP } from '@Globals';
import PlayIcon from './assets/play_circle.svg';
import StopIcon from './assets/stop_circle.svg';

import * as S from './MusicAnswer.styles';

export type Props = {
  title?: string;
  description?: string;
  imageUrl?: string;
  selected: boolean;
  isPlaying: boolean;
  onClick?: () => void;
  onPlayClick?: () => void;
};

export function MusicAnswer({ onClick = NOOP, ...props }: Props) {
  const { title, description, imageUrl, selected, isPlaying, onPlayClick } = props;

  return (
    <S.Container hasSelected={selected} onClick={onClick}>
      <S.ImageBackground src={imageUrl} />
      <S.Overlay />
      <S.PlayButton
        alt={isPlaying ? 'Stop' : 'Play'}
        src={isPlaying ? StopIcon : PlayIcon}
        onClick={onPlayClick}
      />
      <S.TextContainer>
        <Text color={FontColors.White} family={FontFamilies.Regular} size={24}>
          {title}
        </Text>
        <Text color={FontColors.WhiteTransparent} family={FontFamilies.Regular} size={18}>
          {description}
        </Text>
      </S.TextContainer>
    </S.Container>
  );
}
