import { membershipActions } from '@Memberships';
import { paymentActions as paymentDomainActions } from '@Payment';
import { Action } from '@reduxjs/toolkit';
import { takeLatest, put, select, call } from 'redux-saga/effects';

import * as paymentActions from '../actions/payments';
import * as uiActions from '../actions/ui';
import * as userActions from '../actions/user';
import { RequestMethods } from '../api/client/types';
import {
  trackSubscriptionPlanChangeSuccess,
  trackSubscriptionPlanChangeError,
} from '../domains/Analytics/coreAnalytics';
import { PlanTypes } from '../domains/Analytics/coreAnalytics.types';
import { RootReducerType } from '../reducers';
import { paymentSliceActions } from '../reducers/payments';
import { userSliceActions } from '../reducers/user';
import { PaymentTransactionState } from '../types/payments';
import { getErrorMessage } from '../utils/getErrorMessage';
import { Logger } from '../utils/logger';
import { requestSaga } from './httpRequest';

function* createStripeCustomerSaga() {
  try {
    const userInfo: RootReducerType['user']['info'] = yield select(
      (state: RootReducerType) => state.user.info,
    );
    const { result } = yield requestSaga(RequestMethods.POST, '/payments/create-customer', {
      email: userInfo?.email,
    });

    yield put(paymentSliceActions.setCustomerId(result));
  } catch (error) {
    Logger.error(error);
  }
}

function* handlePaymentsSaga(action: Action) {
  const userInfo: RootReducerType['user']['info'] = yield select(
    (state: RootReducerType) => state.user.info,
  );
  const { customerId }: RootReducerType['payments'] = yield select(
    (state: RootReducerType) => state.payments,
  );

  try {
    // set the loading state
    yield put(paymentSliceActions.transactionStart());

    if (paymentActions.makePayment.match(action) && customerId) {
      yield put(paymentSliceActions.setCustomerId(customerId));

      const { result } = yield requestSaga(RequestMethods.POST, '/payments/create-subscription', {
        paymentMethodId: action.payload.paymentMethodId,
        planId: action.payload.planId,
        customerId,
        userId: userInfo?.id,
      });

      if ((result as PaymentTransactionState).status === 'succeeded') {
        yield put(userActions.getInfo());
      }

      yield put(paymentSliceActions.transactionSuccess(result));
    }
  } catch (error) {
    yield put(paymentSliceActions.transactionFailed(getErrorMessage(error)));
    Logger.error(error);
  }
}

function* acknowledgePaymentSaga(action: Action) {
  const userInfo: RootReducerType['user']['info'] = yield select(
    (state: RootReducerType) => state.user.info,
  );
  const { customerId }: RootReducerType['payments'] = yield select(
    (state: RootReducerType) => state.payments,
  );

  try {
    if (paymentActions.finishPayment.match(action)) {
      yield put(paymentSliceActions.transactionStart());

      const { result } = yield requestSaga(RequestMethods.POST, '/payments/confirm-subscription', {
        subscriptionId: action.payload.subscriptionId,
        planId: action.payload.planId,
        customerId,
        userId: userInfo?.id,
      });

      if ((result as PaymentTransactionState).status === 'succeeded') {
        yield put(userActions.getInfo());
      }

      yield put(paymentSliceActions.transactionSuccess(result));
    }
  } catch (error) {
    yield put(paymentSliceActions.transactionFailed(getErrorMessage(error)));
    Logger.error(error);
  }
}

function* cancelMembershipSaga(action: Action) {
  try {
    if (paymentActions.cancelMembership.match(action)) {
      yield put(paymentSliceActions.setStatus('cancelling'));

      const userInfo: RootReducerType['user']['info'] = yield select(
        (state: RootReducerType) => state.user.info,
      );
      const { result } = yield requestSaga(
        RequestMethods.POST,
        '/payments/disable-subscription-auto-renew',
        {
          userId: userInfo?.id,
        },
      );

      yield put(userSliceActions.setMembershipInfo(result));
    }
  } catch (error) {
    Logger.error(error);
  } finally {
    yield put(paymentSliceActions.setStatus('idle'));
  }
}

function* updateMembershipSaga(action: Action) {
  try {
    if (paymentActions.updateMembership.match(action)) {
      yield put(paymentSliceActions.setStatus('updating'));
      yield put(paymentDomainActions.receiveUpgradeProcessing());

      const user: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
      const { result } = yield requestSaga(RequestMethods.POST, '/payments/update-subscription', {
        userId: user.info?.id,
        newMembershipId: action.payload,
      });

      yield put(userSliceActions.setMembershipInfo(result));
      yield put(paymentDomainActions.resetState());
      yield put(membershipActions.fetchMembership());
      yield put(
        uiActions.successModalOpen({
          title: 'Success!',
          description: 'Your account has been upgraded!',
          actions: [{ type: 'primary', text: 'CLOSE', action: uiActions.successModalClose() }],
        }),
      );
      trackSubscriptionPlanChangeSuccess({
        newPlanType: PlanTypes.Yearly,
        oldPlanType: user.membership?.title as PlanTypes,
      });
    }
  } catch (error) {
    trackSubscriptionPlanChangeError({
      errorMessage: getErrorMessage(error),
    });
    yield put(
      uiActions.successModalOpen({
        iconSrc: null,
        title: 'Something went wrong!',
        description:
          'Please check your payment information and try again! If you continue to receive this message use the "Contact Support" option for assistance!',
        actions: [{ type: 'primary', text: 'CLOSE', action: uiActions.successModalClose() }],
      }),
    );
    Logger.error(error);
  } finally {
    yield put(paymentSliceActions.setStatus('idle'));
    yield put(paymentDomainActions.receiveUpgradeError());
  }
}

function* clearTransactionStateSaga(action: Action) {
  if (paymentActions.clearTransactionState.match(action)) {
    yield put(paymentSliceActions.clearTransactionState());
  }
}

export default function* watchPaymentsSaga() {
  yield takeLatest(paymentActions.makePayment.type, handlePaymentsSaga);
  yield takeLatest(paymentActions.finishPayment.type, acknowledgePaymentSaga);
  yield takeLatest(paymentActions.createStripeCustomer.type, createStripeCustomerSaga);

  yield takeLatest(paymentActions.cancelMembership.type, cancelMembershipSaga);
  yield takeLatest(paymentActions.updateMembership.type, updateMembershipSaga);
  yield takeLatest(paymentActions.clearTransactionState.type, clearTransactionStateSaga);
}
