import { Button, FormInput, ButtonVariantSizes } from '@Cortex';
import React from 'react';

import { VariantStates } from '../../../../../Cortex/components/Button/Button';
import * as S from './VerificationForm.styles';
import { PresentationProps } from './VerificationForm.types';

export const VerificationFormPresentation = (props: PresentationProps) => {
  const {
    code,
    isVerifying,
    isResending,
    isResent,
    verificationErrorMessage,
    onChangeCode,
    onVerify,
    onResendEmail,
  } = props;
  return (
    <S.Wrapper>
      <S.Text>Please verify your email address.</S.Text>
      <S.SecondaryText>We've sent you an email with the verification code</S.SecondaryText>
      <S.FormWrapper>
        <FormInput
          errorMessage={verificationErrorMessage}
          label=""
          name="code"
          placeholder="verification code"
          value={code}
          onChange={onChangeCode}
        />
        <S.VerifyButtonWrapper>
          <Button
            data-testid="verifyEmailButton"
            disabled={!code.length}
            size={ButtonVariantSizes.XSmall}
            variantState={isVerifying ? VariantStates.Loading : VariantStates.Idle}
            onClick={onVerify}
          >
            <S.ButtonText>Verify</S.ButtonText>
          </Button>
        </S.VerifyButtonWrapper>
      </S.FormWrapper>
      <S.AdditionalInfo>
        {isResent ? (
          <S.SecondaryText>Verification email has been sent again</S.SecondaryText>
        ) : (
          <S.SecondaryText>
            Didn't get the email?{' '}
            <S.InlineButton disabled={isResending} onClick={onResendEmail}>
              Send Again
            </S.InlineButton>
          </S.SecondaryText>
        )}
      </S.AdditionalInfo>
    </S.Wrapper>
  );
};
