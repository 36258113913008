import { useEffect, useState } from 'react';
import { featureFlagsState } from './featureFlagsState';

export const useFeatureFlagsState = () => {
  const [state, setState] = useState(featureFlagsState.getState());

  useEffect(() => {
    const unsubscribe = featureFlagsState.subscribe(setState);
    return unsubscribe;
  }, []);

  return state;
};
