import styled, { css } from 'styled-components';

const INPUT_HEIGHT = '32px';
const LABEL_HEIGHT = '24px';
const CONTAINER_HEIGHT = '66px';

export const Container = styled.div<{ isFocused: boolean; variant: 'first' | 'last' | 'middle' }>`
  display: flex;
  flex-direction: column;
  padding: 12px 1rem;
  position: relative;
  height: ${CONTAINER_HEIGHT};
  justify-content: center;
  transition: background-color 0.2s ease-in-out;

  background-color: ${({ isFocused }) =>
    isFocused ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.1)'};

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  ${({ variant }) => {
    if (variant === 'first')
      return css`
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      `;
    if (variant === 'last')
      return css`
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      `;
  }}
`;

export const Label = styled.label<{ isFocused: boolean }>`
  height: ${LABEL_HEIGHT};
  pointer-events: none;
  font-size: 16px;
  position: absolute;
  color: rgba(255, 255, 255, 0.5);
  font-family: ${({ theme }) => theme.font.family.default.regular};
  font-weight: ${({ theme }) => theme.font.weight.regular};

  transition:
    transform 0.2s ease-in-out,
    font-size 0.2s ease-in-out,
    color 0.2s ease-in-out,
    font-weight 0.2s ease-in-out,
    font-family 0.2s ease-in-out;

  ${({ isFocused }) => {
    if (isFocused)
      return css`
        transform: translateY(-12px);
        font-size: 12px;
        color: rgba(255, 255, 255, 0.75);
        font-family: ${({ theme }) => theme.font.family.default.medium};
        font-weight: ${({ theme }) => theme.font.weight.medium};
      `;
  }}
`;

export const Input = styled.input<{ isFocused: boolean }>`
  border: none;
  outline: none;
  background: none;
  height: ${INPUT_HEIGHT};
  color: #ffffff;
  font-size: 16px;
  transition: transform 0.2s ease-in-out;

  ${({ isFocused }) => {
    if (isFocused)
      return css`
        transform: translateY(10px);
      `;
  }}
`;

export const PasswordInputEyeIcon = styled.img`
  position: absolute;
  right: 1.25rem;
  bottom: 1.5rem;
  height: 1.25rem;
  width: 1.25rem;
  cursor: pointer;
  z-index: 1;
  opacity: 0.75;
  transition:
    opacity 0.3s ease-in-out,
    right 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;
