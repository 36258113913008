export type PersonalizationQuiz = {
  questions: Question[];
};

type Question = {
  id: string;
  body: string;
  answers: Answer[];
};

type Answer = {
  id: string;
  body: string;
  responseParagraphs: string[];
  resultingEffects: ResultingEffect[];
};

export enum ResultingEffectTypes {
  IncreaseSignal = 'IncreaseSignal',
}

export enum ResultingEffectScopes {
  Genre = 'Genre',
  NeuralEffectLevel = 'NeuralEffectLevel',
}

type ResultingEffect =
  | {
      scope: ResultingEffectScopes.Genre;
      type: ResultingEffectTypes.IncreaseSignal;
      genreTypes: string[];
    }
  | {
      scope: ResultingEffectScopes.NeuralEffectLevel;
      type: ResultingEffectTypes.IncreaseSignal;
      signalBoost: number;
    };

export type PersonalizationQuizResults = {
  responses: PersonalizationQuizResponse[];
};

type PersonalizationQuizResponse = {
  questionId: string;
  answerId: string;
};
