import styled from 'styled-components';

import { FontColors } from '@Cortex';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  isolation: isolate;
  display: flex;
  padding: 1rem;
  justify-content: center;
  display: flex;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

export const ModalWrapper = styled.div`
  display: flex;
  align-self: center;
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  background-position-x: center;
  background-position-y: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1.5rem;
`;

export const Image = styled.img`
  position: absolute;
  top: -10.5rem;
  width: 25rem;
  height: auto;
  align-self: center;
  z-index: 2;
`;

export const AuraImage = styled.img`
  position: absolute;
  border-radius: 10px;
  top: 0rem;
  width: 100%;
  height: 14rem;
  align-self: center;
  z-index: 0;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 1rem;
  z-index: 1;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 1.25rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1rem;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
  color: ${FontColors.WhiteTransparent};
  text-align: center;
`;

export const MembershipInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  padding: 1.25rem 1.5rem;
  gap: 1rem;
  margin: 2rem 0;
  min-width: 27rem;
`;

export const MembershipInformation = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const MembershipTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const MembershipHeaderText = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  color: ${FontColors.White};
`;

export const MembershipInfoText = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.75rem;
  letter-spacing: 1.2px;
  color: ${FontColors.WhiteTransparent};
`;

export const Icon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CloseButtonWrapper = styled.button`
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 5;
`;

export const Close = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;
