import { MentalState } from 'src/domains/Model/MentalState';
import { Serving } from 'src/domains/Model/Serving';
import { DynamicMentalState } from './DynamicMentalState';
import { DynamicActivity } from './DynamicActivity';

export type Track = {
  activityId: string;
  genreName: string;
  hasMultipleNELs: boolean;
  id: string;
  image: string;
  instrumentations: string[];
  isNewlyCreated: boolean;
  lengthInSeconds: number;
  mentalStateId: string;
  moods: string[];
  // name: string;
  complexityDisplayValue: EffectLevels;
  neuralEffectLevelDisplayValue: EffectLevels;
  serving: Serving;
  url: string;
  dynamicMentalState: DynamicMentalState;
  relatedActivities: DynamicActivity[];
};

// v3 types below

export enum EffectLevels {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum TagTypes {
  Activity = 'activity',
  Genre = 'genre',
  Instrument = 'instrument',
  Mood = 'mood',
  Release = 'release',
}

export type V3Track = {
  beatsPerMinute: number;
  brightnessLevel: number;
  complexityLevel: number;
  createdAt: number;
  hasMultipleNELs: boolean;
  id: string;
  imageUrl: string;
  isNewlyCreated: boolean;
  mentalState: MentalState;
  name: string;
  neuralEffectLevel: number;
  similarTracks: Track[];
  tags: Tag[];
  variations: Omit<TrackVariation, 'track'>[];
  releaseStatus: string;
  dynamicMentalState: DynamicMentalState;
  relatedActivities: DynamicActivity[];
};

export type TrackVariation = {
  id: string;
  lengthInSeconds: number;
  neuralEffectLevel: number;
  style: 'normal' | 'guided';
  track: Track;

  // todo, this is in here as a stopgap between old and new paradigms
  // we can remove this after v3 transition
  url: string;
};

export type Tag = {
  type: TagTypes;
  value: string;
};
