import styled from 'styled-components';
import { FontColors } from '@Cortex';
import LinkOutIconSVG from '../../../../../../assets/images/link_out_icon.svg?react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  cursor: pointer;
  align-items: center;
`;

export const LinkText = styled.a`
  color: ${FontColors.White};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
  text-decoration: underline;
`;

export const DescriptionText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${FontColors.WhiteTransparent};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
`;

export const LinkOutIcon = styled(LinkOutIconSVG)`
  height: 1rem;
  width: 1rem;
  margin-left: 0.5rem;
`;
