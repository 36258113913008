import { NOOP } from '@Globals';
import { Formik, FormikConfig } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import * as S from './Form.styles';
import { ButtonWhite } from './components/FormButton.styles';
import { FormInputModern } from '../FormInputModern';

type Inputs<Values> = {
  label: string;
  name: Extract<keyof Values, string>;
  placeholder: string;
  type: string;
};

export type Props<Values> = {
  children?: React.ReactNode;
  formError: string | null;
  formInitialValues: Values;
  formInputs: Inputs<Values>[];
  formValidationSchema: Yup.Schema<any>;
  isLoading: boolean;
  onFormChange?: () => void;
  onFormSubmit: FormikConfig<Values>['onSubmit'];
  onFormSubmitAnalyticsEvent: string;
  submitButtonText: string;
  renderAfterFormInputs?: () => JSX.Element;
};

export function Form<Values extends { [key: string]: string }>(props: Props<Values>) {
  return (
    <S.Form>
      <Formik<Values>
        initialValues={props.formInitialValues}
        validationSchema={props.formValidationSchema}
        onSubmit={props.onFormSubmit}
      >
        {createRenderForm<Values>(props)}
      </Formik>
      {props.children}
    </S.Form>
  );
}

function createRenderForm<Values extends { [key: string]: string }>({
  onFormChange = NOOP,
  renderAfterFormInputs,
  ...props
}: Props<Values>): FormikConfig<Values>['children'] {
  return function renderForm({ touched, handleChange, handleSubmit, handleBlur, values, errors }) {
    function handleFormChange(event: any) {
      onFormChange();
      handleChange(event);
    }

    return (
      <S.FormBody onSubmit={handleSubmit}>
        {props.formInputs.map((input, i) => {
          const isFirst = i === 0;
          const isLast = i === props.formInputs.length - 1;

          return (
            <FormInputModern
              key={input.name}
              errorMessage={touched[input.name] ? (errors[input.name] as string) : undefined}
              hasRoundedBottom={isLast}
              hasRoundedTop={isFirst}
              label={input.label}
              name={input.name}
              placeholder={input.placeholder}
              type={input.type}
              value={values[input.name]}
              onBlur={handleBlur}
              onChange={handleFormChange}
            />
          );
        })}

        {renderAfterFormInputs && renderAfterFormInputs()}

        <S.FormButtonWrapper>
          <ButtonWhite
            data-analytics-event={props.onFormSubmitAnalyticsEvent}
            data-testid="handle-submit-form"
            state={props.formError ? 'error' : props.isLoading ? 'loading' : 'idle'}
            type="submit"
          >
            {props.formError || props.submitButtonText}
          </ButtonWhite>
        </S.FormButtonWrapper>
      </S.FormBody>
    );
  };
}
