import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 32px 24px 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  text-transform: capitalize;
`;

export const LogoutButton = styled.div`
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

export const SectionHeader = styled.div`
  margin-bottom: 12px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1px;
  margin-bottom: 2rem;
`;

export const SectionHeadingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  margin-bottom: 2rem;
`;

export const BackIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0;
`;

export const SlideInLeftContainer = styled.div<{ animationState: TransitionStatus }>`
  visibility: ${({ animationState }) => (animationState === 'entered' ? 'visible' : 'hidden')};
  ${({ animationState }) =>
    animationState === 'exiting' &&
    css`
      animation: slideInLeft 0.5s ease-in-out;
    `}
  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

export const SlideInRightContainer = styled.div<{ animationState: TransitionStatus }>`
  visibility: ${({ animationState }) => (animationState === 'entered' ? 'visible' : 'hidden')};
  animation: slideInRight 0.5s ease-in-out;
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

export const FadeInWrapper = styled.div<{ animationState: TransitionStatus }>`
  width: 100%;
  height: 100%;
  padding-right: 0.25rem;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  animation: fadeInDown 0.3s ease-in-out;
  visibility: ${({ animationState }) => (animationState === 'entered' ? 'visible' : 'hidden')};

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;
