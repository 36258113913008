import { TextBold } from '@Cortex';
import { HTMLAttributes } from 'react';

import * as S from './GenreButton.styles';
import { GenreObject } from '../../../../../../api/modules/GenreNamesByActivity/types';
import CheckmarkIcon from '../../assets/checkmark_icon.svg';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  genre: GenreObject;
  isActive?: boolean;
  onClick: () => void;
  hasRoundedTop?: boolean;
  hasRoundedBottom?: boolean;
}

export const GenreButton = ({
  genre,
  onClick,
  isActive = false,
  hasRoundedTop = false,
  hasRoundedBottom = false,
}: Props) => {
  return (
    <S.Container
      data-testid={`genre_${genre.name.toLowerCase()}`}
      hasRoundedBottom={hasRoundedBottom}
      hasRoundedTop={hasRoundedTop}
      isActive={isActive}
      onClick={onClick}
    >
      <S.Icon src={genre.imageUrl} />
      <S.TextContainer>
        <TextBold size="0.875rem">{genre.name}</TextBold>
      </S.TextContainer>
      <S.Checkmark isSelected={isActive}>
        <img src={CheckmarkIcon} />
      </S.Checkmark>
    </S.Container>
  );
};
