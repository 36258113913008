import { EffectLevels } from '@Model';

const PREFIX = 'Level';

export function getNeuralEffectLevelDisplayValue(normalizedNeuralEffectLevel: number): string {
  return [PREFIX, getNeuralEffectDisplayLevel(normalizedNeuralEffectLevel)].join(' ');
}

function getNeuralEffectDisplayLevel(normalizedNeuralEffectLevel: number): number {
  return Math.ceil(normalizedNeuralEffectLevel * 3) || 1;
}

export function getNelDisplayValue(level: number): EffectLevels {
  if (level <= 0.33) return EffectLevels.Low;
  if (level <= 0.66) return EffectLevels.Medium;
  return EffectLevels.High;
}
