import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Container = styled.div<{ animationState: TransitionStatus }>`
  animation: ${({ theme }) => theme.animations.fadeIn} 0.3s ease-in-out;
  visibility: ${({ animationState }) => (animationState == 'entered' ? 'visible' : 'hidden')};
  margin: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${({ theme }) => theme.mediaQuery.maxWidth.xs} {
    flex-direction: column-reverse;
  }
`;

export const TextWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
`;

export const Body = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const ButtonWrapper = styled.div`
  margin: 0.5rem;
  display: flex;
  justify-content: center;
`;

export const CancellationReason = styled.div`
  margin-bottom: 1rem;
  padding: 0 1rem;
`;
