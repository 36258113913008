import React, { useMemo } from 'react';
import { pluralize } from '@Utils';
import * as S from '../MembershipWidget.styles';

type Props = {
  isTrial: boolean;
  isExpired: boolean;
  isInGracePeriod?: boolean | null;
  timeLeftInDays: number;
  timeLeftInHours: number;
};

export const WidgetBody = (props: Props) => {
  const { isTrial, isExpired, isInGracePeriod, timeLeftInDays, timeLeftInHours } = props;

  const trialMessage = useMemo(() => {
    if (isExpired) return 'Your trial has ended';
    if (timeLeftInDays >= 1)
      return (
        <>
          Your trial ends in <S.TrialLeftNumber>{timeLeftInDays}</S.TrialLeftNumber>{' '}
          {pluralize('days', timeLeftInDays, false)}
        </>
      );
    if (timeLeftInHours >= 1)
      return (
        <>
          Your trial ends in <S.TrialLeftNumber>{timeLeftInHours}</S.TrialLeftNumber>{' '}
          {pluralize('hours', timeLeftInHours, false)}
        </>
      );
    return 'Your trial ends in less than an hour';
  }, [isExpired, timeLeftInDays, timeLeftInHours]);

  const subscriptionMessage = useMemo(() => {
    if (isInGracePeriod) return 'Your subscription is about to expire';
    return 'Your subscription has expired';
  }, [isInGracePeriod]);

  if (!isTrial && !isExpired) return null;

  return <>{isTrial ? trialMessage : subscriptionMessage}</>;
};
