import { getTrackVariationId } from '@Music';

import { RootReducerType } from '../../../reducers';

type Params = {
  trackVariationId: string;
};

export function isTrackVariationInFavorites(params: Params) {
  return function selectIsTrackInFavorites(state: Pick<RootReducerType, 'music'>) {
    return state.music.favorites.some(
      track => getTrackVariationId(track) === params.trackVariationId,
    );
  };
}
