import { ButtonVariantStates } from '@Cortex';
import { PaymentStatus } from '../../../types';

export const getPaymentButtonState = (paymentStatus: PaymentStatus): ButtonVariantStates => {
  if (paymentStatus === 'loading') {
    return ButtonVariantStates.Loading;
  }
  if (paymentStatus === 'error') {
    return ButtonVariantStates.Error;
  }
  if (paymentStatus === 'success') {
    return ButtonVariantStates.Success;
  }

  return ButtonVariantStates.Idle;
};
