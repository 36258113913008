import { NOOP } from '@Globals';

import * as S from './MenuItemWithFill.styles';
import AccountIcon from '../MenuItem/assets/account.svg?react';
import AlertIcon from '../MenuItem/assets/alert.svg?react';
import AppleIcon from '../MenuItem/assets/apple.svg?react';
import CalendarIcon from '../MenuItem/assets/calendar.svg?react';
import SupportIcon from '../MenuItem/assets/support.svg?react';
import BarChartIcon from '../MenuItem/assets/bar_chart.svg?react';
import BarChartThinIcon from '../MenuItem/assets/bar_chart_thin.svg?react';
import CloseIcon from '../MenuItem/assets/close.svg?react';
import FacebookIcon from '../MenuItem/assets/facebook.svg?react';
import FemaleUserIcon from '../MenuItem/assets/female_user.svg?react';
import GalaxyIcon from '../MenuItem/assets/galaxy.svg?react';
import GiftIcon from '../MenuItem/assets/gift.svg?react';
import GoogleIcon from '../MenuItem/assets/google.svg?react';
import GroupUsersIcon from '../MenuItem/assets/group_users.svg?react';
import HourglassIcon from '../MenuItem/assets/hourglass.svg?react';
import InfiniteIcon from '../MenuItem/assets/infinite.svg?react';
import KeyIcon from '../MenuItem/assets/key.svg?react';
import LogoutIcon from '../MenuItem/assets/logout.svg?react';
import SpeechBubbleIcon from '../MenuItem/assets/message-circle.svg?react';
import ReferIcon from '../MenuItem/assets/refer.svg?react';
import SettingsIcon from '../MenuItem/assets/settings.svg?react';
import NELIcon from '../MenuItem/assets/single_dumbel.svg?react';
import WatchIcon from '../MenuItem/assets/watch.svg?react';

export enum Icons {
  Account = 'Account',
  Alert = 'Alert',
  Apple = 'Apple',
  BarChart = 'BarChart',
  BarChartThin = 'BarChartThin',
  Broadcast = 'Broadcast',
  Calendar = 'Calendar',
  Close = 'Close',
  Facebook = 'Facebook',
  FemaleUser = 'FemaleUser',
  Galaxy = 'Galaxy',
  Gift = 'Gift',
  Google = 'Google',
  GroupUsers = 'GroupUsers',
  Hourglass = 'Hourglass',
  Infinite = 'Infinite',
  Key = 'Key',
  Logout = 'Logout',
  Settings = 'Settings',
  SingleDumbbell = 'SingleDumbbell',
  SpeechBubble = 'SpeechBubble',
  Support = 'Support',
  Watch = 'Watch',
}

const ICONS: { [key in Icons]: () => React.ReactNode } = {
  [Icons.Account]: () => <AccountIcon />,
  [Icons.Alert]: () => <AlertIcon />,
  [Icons.Apple]: () => <AppleIcon />,
  [Icons.BarChartThin]: () => <BarChartThinIcon />,
  [Icons.BarChart]: () => <BarChartIcon />,
  [Icons.Broadcast]: () => <ReferIcon />,
  [Icons.Calendar]: () => <CalendarIcon />,
  [Icons.Close]: () => <CloseIcon />,
  [Icons.Facebook]: () => <FacebookIcon />,
  [Icons.FemaleUser]: () => <FemaleUserIcon />,
  [Icons.Galaxy]: () => <GalaxyIcon />,
  [Icons.Gift]: () => <GiftIcon />,
  [Icons.Google]: () => <GoogleIcon />,
  [Icons.GroupUsers]: () => <GroupUsersIcon />,
  [Icons.Hourglass]: () => <HourglassIcon />,
  [Icons.Infinite]: () => <InfiniteIcon />,
  [Icons.Key]: () => <KeyIcon />,
  [Icons.Logout]: () => <LogoutIcon />,
  [Icons.Settings]: () => <SettingsIcon />,
  [Icons.SingleDumbbell]: () => <NELIcon />,
  [Icons.SpeechBubble]: () => <SpeechBubbleIcon />,
  [Icons.Support]: () => <SupportIcon />,
  [Icons.Watch]: () => <WatchIcon />,
};

export type Props = {
  children?: React.ReactNode;
  icon?: Icons | null;
  isActive?: boolean;
  onClick?: () => void;
  hasTopDivider?: boolean;
  testid?: string;
  onClickIcon?: () => void;
};

export function MenuItemWithFill({
  children,
  icon = null,
  isActive = false,
  onClick = NOOP,
  hasTopDivider = false,
  testid,
  onClickIcon,
}: Props) {
  return (
    <>
      {hasTopDivider && <S.Divider />}
      <S.MenuItem data-testid={testid} isActive={isActive} onClick={isActive ? undefined : onClick}>
        {icon !== null ? (
          <S.Icon isActive={isActive} isClickable={Boolean(onClickIcon)} onClick={onClickIcon}>
            {ICONS[icon]()}
          </S.Icon>
        ) : null}
        {children}
      </S.MenuItem>
    </>
  );
}
