export const getDeviceType = () => {
  const UA = navigator?.userAgent || '';
  const isIos = /\b(iPhone|iPad|iPod)\b/i.test(UA);
  const isAndroid = /Android/i.test(UA);

  return {
    isMobile: isIos || isAndroid,
    isIos,
    isAndroid,
  };
};
