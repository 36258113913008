import { Button, ButtonVariants, Switch, TabBar, Text, TextBold } from '@Cortex';
import { TimeUnits } from '@Session';
import React, { useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

import { SessionPlayType } from '../../../../types';
import * as S from './TimeMode.styles';
import TimerOption from './components/TimerOption';
import {
  IntervalTimerBreakSounds,
  intervalRestTimes,
  intervalWorkTimes,
  timerTimes,
} from './constants';
import { TimerFormInput } from './components/TimerFormInput';
import { TimerPomodorIntervals, TimerSettingsPanes, TimerState } from './types';
import { IntervalTimerSettings } from './components/IntervalTimerSettings';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerType } from '../../../../reducers';
import { userSliceActions } from '@User';
import TimerInformation from './components/TimerInformation';

type Props = {
  customTimerOptions: TimerState[];
  sessionTimeMode: SessionPlayType;
  timerUnit: 'mins' | 'hrs';
  timerPane: TimerSettingsPanes;
  isApplyButtonDisabled: boolean;
  isQuotesActive: boolean;
  activePomodoroIntervals: TimerPomodorIntervals | undefined;
  activeTimeSelected: TimerState | undefined;
  showTimerInformation: boolean;
  onApplyTimer: () => void;
  onClose: () => void;
  onSetActiveTimeSelected: (time: TimerState) => void;
  onSetActivePomodoroIntervals(intervals: TimerPomodorIntervals): void;
  onSetPomodoroRest: (time: TimerState) => void;
  onSetPomodoroWork: (time: TimerState) => void;
  onSetTimerUnit: (unit: TimeUnits) => void;
  onSetQuotesActive: (value: boolean) => void;
  onSetTimerPane: (pane: TimerSettingsPanes) => void;
  onSetShowTimerInformation: (value: boolean) => void;
};

const TIME_UNITS = ['mins', 'hrs'];

export function TimeModeDisplay(props: Props) {
  const dispatch = useDispatch();

  const infiniteRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<HTMLDivElement>(null);

  const dynamicActivity = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity,
  );
  const intervalTimerBreakType = useSelector(
    (state: RootReducerType) => state.userV2.preferences.intervalTimerBreakType,
  );
  const [selectedBreakSoundType, setSelectedBreakSoundType] = useState<
    typeof intervalTimerBreakType
  >(intervalTimerBreakType || IntervalTimerBreakSounds[0].type);

  const handleSelect = (selected: typeof intervalTimerBreakType) => {
    dispatch(userSliceActions.setUserPreferences({ intervalTimerBreakType: selected }));
  };

  const handleApplyTimer = () => {
    handleSelect(selectedBreakSoundType);
    props.onApplyTimer();
  };

  return (
    <>
      <S.Container>
        {props.showTimerInformation && (
          <TimerInformation onClose={() => props.onSetShowTimerInformation(false)} />
        )}
        <S.HeadingContainer>
          <TextBold size={16}>{`Timer Settings`}</TextBold>
          <S.InformationIcon onClick={() => props.onSetShowTimerInformation(true)} />
        </S.HeadingContainer>

        <S.ContentContainer>
          <S.TimerOptionsContainer>
            <S.TimerOption
              data-testid="infiniteTab"
              isSelected={props.timerPane === TimerSettingsPanes.Infinite}
              onClick={() => props.onSetTimerPane(TimerSettingsPanes.Infinite)}
            >
              <S.InfiniteIcon active={props.timerPane === TimerSettingsPanes.Infinite} />
              INFINITE
            </S.TimerOption>
            <S.TimerOption
              data-testid="timerTab"
              isSelected={props.timerPane === TimerSettingsPanes.Timer}
              onClick={() => props.onSetTimerPane(TimerSettingsPanes.Timer)}
            >
              <S.TimerIcon active={props.timerPane === TimerSettingsPanes.Timer} />
              TIMER
            </S.TimerOption>
            {dynamicActivity?.mentalState.id === 'focus' ||
            dynamicActivity?.mentalState.displayValue === 'Focus' ? (
              <S.TimerOption
                data-testid="intervalTab"
                isSelected={props.timerPane === TimerSettingsPanes.Intervals}
                onClick={() => props.onSetTimerPane(TimerSettingsPanes.Intervals)}
              >
                <S.IntervalIcon active={props.timerPane === TimerSettingsPanes.Intervals} />
                INTERVALS
              </S.TimerOption>
            ) : null}
          </S.TimerOptionsContainer>

          <Transition
            in={props.timerPane === TimerSettingsPanes.Infinite}
            mountOnEnter
            nodeRef={infiniteRef}
            timeout={0}
            unmountOnExit
          >
            {animationState => (
              <S.FadeInWrapper ref={infiniteRef} animationState={animationState}>
                <S.SectionHeader>
                  <TextBold size={32}>{`Infinite Play`}</TextBold>
                  <S.DescriptionText>
                    Listen to tracks freely without any time restrictions.
                  </S.DescriptionText>
                </S.SectionHeader>
                <S.SwitchSection>
                  <S.Column>
                    <Text size={14}>Activate Quotes</Text>
                    <S.DescriptionText>Quotes replace the timer display.</S.DescriptionText>
                  </S.Column>
                  <Switch
                    data-testid="quoteSwitch"
                    isActive={props.isQuotesActive}
                    size={'xlarge'}
                    onClick={() => props.onSetQuotesActive(!props.isQuotesActive)}
                  />
                </S.SwitchSection>
              </S.FadeInWrapper>
            )}
          </Transition>

          <Transition
            in={props.timerPane === TimerSettingsPanes.Timer}
            mountOnEnter
            nodeRef={timerRef}
            timeout={0}
            unmountOnExit
          >
            {animationState => (
              <S.FadeInWrapper ref={timerRef} animationState={animationState}>
                <S.SectionHeader>
                  <TextBold size={32}>{`Set Timer`}</TextBold>
                  <S.DescriptionText>
                    Select when you’d like the music to stop playing.
                  </S.DescriptionText>
                </S.SectionHeader>
                <S.Column>
                  <S.TimerContainer data-testid="timerOptions">
                    {props.customTimerOptions.map(time => (
                      <TimerOption
                        key={time.label}
                        isActive={props.activeTimeSelected?.label === time.label}
                        text={time.label}
                        onClick={() => props.onSetActiveTimeSelected(time)}
                      />
                    ))}
                  </S.TimerContainer>
                  <TimerFormInput
                    data-testid="timerLengthInput"
                    placeholder="Custom"
                    rightSideContent={() => (
                      <div style={{ width: '6.25rem' }}>
                        <TabBar
                          activeTabIndex={TIME_UNITS.findIndex(item => item === props.timerUnit)}
                          items={['mins', 'hrs']}
                          onSelect={unit => props.onSetTimerUnit(unit)}
                        />
                      </div>
                    )}
                    value={props.activeTimeSelected?.value || ''}
                    onChange={e =>
                      props.onSetActiveTimeSelected({ label: 'custom', value: e.target.value })
                    }
                  />
                </S.Column>
              </S.FadeInWrapper>
            )}
          </Transition>

          <Transition
            in={props.timerPane === TimerSettingsPanes.Intervals}
            mountOnEnter
            nodeRef={intervalRef}
            timeout={0}
            unmountOnExit
          >
            {animationState => (
              <S.FadeInWrapper ref={intervalRef} animationState={animationState}>
                <S.SectionHeader>
                  <TextBold size={32}>{`Set Interval`}</TextBold>
                  <S.DescriptionText>Select your desired work and rest lengths.</S.DescriptionText>
                </S.SectionHeader>

                <S.IntervalContainer>
                  <S.IntervalSection>
                    <S.IntervalText>Work Time</S.IntervalText>
                    {intervalWorkTimes.map(time => (
                      <TimerOption
                        key={time.label}
                        isActive={props.activePomodoroIntervals?.focus === time}
                        text={time.label}
                        onClick={() => props.onSetPomodoroWork(time)}
                      />
                    ))}
                    <TimerFormInput
                      data-testid="focusLengthInput"
                      placeholder="Custom"
                      rightSideContent="min"
                      value={props.activePomodoroIntervals?.focus.value || ''}
                      onChange={e =>
                        props.onSetPomodoroWork({ label: 'custom', value: e.target.value })
                      }
                    />
                  </S.IntervalSection>

                  <S.IntervalSection>
                    <S.IntervalText>Rest Time</S.IntervalText>
                    {intervalRestTimes.map(time => (
                      <TimerOption
                        key={time.label}
                        isActive={props.activePomodoroIntervals?.break === time}
                        text={time.label}
                        onClick={() => props.onSetPomodoroRest(time)}
                      />
                    ))}
                    <TimerFormInput
                      data-testid="breakLengthInput"
                      placeholder="Custom"
                      rightSideContent="min"
                      value={props.activePomodoroIntervals?.break.value || ''}
                      onChange={e =>
                        props.onSetPomodoroRest({ label: 'custom', value: e.target.value })
                      }
                    />
                  </S.IntervalSection>
                </S.IntervalContainer>

                <S.ChimeSettingsContainer>
                  <IntervalTimerSettings
                    intervalBreakTypes={IntervalTimerBreakSounds}
                    selected={selectedBreakSoundType}
                    onSelect={setSelectedBreakSoundType}
                  />
                </S.ChimeSettingsContainer>
              </S.FadeInWrapper>
            )}
          </Transition>
        </S.ContentContainer>
      </S.Container>
      <S.ButtonsContainer>
        <Button
          data-testid="timerClose"
          isFullWidth
          keepTextCase
          style={{ fontSize: '0.75rem', height: '48px' }}
          title="Cancel"
          variant={ButtonVariants.Base}
          onClick={props.onClose}
        >
          CANCEL
        </Button>
        <Button
          data-testid="timerApply"
          disabled={props.isApplyButtonDisabled}
          isFullWidth
          keepTextCase
          style={{ fontSize: '0.75rem', height: '48px' }}
          variant={ButtonVariants.Secondary}
          onClick={handleApplyTimer}
        >
          APPLY
        </Button>
      </S.ButtonsContainer>
    </>
  );
}
