import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { baseStyles, CLOSE_TIMEOUT_MS } from '../../../../modals/modalStyles';
import { RootReducerType } from '../../../../reducers';
import { uiSliceActions } from '../../../../reducers/uiReducer';
import { UpgradeStripeSubscriptionPanel } from '../UpgradeStripeSubscriptionPanel';

export function UpgradeStripeSubscriptionModal() {
  const dispatch = useDispatch();
  const { modalType } = useSelector((state: RootReducerType) => state.ui);

  const handleClose = () => {
    dispatch(uiSliceActions.setModalType('profile'));
  };

  return (
    <ReactModal
      closeTimeoutMS={CLOSE_TIMEOUT_MS}
      isOpen={modalType === 'upgradeStripeSubscription'}
      style={baseStyles}
      onRequestClose={handleClose}
    >
      <UpgradeStripeSubscriptionPanel onClose={handleClose} />
    </ReactModal>
  );
}
