import React, { useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { NoData } from './components/NoData';
import { RecentTracks } from './components/RecentTracks';

import * as S from './styles';
import { JumpBackInButton } from './components';
import { RecentSession } from './components/RecentSession';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerType } from 'src/reducers';
import { RecentSessionType } from './types';
import { createDynamicSessionFromJumpBackIn } from '../../../../actions/sessionManager';
import { useNavigate } from 'react-router-dom';
import { Serving, Track } from '@Model';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';

type Props = {
  onOpen: () => void;
  onClose: () => void;
  onSelectPreviewTrack: (track: Track | Serving) => void;
  onOpenTrackInfoModal: () => void;
};

export const JumpBackInModal = ({
  onOpen,
  onClose,
  onSelectPreviewTrack,
  onOpenTrackInfoModal,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [bodyMounted, setBodyMounted] = useState(false);
  const ref = useRef(null);
  const recentTracksRef = useRef<HTMLDivElement | null>(null);
  const isMobileView = useIsMobileView();

  const recentSessions = useSelector((state: RootReducerType) => state.recentSessions);

  const handleOpen = () => {
    setIsOpen(state => !state);
    setBodyMounted(true);
    onOpen();
  };

  const handleClose = () => {
    setIsOpen(state => !state);
    onClose();
  };

  const unmountBody = () => {
    if (!isOpen) {
      setBodyMounted(false);
    }
  };

  const handleRecentSessionClick = (recentSession: RecentSessionType) => {
    dispatch(createDynamicSessionFromJumpBackIn({ recentSession, navigate }));
  };

  const handleTrackClickMore = (track: Track | Serving) => {
    onOpenTrackInfoModal();
    onSelectPreviewTrack(track);
  };

  return (
    <>
      <S.Container isMobileView={isMobileView} isOpen={isOpen} onTransitionEnd={unmountBody}>
        <S.ButtonContainer isOpen={isOpen}>
          <JumpBackInButton isOpen={isOpen} onClick={isOpen ? handleClose : handleOpen} />
        </S.ButtonContainer>

        <Transition in={bodyMounted} mountOnEnter nodeRef={ref} timeout={100} unmountOnExit>
          {state => (
            <S.TransitionWrapper ref={ref} animationState={state} onClick={handleClose}>
              <S.ContentWrapper
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {recentSessions.status !== 'loading' && !recentSessions.ids.length ? (
                  <NoData />
                ) : (
                  <>
                    <S.TitleWrapper style={{ display: 'flex', flexDirection: 'row' }}>
                      <S.Title>RECENT SESSIONS</S.Title>
                      {recentSessions.status === 'loading' ? <S.LoadingIconAnimating /> : null}
                    </S.TitleWrapper>
                    <S.RecentSessionsContainer ref={recentTracksRef}>
                      {recentSessions.ids.map((id, i) => {
                        const recentSession = recentSessions.results[id];
                        const isRight = i % 2 === 0;

                        return (
                          <S.RecentSessionWrapper
                            key={`${recentSession.id}_${i}`}
                            isRight={isRight}
                          >
                            <RecentSession
                              recentSession={recentSession}
                              onClick={() => handleRecentSessionClick(recentSession)}
                            />
                          </S.RecentSessionWrapper>
                        );
                      })}
                    </S.RecentSessionsContainer>
                    <S.TitleWrapper
                      style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}
                    >
                      <S.Title>RECENT TRACKS</S.Title>
                    </S.TitleWrapper>
                    <RecentTracks isFromJumpBackIn onTrackClickMore={handleTrackClickMore} />
                  </>
                )}
              </S.ContentWrapper>
            </S.TransitionWrapper>
          )}
        </Transition>
      </S.Container>
    </>
  );
};
