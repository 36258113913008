import { createSlice } from '@reduxjs/toolkit';

export enum ActivationStatuses {
  Idle = 'idle',
  Submitting = 'submitting',
  Accepted = 'accepted',
  Errored = 'errored',
}

export type ActivationState = {
  status: ActivationStatuses;
  pin: string;
};

export const initialState: ActivationState = {
  status: ActivationStatuses.Idle,
  pin: '',
};

export const { actions: activationActions, reducer: activationReducer } = createSlice({
  name: 'ACTIVATION',
  initialState,
  reducers: {
    setPin: (state, action: { payload: string }) => {
      state.status = ActivationStatuses.Idle;
      state.pin = action.payload;
    },
    submitPin: (state, action: { payload: { userId?: string; pin: string } }) => {
      state.status = ActivationStatuses.Submitting;
    },
    acceptPin: state => {
      state.status = ActivationStatuses.Accepted;
    },
    rejectPin: state => {
      state.status = ActivationStatuses.Errored;
    },
  },
});
