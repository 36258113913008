import { Paper, CloseButton, Button, ButtonVariants } from '@Cortex';
import { TeamType } from '@Teams';
import React from 'react';
import { VariantStates } from '../Cortex/components/Button/Button';
import * as S from './JoinTeam.styles';

type Props = {
  isLoadingData: boolean;
  onStartOnboarding: () => void;
  onCancel: () => void;
  teamInfo?: TeamType;
};

export const JoinTeamPresentation = (props: Props) => {
  const { isLoadingData, onStartOnboarding, teamInfo, onCancel } = props;

  return (
    <S.Wrapper>
      {isLoadingData && <S.Loading />}
      {!isLoadingData && (
        <S.Content maxWidth="40rem">
          <Paper isScrollable={true} padding="4rem 2rem" style={{ maxHeight: '90vh' }}>
            {onCancel && (
              <S.CloseButton>
                <CloseButton data-testid="onboardingCardCloseButton" onClick={onCancel} />
              </S.CloseButton>
            )}
            <S.Wrapper>
              <S.TextColumn>
                {teamInfo ? <S.Title>Welcome to {teamInfo?.title} on Brain.fm!</S.Title> : null}
                <S.Body>
                  You're now part of {teamInfo?.title} and have access to 1000+ tracks across a
                  variety of music genres and nature soundscapes that will get you instantly
                  productive.
                </S.Body>
              </S.TextColumn>
              <S.ImageColumn>
                <S.Image
                  alt="Join Team"
                  src="https://cdn.brain.fm/images/payment-september-wide.png"
                  width="100%"
                />
              </S.ImageColumn>
              <S.TextColumn>
                <S.Body>Personalize your experience in minutes with our quick onboarding.</S.Body>
              </S.TextColumn>
              <S.TextColumn>
                <S.Body>
                  Want to skip it and explore instead? You can always access onboarding later by
                  heading to "Settings" in your profile.
                </S.Body>
              </S.TextColumn>

              <S.Buttons>
                {onCancel && (
                  <S.SkipButton>
                    <Button onClick={onCancel}>Skip for now</Button>
                  </S.SkipButton>
                )}
                {onStartOnboarding && (
                  <Button variant={ButtonVariants.Primary} onClick={onStartOnboarding}>
                    Start onboarding
                  </Button>
                )}
              </S.Buttons>
            </S.Wrapper>
          </Paper>
        </S.Content>
      )}
    </S.Wrapper>
  );
};
