import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  min-height: 3.5rem;
  position: relative;
  text-align: left;
  width: 100%;
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  flex-direction: column;
  padding: 1.5rem 1rem;
`;

export const GenresImage = styled.img``;
export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const Description = styled.p`
  color: rgba(255, 255, 255, 0.75);
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding-top: 1rem;
  text-align: center;
`;
export const ActivityTitle = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bold};
`;
