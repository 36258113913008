import { useSelector } from 'react-redux';

import { RootReducerType } from '../reducers';

export const useStreaks = () => {
  const streaks = useSelector((state: RootReducerType) => state.user.streaks);
  const streakType = useSelector((state: RootReducerType) => state.userV2.preferences?.streakType);

  return {
    streakType,
    streaks,
  };
};
