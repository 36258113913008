import { EffectLevels } from '@Model';

export const DEMO_TRACK = {
  imageUrl:
    'https://images.unsplash.com/photo-1602731465531-14015e83a6de?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzd8fGRyZWFtJTIwbGlnaHR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
  neuralEffectLevel: EffectLevels.High,
  genre: 'Electronic',
  activity: 'Deep Work',
  title: 'Dreamlight',
  url: 'https://cdn.brain.fm/sounds/demo_track/Dreamlight_Focus_DeepWork_Electronic_5_120bpm_Nrmlzd2.mp3',
};
