import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ animationState: TransitionStatus }>`
  animation: ${({ theme }) => theme.animations.fadeIn} 0.3s ease-in-out;
  visibility: ${({ animationState }) => (animationState == 'entered' ? 'visible' : 'hidden')};
  margin: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${({ theme }) => theme.mediaQuery.maxWidth.xs} {
    flex-direction: column-reverse;
  }
`;

export const TextWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 20px;
`;

export const Text = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  span {
    font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
    color: white;
  }
`;

export const BoldText = styled(Text)`
  font-weight: 700;
  color: white;
`;

export const ButtonWrapper = styled.div`
  flex-direction: row;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  object-fit: contain;
  margin-bottom: 1rem;
`;
