import styled from 'styled-components';

export const OnboardingCardImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
  align-self: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 100%;
  }
`;

export const OnboardingCardTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
  align-self: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 100%;
  }
`;

export const Image = styled.img`
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 100%;
  }
`;

export const Subtitle = styled.div`
  color: #d4d2ea;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  letter-spacing: 0.2em;
  margin-bottom: 0.75rem;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 2rem;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 1rem;
  text-transform: lowercase;
`;

export const Body = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  margin-bottom: 0.75rem;
  line-height: 1.5rem;
`;

export const Buttons = styled.div`
  bottom: 5rem;
  display: flex;
  position: absolute;
  right: 2rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    bottom: auto;
    justify-content: flex-end;
    position: relative;
    right: auto;
    width: 100%;
  }
`;

export const SkipButton = styled.div`
  display: flex;
  margin-right: 1rem;
`;

export const StepPills = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 3.5rem;
  width: 100%;
`;

export const StepPill = styled.div<{ isActive?: boolean }>`
  background: ${({ theme, isActive }) =>
    isActive
      ? 'linear-gradient(60.85deg, #4915F8 -89.64%, #FF496B 126.05%)'
      : theme.colors.white300};
  border-radius: 0.5rem;
  height: 0.5rem;
  margin-right: 0.25rem;
  width: ${({ isActive }) => (isActive ? '2rem' : '1rem')};
`;
