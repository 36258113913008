import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 1rem;
  color: white;
  padding: 1rem 1.5rem;
  width: 100%;
`;

export const BackgroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100px;
  img {
    width: 100%;
  }
`;

export const BlueGreenReviewsWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const Review = styled.div<{ opacity: number }>`
  display: flex;
  flex-direction: column;
  opacity: ${props => props.opacity};
  position: absolute;
  top: 0;
  transition: opacity 1s ease-in-out;
`;

export const ReviewBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  font-weight: 300;
`;

export const ReviewAuthor = styled.div`
  color: #d4d2ea;
  font-size: 1rem;
  font-weight: 300;
  opacity: 0.7;
`;

export const ReviewSpacer = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
`;
