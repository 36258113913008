import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.625rem 1.25rem;
  border: 1px solid #ffffff;
  border-radius: 64px;
  user-select: none;
`;

export const IconContainer = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
`;

export const Icon = styled.img`
  height: 100%;
  width: auto;
  object-fit: contain;
`;

export const Text = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 0.75rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
`;
