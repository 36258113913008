import lottie from 'lottie-web';
import { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import loading from './assets/loading.json';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const shimmer = keyframes`
  from {
    opacity: 0.1;
  }
  50% {
    opacity: 0.15;
  }
  to {
    opacity: 0.1;
  }
`;

const Animation = styled.div<Pick<Props, 'height'>>`
  height: ${props => props.height};
`;

const Placeholder = styled.div<{ width: string }>`
  animation: ${shimmer} 2s infinite ease-in-out;
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0.1));
  border-radius: 0.25rem;
  color: transparent;
  margin-bottom: 0.125rem;
  width: ${({ width }) => width};
`;

export type Props = {
  uniqueId: string;
  height?: string;
};

export function Loading(props: Props) {
  useEffect(() => {
    const animation = lottie.loadAnimation({
      animationData: loading,
      autoplay: true,
      container: document.getElementById(props.uniqueId) as HTMLElement,
      loop: false,
      renderer: 'svg',
    });

    const onComplete = () => {
      animation.setSpeed(0.5);
      animation.goToAndPlay(11, true);
    };

    animation.addEventListener('complete', onComplete);

    return () => {
      animation.removeEventListener('complete', onComplete);
      animation.destroy();
    };
  }, []);

  return (
    <Container>
      <Animation height={props.height || '100%'} id={props.uniqueId} />
    </Container>
  );
}

type LoadingTextProps = {
  children?: string;
  width?: string;
};

export function LoadingText({ children = 'Loading...', width = 'auto' }: LoadingTextProps) {
  return <Placeholder width={width}>{children}</Placeholder>;
}
