import { Button, ButtonVariants } from '@Cortex';
import { NOOP } from '@Globals';
import React from 'react';

import * as S from './ProfileInfo.styles';
import VerifiedIcon from './assets/verified_icon.svg';
import { AuthenticatedWith } from './components/AuthenticatedWith';
import { UserProperty } from './components/UserProperty';
import { VerificationForm } from './components/VerificationForm';

export interface Props {
  authType?: string;
  name: string;
  isVerified?: boolean;
  isVisibleVerificationData?: boolean;
  email: string;
  onPasswordChange?: () => void;
  onEditProfile?: () => void;
}

export const ProfileInfoDisplay = ({
  authType = 'email',
  name,
  email,
  isVisibleVerificationData,
  isVerified,
  onEditProfile = NOOP,
  onPasswordChange = NOOP,
}: Props) => {
  return (
    <S.ContentContainer>
      {authType === 'apple' || authType === 'facebook' ? (
        <AuthenticatedWith platform={authType || ''} />
      ) : null}

      {name ? (
        <S.UserPropertyContainer>
          <UserProperty label="Name" value={name} valueTestId="profileName" />
        </S.UserPropertyContainer>
      ) : null}
      <S.UserPropertyContainer>
        <UserProperty
          icon={isVerified ? VerifiedIcon : undefined}
          iconTitle={isVerified ? 'Verified Email' : 'undefined'}
          label="Email"
          value={email}
          valueTestId="profileEmail"
        />
      </S.UserPropertyContainer>
      {!isVerified && isVisibleVerificationData && <VerificationForm />}
      <S.ButtonsWrapper>
        {authType === 'email' ? (
          <Button
            data-testid="changePassword"
            isFullWidth={true}
            keepTextCase={true}
            style={{ fontSize: '0.75rem' }}
            variant={ButtonVariants.Outline}
            onClick={onPasswordChange}
          >
            CHANGE PASSWORD
          </Button>
        ) : null}
        <Button
          data-testid="editProfileButton"
          isFullWidth={true}
          keepTextCase={true}
          style={{ fontSize: '0.75rem' }}
          variant={ButtonVariants.Secondary}
          onClick={onEditProfile}
        >
          EDIT PROFILE
        </Button>
      </S.ButtonsWrapper>
    </S.ContentContainer>
  );
};
