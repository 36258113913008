import { CoreAnalytics } from '@Analytics';
import { SignInForm } from '@Authentication';
import { CredentialResponse } from 'google-one-tap';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import * as authActions from '../../actions/authentication';
import { STORAGE_REDIRECT_PATH_KEY } from '../../constants';
import { useIsExtendedPromoAvailable } from '../../domains/Payment/components/PaywallModal/hooks/useIsExtendedPromoAvailable';
import { useAnalyticsPageView } from '../../hooks';
import { useInitializeGoogleAuth } from '../../hooks/useInitializeGoogleAuth';
import { RootReducerType } from '../../reducers';
import { FacebookLoginResponse } from '../../types/FacebookLogin';
import { getGAEvent } from '../../utils/analytics/ga';

export type SignInFormValues = {
  email: string;
  password: string;
};

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().min(3).required(),
});

export const LoginForm = () => {
  useAnalyticsPageView('sign_in_page_view');

  const isExtendedPromoAvailable = useIsExtendedPromoAvailable();
  const [searchParams] = useSearchParams();
  const referrerPath = sessionStorage.getItem(STORAGE_REDIRECT_PATH_KEY) || '/';
  const displayJoinTeamScreenOnSuccess = Boolean(searchParams.get('join'));
  const queryParams = createSearchParams(searchParams).toString();
  const successRedirectUrl = displayJoinTeamScreenOnSuccess
    ? `/joined/${searchParams.get('join')}`
    : isExtendedPromoAvailable
      ? `/payment${queryParams ? `?${queryParams}` : ''}`
      : `${referrerPath}${queryParams ? `?${queryParams}` : ''}`;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login } = useSelector((state: RootReducerType) => state.auth);

  const handleFacebookSignin = ({ authResponse }: FacebookLoginResponse) => {
    if (authResponse) {
      dispatch(
        authActions.facebookLoginRequest({
          accessToken: authResponse.accessToken,
          userID: authResponse.userID,
          navigate,
          successRedirectUrl,
        }),
      );
    } else {
      CoreAnalytics.trackSignInError({
        error: 'user cancelled facebook sign in',
        method: 'facebook',
      });
    }
  };

  const trackGoogleSignIn = useCallback(() => {
    getGAEvent('Signin', 'sign_in_google');
  }, []);

  const handleGoogleSignIn = useCallback((response: CredentialResponse) => {
    if (response.credential) {
      dispatch(
        authActions.googleLoginRequest({
          accessToken: response.credential,
          navigate,
          successRedirectUrl,
        }),
      );
    } else {
      CoreAnalytics.trackSignInError({
        error: 'Something went wrong during google sign in',
        method: 'google',
      });
    }
  }, []);

  useInitializeGoogleAuth({
    callback: handleGoogleSignIn,
    clickListener: trackGoogleSignIn,
  });

  const handleAppleLogin = async () => {
    CoreAnalytics.trackSignInAttempt({ method: 'apple' });
    dispatch(authActions.appleLoginRequest({ navigate, successRedirectUrl }));
  };

  const handleForgotPasswordClick = () => {
    navigate('/forgot-password');
  };

  return (
    <SignInForm<SignInFormValues>
      formError={login.error || null}
      formInitialValues={{ email: '', password: '' }}
      formInputs={[
        {
          label: 'Email',
          name: 'email',
          placeholder: '',
          type: 'email',
        },
        {
          label: 'Password',
          name: 'password',
          placeholder: '',
          type: 'password',
        },
      ]}
      formValidationSchema={validationSchema}
      isLoading={login.inProgress || login.emailLoading}
      onClickForgotPassword={handleForgotPasswordClick}
      onClickForgotPasswordAnalyticsEvent={getGAEvent('Signin', 'forgot_password')}
      onClickSignInWithApple={handleAppleLogin}
      onClickSignInWithAppleAnalyticsEvent={getGAEvent('Signin', 'sign_in_apple')}
      onClickSignInWithFacebook={() => {
        FB.login(handleFacebookSignin, { scope: 'public_profile,email' });
        CoreAnalytics.trackSignInAttempt({ method: 'facebook' });
      }}
      onClickSignInWithFacebookAnalyticsEvent={getGAEvent('Signin', 'sign_in_facebook')}
      onClickSignUp={() =>
        navigate({
          pathname: '/',
          search: createSearchParams(searchParams).toString(),
        })
      }
      onClickSignUpAnalyticsEvent={getGAEvent('Signin', 'sign_up_form')}
      onFormChange={() => {
        if (login.error) dispatch(authActions.clearErrors());
      }}
      onFormSubmit={values => {
        CoreAnalytics.trackSignInAttempt({ method: 'email' });
        dispatch(
          authActions.emailLoginRequest({
            values, // change types
            navigate,
            successRedirectUrl,
          }),
        );
      }}
      onFormSubmitAnalyticsEvent={getGAEvent('Signin', 'sign_in_email')}
    />
  );
};
