import styled from 'styled-components';

export const SubmitButton = styled.div`
  margin-bottom: 1rem;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  isolation: isolate;
  display: flex;
  padding: 1rem;
  justify-content: center;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.6);
  align-items: center;
`;
