import { takeEvery } from 'redux-saga/effects';

import { cancelStripeSubscription, acceptStripeSubscriptionDiscount } from '../actions';
import { cancelStripeSubscriptionSaga } from './cancelStripeSubscription';
import { acceptStripeSubscriptionDiscountSaga } from './acceptStripeSubscriptionDiscount';

export function* paymentSagas() {
  yield takeEvery(cancelStripeSubscription.type, cancelStripeSubscriptionSaga);
  yield takeEvery(acceptStripeSubscriptionDiscount.type, acceptStripeSubscriptionDiscountSaga);
}
