import { useCallback } from 'react';

import { useGet } from '../../client/client';
import { Endpoints } from '../../common/endpoints';
import { DynamicMentalStateResponseType } from './types';

export const useRequestHandler = () => {
  const get = useGet<DynamicMentalStateResponseType>();

  return useCallback(async () => {
    const data = await get({
      path: Endpoints.getDynamicMentalStates(),
      options: { apiVersion: 3 },
    });
    return data.result;
  }, [get]);
};
