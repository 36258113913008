import { API_BASE_URL, API_V3_BASE_URL } from '@Globals';

export const getBaseUrl = (version?: 2 | 3) => {
  switch (version) {
    case 3:
      return API_V3_BASE_URL;
    case 2:
    default:
      return API_BASE_URL;
  }
};
