import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TeamsState, UserTeamType } from '../types';

export const initialState: TeamsState = {
  list: [],
  isFetched: false,
};

const { actions: teamsSliceActions, reducer: teamsReducer } = createSlice({
  name: 'Teams',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    teamsInitiallyFetched(state) {
      return state;
    },
    teamsInitialFetchError(state) {
      return state;
    },
    setTeams(state, action: PayloadAction<UserTeamType[]>) {
      state.list = action.payload;
      state.isFetched = true;
    },
  },
});

export { teamsSliceActions, teamsReducer };
