import { TNewlyReleasedTracksArgs } from '../modules/NewlyReleasedTracks/types';
import { TRecentTracksArgs } from '../modules/RecentTracks/types';
import { TTracksByGenreArgs } from '../modules/TracksByGenre/types';
import { TTracksByMoodArgs } from '../modules/TracksByMood/types';

export const Endpoints = {
  getDynamicMentalStates: () => '/mentalStates/dynamic',
  getFastSubscriptionInfo: () => '/payments/fast-subscription-create-info',
  postCreateFastSubscription: () => '/payments/create-fast-subscription',
  getDynamicActivitiesByMentalStateId: (mentalStateId: string) =>
    `/mentalStates/dynamic/${mentalStateId}/activities`,
  getGenreNamesByMentalState: (mentalStateId: string) => `/mentalStates/${mentalStateId}/genres`,
  getGenreNamesByDynamicMentalState: (dynamicMentalStateId: string) =>
    `/mentalStates/dynamic/${dynamicMentalStateId}/genres`,
  getMoodsByDynamicMentalState: (dynamicMentalStateId: string) =>
    `/mentalStates/dynamic/${dynamicMentalStateId}/moods`,
  getGenreNamesByActivity: (activityId: string) => `/activities/${activityId}/genres`,
  getGenreNamesByDynamicActivity: (activityId: string) =>
    `/activities/dynamic/${activityId}/genres`,
  getSimilarTracks: (trackId: string) =>
    `/tracks/${trackId}/similar?platform=web&activitiesMode=dynamic`,
  getNewlyReleasedTracks: ({ mentalStateId, dynamicMentalStateId }: TNewlyReleasedTracksArgs) =>
    `/servings/new?${
      mentalStateId
        ? `mentalStateId=${mentalStateId}`
        : `dynamicMentalStateId=${dynamicMentalStateId}`
    }`,
  getRecentTracks: (userId: string, { mentalStateId, dynamicMentalStateId }: TRecentTracksArgs) =>
    `/users/${userId}/servings/recent?platform=web${
      mentalStateId ? `&mentalStateId=${mentalStateId}` : ''
    }${dynamicMentalStateId ? `&dynamicMentalStateId=${dynamicMentalStateId}` : ''}`,
  getTracksByGenre: (
    genreName: string,
    { mentalStateId, dynamicMentalStateId }: TTracksByGenreArgs,
  ) =>
    `/servings/search?genre=${genreName}&${
      mentalStateId
        ? `mentalStateId=${mentalStateId}`
        : `dynamicMentalStateId=${dynamicMentalStateId}`
    }`,
  getTracksByMood: (mood: string, { mentalStateId, dynamicMentalStateId }: TTracksByMoodArgs) =>
    `/servings/search?mood=${mood}&${
      mentalStateId
        ? `mentalStateId=${mentalStateId}`
        : `dynamicMentalStateId=${dynamicMentalStateId}`
    }`,
  getGiftCardInfo: (cardCode: string) => `/membership/gift-card/${cardCode}`,
  getUserTeams: (userId: string) => `/users/${userId}/teams`,
  getRecommendedTrack: (userId: string) =>
    `/users/${userId}/servings/recommended?platform=web&activitiesMode=dynamic`,
  getPublicTeamData: (teamId: string) => `/teams/public/${teamId}`,
  getTeamDataById: (teamId: string) => `/teams/${teamId}`,
  verifyEmail: (userId: string) => `/users/${userId}/verify`,
  enableSubscriptionAutoRenew: () => `/payments/enable-subscription-auto-renew`,
  resendVerificationEmail: (userId: string) => `/users/${userId}/sendVerificationEmail`,
  postRedeemGiftCard: (cardCode: string) => `/membership/gift-card/${cardCode}/redeem`,
  postJoinTeam: (userId: string, teamId: string) => `/users/${userId}/teams/${teamId}/join`,
  getPromotions: () => `/promotions`,
};
