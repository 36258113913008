import { Track } from '@Model';
import { createAction } from '@reduxjs/toolkit';

// TODO - is this needed or ultimately used?

type SetTrackPayload = Pick<
  Track,
  'id' | 'mentalStateId' | 'activityId' | 'genreName' | 'lengthInSeconds'
>;
export const setTrack = createAction<SetTrackPayload>('CURRENT_TRACK_SET');
