import { FontFamilies } from '@Cortex';
import styled from 'styled-components';
import { Assets } from '../../utils/assets';

export const Wrapper = styled.div`
  background-image: url(${Assets.images.activationBg.url});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
`;

export const Circles = styled.img``;

export const Title = styled.h1`
  color: #fff;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: center;
  margin-top: -100px;
`;

export const Description = styled.p`
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.9rem;
  line-height: 1.25rem;
  margin-bottom: 30px;
`;

export const PinWrapper = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 2rem;
  display: flex;
  gap: 5px;
`;

export const SubmitWrapper = styled.div`
  max-width: 392px;
  width: 100%;
  margin-top: 40px;
`;

export const ErrorWrapper = styled.div`
  color: red;
  font-family: ${FontFamilies.Bold};
  font-size: 18px;
  margin-top: 30px;
`;
