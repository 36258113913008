import { API_BASE_URL } from '@Globals';
import { CardElement } from '@stripe/react-stripe-js';
import { SetupIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';

export type Params = {
  billingName: string;
  stripe: Stripe | null;
  stripeElements: StripeElements | null;
  token: string;
  userId: string;
};

export enum Errors {
  CardElement = 'There was a problem finding the stripe.js Card Element',
  ClientSecret = 'There was a problem fetching the stripe client secret from brain.fm',
  ConfirmingPaymentIntent = 'There was a problem confirming payment intent with Stripe',
  StripeLibraries = 'There was a problem finding the stripe sdks',
  UpdatingPaymentMethod = 'There was a problem storing the update with brain.fm',
}

// TODO this is huge, but fully test covered, would like to break up when we start messing with this.
export async function handleSubmitUpdatePaymentForm(params: Params) {
  if (!params.stripe || !params.stripeElements) {
    return { error: Errors.StripeLibraries };
  }

  const cardElement = params.stripeElements.getElement(CardElement);
  if (!cardElement) {
    return { error: Errors.CardElement };
  }

  let clientSecretResponse: Response | null = null;
  let clientSecretResponseBody: { result?: { clientSecret: string }; messages?: string[] } | null =
    null;

  try {
    clientSecretResponse = await fetch(`${API_BASE_URL}/payments/subscription/payment-method`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${params.token}`, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userId: params.userId,
      }),
    });
    clientSecretResponseBody = await clientSecretResponse.json();
  } catch (e) {}

  const clientSecret = clientSecretResponseBody?.result?.clientSecret || null;
  if (!clientSecret) {
    return { error: Errors.ClientSecret };
  }

  let confirmCardSetupResponse: SetupIntentResult | null = null;

  try {
    confirmCardSetupResponse = await params.stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: params.billingName || undefined,
        },
      },
    });
  } catch (e) {
    throw e;
  }

  const paymentMethodId = confirmCardSetupResponse?.setupIntent?.payment_method;
  if (!paymentMethodId) {
    return { error: Errors.ConfirmingPaymentIntent };
  }

  let updatePaymentResponse: Response | null = null;
  let updatePaymentResponseBody: { result?: { success: boolean }; messages?: string[] } | null =
    null;

  try {
    updatePaymentResponse = await fetch(`${API_BASE_URL}/payments/subscription/payment-method`, {
      method: 'PUT',
      headers: { Authorization: `Bearer ${params.token}`, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        paymentMethodId,
        userId: params.userId,
      }),
    });
    updatePaymentResponseBody = await updatePaymentResponse.json();
  } catch (e) {}

  const isSuccessful = updatePaymentResponseBody?.result?.success || null;
  if (!isSuccessful) {
    return { error: Errors.UpdatingPaymentMethod };
  }

  return {
    result: isSuccessful,
    error: null,
  };
}
