type Listener = (state: { isReceived: boolean; error: any; isSettled: boolean }) => void;

class FeatureFlagsState {
  private isReceived = false;
  private error: any = null;
  private isSettled = false;
  private listeners: Listener[] = [];

  setState({
    isReceived,
    error,
    isSettled,
  }: {
    isReceived?: boolean;
    error?: any;
    isSettled?: boolean;
  }) {
    if (isReceived !== undefined) this.isReceived = isReceived;
    if (error !== undefined) this.error = error;
    if (isSettled !== undefined) this.isSettled = isSettled;

    this.listeners.forEach(listener =>
      listener({
        isReceived: this.isReceived,
        error: this.error,
        isSettled: this.isSettled,
      }),
    );
  }

  getState() {
    return {
      isReceived: this.isReceived,
      error: this.error,
      isSettled: this.isSettled,
    };
  }

  subscribe(listener: Listener) {
    this.listeners.push(listener);
    listener(this.getState());

    return () => {
      this.listeners = this.listeners.filter(l => l !== listener);
    };
  }

  reset() {
    this.isReceived = false;
    this.error = null;
    this.isSettled = false;
    // notify all listeners about the reset state
    this.listeners.forEach(listener => listener(this.getState()));
  }
}

export const featureFlagsState = new FeatureFlagsState();
