import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../../reducers';

export const useEligibleForTrial = () => {
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);
  const hadStripeSubscription = legacyMembership?.source === 'Stripe';

  return useMemo(() => {
    return !hadStripeSubscription;
  }, [hadStripeSubscription]);
};
