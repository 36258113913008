import { generatePath } from 'react-router-dom';
import { DA_PREFIXES, DYNAMIC_PLAYER_ACTIVITY_PATH } from '../constants';

export const getDynamicFavoritesActivityPlayerPath = (params: {
  userId: string;
  dynamicMentalStateId: string;
}) => {
  return generatePath(DYNAMIC_PLAYER_ACTIVITY_PATH, {
    activityId: `${DA_PREFIXES.FAVORITES}${params.userId}_${params.dynamicMentalStateId}`,
  });
};
