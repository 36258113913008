import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';

export function isExpired(membership: RootReducerType['user']['membership']): boolean {
  const expirationDate = new Date(membership?.expirationDate || '');
  const timeLeftInMilliseconds = expirationDate.getTime() - Date.now();

  return timeLeftInMilliseconds <= 0;
}

export function useIsExpired() {
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);

  return useMemo(() => {
    return isExpired(legacyMembership);
  }, [legacyMembership?.expirationDate]);
}
