import { StreakTypes } from '@User';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../actions/ui';

import { useStreaks } from '../../../hooks';
import { usePrevious } from '../../../hooks/usePrevious';
import { STREAK_MILESTONES_SHOW_MILESTONE_MODAL } from '../../../types';

export const useStreakMilestoneModalHandler = () => {
  const dispatch = useDispatch();
  const { streaks, streakType } = useStreaks();
  const prevCount = usePrevious(streaks.data.weekly.currentCount);

  useEffect(() => {
    if (streakType === StreakTypes.Hidden) return; // prevent animation if streaks are turned off
    if (
      typeof prevCount === 'undefined' ||
      prevCount >= streaks.data.weekly.currentCount ||
      !(STREAK_MILESTONES_SHOW_MILESTONE_MODAL as readonly number[]).includes(
        streaks.data.weekly.currentCount,
      )
    )
      return;
    dispatch(setModal('streakMilestone'));
  }, [streaks.data.weekly.currentCount, dispatch]);
};
