import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../../reducers';
import { EnhancedMembershipPlanType } from '../../../../../types';
import { PaymentStatus } from '../types';
import { useGetMembershipPlans } from './useGetMembershipPlans';
import { useHandlePayment } from './useHandlePayment';
import { useHandleStripeCheckout } from './useHandleStripeCheckout';

type Props = {
  onPaymentComplete?: () => void;
};

export const useCommonPaywallData = ({ onPaymentComplete }: Props) => {
  const plans = useGetMembershipPlans();
  const { info } = useSelector((state: RootReducerType) => state.user);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>('idle');
  const [selectedPlan, setSelectedPlan] = useState<EnhancedMembershipPlanType | null>(
    plans?.[0] || null,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handlePayment = useHandlePayment({
    plan: selectedPlan,
    onPaymentStatusChange: setPaymentStatus,
    onPaymentComplete,
    onError: setErrorMessage,
  });

  const handleStripeCheckout = useHandleStripeCheckout({
    plan: selectedPlan,
    onPaymentStatusChange: setPaymentStatus,
    onError: setErrorMessage,
  });

  const defaultBillingName = info?.firstName;

  return {
    plans,
    selectedPlan,
    setSelectedPlan,
    errorMessage,
    handlePayment,
    handleStripeCheckout,
    paymentStatus,
    defaultBillingName,
  };
};
