import * as S from './RadioButton.styles';

interface Props {
  text: string;
  variant: 'first' | 'default' | 'last';
  selected: boolean;
  onSelect: (value: string) => void;
  testid?: string;
}

export const RadioButton = ({ text, variant, selected, onSelect, testid }: Props) => {
  return (
    <S.Container data-testid={testid} variant={variant} onClick={() => onSelect(text)}>
      <S.RadioButtonLabel>{text}</S.RadioButtonLabel>
      <S.Radio>{selected ? <S.RadioSelected /> : null}</S.Radio>
    </S.Container>
  );
};
