import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileDisplay } from './Profile.display';
import { profileScreenLogEvent } from '../../actions/analytics';
import { signOut } from '../../actions/authentication';
import { useAccountItems } from './utils/useAccountItems';
import { ProfileItemActionType, ProfileItemType } from './Profile.types';
import { useCompanyItems } from './utils/useCompanyItems';
import { ProfileSection, SideDeckTab } from '../../types';
import { RootReducerType } from '../../reducers';

export function Profile() {
  const dispatch = useDispatch();
  const accountItems = useAccountItems();
  const companyItems = useCompanyItems();
  const [selectedView, setSelectedView] = useState<ProfileSection>(ProfileSection.Profile);
  const selectedTab = useSelector((state: RootReducerType) => state.ui.sideDeck.selectedTab);

  useEffect(() => {
    if (selectedTab.tab === SideDeckTab.Profile && selectedTab.profileSection) {
      setSelectedView(selectedTab.profileSection);
    }
  }, [selectedTab]);

  const handleLogout = useCallback(() => {
    dispatch(profileScreenLogEvent('profile_logout'));
    dispatch(signOut());
  }, [dispatch]);

  const handleSelectItem = useCallback((item: ProfileItemType) => {
    if (item.type === ProfileItemActionType.Screen) {
      setSelectedView(item.screen ?? ProfileSection.Profile);
    } else if (item.type === ProfileItemActionType.Linkout) {
      window.open(item.href, '_blank');
    }
  }, []);

  return (
    <ProfileDisplay
      accountItems={accountItems}
      companyItems={companyItems}
      selectedView={selectedView}
      onClickBack={() => setSelectedView(ProfileSection.Profile)}
      onClickLogout={handleLogout}
      onSelectItem={handleSelectItem}
    />
  );
}
