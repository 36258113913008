import { InputHTMLAttributes } from 'react';

import { Text } from '../Text';
import * as S from './ProfileFormInput.styles';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  errorMessage?: string;
  variant?: 'default' | 'first' | 'last' | 'individual';
  rightSideContent?: string | (() => JSX.Element);
}

export const ProfileFormInput = ({
  name,
  label,
  errorMessage = undefined,
  variant = 'default',
  ...props
}: Props) => {
  const inputStyles = {
    backgroundPosition: errorMessage ? '97%' : '120%',
    borderColor: errorMessage ? '#F34675' : 'initial',
  };

  return (
    <S.InputContainer variant={variant}>
      <S.InputLabel
        htmlFor={name}
        style={{ color: errorMessage ? '#F34675' : 'rgba(255, 255, 255, 0.75)' }}
      >
        {label}
      </S.InputLabel>
      <S.InputField id={name} name={name} style={inputStyles} {...props} />
      {props.rightSideContent ? (
        <S.RightSideContent>
          {typeof props.rightSideContent === 'string' ? (
            <Text style={{ marginRight: '1rem' }}>{props.rightSideContent}</Text>
          ) : (
            props.rightSideContent()
          )}
        </S.RightSideContent>
      ) : null}
      {errorMessage ? <S.ErrorText>{errorMessage}</S.ErrorText> : null}
    </S.InputContainer>
  );
};
