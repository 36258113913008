import * as S from './Paper.styles';

export type Props = {
  backgroundColor?: string;
  borderRadius?: string;
  children?: React.ReactNode;
  isScrollable?: boolean;
  padding?: string;
  style?: React.CSSProperties;
};

export function Paper({
  backgroundColor,
  borderRadius = '20px',
  children,
  isScrollable = false,
  padding = '2rem',
  style,
}: Props) {
  return (
    <S.Paper
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      isScrollable={isScrollable}
      padding={padding}
      style={style}
    >
      {children}
    </S.Paper>
  );
}

export function PaperOnHover(props: Props & { onClick?: () => void }) {
  return (
    <S.PaperOnHover
      backgroundColor={props.backgroundColor}
      borderRadius={props.borderRadius || '20px'}
      isScrollable={props.isScrollable || false}
      padding={props.padding || '2rem'}
      onClick={props.onClick}
    >
      {props.children}
    </S.PaperOnHover>
  );
}
