import { EffectLevels } from '@Model';
import { musicActions } from '@Music';
import { useDispatch, useSelector } from 'react-redux';

import { RootReducerType } from '../../../../../reducers';

export function useNeuralEffectFilterHandler() {
  const selectedNeuralEffectLevels = useSelector(
    (state: RootReducerType) => state.music.neuralEffectFilters,
  );
  const dispatch = useDispatch();

  function handleToggleSelectNeuralEffect(selectedNeuralEffect: EffectLevels) {
    if (selectedNeuralEffectLevels.includes(selectedNeuralEffect)) {
      dispatch(
        musicActions.setNeuralEffectFilters(
          selectedNeuralEffectLevels.filter(level => level !== selectedNeuralEffect),
        ),
      );
      return;
    }
    dispatch(
      musicActions.setNeuralEffectFilters([...selectedNeuralEffectLevels, selectedNeuralEffect]),
    );
  }

  return {
    title: 'Neural Effect',
    selectedItems: selectedNeuralEffectLevels,
    items: Object.values(EffectLevels),
    onItemClick: handleToggleSelectNeuralEffect,
  };
}
