import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Assets } from '../../../../utils/assets';
import VolumeLow from './assets/volume-1.svg';
import VolumeHigh from './assets/volume-2.svg';
import VolumeMute from './assets/volume-x.svg';

import * as S from './OnboardingVolumeControl.styles';

export type Props = {
  onChange: (volume: number) => void;
};

const DEFAULT_VOLUME_LEVEL = 0.8;

export const OnboardingVolumeControl = (props: Props) => {
  const [volume, setVolume] = useState(DEFAULT_VOLUME_LEVEL);
  const [lastManuallySetVolume, setLastManuallySetVolume] = useState(DEFAULT_VOLUME_LEVEL);
  const muted = volume == 0;

  const toggleMute = useCallback(() => {
    const newValue = muted ? Math.max(lastManuallySetVolume, 0.03) : 0;
    setVolume(newValue);
    props.onChange(newValue);
  }, [setVolume, muted, lastManuallySetVolume]);

  const handleVolumeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value) / 100;
      setVolume(value);
      setLastManuallySetVolume(value);
      props.onChange(value);
    },
    [setVolume, setLastManuallySetVolume],
  );

  const VolumeIcon: string = useMemo(() => {
    if (volume === 0) {
      return VolumeMute;
    }

    if (volume < 0.5) {
      return VolumeLow;
    }

    return VolumeHigh;
  }, [volume]);

  return (
    <S.Wrapper>
      <S.Image alt={Assets.icons.speakerOn.alt} src={VolumeIcon} onClick={toggleMute} />
      <S.SlideContainer active={true}>
        <S.SlideBg />
        <S.SlideFill style={{ width: `${volume * 100}%` }} />
        <S.Slide
          max="100"
          min="0"
          type="range"
          value={volume * 100}
          onChange={handleVolumeChange}
        />
      </S.SlideContainer>
    </S.Wrapper>
  );
};
