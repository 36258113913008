import { TextBold, Text } from '@Cortex';
import styled, { keyframes } from 'styled-components';
import { ANIMATION_TIMING_MS, HEADER_HEIGHT, HEADER_PADDING } from './constants';
import { JUMP_BACK_IN_HEIGHT } from '../../domains/Session/components/JumpBackInModal/constants';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    overflow: auto;
  }
`;

export const Wrapper = styled.div<{ selectedState: string; isJumpBackInVisible: boolean }>`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  transition:
    opacity ${ANIMATION_TIMING_MS}ms,
    transform 0.5s,
    filter 0.5s;
  // turns the opacity down during transition when a mental state is selected
  opacity: ${({ selectedState }) => (selectedState !== '' ? 0 : 1)};
  transform: ${({ isJumpBackInVisible }) => (isJumpBackInVisible ? 'scale(0.9)' : 'scale(1)')};
  filter: ${({ isJumpBackInVisible }) =>
    isJumpBackInVisible ? 'brightness(0.5)' : 'brightness(1)'};
  transform-origin: center;
  will-change: transform;
`;

export const Body = styled.div`
  animation: ${fadeInAnimation} 0.3s ease-out forwards;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    flex-direction: row;
    padding-bottom: ${HEADER_HEIGHT + HEADER_PADDING * 2}rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const BodyDynamic = styled.div`
  animation: ${fadeInAnimation} 0.3s ease-out forwards;
  flex: 1;
  width: 100%;
  height: calc(100% - ${HEADER_HEIGHT + HEADER_PADDING * 2}rem);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 4px;
  margin-bottom: 16px;
`;

export const HeaderIconLabel = styled.span`
  position: absolute;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  bottom: -14px;
  opacity: 0;
  transform: scale(0.4);
  transition: all 0.3s ease-in-out;
`;

export const HeaderIconContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover > ${HeaderIconLabel} {
    bottom: -20px;
    opacity: 1;
    transform: scale(1);
  }
  padding: 8px;
  border-radius: 100%;
  transition: background 0.2s ease-in-out;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const RecommendedActivitiesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    flex-direction: column;
    max-width: unset;
  }
`;

export const MentalStatesWrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template: repeat(4, 1fr) / 1fr;

  > * {
    justify-self: stretch;
    align-self: stretch;
    height: auto;
    width: auto;
    max-height: unset;
    max-width: unset;
    min-height: 120px;
    p {
      font-size: 1.5rem;
      text-transform: capitalize;
      pointer-events: none;
    }
  }

  > :first-child {
    grid-area: 1 / 1;
  }
  > :not(:first-child) {
    grid-column: 1;
  }

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    max-width: 1120px;
    max-height: 700px;
    grid-template: repeat(3, 1fr) / repeat(2, 1fr);
    :first-child {
      grid-area: 1 / 1 / 4 / 2;
    }
    :not(:first-child) {
      grid-column: 2;
    }
  }
`;

export const NewLayoutContainer = styled.div`
  width: 100%;
  padding: 12px;
  margin: 2rem 2rem 0 2rem;
  max-width: min(80vw, 1120px);

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    max-width: unset;
  }
`;

export const MentalStatesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    flex-direction: column;
    max-width: unset;
  }
`;

export const JumpBackInContainer = styled.div`
  position: relative;
  height: ${JUMP_BACK_IN_HEIGHT};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  opacity: 0.5;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    max-width: unset;
  }
`;

export const SeparatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1rem 0;
  border: 1px solid yelllow;
`;

export const Title = styled(TextBold)`
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #fff;
`;

export const Subtitle = styled(Text)`
  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
`;

export const MiniPlayerWrapper = styled.div`
  width: 100%;
  padding: 0 12px;

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    max-width: min(1120px);
  }
`;

export const MentalStateTitle = styled(Subtitle)`
  margin: 0 1rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;
