import * as Braze from '@braze/web-sdk';
import { useCallback, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';

import { Analytics } from '../../utils/analytics';
import { Events } from '../../utils/analytics/events';
import { Logger } from '../../utils/logger';

export function useTrackPageView() {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView();
  }, [location.pathname]);
}

export function useTrackPwaInstall() {
  const trackPwaInstall = useCallback(() => {
    Analytics.logEventWithProperties(Events.pwa_app_install, {});
  }, []);

  useEffect(() => {
    window.addEventListener('appinstalled', trackPwaInstall);
    return () => window.removeEventListener('appinstalled', trackPwaInstall);
  }, []);
}

export function trackSignUp(params: { userId?: string } = {}) {
  try {
    // 'signUp' event is deprecated, remove on february 17, 2023
    trackGoogleTagManagerEvent({
      event: 'signUp',
      transactionId: params.userId,
    });
    trackGoogleTagManagerEvent({
      event: 'createdAccount',
      transactionId: params.userId,
    });
    ReactPixel.track('CompleteRegistration');
  } catch (e) {
    Logger.error(new Error('useTrackSignUp(): unable to track analytics sign in'), { reason: e });
  }
}

export function trackPurchase(
  params: { now?: number; userId?: string; value?: number; planName?: string } = {},
) {
  try {
    // 'purchase' event is deprecated, remove on february 17, 2023
    trackGoogleTagManagerEvent({
      event: 'purchase',
      transactionId: `${params.userId}__${params.now || Date.now()}`,
      conversionValue: params.value,
    });
    trackGoogleTagManagerEvent({
      event: 'startedNewSubscription',
      transactionId: `${params.userId}__${params.now || Date.now()}`,
      conversionValue: params.value,
    });
    ReactPixel.track('Purchase', { value: params.value, currency: 'USD' });
    Braze.logCustomEvent(`purchase__${params.planName}`);
  } catch (e) {
    Logger.error(new Error('useTrackSignUp(): unable to track analytics purchase'), { reason: e });
  }
}

function trackGoogleTagManagerEvent(params: {
  event: 'createdAccount' | 'purchase' | 'signUp' | 'startedNewSubscription';
  transactionId?: string;
  conversionValue?: number;
}): void {
  (window as unknown as WindowWithGoogleTagManager).dataLayer.push(params);
}

export function trackSignInWithMagicLink(params: { campaignName: string }) {
  try {
    Braze.logCustomEvent(`sign_in_with_magic_link__${params.campaignName}`);
  } catch (e) {
    Logger.error(new Error('trackSignInWithMagicLink(): unable to track via braze'), { reason: e });
  }
}

export function trackListeningMilestone(params: { milestone: number }) {
  try {
    Braze.logCustomEvent(`listening_milestone__${params.milestone}`);
  } catch (e) {
    Logger.error(new Error('trackListeningMilestone(): unable to track via braze'), { reason: e });
  }
}

type WindowWithGoogleTagManager = {
  dataLayer: Datalayer;
};

type Datalayer = {
  push: (event: { event: string; transactionId?: string; conversionValue?: number }) => boolean;
};
