import { DynamicActivity } from '@Model';
import * as S from './styles';

type Props = {
  activity: DynamicActivity;
  isSmall: boolean;
  onClick: () => void;
};

export const RecommendedActivity = ({ activity, isSmall, onClick }: Props) => {
  if (!activity.metadata) return null;

  return (
    <S.Container isSmall={isSmall} onClick={onClick}>
      <S.ImageContainer>
        <S.AuraImage src={activity.metadata.images.aura.imageURL} />
        <S.Image
          alt={activity.metadata.images.background.altText}
          src={activity.metadata.images.background.imageURL}
        />
      </S.ImageContainer>
      <S.Title>{activity.displayValue}</S.Title>
      <S.Subtitle>
        {ACTIVITY_SUBTITLES[activity.displayValue] || 'A pick-me-up for your day'}
      </S.Subtitle>
    </S.Container>
  );
};

// TODO: tech debt for if we launch recommended activity after testing.
// needs to move to API
const ACTIVITY_SUBTITLES: { [key: string]: string } = {
  'Deep Sleep': 'Get healthy and prolonged rest',
  'Deep Work': 'Designed for tasks that require intense focus',
  'Guided Sleep': 'Get help transitioning from wakefulness to sleep',
  'Light Work': 'Tackle smaller tasks in quick succession',
  'Power Nap': 'Take short restorative naps',
  'Sleep & Wake': 'Ease into sleep and wake up energized',
  'Unwind & Sleep': 'Shift from alertness to restful sleep',
  Cardio: 'Boost energy and performance',
  Chill: 'Relax with engaging musical selections',
  Creativity: 'Inspire design, ideation, or expression',
  Destress: 'Transition from heightened tension to relaxation',
  Guided: 'Meditate with guidance from trained practitioners',
  Learning: 'Boost knowledge retention or support long reading sessions',
  Motivation: 'Enhance your drive and concentration',
  Recharge: 'Refocus, recenter, and gain energy',
  Unguided: 'Reach deeper levels of meditation',
  Unwind: 'Relax after a period of work or intense focus',
};
