import styled from 'styled-components';

export const Container = styled.button<{
  isActive: boolean;
  hasRoundedTop: boolean;
  hasRoundedBottom: boolean;
}>`
  align-items: center;
  background: ${({ isActive }) => (isActive ? 'rgba(255,255,255,0.1)' : 'transparent')};
  border: none;
  cursor: pointer;
  display: flex;
  min-height: 3.5rem;
  outline: none;
  padding: 0.75rem 1rem;
  position: relative;
  text-align: left;
  width: 100%;

  border-top-left-radius: ${({ hasRoundedTop }) => (hasRoundedTop ? '1rem' : '0')};
  border-top-right-radius: ${({ hasRoundedTop }) => (hasRoundedTop ? '1rem' : '0')};
  border-bottom-left-radius: ${({ hasRoundedBottom }) => (hasRoundedBottom ? '1rem' : '0')};
  border-bottom-right-radius: ${({ hasRoundedBottom }) => (hasRoundedBottom ? '1rem' : '0')};
  border-top-width: ${({ hasRoundedTop }) => (hasRoundedTop ? '1px' : '0')};
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: rgba(255, 255, 255, 0.1);
  border-style: solid;

  transition: background 0.2s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    opacity: 1;
  }

  &:active {
    background: #333152;
  }
`;

export const TextContainer = styled.div`
  margin-right: 5px;
  flex: 1;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const Checkmark = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in-out;
  opacity: ${({ isSelected }) => (isSelected ? '1' : '0')};
`;
