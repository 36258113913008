import { useDispatch } from 'react-redux';

import { timerActions } from './reducer';

export function useTurnOnPomodoroTimer() {
  const dispatch = useDispatch();

  return function turnOnPomodoroMode() {
    dispatch(timerActions.turnOnPomodoroMode());
  };
}
