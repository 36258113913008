import styled from 'styled-components';
import { Assets } from '../../../../utils/assets';

export const CallToActionWidget = styled.div`
  align-items: center;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;
  background: linear-gradient(0deg, rgba(36, 32, 49, 1) 0%, rgba(49, 43, 67, 1) 59%);
  border-radius: 76px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  display: flex;
  flex-shrink: 0;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  gap: 1rem;
  padding: 0.4rem 0.4rem 0.4rem 0.8rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    justify-content: space-between;
    width: 100%;
  }
`;

export const Body = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Icon = styled.img``;

export const Highlight = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  position: relative;
  display: inline-block;
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 7px;
    bottom: -7px;
    width: 100%;
    background-image: url(${Assets.images.highlight.url});
    background-repeat: no-repeat;
    background-position: center bottom;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
