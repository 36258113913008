import { MembershipPlanType, EnhancedMembershipPlanType } from '../../../../../types';

/**
 * Modifies given monthly and yearly membership plans' description and adds
 * a new property called monthlyCost and trial lengths. Filters non-yearly plans in case extended trial is applied
 * @param plans
 * @param trialLengths
 * @param isExtendedPromoAvailable
 * @returns
 */
type Args = {
  plans: MembershipPlanType[];
  trialLengths: { yearly: number; monthly: number };
};

export function enhanceMembershipInfo({ plans, trialLengths }: Args): EnhancedMembershipPlanType[] {
  const enhancedPlans: EnhancedMembershipPlanType[] = [];

  plans.forEach(plan => {
    let newPlan: EnhancedMembershipPlanType = {
      ...plan,
      displayCost: '0',
      displayInterval: 'mo',
      trialLength: 0,
    };

    if (plan.title === 'Yearly') {
      const price = plan.price / 100;

      newPlan.price = price;
      newPlan.displayCost = (price / 12).toFixed(2).toLocaleString();
      newPlan.description = `$${price} billed yearly.`;
      newPlan.trialLength = trialLengths.yearly;
    } else {
      const price = plan.price / 100;

      newPlan.price = price;
      newPlan.displayCost = price.toString();
      newPlan.description = `$${price} billed monthly.`;
      newPlan.trialLength = trialLengths.monthly;
    }

    enhancedPlans.push(newPlan);
  });

  return enhancedPlans;
}
