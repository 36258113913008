import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../reducers';
import { Assets } from '../../../../utils/assets';
import * as S from './PredefinedGenres.styles';
export const PredefinedGenres = () => {
  const sessionDynamicActivityTitle = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.displayValue,
  );

  return (
    <S.Container>
      <S.BackgroundImage
        alt={Assets.images.circlesGradient.alt}
        src={Assets.images.circlesGradient.url}
      />
      <S.GenresImage alt={Assets.images.genres.alt} src={Assets.images.genres.url} />
      <S.Description>
        <S.ActivityTitle>{sessionDynamicActivityTitle}</S.ActivityTitle> has its own mix of genres
        for a unique listening experience.
      </S.Description>
    </S.Container>
  );
};
