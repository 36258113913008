import { MARQUEE_TEXT_ANIMATION_LENGTH_IN_SECONDS } from './MarqueeText.constants';
import styled from 'styled-components';

export const MarqueeTextContainer = styled.div`
  overflow: hidden;
  width: 100%;
  font-size: 0;
`;

export const MarqueeTextScrollingText = styled.div<{ distanceFromRight: number }>`
  display: inline-block;
  transform: translateX(-${({ distanceFromRight }) => distanceFromRight}px);
  transition: transform ${MARQUEE_TEXT_ANIMATION_LENGTH_IN_SECONDS / 2}s linear;
  white-space: nowrap;
`;
