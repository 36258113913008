import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { RecentSessionType } from '../domains/Session/components/JumpBackInModal/types';

export interface RecentSessionsReducerType {
  status: 'loading' | 'idle';
  ids: string[];
  results: {
    [key: string]: RecentSessionType;
  };
}

const initialState: RecentSessionsReducerType = {
  status: 'idle',
  ids: [],
  results: {},
};

const recentSessionsSlice = createSlice({
  name: 'recentSessions',
  initialState,
  reducers: {
    fetchRecentSessions: state => {
      state.status = 'loading';
      return state;
    },
    fetchRecentSessionsSuccess: (state, action: PayloadAction<RecentSessionType[]>) => {
      const ids = action.payload.map(payload => payload.id);
      const results = action.payload.reduce<RecentSessionsReducerType['results']>((acc, curr) => {
        return {
          ...acc,
          [curr.id]: curr,
        };
      }, {});

      return { ...state, status: 'idle', ids, results };
    },
    clearState: () => initialState,
  },
});

const persistConfig = {
  key: 'recentSessions',
  storage,
  blacklist: ['status'],
};

export const recentSessionsSliceActions = recentSessionsSlice.actions;
export const recentSessionsPersistReducer = persistReducer(
  persistConfig,
  recentSessionsSlice.reducer,
);
