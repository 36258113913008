import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 2.5rem;
  gap: 8px;
`;

export const Item = styled.button<{ isActive: boolean }>`
  flex: 1;
  background-color: ${({ isActive }) => (isActive ? 'white' : 'transparent')};
  color: ${({ isActive }) => (isActive ? 'black' : 'white')};
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  border-radius: 76px;
  padding: 8px 16px;
`;

export const Pill = styled.div`
  position: absolute;
  z-index: 1;
  background: white;
  border-radius: 4.75rem;
  height: 100%;
  transition:
    transform 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
`;
