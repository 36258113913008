import styled from 'styled-components';

export const Wrapper = styled.div`
  color: #fff;
  display: flex;
  min-height: 600px;
  gap: 15px;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    min-height: initial;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 1;
`;
