import { Text } from '@Cortex';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
  padding: 0.25rem;
  width: 100%;
  flex-direction: column;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.25rem;
  margin-bottom: 1.25rem;
  flex: 1;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SectionTitle = styled(Text)`
  color: rgba(255, 255, 255, 0.75);
  margin-bottom: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 10px;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const GenresContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const GenreContainer = styled.div`
  width: 100%;
`;

export const Loading = styled.div`
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  &:after {
    animation: rotation 0.8s linear infinite;
    border-radius: 100%;
    border-right: 3px solid transparent;
    border-top: 3px solid rgba(255, 255, 255, 0.8);
    content: '';
    height: 2rem;
    position: absolute;
    width: 2rem;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;
