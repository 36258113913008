import { FontColors } from '@Cortex';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const ContainerWithIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 40rem;
  max-width: 55rem;
  margin: auto;
  padding: 1rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 2.5rem 2.5rem 2.5rem;
  width: 30.625rem;
  margin: auto;
  background: linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 0.75rem;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
`;

export const ImageContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
  border-radius: 100%;
  background: #6efe96;
  box-shadow:
    0px 0px 8px 0px rgba(110, 254, 150, 0.5),
    0px 0px 32px 0px rgba(110, 254, 150, 0.5);
`;

export const Image = styled.img`
  width: 2rem;
  height: 2rem;
  object-fit: contain;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1.25rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0.5rem;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
  color: ${FontColors.WhiteTransparent};
  text-align: center;
  width: 90%;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
