import { useSelector } from 'react-redux';

import { RootReducerType } from '../../reducers';
import { TimerDisplayTypes } from './constants';
import {
  calculatePomodoroDisplayValues,
  getDisplayValueFromMilliseconds,
} from '../../components/session/utils/formatTime';

export function useTimerDisplayValue() {
  const timerDisplayType = useSelector<RootReducerType, RootReducerType['timer']['displayType']>(
    state => state.timer.displayType,
  );
  const countUpDisplayValue = useCountUpTimerDisplayValue();
  const pomodoroDisplayValue = usePomodoroDisplayValues().time;

  if (timerDisplayType === TimerDisplayTypes.Pomodoro) {
    return pomodoroDisplayValue;
  }

  return countUpDisplayValue;
}

function useCountUpTimerDisplayValue() {
  const timerState = useSelector<RootReducerType, RootReducerType['timer']>(state => state.timer);
  if (timerState.startTime === null) return '0:00';

  const elapsedTimeInMilliseconds =
    timerState.currentTime - timerState.startTime - (timerState.timeSpentPaused ?? 0);
  return getDisplayValueFromMilliseconds(elapsedTimeInMilliseconds);
}

export function usePomodoroDisplayValues() {
  const timerState = useSelector<RootReducerType, RootReducerType['timer']>(state => state.timer);
  return calculatePomodoroDisplayValues(timerState);
}
