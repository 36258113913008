import { Serving, Track } from '@Model';

import { isServing } from './isServing';

export function getTrackLengthInSeconds(trackOrServing?: Track | Serving | null): number {
  if (isServing(trackOrServing)) {
    return trackOrServing.trackVariation.lengthInSeconds;
  }

  return trackOrServing?.serving.trackVariation.lengthInSeconds || 0;
}
