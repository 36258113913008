import { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';

import { Assets } from '../../utils/assets';
import { ButtonProps } from '../buttons/ButtonBase';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

const Image = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  object-fit: contain;
`;

const Text = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.family.default.medium};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: ${({ theme }) => theme.fontSize.extraSmall};
  margin: 0 0.5rem;
`;

export const ForgotPasswordButton: React.FC<
  AnchorHTMLAttributes<HTMLAnchorElement> & ButtonProps
> = ({ analyticsEvent, href, onClick, ...props }) => {
  return (
    <Container>
      <StyledLink data-analytics-event={analyticsEvent} href={href} onClick={onClick} {...props}>
        <Image alt={Assets.emojis.monkeyNoPeek.alt} src={Assets.emojis.monkeyNoPeek.url} />
        <Text>Forgot your password?</Text>
      </StyledLink>
    </Container>
  );
};
