import lottie from 'lottie-web';
import { useEffect } from 'react';
import styled from 'styled-components';

import loading from '../../assets/lottie/loading.json';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingAnimation = styled.div`
  width: 100%;
  max-width: 30rem;
  height: auto;
`;

const AppLoading = () => {
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: document.getElementById('loadingApp') as HTMLElement,
      autoplay: true,
      renderer: 'svg',
      loop: false,
      animationData: loading,
    });

    const onComplete = () => {
      animation.setSpeed(0.5);
      animation.goToAndPlay(11, true);
    };

    animation.addEventListener('complete', onComplete);

    return () => {
      animation.removeEventListener('complete', onComplete);
      animation.destroy();
    };
  }, []);

  return (
    <Container>
      <LoadingAnimation id="loadingApp" />
    </Container>
  );
};

export default AppLoading;
