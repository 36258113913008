import { put, takeEvery } from 'redux-saga/effects';
import { activationActions } from './reducer';
import { Action } from '@reduxjs/toolkit';
import { requestSaga } from '../../sagas/httpRequest';
import { RequestMethods } from '../../api/client/types';

export function* activationSagas() {
  yield takeEvery(activationActions.submitPin.type, submitPinSaga);
}

function* submitPinSaga(action: Action) {
  if (!activationActions.submitPin.match(action)) return;
  if (!action.payload.userId) return;

  try {
    const response = (yield requestSaga(
      RequestMethods.POST,
      `/users/${action.payload.userId}/devicePairings/${action.payload.pin}`,
      undefined,
      3,
    )) as unknown as Response;

    if (response.status === 200) {
      yield put(activationActions.acceptPin());
    } else {
      yield put(activationActions.rejectPin());
    }
  } catch (error) {
    yield put(activationActions.rejectPin());
  }
}
