// we can rename all origins to what they are in the core analytics when we remove old events from the codebase
import { SubscriptionImpressionOrigin } from '../../../../Analytics/coreAnalytics.types';

export const getAnalyticsOrigin = (origin?: string): SubscriptionImpressionOrigin => {
  switch (origin) {
    case 'Membership Widget':
      return SubscriptionImpressionOrigin.Player;
    case 'Membership Panel TrialCallToAction':
    case 'Membership Panel SubscriptionInformation':
    case 'Membership Panel Expired':
      return SubscriptionImpressionOrigin.MenuSubscriptionPage;
    case 'Expired Modal':
      return SubscriptionImpressionOrigin.SubscriptionExpiredState;
    case 'Onboarding':
      return SubscriptionImpressionOrigin.Onboarding;
    default:
      return SubscriptionImpressionOrigin.Direct;
  }
};
