import { Button, ButtonVariants, ButtonVariantStates } from '@Cortex';
import { NOOP } from '@Globals';
import { Formik } from 'formik';
import React, { ReactNode } from 'react';
import * as Yup from 'yup';
import CardSVG from './assets/card_pulse.svg';

import * as S from './UpdateStripePaymentMethodPanel.styles';
import { ModalContainer } from '../../../shared/ModalContainer';

const validationSchema = Yup.object({
  name: Yup.string().required('Please fill out billing name'),
});

export type Props = {
  cardInformation: string;
  errorMessage?: string | null;
  billingName?: string;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit?: (event: string) => void;
  submitButtonTitle?: string;
  submitButtonVariantState?: ButtonVariantStates;
  children: ReactNode | undefined;
};

export const UpdateStripePaymentMethodPanel: React.FC<Props> = ({
  cardInformation,
  children,
  errorMessage = null,
  billingName = '',
  isLoading = false,
  onClose,
  onSubmit = NOOP,
  submitButtonTitle = 'Save',
  submitButtonVariantState = ButtonVariantStates.Idle,
}) => {
  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  return (
    <S.Container>
      <ModalContainer
        description={`Current payment method is ${cardInformation}.`}
        headerImage={CardSVG}
        title="Change Payment Method"
        onClose={handleClose}
      >
        <Formik
          initialValues={{ name: billingName }}
          validationSchema={validationSchema}
          onSubmit={evt => onSubmit(evt.name)}
        >
          {({ handleSubmit, handleChange, errors, touched, values }) => {
            const hasErrors = touched.name && errors.name ? true : false;

            return (
              <>
                <S.InputContainer variant="first">
                  <S.NameInput
                    autoComplete="name"
                    id="nameInput"
                    name="name"
                    placeholder="Billing Name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </S.InputContainer>

                <S.InputContainer data-testid="cardContainer" variant="last">
                  {children}
                </S.InputContainer>

                <S.ErrorBox>
                  <S.ErrorText>{errorMessage || (hasErrors ? errors.name : '')}</S.ErrorText>
                </S.ErrorBox>

                <S.SubmitButton>
                  <Button
                    data-testid="confirmUpdatePaymentButton"
                    isFullWidth={true}
                    keepTextCase
                    style={{ fontSize: '0.75rem' }}
                    type="button"
                    variant={ButtonVariants.Secondary}
                    variantState={
                      isLoading ? ButtonVariantStates.Loading : submitButtonVariantState
                    }
                    onClick={() => handleSubmit()}
                  >
                    {submitButtonTitle.toUpperCase()}
                  </Button>
                </S.SubmitButton>
                <Button
                  keepTextCase
                  style={{ fontSize: '0.75rem' }}
                  type="button"
                  variant={ButtonVariants.Base}
                  variantState={isLoading ? ButtonVariantStates.Loading : ButtonVariantStates.Idle}
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
              </>
            );
          }}
        </Formik>
      </ModalContainer>
    </S.Container>
  );
};
