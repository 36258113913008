import {
  Button,
  ButtonVariants,
  ButtonVariantStates,
  TextBold,
  Text,
  Icon,
  TextSemiBold,
} from '@Cortex';
import { NOOP } from '@Globals';
import { Formik } from 'formik';
import React, { ReactNode, useState } from 'react';
import * as Yup from 'yup';

import { EnhancedMembershipPlanType } from '../../../../../../../../types';
import { Assets } from '../../../../../../../../utils/assets';
import * as S from './SingleScreenSlideUpPaywallPaymentForm.styles';
import { Checkmark } from '../Checkmark/Checkmark';
import { CouponCode } from '@Payment';
import { GiftCard } from '../../../../../GiftCard';

const validationSchema = Yup.object({
  name: Yup.string().required('Please fill out billing name'),
});

export interface Props {
  billingInterval?: string;
  billingName?: string;
  buttonSubtitle?: string;
  errorMessage?: string | null;
  hasDiscount: boolean;
  onBack: () => void;
  onSubmit?: (value: string) => void;
  selectedPlan: EnhancedMembershipPlanType | null;
  children: ReactNode | undefined;
  variantState: ButtonVariantStates;
  footnote: string;
}

export type PaymentStatus = 'idle' | 'loading' | 'success' | 'error';

export const SingleScreenSlideUpPaywallPaymentForm: React.FC<Props> = ({
  footnote,
  billingName = '',
  buttonSubtitle,
  children,
  errorMessage = null,
  onSubmit = NOOP,
  onBack,
  hasDiscount,
  selectedPlan,
  variantState,
}) => {
  const [couponSectionVisible, setCouponSectionVisible] = useState(false);
  const [nameInputFocused, setNameInputFocused] = useState(false);

  return (
    <S.Container>
      <S.PaymentContainer>
        <S.ChevronWrapper>
          <Icon
            size={34}
            src={Assets.icons.arrowUp.url}
            style={{ cursor: 'pointer' }}
            onClick={onBack}
          />
        </S.ChevronWrapper>
        <form style={{ display: 'flex', justifyContent: 'center' }}>
          <Formik
            initialValues={{ name: billingName }}
            validationSchema={validationSchema}
            onSubmit={values => onSubmit(values.name)}
          >
            {({ handleChange, handleSubmit, errors, touched, values }) => {
              const hasErrors = !!(touched.name && errors.name);

              return (
                <S.FormBox>
                  <S.CardBox>
                    <S.TrialInfo>
                      {selectedPlan?.trialLength
                        ? `${selectedPlan?.trialLength}-Day Free Trial`
                        : `Start your ${selectedPlan?.title} Membership`}
                    </S.TrialInfo>
                    <S.PlanDescription>{selectedPlan?.description}</S.PlanDescription>
                    <S.NameInputContainer>
                      <S.NameInputLabel htmlFor="billingName" isError={hasErrors}>
                        {hasErrors ? errors.name : 'Billing Name'}
                      </S.NameInputLabel>
                      <S.NameInput
                        data-testid="billingName"
                        id="billingName"
                        isFocused={nameInputFocused}
                        name="name"
                        placeholder="Billing Name"
                        value={values.name}
                        onBlur={() => setNameInputFocused(false)}
                        onChange={handleChange}
                        onFocus={() => setNameInputFocused(true)}
                      />
                    </S.NameInputContainer>

                    <S.NameInputLabel htmlFor="billingName">Card Information</S.NameInputLabel>
                    <S.CardContainer data-testid="cardContainer">{children}</S.CardContainer>
                    <S.BelowCardInfo>
                      <S.PoweredByImage src={Assets.images.poweredByStripe.url} />
                      <Checkmark customIcon={Assets.icons.locker.url}>
                        Guaranteed safe and secure checkout
                      </Checkmark>
                      <Checkmark customIcon={Assets.icons.guarantee.url}>
                        60-Day money back guarantee
                      </Checkmark>
                    </S.BelowCardInfo>

                    <S.StartContainer>
                      {selectedPlan?.trialLength ? (
                        <S.StartInfoContainer>
                          <S.PriceInfo
                            data-testid={
                              hasDiscount ? 'discountAdditionalInfo' : 'planAdditionalInfo'
                            }
                          >
                            <Text size="1rem">Due Today</Text>
                            <TextBold size="2rem">$0.00</TextBold>
                          </S.PriceInfo>
                          <S.YearlyPriceTextContainer>
                            <S.YearlyPriceText data-testid="trialLengthInfo">
                              {`After ${selectedPlan?.trialLength} days: $${
                                hasDiscount ? selectedPlan?.displayCost : selectedPlan?.price
                              }`}
                            </S.YearlyPriceText>
                          </S.YearlyPriceTextContainer>
                        </S.StartInfoContainer>
                      ) : (
                        <S.StartInfoContainer>
                          <S.PriceInfo
                            data-testid={
                              hasDiscount ? 'discountAdditionalInfo' : 'planAdditionalInfo'
                            }
                          >
                            <Text size="1rem">Price</Text>
                            <TextBold size="2rem">
                              ${hasDiscount ? selectedPlan?.displayCost : selectedPlan?.price}
                            </TextBold>
                          </S.PriceInfo>
                        </S.StartInfoContainer>
                      )}
                      <S.ButtonContainer>
                        <Button
                          data-testid="handlePurchase"
                          isFullWidth
                          keepTextCase
                          type="button"
                          variant={ButtonVariants.Secondary}
                          variantState={variantState}
                          onClick={() => handleSubmit()}
                        >
                          {selectedPlan?.trialLength ? 'Start free trial' : 'Start Subscription'}
                        </Button>
                      </S.ButtonContainer>
                    </S.StartContainer>
                    {buttonSubtitle ? <S.ButtonSubtitle>{buttonSubtitle}</S.ButtonSubtitle> : null}

                    <S.CouponContainer>
                      {!couponSectionVisible ? (
                        <S.CouponText>
                          Have a gift card or coupon code?
                          <S.CouponButton
                            data-testid="displayCouponForm"
                            type="button"
                            onClick={() => setCouponSectionVisible(true)}
                          >
                            Click here
                          </S.CouponButton>
                        </S.CouponText>
                      ) : null}

                      {couponSectionVisible ? (
                        <S.CouponGiftCardContainer>
                          <S.CouponSection>
                            <S.CouponTextWrapper>
                              <TextSemiBold>Coupon Code</TextSemiBold>
                            </S.CouponTextWrapper>
                            <S.CouponSectionInputWrapper>
                              <CouponCode layout="modern" />
                            </S.CouponSectionInputWrapper>
                          </S.CouponSection>

                          <S.CouponSection>
                            <S.CouponTextWrapper>
                              <TextSemiBold>Gift Card</TextSemiBold>
                            </S.CouponTextWrapper>
                            <S.CouponSectionInputWrapper>
                              <GiftCard layout="modern" />
                            </S.CouponSectionInputWrapper>
                          </S.CouponSection>
                        </S.CouponGiftCardContainer>
                      ) : null}
                    </S.CouponContainer>
                  </S.CardBox>
                  {errorMessage ? (
                    <S.ErrorWrapper>
                      <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
                    </S.ErrorWrapper>
                  ) : null}
                </S.FormBox>
              );
            }}
          </Formik>
        </form>
        <S.SubscriptionInfoText>{footnote}</S.SubscriptionInfoText>
      </S.PaymentContainer>
    </S.Container>
  );
};
