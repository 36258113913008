import { createAction } from '@reduxjs/toolkit';

export interface MakePaymentType {
  paymentMethodId: string;
  planId: number;
}

export interface FinishPaymentType {
  subscriptionId: string;
  planId: number;
}

export const makePayment = createAction<MakePaymentType>('PAYMENTS_SUBMIT');
export const finishPayment = createAction<FinishPaymentType>('PAYMENTS_FINISH');
export const createStripeCustomer = createAction('PAYMENTS_CREATE_CUSTOMER');

export const cancelMembership = createAction('PAYMENTS_CANCEL_PLAN');
export const updateMembership = createAction<number>('PAYMENTS_UPDATE_PLAN');

export const clearTransactionState = createAction('PAYMENTS_CLEAR_TRANSACTION_STATE');
