import { Icon } from '@Cortex';
import { StreakTypes } from '@User';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as uiActions from '../../../../../../actions/ui';
import { useStreaks } from '../../../../../../hooks';
import { Assets } from '../../../../../../utils/assets';

import * as S from './StreaksWidgetLabel.styles';

export const StreaksWidgetLabel = () => {
  const { streaks, streakType } = useStreaks();
  const dispatch = useDispatch();
  const setStreaksDetailsExpanded = useCallback(() => {
    dispatch(uiActions.setModal('streaksInfo'));
  }, [dispatch]);

  if (streakType === StreakTypes.Hidden) return null;
  const value = streaks.data.weekly.currentCount;

  return (
    <S.Wrapper data-testid="streaks" onClick={setStreaksDetailsExpanded}>
      <Icon size={24} src={Assets.icons.rocket.url} />
      <S.Text>{`${value} week streak`}</S.Text>
    </S.Wrapper>
  );
};
