import { HTMLAttributes } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ isActive: boolean }>`
  margin: 0.75rem;
  width: 90%;
  height: 100%;
  max-height: 25vh;
  min-width: 13rem;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.01) 100%);
  position: relative;

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;

  background-origin: border-box;
  background-clip: content-box, border-box;

  transition:
    opacity 0.25s ease-in-out,
    transform 0.3s ease-in;
  overflow: hidden;

  &:hover {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.04) 100%
    );
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    width: 25vw;
    height: 75vh;
    max-width: 426.44px;
    max-height: 699.04px;
    border-radius: 1rem;
  }
`;

const ImageArea = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const TextContainer = styled.div`
  display: inline;
  flex-direction: column;
  padding: 3rem;
  line-height: 24px;
  color: #ffffff;
  font-size: 2.25rem;
  font-weight: semi-bold;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    bottom: 1rem;
    left: 2rem;
    padding: 1rem;
  }
`;

const Caption = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-size: 22px;
  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    font-size: 28px;
  })
`;

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  isActive?: boolean;
  text: string;
}

const Card: React.FC<CardProps> = ({ text, isActive = true, children, ...props }) => {
  return (
    <Container isActive={isActive} {...props}>
      <ImageArea>{children}</ImageArea>
      <TextContainer>
        <Caption>{text}</Caption>
      </TextContainer>
    </Container>
  );
};

export default Card;
