import { AudioPlayerPlayStyles } from '@AudioPlayer';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../reducers';

export function useIsAudioPlayerRepeatEnabled() {
  return useSelector(isAudioPlayerRepeatEnabled);
}

export function isAudioPlayerRepeatEnabled(state: RootReducerType): boolean {
  return state.audioPlayer.playStyle === AudioPlayerPlayStyles.Repeat;
}
