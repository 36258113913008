import {
  UserPreferenceDisplayTypes,
  UserMentalStatePreferenceTypes,
  UserPreferencesType,
  userSliceActions,
  userActions,
} from '@User';
import { APIResponse } from '@Utils';
import { Action } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import { RootReducerType } from 'src/reducers';

import { RequestMethods } from '../../../api/client/types';
import { requestSaga, SuccessResponseType } from '../../../sagas/httpRequest';
import { MentalStates, SessionType } from '../../../types';
import { Logger } from '../../../utils/logger';
import * as userAction from '../actions';
import { getPreferenceAdditionsAndRemovals } from './lib/getPreferenceAdditionsAndRemovals';

interface SessionPreferences {
  defaultDisplay: {
    type: UserPreferenceDisplayTypes;
  };
  genres: string[];
  genreNames: string[];
  neuralEffectLevels: string[];
}

interface MentalStatePreferencesResponseType {
  focus: SessionPreferences;
  relax: SessionPreferences;
  sleep: SessionPreferences;
  meditate: SessionPreferences;
  workout: SessionPreferences;
}

export function* getSessionPreferencesSaga() {
  try {
    const user: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
    const { result } = (yield requestSaga(
      RequestMethods.GET,
      `/users/${user.info?.id}/session/preferences`,
    )) as APIResponse<MentalStatePreferencesResponseType>;

    yield put(userSliceActions.setMentalStatePreferences(result));
  } catch (error) {
    Logger.error(error);
  }
}

export function* getUserPreferencesSaga() {
  try {
    const user: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
    const { result } = (yield requestSaga(
      RequestMethods.GET,
      `/users/${user.info?.id}/preferences/web`,
      {},
      3,
    )) as APIResponse<UserPreferencesType>;

    yield put(userSliceActions.setUserPreferences(result));
  } catch (error: unknown) {
    yield put(
      userSliceActions.setUserPreferences({
        streakType: null,
      }),
    );

    const isNormal404 = (error as { message?: string })?.message === 'User preferences not found.';
    if (!isNormal404) {
      Logger.error(error);
    }
  }
}

export function* addSessionPreferencesSaga(action: Action) {
  if (userAction.addSessionPreference.match(action)) {
    try {
      const user: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
      yield requestSaga(
        RequestMethods.POST,
        `/users/${user.info?.id}/session/preferences`,
        action.payload,
      );
    } catch (error) {
      Logger.error(error);
    }
  }
}

export function* removeSessionPreferencesSaga(action: Action) {
  if (userAction.removeSessionPreference.match(action)) {
    try {
      const user: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
      yield requestSaga(
        RequestMethods.DELETE,
        `/users/${user.info?.id}/session/preferences`,
        action.payload,
      );
    } catch (error) {
      Logger.error(error);
    }
  }
}

export function* toggleSessionPreferencesSaga(action: Action) {
  if (!userAction.toggleSessionPreference.match(action)) return;

  const sessionMentalStateId: RootReducerType['sessionManager']['sessionMentalStateId'] =
    yield select((state: RootReducerType) => state.sessionManager.sessionMentalStateId);
  const sessionDynamicMentalStateId: string = yield select(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.mentalState.id,
  );
  if (!sessionDynamicMentalStateId) return;

  const { mentalStatePreferences }: RootReducerType['userV2'] = yield select(
    (state: RootReducerType) => state.userV2,
  );

  const mentalStateType = sessionDynamicMentalStateId as MentalStates;

  if (!mentalStateType) return;

  const currentPreferences = mentalStatePreferences[mentalStateType];
  const {
    genreNamesToAdd,
    neuralEffectLevelsToAdd,
    genreNamesToRemove,
    neuralEffectLevelsToRemove,
  } = getPreferenceAdditionsAndRemovals({
    currentPreferences,
    newPreferences: {
      genreNames: action.payload.genreNames || null,
      neuralEffectLevels: action.payload.neuralEffectLevels || null,
    },
  });

  // update client side genres state
  if (action.payload.genreNames) {
    yield put(
      userSliceActions.setMentalStatePreference({
        mentalState: mentalStateType,
        preferenceType: UserMentalStatePreferenceTypes.GenreNames,
        preferences: action.payload.genreNames,
      }),
    );
  }

  // update client side nels state
  if (action.payload.neuralEffectLevels) {
    yield put(
      userSliceActions.setMentalStatePreference({
        mentalState: mentalStateType,
        preferenceType: UserMentalStatePreferenceTypes.NeuralEffectLevels,
        preferences: action.payload.neuralEffectLevels,
      }),
    );
  }

  // update server side removals
  if (genreNamesToRemove.length || neuralEffectLevelsToRemove.length) {
    yield put(
      userActions.removeSessionPreference({
        genreNames: genreNamesToRemove,
        neuralEffectLevels: neuralEffectLevelsToRemove,
        mentalState: mentalStateType,
      }),
    );
  }

  // update server side additionas
  if (genreNamesToAdd.length || neuralEffectLevelsToAdd.length) {
    yield put(
      userActions.addSessionPreference({
        genreNames: genreNamesToAdd,
        neuralEffectLevels: neuralEffectLevelsToAdd,
        mentalState: mentalStateType,
      }),
    );
  }
}

export function* setDefaultDisplayTypeSaga(action: Action) {
  if (!userAction.setDefaultDisplayType.match(action)) return;
  const mentalStateId: string | undefined = yield select(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.mentalState.id,
  );

  if (!mentalStateId) return;
  yield put(
    userSliceActions.setMentalStateDefaultDisplayType({
      mentalState: mentalStateId as SessionType, // remove casting when dynamic activities are rolled out
      defaultDisplayType: action.payload.type,
    }),
  );

  try {
    const user: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
    const { result } = (yield requestSaga(
      RequestMethods.POST,
      `/users/${user.info?.id}/session/preferences`,
      {
        mentalState: mentalStateId,
        defaultDisplay: {
          type: action.payload.type,
        },
      },
    )) as APIResponse<MentalStatePreferencesResponseType>;

    yield put(userSliceActions.setMentalStatePreferences(result));
  } catch (error) {
    Logger.error(error);
  }
}

export function* updateUserPreferencesSaga(action: Action) {
  if (userActions.updateUserPreferences.match(action)) {
    try {
      const { info }: RootReducerType['user'] = yield select(
        (state: RootReducerType) => state.user,
      );
      const { preferences }: RootReducerType['userV2'] = yield select(
        (state: RootReducerType) => state.userV2,
      );
      const data: SuccessResponseType<{ result: UserPreferencesType }> = yield requestSaga(
        RequestMethods.PUT,
        `/users/${info?.id}/preferences/web`,
        {
          ...preferences,
          ...action.payload,
        },
        3,
      );

      // filter out all null preferences as we don't use them still
      const updatedPreferences = Object.fromEntries(
        Object.entries(data.result).filter(([_, v]) => v != null),
      ) as UserPreferencesType;

      yield put(userSliceActions.setUserPreferences(updatedPreferences));
    } catch (err) {
      Logger.error(err);
    }
  }
}
