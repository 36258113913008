import { MembershipSources, membershipActions, useIsTrialUser } from '@Memberships';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { setModal } from '../../../../actions/ui';
import * as userActions from '../../../../actions/user';
import { RootReducerType } from '../../../../reducers';
import { trackSubscriptionStartSuccess } from '../../../Analytics/coreAnalytics';
import { PlanTypes } from '../../../Analytics/coreAnalytics.types';
import { useIsExpired } from '../../../Memberships/lenses/isExpired';
import { SubscriptionSuccessModalDisplay } from './SubscriptionSuccessModal.display';

export function SubscriptionSuccessModal() {
  const modalType = useSelector((state: RootReducerType) => state.ui.modalType);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const hasTrackedSubscriptionStartRef = useRef(false);
  const isOnPaidTrial = useSelector((state: RootReducerType) => state.membership.isOnTrial);
  const isTrialUser = useIsTrialUser();
  const isExpired = useIsExpired();
  const membershipSource = useSelector((state: RootReducerType) => state.membership.source);
  const membershipTitle = useSelector((state: RootReducerType) => state.user.membership?.title);
  const legacyMembershipSource = useSelector(
    (state: RootReducerType) => state.user.membership?.source,
  );
  const queryParams = new URLSearchParams(location.search);
  const isStripeCheckoutSuccessRef = useRef(queryParams.get('payment_success') === 'true');

  const isOpen = modalType === 'subscriptionSuccess';
  const isPaidMembershipReceivedAndActive = Boolean(
    !isTrialUser &&
      !isExpired &&
      legacyMembershipSource &&
      membershipSource &&
      [
        MembershipSources.Stripe,
        MembershipSources.Google,
        MembershipSources.Apple,
        MembershipSources.GiftUp,
      ].includes(membershipSource),
  );

  useEffect(() => {
    if (
      !isStripeCheckoutSuccessRef.current ||
      !isPaidMembershipReceivedAndActive ||
      hasTrackedSubscriptionStartRef.current ||
      !isOpen
    )
      return;
    trackSubscriptionStartSuccess({
      isStripeCheckout: true,
      planType: membershipTitle as PlanTypes,
    });
    hasTrackedSubscriptionStartRef.current = true;
  }, [isPaidMembershipReceivedAndActive, membershipTitle, isOpen]);

  useEffect(() => {
    if (isPaidMembershipReceivedAndActive || !isOpen) return;
    const interval = setInterval(() => {
      dispatch(membershipActions.fetchMembership());
      dispatch(userActions.getInfo()); // to update legacy subscription
    }, 3000);

    return () => clearInterval(interval);
  }, [dispatch, isPaidMembershipReceivedAndActive, isOpen]);

  const membership = useSelector((state: Pick<RootReducerType, 'membership'>) => state.membership);
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);
  const expirationDateLegacyMembership = new Date(legacyMembership?.expirationDate || '');
  const expirationDate = membership.currentPeriodEndDate;
  const planTypeTitle = legacyMembership?.title || '';
  const currentDate = new Date();
  const endDate = new Date(expirationDateLegacyMembership);
  const diffInTime = endDate.getTime() - currentDate.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));
  const isTrialInfoVisible = Boolean(isOnPaidTrial && diffInDays > 0);

  const onClose = useCallback(() => {
    dispatch(setModal(null));
  }, [dispatch, setModal]);

  const primaryButtonAction = useCallback(() => {
    onClose();
    navigate('/');
  }, [navigate, onClose]);

  const description = isTrialInfoVisible
    ? 'Your payment information has been saved. Here’s what’s next:'
    : 'Your payment has been processed.';

  return (
    <SubscriptionSuccessModalDisplay
      description={description}
      isLoading={!isPaidMembershipReceivedAndActive}
      isOpen={isOpen}
      isTrialInfoVisible={isTrialInfoVisible}
      primaryButtonAction={primaryButtonAction}
      primaryButtonText="LET'S GO!"
      subscriptionInformation={`Your ${planTypeTitle.toLocaleLowerCase()} plan will renew on
      ${expirationDate}`}
      title="Congratulations!"
      trialInformation={`${diffInDays} days`}
      onClose={onClose}
    />
  );
}
