import { Serving, TagTypes, Track } from '@Model';

import { isServing } from './isServing';

export function getTrackGenreName(trackOrServing?: Track | Serving | null): string {
  if (isServing(trackOrServing)) {
    return (
      trackOrServing.track.tags.find(tag => tag.type === TagTypes.Genre && tag.value !== 'Nature')
        ?.value || ''
    );
  }

  return trackOrServing?.genreName || '';
}
