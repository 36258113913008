export type FontType = 'regular' | 'medium' | 'bold';
export type FontWeight = {
  [key in FontType]: number;
};

export type FontFamily = {
  default: { [key in FontType]: string };
  ttnorms: { [key in FontType]: string };
  poppins: { [key in FontType]: string };
};

export type Font = {
  weight: FontWeight;
  family: FontFamily;
};

export const fontSize = {
  tiny: '0.6rem',
  extraSmall: '0.75rem',
  small: '1rem',
  medium: '1.25rem',
  large: '1.5rem',
  extraLarge: '1.75rem',
  h1: '2.2rem',
  h2: '2rem',
};

export const fontFamily = {
  regular: 'TTNormsPro-Regular',
  medium: 'TTNormsPro-Medium',
  bold: 'TTNormsPro-Bold',
};

export const font: Font = {
  weight: {
    regular: 300,
    medium: 500,
    bold: 700,
  },
  family: {
    default: {
      regular: 'Poppins, TTNormsPro-Regular',
      medium: 'Poppins, TTNormsPro-Medium',
      bold: 'Poppins, TTNormsPro-Bold',
    },
    ttnorms: {
      regular: 'TTNormsPro-Regular',
      medium: 'TTNormsPro-Medium',
      bold: 'TTNormsPro-Bold',
    },
    poppins: {
      regular: 'Poppins',
      medium: 'Poppins',
      bold: 'Poppins',
    },
  },
};
