import { TimeUnits } from '@Session';
import { TimerState } from '../types';

export const getTimerValidValue = ({
  maxTimerLengthInHrs,
  timeUnit,
  time,
}: {
  maxTimerLengthInHrs: number;
  timeUnit: TimeUnits;
  time: TimerState | undefined;
}): string | undefined => {
  if (time === undefined) return undefined;

  const isEmpty = time.value === '' || time.value === undefined;
  const isValidNumber = isFinite(parseInt(time.value, 10));

  if (!isValidNumber && !isEmpty) return undefined;

  return isEmpty
    ? ''
    : Math.min(
        parseInt(time.value, 10),
        maxTimerLengthInHrs * (timeUnit === TimeUnits.Hrs ? 1 : 60),
      ).toString();
};
