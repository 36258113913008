import { Serving, Track } from '@Model';

import { isServing } from './isServing';

export function getTrackBeatsPerMinute(trackOrServing?: Track | Serving | null): number | null {
  if (isServing(trackOrServing)) {
    return trackOrServing.track.beatsPerMinute;
  }

  return null;
}
