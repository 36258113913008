import { Serving, Track } from '@Model';

import { isServing } from './isServing';

export const getTrackVariationId = (trackOrServing?: Track | Serving | null) => {
  if (isServing(trackOrServing)) {
    return trackOrServing.trackVariation.id;
  }

  return trackOrServing?.serving?.trackVariation.id || '';
};
