import React, { useEffect, useRef, useState } from 'react';
import * as S from './TimerRadioSelector.styles';
import PlayIcon from '../assets/play_circle.svg';
import StopIcon from '../assets/stop_circle.svg';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  description?: string;
  isSelected: boolean;
  audioUrl: string;
}

export const TimerRadioSelector = ({
  title,
  description,
  isSelected,
  onClick,
  audioUrl,
  ...rest
}: Props) => {
  const audioRef = useRef<HTMLAudioElement>(new Audio(audioUrl));
  const [audioState, setAudioState] = useState('stopped');

  const playAudio = () => {
    audioRef.current.volume = 0.5;
    audioRef.current.play();
    setAudioState('playing');
  };

  const stopAudio = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setAudioState('stopped');
  };

  useEffect(() => {
    if (!isSelected) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setAudioState('stopped');
    }
  }, [isSelected]);

  useEffect(() => {
    audioRef.current.addEventListener('ended', stopAudio);

    return () => {
      audioRef.current.removeEventListener('ended', stopAudio);
    };
  }, []);

  const isStopped = audioState === 'stopped';

  return (
    <S.Container isSelected={isSelected} onClick={onClick} {...rest}>
      <S.PlayButton
        src={isStopped ? PlayIcon : StopIcon}
        title={isStopped ? 'Preview' : 'Stop'}
        onClick={isStopped ? playAudio : stopAudio}
      />
      <S.TextContainer>
        <S.Title>{title}</S.Title>
        {description ? <S.Description>{description}</S.Description> : null}
      </S.TextContainer>
      <S.RadioContainer>
        <S.Radio isSelected={isSelected} />
      </S.RadioContainer>
    </S.Container>
  );
};
