import { Assets } from '../../../../utils/assets';
import styled, { css } from 'styled-components';

type Props = {
  backgroundColor?: string;
  borderRadius: string;
  isScrollable: boolean;
  padding: string;
};

const SharedStyles = css<Props>`
  border-radius: ${props => props.borderRadius};
  padding: ${props => props.padding};
  position: relative;
  width: 100%;

  ${props => (props.isScrollable ? 'overflow-x: hidden' : 'overflow: hidden')};

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const PaperOnStyles = css<Props>`
  background: #211d3f;
  backdrop-filter: blur(64px);
  border: 1px solid rgba(55, 62, 91, 0.7);
  box-shadow: 0px 20px 50px rgba(25, 23, 54, 0.65);

  @supports (backdrop-filter: blur(64px)) {
    backdrop-filter: blur(64px);
    background: ${props => props.backgroundColor || 'rgba(255, 255, 255, 0.05)'};
  }
`;

const PaperOffStyles = css<Props>`
  background: rgba(54, 50, 95, 0);
  border: 1px solid rgba(55, 62, 91, 0);
  box-shadow: 0px 20px 50px rgba(25, 23, 54, 0);
`;

export const Paper = styled.div<Props>`
  ${SharedStyles}
  ${PaperOnStyles}
`;

export const PaperOnHover = styled.div<Props>`
  cursor: pointer;
  transition:
    background 0.2s ease-in-out,
    border 0.2s ease-in-out;

  ${SharedStyles}
  ${PaperOffStyles}

  &:hover {
    ${PaperOnStyles}
  }
`;
