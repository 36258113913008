import { MenuItem, MenuItemIcons } from '@Cortex';
import * as S from './styles';

type Props = {
  platform?: 'apple' | 'facebook';
} & React.HTMLAttributes<HTMLButtonElement>;

export const AuthButton = ({ platform, children, ...props }: Props) => {
  if (platform === 'apple') {
    return (
      <S.Button {...props}>
        <MenuItem icon={MenuItemIcons.Apple} />
      </S.Button>
    );
  }

  if (platform === 'facebook') {
    return (
      <S.Button {...props}>
        <MenuItem icon={MenuItemIcons.Facebook} />
      </S.Button>
    );
  }

  return <S.Button {...props}>{children}</S.Button>;
};
