import React from 'react';
import * as S from './TimerOption.styles';

type Props = {
  isActive: boolean;
  text: string;
  onClick: () => void;
};

const TimerOption = ({ isActive, text, onClick }: Props) => {
  return (
    <S.Container isActive={isActive} onClick={onClick}>
      <S.Text isActive={isActive}>{text}</S.Text>
    </S.Container>
  );
};

export default TimerOption;
