import { InputHTMLAttributes, useRef, useState } from 'react';

import * as S from './FormInputModern.styles';
import EyeIcon from '../../../../assets/images/eye.svg';
import EyeSlashIcon from '../../../../assets/images/eye_slash.svg';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  errorMessage?: string;
  hasRoundedBottom: boolean;
  hasRoundedTop: boolean;
}

export const FormInputModern = ({
  name,
  label,
  errorMessage = undefined,
  type,
  hasRoundedBottom,
  hasRoundedTop,
  value,
  ...props
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputFocused, setInputFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const inputStyles = {
    backgroundPosition: errorMessage ? '97%' : '120%',
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <S.InputContainer
      hasRoundedBottom={hasRoundedBottom}
      hasRoundedTop={hasRoundedTop}
      onClick={() => {
        inputRef.current?.focus();
      }}
    >
      <S.InputWrapper>
        <S.InputField
          ref={inputRef}
          id={name}
          name={name}
          style={inputStyles}
          type={type === 'password' && showPassword ? 'text' : type}
          value={value}
          onBlur={() => setInputFocus(false)}
          onFocus={() => setInputFocus(true)}
          {...props}
        />
        {type === 'password' ? (
          <S.Image
            src={showPassword ? EyeSlashIcon : EyeIcon}
            style={{ right: !!errorMessage ? 'calc(1rem + 3% + 1rem)' : '1rem' }}
            onClick={handleShowPassword}
          />
        ) : null}
      </S.InputWrapper>

      {errorMessage ? (
        <S.ErrorText>{errorMessage}</S.ErrorText>
      ) : (
        <S.InputLabel htmlFor={name} inputIsFocused={!!value || inputFocused}>
          {label}
        </S.InputLabel>
      )}
    </S.InputContainer>
  );
};
