import { UserPreferenceDisplayTypes, useDefaultDisplayType } from '@User';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSessionModalContent } from '../../../../actions/ui';
import { RootReducerType } from '../../../../reducers';
import { SessionModalContent } from '../../../../types';
import { QuotesContainer } from '../QuotesContainer';
import { Timer } from '../Timer';
import { getAfterCountdownTextForDynamicMentalState } from './utils/getAfterCountdownTextForMentalState';

export const TimerContainerDynamic = () => {
  const dispatch = useDispatch();
  const mentalStateDisplayValue = useSelector(
    (state: RootReducerType) =>
      state.sessionManager.sessionDynamicActivity?.mentalState.displayValue,
  );
  const countdownText = useSelector(
    (state: RootReducerType) =>
      state.sessionManager.sessionDynamicActivity?.mentalState.countdownText,
  );
  const defaultDisplayType = useDefaultDisplayType();
  const sessionPlayType = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionPlayType,
  );
  const sessionModalContent = useSelector((state: RootReducerType) => state.ui.sessionModalContent);
  const setSessionSidebar = (contentType: SessionModalContent) => {
    dispatch(
      setSessionModalContent({ sessionModalContent: contentType, origin: 'player_display' }),
    );
  };
  const closeSidebarComponent = useCallback(() => {
    dispatch(setSessionModalContent({ sessionModalContent: null, origin: undefined }));
  }, [dispatch]);

  // currently TIMER is not ever counted as a default display type, so we need to only render
  // quotes when thats not the session type.
  const shouldRenderQuotes =
    defaultDisplayType === UserPreferenceDisplayTypes.Quotes && sessionPlayType !== 'TIMER';

  const onClick = useCallback(() => {
    if (sessionModalContent) {
      closeSidebarComponent();
      return;
    }
    setSessionSidebar(SessionModalContent.TimerSettings);
  }, [sessionModalContent, closeSidebarComponent, setSessionSidebar]);

  return shouldRenderQuotes ? (
    <QuotesContainer onClick={onClick} />
  ) : (
    <Timer
      afterCountdownText={getAfterCountdownTextForDynamicMentalState(mentalStateDisplayValue || '')}
      countdownText={countdownText || ''}
      onClick={onClick}
    />
  );
};
