import styled, { css } from 'styled-components';
import AlertIcon from '../../../../../assets/images/alert_icon.svg';

export const InputContainer = styled.div<{ variant: 'default' | 'first' | 'last' | 'individual' }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.75rem 1rem;
  min-height: 4.125rem;
  justify-content: center;

  ${props =>
    (props.variant === 'first' || props.variant === 'individual') &&
    css`
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    `}
  ${props =>
    (props.variant === 'last' || props.variant === 'individual') &&
    css`
      margin-top: 1px;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    `}
  ${props =>
    props.variant === 'default' &&
    css`
      margin-top: 1px;
    `}
`;

export const InputField = styled.input`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 1rem;
  color: #d4d2ea;
  height: 2.75rem;
  background: none;
  outline: none;
  border: none;
  transition:
    border 0.2s ease-in-out,
    background 0.3s ease-in-out;

  background-image: url(${AlertIcon});
  background-repeat: no-repeat;
  background-size: 1.25rem;
  background-position: 120%;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    box-shadow: 0 0 0 300px ${({ theme }) => theme.colors.brandPrimary} inset;
    animation-name: autofill;
    animation-fill-mode: both;
    border-radius: 0.75rem;
    font-size: 0.875rem;
    -webkit-text-fill-color: #fff;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
    font-size: 1rem;
  }
`;

export const PasswordInputEyeIcon = styled.img`
  position: absolute;
  right: 1.25rem;
  bottom: 1.5rem;
  height: 1.25rem;
  width: 1.25rem;
  cursor: pointer;
  z-index: 1;
  opacity: 0.75;
  transition:
    opacity 0.3s ease-in-out,
    right 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

export const ErrorText = styled.span`
  bottom: 4px;
  left: 0;
  right: 0;
  padding-left: 1rem;
  color: red;
  font-size: 12px;
  position: absolute;
  text-transform: lowercase;
`;
