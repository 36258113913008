import { Action } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import { RootReducerType } from 'src/reducers';
import * as analyticsActions from '../../../actions/analytics';
import * as uiActions from '../../../actions/ui';

import { RequestMethods } from '../../../api/client/types';
import { requestSaga, SuccessResponseType } from '../../../sagas/httpRequest';
import { SubscriptionEvents } from '../../../utils/analytics/events';
import { Logger } from '../../../utils/logger';
import { membershipActions } from '../../Memberships/reducer';
import { acceptStripeSubscriptionDiscount } from '../actions';
import { paymentActions } from '../reducer/reducer';

export function* acceptStripeSubscriptionDiscountSaga(action: Action) {
  if (!acceptStripeSubscriptionDiscount.match(action)) return;
  yield put(
    analyticsActions.subscriptionLogEvent(
      SubscriptionEvents.core_cancelation_discount_accept_attempt,
    ),
  );

  yield put(paymentActions.receiveApplyingDiscountProcessing());

  try {
    const { info }: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
    const response: SuccessResponseType = yield requestSaga(
      RequestMethods.POST,
      '/payments/accept-monthly-cancellation-discount',
      {
        userId: info?.id,
      },
    );

    if (response.status === 200) {
      yield put(membershipActions.fetchMembership());
      yield put(paymentActions.resetState());
      yield put(
        analyticsActions.subscriptionLogEvent(
          SubscriptionEvents.core_cancelation_discount_accept_success,
        ),
      );
      yield put(
        uiActions.successModalOpen({
          title: 'Success!',
          description:
            "You've unlocked 50% off for the next 3 months. We're excited to keep you on board!",
          actions: [{ type: 'primary', text: 'CLOSE', action: uiActions.successModalClose() }],
        }),
      );
      return;
    }
  } catch (error) {
    Logger.error(new Error('acceptStripeSubscriptionDiscountSaga(): unable to accept discount'), {
      reason: error,
    });
    yield put(
      analyticsActions.subscriptionLogEvent(
        SubscriptionEvents.core_cancelation_discount_accept_error,
      ),
    );
    yield put(
      uiActions.successModalOpen({
        iconSrc: null,
        title: 'Something went wrong!',
        description:
          'If you continue to receive this message use the "Contact Support" option for assistance!',
        actions: [{ type: 'primary', text: 'CLOSE', action: uiActions.successModalClose() }],
      }),
    );
  }

  yield put(paymentActions.receiveApplyingDiscountError());
}
