import { GAAnalyticsEvent, GACategories } from '../../types/utils/analytics';

/**
 * Helper function to unify events for Google Analytics and get a
 * stringified JSON with 1 shot.
 * @param category
 * @param action
 * @param label
 * @returns Stringified JSON
 */
export const getGAEvent = (category: GACategories, action: string, label?: string): string => {
  const obj: GAAnalyticsEvent = {
    category,
    action,
  };

  if (label) {
    obj.label = label;
  }

  return JSON.stringify(obj);
};

export const trackGAEvent = (event: MouseEvent) => {
  const analyticsEvent = getAnalyticsEvent(event);

  if (analyticsEvent) {
    const { category, action, label } = JSON.parse(analyticsEvent);
    // google
    ga('send', 'event', category, action, label);
  }
};

/**
 * Helper function.
 * Follows click event propagation path on component tree.
 * Get a composed element paths and loops over elemnts to find
 * custom attribute.
 * @param event
 */
const getAnalyticsEvent = (event: MouseEvent): string | null => {
  const paths = event.composedPath();

  let attribute: string | null = null;

  for (const element of Object.values(paths)) {
    if (element && (element as HTMLElement).getAttribute) {
      const customAttribute = (element as HTMLElement).getAttribute('data-analytics-event');

      if (customAttribute) {
        attribute = customAttribute;
        break;
      }
    }
  }

  return attribute;
};
