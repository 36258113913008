import { useIsLifetimeUser, useIsTrialUser, MembershipSources } from '@Memberships';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../../reducers';
import { useIsExpired } from '../../../lenses/isExpired';

export enum MembershipPanelType {
  Lifetime = `Lifetime`,
  ExpiredSubscriptionNotInGracePeriod = `ExpiredSubscriptionNotInGracePeriod`,
  ExpiredSubscriptionInGracePeriod = `ExpiredSubscriptionInGracePeriod`,
  ActiveSubscription = `ActiveSubscription`,
  Trial = `Trial`,
  Unknown = `Unknown`,
}

export const useMembershipPanelType = (): MembershipPanelType => {
  const isLifetime = useIsLifetimeUser();
  const isTrial = useIsTrialUser();
  const isExpired = useIsExpired();
  const membershipSource = useSelector(
    (state: Pick<RootReducerType, 'membership'>) => state.membership.source,
  );
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);
  const gracePeriodExpirationDate = new Date(legacyMembership?.gracePeriodExpirationDate || '');
  const gracePeriodTimeLeftInMilliseconds = gracePeriodExpirationDate.getTime() - Date.now();

  const isInGracePeriod =
    gracePeriodTimeLeftInMilliseconds >= 0 && legacyMembership?.isInGracePeriod;

  if (isLifetime) return MembershipPanelType.Lifetime;

  if (isTrial) return MembershipPanelType.Trial;

  if (isExpired) {
    return isInGracePeriod
      ? MembershipPanelType.ExpiredSubscriptionInGracePeriod
      : MembershipPanelType.ExpiredSubscriptionNotInGracePeriod;
  }

  if (membershipSource && membershipSource !== MembershipSources.Unknown)
    return MembershipPanelType.ActiveSubscription;

  return MembershipPanelType.Unknown;
};
