import { Paper } from '@Cortex';
import { ReactNode } from 'react';

import * as S from './CardWrapperTwo.styles';

export type Props = {
  children: ReactNode;
  height?: string;
};

export const CardWrapperTwo = ({ children, height }: Props) => {
  return <S.Wrapper style={{ height }}>{children}</S.Wrapper>;
};
