import { showToast } from '@Utils';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setModal } from '../../../../actions/ui';
import { useEnableSubscriptionAutoRenew } from '../../../../api/modules/EnableSubscriptionAutoRenew';
import { useGetStripeMembershipCycle } from '../../../../hooks/useGetStripeMembershipCycle';
import { RootReducerType } from '../../../../reducers';
import { Analytics } from '../../../../utils/analytics';
import { StripeMembershipCycle } from '../../../../utils/getStripeMembershipCycle';
import { RenewSubscriptionModalPresentation } from './RenewSubscriptionModal.presentation';

export function RenewSubscriptionModal() {
  const { modalType } = useSelector((state: RootReducerType) => state.ui);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpen = modalType === 'renewSubscription';
  const {
    mutate: handleEnableAutoRenew,
    isLoading: isLoading,
    isError,
  } = useEnableSubscriptionAutoRenew();
  const [isImpressionTracked, setIsImpressionTracked] = useState(false);
  const stripeMembershipCycle = useGetStripeMembershipCycle();

  const isEligibleForRenewal = [
    StripeMembershipCycle.FreeTrialNotRenewingActive,
    StripeMembershipCycle.PaidNotRenewingActive,
  ].includes(stripeMembershipCycle);

  // additional check not to display the modal (triggered from braze) for users who are not eligible for renewal
  useEffect(() => {
    const isRenewalModalObsolete = !isEligibleForRenewal && isOpen;
    if (isRenewalModalObsolete) {
      onClose();
    }
  }, [isEligibleForRenewal, isOpen]);

  useEffect(() => {
    const shouldTrackImpression = isEligibleForRenewal && isOpen && !isImpressionTracked;
    if (shouldTrackImpression) {
      setIsImpressionTracked(true);
      Analytics.logEventWithProperties('core_renewal_modal_impression', {});
    }
  }, [isEligibleForRenewal, isOpen, isImpressionTracked, setIsImpressionTracked]);

  useEffect(() => {
    const isRenewalFailed = isOpen && isError;
    if (isRenewalFailed) {
      onClose();
      showToast({
        message: 'Something went wrong, contact support if the error continues',
        options: { type: 'error' },
      });
    }
  }, [isError, isOpen]);

  const isOnTrial = [
    StripeMembershipCycle.FreeTrialRenewing,
    StripeMembershipCycle.FreeTrialNotRenewingActive,
    StripeMembershipCycle.FreeTrialNotRenewingExpired,
  ].includes(stripeMembershipCycle);

  const onClose = useCallback(() => {
    dispatch(setModal(null));
  }, [dispatch, setModal]);

  const primaryButtonAction = useCallback(() => {
    handleEnableAutoRenew();
    onClose();
  }, [navigate, onClose, handleEnableAutoRenew]);

  const title = 'Keep Your Access!';
  const description = `You’ll lose access once your ${isOnTrial ? 'trial' : 'subscription'} is up!`;

  return (
    <RenewSubscriptionModalPresentation
      description={description}
      isLoading={isLoading}
      isOpen={isOpen}
      primaryButtonAction={primaryButtonAction}
      title={title}
      onClose={onClose}
    />
  );
}
