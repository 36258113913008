import { FormInput, Button, ButtonVariants } from '@Cortex';
import { membershipActions, MembershipPromotion, MembershipPromotionTypes } from '@Memberships';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { get } from '../../../../api/client/client';
import { Analytics } from '../../../../utils/analytics';

import * as S from './CouponCode.styles';

type Props = {
  layout?: 'modern' | 'classic';
};

export function CouponCode({ layout = 'classic' }: Props) {
  const dispatch = useDispatch();
  const [couponCode, setCouponCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isInputFocused, setInputFocused] = useState(false);

  async function handleFetchCouponPromotion() {
    const { result } = await get<{ status: number; result: MembershipPromotion | null }>({
      path: `/membership/promotions/${couponCode}`,
      token: null,
    });
    if (!result) {
      setErrorMessage('Invalid Coupon!');
      return;
    }
    Analytics.logEventWithProperties('coupon_applied', {
      coupon_code: couponCode,
      ...result,
    });
    dispatch(
      membershipActions.receivePromotion({
        ...result,
        type: MembershipPromotionTypes.StripeCoupon,
      } as MembershipPromotion),
    );
  }

  async function handleFetchCouponPromotionOnEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') return;
    handleFetchCouponPromotion();
  }

  function handleChangeCouponCode(event: React.ChangeEvent<HTMLInputElement>): void {
    const newValue = event.currentTarget.value;
    setErrorMessage('');
    setCouponCode(newValue);
  }

  if (layout === 'modern') {
    const inputFocused = isInputFocused || couponCode.length > 0;

    return (
      <S.Container isFocused={isInputFocused}>
        {errorMessage ? <S.ErrorText>{errorMessage}</S.ErrorText> : null}
        <S.ModernForm>
          <S.Input
            data-testid="couponCode"
            placeholder="Enter coupon code"
            value={couponCode}
            onBlur={() => setInputFocused(false)}
            onChange={handleChangeCouponCode}
            onFocus={() => setInputFocused(true)}
            onKeyDown={handleFetchCouponPromotionOnEnter}
          />
          <S.ModernButtonWrapper>
            <S.Button isFocused={inputFocused} type="button" onClick={handleFetchCouponPromotion}>
              Apply
            </S.Button>
          </S.ModernButtonWrapper>
        </S.ModernForm>
      </S.Container>
    );
  }

  return (
    <S.CouponCodeContainer>
      <S.Form>
        <FormInput
          data-testid="couponCode"
          errorMessage={errorMessage}
          label="Have a coupon code?"
          value={couponCode}
          onChange={handleChangeCouponCode}
          onKeyDown={handleFetchCouponPromotionOnEnter}
        />
        <S.ButtonWrapper>
          <Button variant={ButtonVariants.Outline} onClick={handleFetchCouponPromotion}>
            Apply
          </Button>
        </S.ButtonWrapper>
      </S.Form>
    </S.CouponCodeContainer>
  );
}
