import styled from 'styled-components';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FormBody = styled.form`
  margin-bottom: 1.5rem;
`;

export const FormButtonWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
`;
