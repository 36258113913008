import { DynamicMentalState } from '@Model';
import LoadingIcon from '../../../../components/icons/LoadingIcon';
import { HeaderWithLogoAndClose } from '../../../shared/HeaderWithLogoAndClose';
import { MentalStateCard } from './MentalStateCard';
import * as S from './OnboardingMentalStates.styles';
import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';

type Props = {
  dynamicMentalStates?: DynamicMentalState[];
  onSelect: (mentalStateId: string) => () => void;
  isLoading: boolean;
};

export const OnboardingMentalStatesPresentation = ({
  isLoading,
  dynamicMentalStates,
  onSelect,
}: Props) => {
  const ref = useRef(null);

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <HeaderWithLogoAndClose />
      </S.HeaderWrapper>
      <S.Container>
        <Transition in mountOnEnter nodeRef={ref} timeout={100} unmountOnExit>
          {state => (
            <S.ContentWrapper ref={ref} animationState={state}>
              <S.Header>Get started</S.Header>
              <S.Subheader>What would you like to try first?</S.Subheader>
              {isLoading && (
                <S.MentalStates>
                  <LoadingIcon />
                </S.MentalStates>
              )}
              {!isLoading && (
                <S.MentalStates>
                  {(dynamicMentalStates || [])?.map(mentalState => (
                    <MentalStateCard
                      key={mentalState.id}
                      imageOne={{
                        url: mentalState.imageUrl,
                        alt: mentalState.displayValue,
                      }}
                      imageTwo={{
                        url: mentalState.imageUrlSecondary,
                        alt: mentalState.displayValue,
                      }}
                      text={mentalState.displayValue}
                      onClick={onSelect(mentalState.id)}
                    />
                  ))}
                </S.MentalStates>
              )}
            </S.ContentWrapper>
          )}
        </Transition>
      </S.Container>
    </S.Wrapper>
  );
};
