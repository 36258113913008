import { CoreAnalytics } from '@Analytics';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setInteraction } from '../../../../actions/user';
import { useGetResultsCards } from '../../hooks/useGetResultsCards';
import { OnboardingSpecification, OnboardingSteps } from '../../types';
import { ResultCardsPresentation } from './ResultCards.presentation';

type Props = {
  onboardingSpecification: OnboardingSpecification;
  quizAnswers: number[];
  onSetStep: (step: OnboardingSteps) => void;
  onMusicUrlReceived: (value: string) => void;
};

export const ResultCards = (props: Props) => {
  const { onboardingSpecification, quizAnswers, onSetStep, onMusicUrlReceived } = props;
  const dispatch = useDispatch();
  const { cards, isLoading, onboardingMusicUrl } = useGetResultsCards(
    onboardingSpecification,
    quizAnswers,
  );

  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [mountedCardIndex, setMountedCardIndex] = useState(0);
  const cardsAmount = (cards || []).length;

  useEffect(() => {
    onMusicUrlReceived(onboardingMusicUrl);
  }, [onboardingMusicUrl]);

  useEffect(() => {
    dispatch(setInteraction('onboarding_welcome'));
  }, [dispatch]);

  useEffect(() => {
    if (isLoading || (cards || []).length) return;
    onSetStep(OnboardingSteps.Funbars);
  }, [isLoading, cards]);

  const handleMountNextCard = useCallback(() => {
    if (mountedCardIndex + 1 >= cardsAmount) return;
    setMountedCardIndex(mountedCardIndex + 1);
  }, [cardsAmount, setMountedCardIndex, mountedCardIndex]);

  const handleNextCard = useCallback(() => {
    if (currentCardIndex + 1 >= cardsAmount) {
      onSetStep(OnboardingSteps.Funbars);
      return;
    }

    setCurrentCardIndex(currentCardIndex + 1);
  }, [setCurrentCardIndex, currentCardIndex, cardsAmount, cards]);

  const handleSkipCard = () => {
    CoreAnalytics.trackOnboardingSkipEvent({
      variation: onboardingSpecification.id,
      cardIndex: currentCardIndex,
      cardTitle: cards[currentCardIndex]?.title || '',
      module: 'info_card',
    });
    handleNextCard();
  };

  return (
    <ResultCardsPresentation
      cards={cards || []}
      currentCardIndex={currentCardIndex}
      isLoading={isLoading}
      mountedCardIndex={mountedCardIndex}
      onHandleMountNextCard={handleMountNextCard}
      onHandleNextCard={handleNextCard}
      onHandleSkipCard={handleSkipCard}
    />
  );
};
