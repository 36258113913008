import styled from 'styled-components';

export const Container = styled.div`
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1.25rem;
  border: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: column;
  margin: 1rem;
  max-width: 20rem;
  padding: 1rem 1rem 2rem 1rem;
  width: 100%;
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const HeaderText = styled.div`
  margin-bottom: 1rem;
`;
