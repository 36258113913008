import * as S from './FavoriteShuffleCard.styles';
import { RootReducerType } from '../../../../../../../../reducers';
import { useSelector } from 'react-redux';
import { useDynamicMentalStates } from '../../../../../../../../api/modules/DynamicMentalStates';

export type Props = {
  onClick: () => void;
};

export const FavoriteShuffleCard = ({ onClick }: Props) => {
  const { data: dynamicMentalStates } = useDynamicMentalStates();
  const dynamicMentalStateId = useSelector(
    (state: RootReducerType) => state.ui.sideDeck.librarySelectedMentalState?.id,
  );
  const mentalState = dynamicMentalStates?.find(
    mentalState => mentalState.id === dynamicMentalStateId,
  );

  return (
    <S.Container onClick={onClick}>
      <S.InnerContainer>
        <S.TextContainer>
          <S.Title>Play All</S.Title>
          <S.Description>
            Start a listening session that will shuffle all your favorite{' '}
            <S.DescriptionBold>{mentalState?.displayValue}</S.DescriptionBold> tracks
          </S.Description>
          <S.Image src={mentalState?.playImageUrl} />
        </S.TextContainer>
      </S.InnerContainer>
    </S.Container>
  );
};
