import { HTMLAttributes } from 'react';
import * as S from './MiniPlayer.styles';
import { FontColors, Icon, Text, TextSemiBold } from '@Cortex';
import { useTimerDisplayValue } from '@Timer';
import { useTimerTypeIcon } from '../../../../components/session/utils/useTimerTypeIcon';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../reducers';
import { formatTime } from '../../../../components/session/utils/formatTime';
import PlayMiniIcon from './assets/play_mini_icon.svg';
import PauseMiniIcon from './assets/pause_mini_icon.svg';

export type Props = HTMLAttributes<HTMLDivElement> & {
  trackName: string;
  trackImageUrl: string;
  trackGenre: string;

  onClick: () => void;
};

export const MiniPlayer = (props: Props) => {
  const { sessionPlayStatus, currentTrackTimeStamp, sessionPlayType, timerLength, timerPlayTime } =
    useSelector((state: RootReducerType) => state.sessionManager);
  const timerText = useTimerDisplayValue();
  const timerTypeIcon = useTimerTypeIcon();

  const emmitEvent = (name: string) => {
    window.dispatchEvent(new Event(name));
  };

  const renderTimer = () => {
    if (sessionPlayType === 'TIMER') {
      const totalTimerPlayTime = (timerPlayTime || 0) + currentTrackTimeStamp;
      const timerDisplay = (timerLength || 0) - totalTimerPlayTime;

      return formatTime(timerDisplay >= 0 ? timerDisplay : 0);
    } else {
      return timerText;
    }
  };

  const playClickHandler = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    return emmitEvent('resume');
  };

  const pauseClickHandler = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    return emmitEvent('pause');
  };

  return (
    <S.Panel onClick={props.onClick}>
      <S.LeftContainer>
        <S.Image src={props.trackImageUrl} />
        <S.TextWrapper>
          <TextSemiBold>{props.trackName}</TextSemiBold>
          <Text color={FontColors.WhiteTransparent}>{props.trackGenre}</Text>
        </S.TextWrapper>
      </S.LeftContainer>
      <S.RightContainer>
        <S.TimerContainer>
          <Icon size={20} src={timerTypeIcon} />
          <Text style={{ fontVariantNumeric: 'tabular-nums' }}>{renderTimer()}</Text>
        </S.TimerContainer>

        <S.PausePauseWrapper onClick={e => e.stopPropagation}>
          {sessionPlayStatus === 'PAUSED' ? (
            <Icon size={32} src={PlayMiniIcon} onClick={playClickHandler} />
          ) : (
            <Icon size={32} src={PauseMiniIcon} onClick={pauseClickHandler} />
          )}
        </S.PausePauseWrapper>
      </S.RightContainer>
    </S.Panel>
  );
};
