import { useGetAvailableStripeCouponPromotion, MembershipPromotion } from '@Memberships';
import { ProfileMenuItemUrlPaths } from '@User';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useIsExpired } from '../../lenses/isExpired';
import { useIsTrialUser } from '../../lenses/isTrialUser';
import { useMembershipPeriodTimeLeft } from '../MembershipPanel/hooks/useMembershipPeriodTimeLeft';
import { useShouldRenderMembershipWidget } from './hooks/useShouldRenderMembershipWidget';
import { MembershipWidgetPresentation } from './MembershipWidget.presentation';

type Props = {
  testid?: string;
};

export function MembershipWidget(props: Props) {
  const { testid } = props;
  const navigate = useNavigate();
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const isTrial = useIsTrialUser();
  const isExpired = useIsExpired();
  const { isInGracePeriod, timeLeftInDays, timeLeftInHours } = useMembershipPeriodTimeLeft();
  const shouldRenderMembershipWidget = useShouldRenderMembershipWidget();

  const goToProfile = useCallback(() => {
    navigate(ProfileMenuItemUrlPaths.Account);
  }, [navigate]);

  const goToPayment = useCallback(() => {
    navigate({ pathname: '/payment' }, { state: { from: 'Membership Widget' } });
  }, [navigate]);

  const buttonText =
    getCouponCallToAction({ availablePromotion, isInGracePeriod }) ||
    (isTrial ? 'SUBSCRIBE' : 'UPDATE SUBSCRIPTION');

  if (!shouldRenderMembershipWidget) return null;

  return (
    <MembershipWidgetPresentation
      buttonText={buttonText}
      isExpired={isExpired}
      isInGracePeriod={isInGracePeriod}
      isTrial={isTrial}
      testid={testid}
      timeLeftInDays={timeLeftInDays}
      timeLeftInHours={timeLeftInHours}
      onClickButton={isInGracePeriod ? goToProfile : goToPayment}
    />
  );
}

function getCouponCallToAction(params: {
  availablePromotion: MembershipPromotion | null;
  isInGracePeriod?: boolean | null;
}): string | undefined {
  if (params.isInGracePeriod || !params.availablePromotion) return;
  return 'Your coupon expires soon!';
}
