import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Assets } from '../../utils/assets';

const Container = styled.div`
  width: 100%;
  max-width: 432px;
  padding: 1rem;
`;

const ContainerBody = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  background-color: ${({ theme }) => theme.colors.brandPrimaryDark};
  border-radius: 0.5rem;
  padding: 2rem 3rem;
  flex-direction: column;
  transition: padding 0.2s ease-in;
`;

const ToggleErrorContainer = css`
  opacity: 1;
  visibility: visible;
`;

const ErrorWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const ErrorContainer = styled.div<{ error: boolean }>`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: red;
  padding: 1rem;
  border-radius: 0.5rem;
  opacity: 0;
  visibility: none;
  transition:
    opacity 0.2s ease-in,
    transform 0.2s ease-in;
  display: flex;

  ${({ error }) => error && ToggleErrorContainer}
`;

const ErrorCloseButton = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  background-color: rgba(255, 255, 255, 0.6);
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  transition:
    transform 0.4s ease-in-out,
    background-color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

const ErrorCloseImage = styled.img`
  margin: auto;
  width: 1.25rem;
  height: 1.25rem;
`;

const ErrorText = styled.span`
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

interface FormContainerProps {
  onError?: string;
  onErrorClose?: () => void;
  children: ReactNode | undefined;
}

export const FormContainer: React.FC<FormContainerProps> = ({
  children,
  onError,
  onErrorClose,
}) => {
  return (
    <Container>
      <ErrorWrapper>
        <ErrorContainer error={!!onError}>
          {onError && (
            <>
              <ErrorText>{onError}</ErrorText>
              <ErrorCloseButton onClick={onErrorClose}>
                <ErrorCloseImage alt={Assets.icons.close.alt} src={Assets.icons.close.url} />
              </ErrorCloseButton>
            </>
          )}
        </ErrorContainer>
      </ErrorWrapper>
      <ContainerBody>{children}</ContainerBody>
    </Container>
  );
};
