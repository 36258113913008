import { FontColors } from '@Cortex';
import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  font-size: 0.75rem;
  font-style: ${({ theme }) => theme.fontFamily.regular};
  font-weight: 500;
  line-height: 120%;
  color: ${FontColors.WhiteTransparent};
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

export const Divider = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  margin: 1rem 0;
`;
