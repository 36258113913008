import { ANDROID_DOWNLOAD_LINK, IOS_DOWNLOAD_LINK } from '@Globals';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { trackCardLoad } from '../../actions/sessionManager';
import { RootReducerType } from '../../reducers';
import { UNIVERSAL_LINK_ACTIONS } from '../../types/universalLinks';
import { getDeviceType } from '../../utils/getDeviceType';
import { NoMissingKeys, useFeatureFlagsState } from '@Utils';
import { getDynamicSimilarActivityPlayerPath } from '../../utils/getDynamicSimilarActivityPlayerPath';

type PlayTrackIntentionParams = {
  actionType: string | null;
  activityType: 'dynamic' | 'legacy' | null;
  mentalState: string | null;
  trackId: string | null;
};

export function IntentionsPlayTrackHandler() {
  redirectMobileUsersToDownloadTheApp();

  const params = usePlayTrackIntentionParams();
  useHandlePlayerAction(params);

  // this is rendered as a component so return null to avoid displaying anything
  return null;
}

function redirectMobileUsersToDownloadTheApp(): void {
  const { isMobile, isIos } = getDeviceType();
  if (!isMobile) return;

  window.location.replace(isIos ? IOS_DOWNLOAD_LINK : ANDROID_DOWNLOAD_LINK);
}

function usePlayTrackIntentionParams(): PlayTrackIntentionParams {
  const [searchParams] = useSearchParams();
  const { isSettled: isFeatureFlagsReceived } = useFeatureFlagsState();

  const actionType = searchParams.get('intention_action') || null;
  const trackId = searchParams.get('intention_value') || null;
  const mentalState = searchParams.get('mental_state') || null;

  const activityType = isFeatureFlagsReceived ? 'dynamic' : null;

  return useMemo(
    () => ({
      actionType,
      activityType,
      mentalState,
      trackId,
    }),
    [actionType, activityType, mentalState, trackId],
  );
}

function useHandlePlayerAction(params: PlayTrackIntentionParams): void {
  const { actionType, activityType, trackId, mentalState } = params;

  const [hasBeenActivated, setHasBeenActivated] = useState(false);
  const { status: appStatus } = useSelector((state: RootReducerType) => state.app);
  const playTrack = usePlayTrack(activityType);

  return useEffect(() => {
    const shouldActivate = activityType !== null && appStatus === 'ready' && !hasBeenActivated;
    if (!shouldActivate) return;

    setHasBeenActivated(true);

    if (!trackId || !actionType || !mentalState) return;

    switch (actionType) {
      case UNIVERSAL_LINK_ACTIONS.PlayTrack:
        playTrack({ activityType, actionType, trackId, mentalState });
    }
  }, [appStatus, trackId, activityType]);
}

function usePlayTrack(activityType: null | 'dynamic' | 'legacy') {
  const playDynamicTrack = usePlayDynamicTrack();
  const playLegacyTrack = usePlayLegacyTrack();

  return useCallback(
    function playTrack(params: NoMissingKeys<PlayTrackIntentionParams>) {
      switch (activityType) {
        case 'dynamic':
          playDynamicTrack(params);
          break;
        case 'legacy':
          playLegacyTrack(params);
          break;
        default:
          break;
      }
    },
    [activityType, playDynamicTrack, playLegacyTrack],
  );
}

function usePlayLegacyTrack() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useCallback(
    (params: NoMissingKeys<PlayTrackIntentionParams>) => {
      if (!params.trackId) return;

      navigate(`/${params.mentalState}`, { replace: true });
      dispatch(
        trackCardLoad({
          trackId: params.trackId,
          loadWithoutPlaying: true,
        }),
      );
    },
    [dispatch, navigate],
  );
}

function usePlayDynamicTrack() {
  const navigate = useNavigate();

  return useCallback(
    (params: NoMissingKeys<PlayTrackIntentionParams>) => {
      navigate(getDynamicSimilarActivityPlayerPath(params.trackId));
    },
    [navigate],
  );
}
