import styled from 'styled-components';

export const CardsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 0.25rem;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const CardContainer = styled.div`
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;
`;

export const Loading = styled.div`
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:after {
    animation: rotation 0.8s linear infinite;
    border-radius: 100%;
    border-right: 3px solid transparent;
    border-top: 3px solid rgba(255, 255, 255, 0.8);
    content: '';
    height: 2rem;
    position: absolute;
    width: 2rem;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;
