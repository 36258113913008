import { useSelector } from 'react-redux';

import { RootReducerType } from '../../reducers';
import { MentalStates } from '../../types';

export function useUser() {
  const user = useSelector(getUser);
  return user;
}

export function getUser(state: RootReducerType) {
  return state.userV2;
}

export function useDefaultDisplayType() {
  const user = useUser();
  let mentalStateId: string | undefined;

  mentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.mentalState.id,
  );

  if (!mentalStateId) return null;

  return user.mentalStatePreferences[mentalStateId as MentalStates].defaultDisplay.type;
}

export function getUserId(state: RootReducerType) {
  return state.user.info?.id;
}
