import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, createSearchParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { STORAGE_REDIRECT_PATH_KEY } from '../constants';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

const RedirectText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.medium};
`;

interface NotFoundProps {
  isAuthenticated: boolean;
}

const NotFound: React.FC<NotFoundProps> = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [secsonds, setSeconds] = useState(3);
  const [searchParams] = useSearchParams();
  const queryParams = createSearchParams(searchParams).toString();
  const unauthenticatedRedirectUrl = `/${queryParams ? `?${queryParams}` : ''}`;

  useEffect(() => {
    if (!isAuthenticated) {
      sessionStorage.setItem(STORAGE_REDIRECT_PATH_KEY, pathname);
      navigate(unauthenticatedRedirectUrl, { replace: true });
    } else {
      const timer = setInterval(() => {
        setSeconds(current => current - 1);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isAuthenticated, history, queryParams, unauthenticatedRedirectUrl]);

  useEffect(() => {
    if (secsonds === 0) {
      navigate('/', { replace: true });
    }
  }, [secsonds, history]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Container>
      <RedirectText>
        Sorry, the link you navigated to doesn't exist.
        <br />
        Redirecting you to the start screen in {secsonds} seconds...
      </RedirectText>
    </Container>
  );
};

export default NotFound;
