import { Text, TextBold } from '@Cortex';

import * as S from './NoRecents.styles';
import NoFavoriteImage from './assets/no_recents.webp';
import { DynamicMentalState } from '@Model';

interface Props {
  mentalState: DynamicMentalState | null;
}

export const NoRecents = ({ mentalState }: Props) => {
  return (
    <S.Container>
      <S.ImageContainer>
        <S.Image src={NoFavoriteImage} />
      </S.ImageContainer>
      <S.HeadingContainer>
        <S.TextWrapper>
          {mentalState && (
            <TextBold size={'1.25rem'}>
              No {mentalState.displayValue} tracks added to Recents yet.
            </TextBold>
          )}
        </S.TextWrapper>
        <S.TextWrapper>
          <Text>Start listening and you'll find your track history here.</Text>
        </S.TextWrapper>
      </S.HeadingContainer>
    </S.Container>
  );
};
