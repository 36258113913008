import { useEffect } from 'react';
import { useDynamicMentalStates } from '../../../../api/modules/DynamicMentalStates';
import { OnboardingMentalStatesPresentation } from './OnboardingMentalStates.presentation';
import { CoreAnalytics } from '@Analytics';

type Props = {
  onSelect: (mentalStateId: string) => () => void;
};

export const OnboardingMentalStates = ({ onSelect }: Props) => {
  useEffect(() => {
    CoreAnalytics.trackOnboardingMentalStateImpressionEvent();
  }, []);

  const { data: dynamicMentalStates, isFetched } = useDynamicMentalStates();

  return (
    <OnboardingMentalStatesPresentation
      dynamicMentalStates={dynamicMentalStates}
      isLoading={!isFetched}
      onSelect={onSelect}
    />
  );
};
