import { createSelector } from '@reduxjs/toolkit';
import { RootReducerType } from 'src/reducers';
import { getTrackDynamicMentalStateId } from './getTrackDynamicMentalStateId';

const getMusicFavorites = (state: RootReducerType) => state.music.favorites;
const getMentalStateId = (state: RootReducerType) =>
  state.ui.sideDeck.librarySelectedMentalState?.id;

export const getDynamicFavorites = createSelector(
  [getMusicFavorites, getMentalStateId],
  (favorites, mentalStateId) => {
    return favorites.filter(track => {
      return getTrackDynamicMentalStateId(track) === mentalStateId;
    });
  },
);
