import { Button, ButtonVariants } from '@Cortex';
import { NOOP } from '@Globals';
import React from 'react';

import { CardWrapper } from '../CardWrapper';
import * as S from './VideoCard.styles';

export type Props = {
  activeStepIndex: number;
  numberOfSteps: number;
  onClickClose?: () => void;
  onClickNext?: () => void;
  onClickSkip?: () => void;
  ctaButtonText?: string;
  secondaryButtonText?: string;
  heading?: string;
  image?: string;
  title?: string;
  youtubeVideoId?: string;
};

export function VideoCard(props: Props) {
  const {
    activeStepIndex,
    numberOfSteps,
    heading,
    image,
    title,
    youtubeVideoId,
    ctaButtonText,
    secondaryButtonText,
    onClickClose,
    onClickSkip,
    onClickNext,
  } = props;

  return (
    <CardWrapper onClickClose={onClickClose}>
      <S.OnboardingCardImageColumn>
        <S.Image alt={title} src={image} width="100%" />
      </S.OnboardingCardImageColumn>
      <S.OnboardingCardTextColumn>
        {heading ? <S.Subtitle>{heading}</S.Subtitle> : null}
        {title ? <S.Title>{title}</S.Title> : null}
        <S.Body>
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            height="315"
            src={`https://www.youtube.com/embed/${youtubeVideoId}`}
            title="YouTube video player"
            width="100%"
          ></iframe>
        </S.Body>
        {activeStepIndex !== null && numberOfSteps !== null && numberOfSteps !== 1 ? (
          <S.StepPills>
            {Array(numberOfSteps)
              .fill('')
              .map((_, index) => (
                <S.StepPill key={index} isActive={index === props.activeStepIndex} />
              ))}
          </S.StepPills>
        ) : null}
      </S.OnboardingCardTextColumn>
      <S.Buttons>
        {onClickSkip && (
          <S.SkipButton>
            <Button onClick={onClickSkip}>{secondaryButtonText || 'Skip'}</Button>
          </S.SkipButton>
        )}
        {onClickNext && (
          <Button variant={ButtonVariants.Primary} onClick={onClickNext}>
            {ctaButtonText || 'Next'}
          </Button>
        )}
      </S.Buttons>
    </CardWrapper>
  );
}
