import { Serving } from '@Model';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { isOfflineError } from '../../../utils/isOfflineError';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { useRequestHandler } from './useRequestHandler';

export const useRecentTracksAllMentalStates = () => {
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);

  const getRecentTracks = useRequestHandler();

  return useQuery<Serving[], Error>(
    Queries.getRecentTracks(userId!, 'global'),
    () => getRecentTracks(userId!),
    {
      onError: err => {
        if (isOfflineError(err)) return;
        Logger.error(new Error('Error getting Recent Tracks for all mental states'), {
          err,
          userId,
        });
      },
      enabled: Boolean(userId),
    },
  );
};
