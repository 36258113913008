import { OnboardingCardTypes, OnboardingQuizAnswerTypes, OnboardingSpecification } from './types';

export const ONBOARDING_MENTAL_STATE_ID_KEY = 'onboardingMentalStateId';
export const ONBOARDING_DYNAMIC_ACTIVITY_ID_KEY = 'onboardingDynamicActivityId';
export const ONBOARDING_DELAYED_HEAR_THE_DIFFERENCE_KEY = 'onboardingDelayedHearTheDifference';
export const ONBOARDING_DELAYED_TEST_TIMER = 3 * 1000 * 60;

export const HEAR_THE_DIFFERENCE_DELAYED_VARIATION_NOSKIP = 'hear_the_difference_delay_noskip';
export const HEAR_THE_DIFFERENCE_DELAYED_VARIATION = 'hear_the_difference_delay';

export const DEFAULT_ONBOARDING: OnboardingSpecification = {
  cards: [],
  id: 'hear_the_difference_delay',
  quizSpecification: {
    id: 'hear_the_difference_delay_quiz_specification',
    neurotypeMatrix: [
      [
        {
          id: 'low_nel_nature',
          preferredFocusGenreNames: [
            'Beach',
            'Chimes & Bowls',
            'Forest',
            'Nightsounds',
            'Rain',
            'Rainforest',
            'River',
            'Thunder',
            'Underwater',
            'Wind',
          ],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
        {
          id: 'low_nel_unplugged',
          preferredFocusGenreNames: ['Acoustic', 'Piano', 'Classical'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
        {
          id: 'low_nel_epic_ambient',
          preferredFocusGenreNames: ['Atmospheric', 'Drone', 'Post Rock', 'Cinematic'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
        {
          id: 'low_nel_modern_beats',
          preferredFocusGenreNames: ['Electronic', 'Grooves', 'Lofi'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
      ],
      [
        {
          id: 'low_med_nel_nature',
          preferredFocusGenreNames: [
            'Beach',
            'Chimes & Bowls',
            'Forest',
            'Nightsounds',
            'Rain',
            'Rainforest',
            'River',
            'Thunder',
            'Underwater',
            'Wind',
          ],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
        {
          id: 'low_med_nel_unplugged',
          preferredFocusGenreNames: ['Acoustic', 'Piano', 'Classical'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
        {
          id: 'low_med_nel_epic_ambient',
          preferredFocusGenreNames: ['Atmospheric', 'Drone', 'Post Rock', 'Cinematic'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
        {
          id: 'low_med_nel_modern_beats',
          preferredFocusGenreNames: ['Electronic', 'Grooves', 'Lofi'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
      ],
      [
        {
          id: 'med_high_nel_nature',
          preferredFocusGenreNames: [
            'Beach',
            'Chimes & Bowls',
            'Forest',
            'Nightsounds',
            'Rain',
            'Rainforest',
            'River',
            'Thunder',
            'Underwater',
            'Wind',
          ],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
        {
          id: 'med_high_nel_unplugged',
          preferredFocusGenreNames: ['Acoustic', 'Piano', 'Classical'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
        {
          id: 'med_high_nel_epic_ambient',
          preferredFocusGenreNames: ['Atmospheric', 'Drone', 'Post Rock', 'Cinematic'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
        {
          id: 'med_high_nel_modern_beats',
          preferredFocusGenreNames: ['Electronic', 'Grooves', 'Lofi'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
      ],
      [
        {
          id: 'high_nel_nature',
          preferredFocusGenreNames: [
            'Beach',
            'Chimes & Bowls',
            'Forest',
            'Nightsounds',
            'Rain',
            'Rainforest',
            'River',
            'Thunder',
            'Underwater',
            'Wind',
          ],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
        {
          id: 'high_nel_unplugged',
          preferredFocusGenreNames: ['Acoustic', 'Piano', 'Classical'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
        {
          id: 'high_nel_epic_ambient',
          preferredFocusGenreNames: ['Atmospheric', 'Drone', 'Post Rock', 'Cinematic'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
        {
          id: 'high_nel_modern_beats',
          preferredFocusGenreNames: ['Electronic', 'Grooves', 'Lofi'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [],
        },
      ],
    ],
    questions: [
      {
        answers: [
          {
            type: OnboardingQuizAnswerTypes.Default,
            benefits: [],
            testimonials: [],
            title: 'Social Media or YouTube',
            value: 0,
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            benefits: [],
            testimonials: [],
            title: 'Search or Online Ad',
            value: 0,
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            benefits: [],
            testimonials: [],
            title: 'Personal Recommendation',
            value: 0,
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            benefits: [],
            testimonials: [],
            title: 'News Article, Blog Post, or Podcast',
            value: 0,
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            benefits: [],
            testimonials: [],
            title: 'I don’t remember',
            value: 0,
          },
        ],
        description: 'Help us improve by telling us how you found our app.',
        scoreId: 'nel',
        stepText: 'Source',
        title: 'How did you find us?',
      },
      {
        answers: [
          {
            type: OnboardingQuizAnswerTypes.Default,
            benefits: [
              'Get motivated and crush procrastination',
              'Enjoy getting in the zone and do more than ever',
              'Diverse genres tailored for you',
              'Unlimited listening on web and mobile',
              'New sounds added every week',
            ],
            responseCards: [
              {
                body: 'Users who listen for at least *2 hours a week* report feeling *4x more motivated.*',
                ctaButtonText: 'Keep personalizing',
                image: 'https://cdn.brain.fm/images/onboarding/onboarding_lets_get_started.webp',
                isSkippable: true,
                stepText: 'Goals',
                title: "Let's get started",
                type: OnboardingCardTypes.InfoTwo,
              },
            ],
            testimonials: [
              {
                author: 'Y.A.',
                text: 'I just put on headphones and get focused within 5 minutes.',
              },
              {
                author: 'G.L.',
                text: 'For anyone who has trouble focusing, Brain.fm can be truly career-saving.',
              },
              {
                author: 'G.L.',
                text: 'The focus music works extremely well, keeping me motivated and focused.',
              },
              {
                author: 'R.M.',
                text: 'After launching Brain.fm it immediately made me focused and I found the drive to finish my tasks.',
              },
              {
                author: 'J.D.',
                text: 'I have a minor case of ADHD. Put on Deep Work and I can feel my mindset change in about 60 seconds. It’s crazy.',
              },
            ],
            title: 'Get motivated',
            value: 0,
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            benefits: [
              'Reduce distractions and keep your focus',
              'Work effectively and do more than ever',
              'Diverse genres tailored for you',
              'Unlimited listening on web and mobile',
              'New sounds added every week',
            ],
            responseCards: [
              {
                body: 'Users who listen for at least *2 hours a week* report *3x fewer focus disruptions.*',
                ctaButtonText: 'Keep personalizing',
                image:
                  'https://cdn.brain.fm/images/onboarding/onboarding_stay_on_task_updated.webp',
                isSkippable: true,
                stepText: 'Goals',
                title: 'Stay on task longer',
                type: OnboardingCardTypes.InfoTwo,
              },
            ],
            testimonials: [
              {
                author: 'O.L.',
                text: 'This app is one of my most valuable tools to hack my brain and shut out the clutter.',
              },
              {
                author: 'B.F.',
                text: 'Even at the loudest places, I am able to deeply focus while using this app.',
              },
              {
                author: 'K.D.',
                text: 'The music helps drown out background noises without being distracting.',
              },
              {
                author: 'A.N.',
                text: 'Brain.fm is incredible at getting me to hone in on the task at hand, especially for difficult tasks.',
              },
            ],
            title: 'Block distractions',
            value: 0,
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            benefits: [
              'Stay focused all day without fatigue',
              'Find consistent productivity and do more than ever',
              'Diverse genres tailored for you',
              'Unlimited listening on web and mobile',
              'New sounds added every week',
            ],
            responseCards: [
              {
                body: 'Users who listen for at least *2 hours a week* report getting *2x more work done.*',
                ctaButtonText: 'Keep personalizing',
                image: 'https://cdn.brain.fm/images/onboarding/onboarding_stay_in_the_zone.webp',
                isSkippable: true,
                stepText: 'Goals',
                title: 'Stay in the zone longer',
                type: OnboardingCardTypes.InfoTwo,
              },
            ],
            testimonials: [
              {
                author: 'Z.S.',
                text: 'I swear, the duration of  time when I can focus doubles when I listen to Brain.fm.',
              },
              {
                author: 'M.G.',
                text: 'It works! I put on noise-cancelling headphones at work and played the music. Focused for hours!',
              },
              {
                author: 'B.S.',
                text: 'It’s a literal cure for my ADHD. Just came out of a 4.5hr deep work session. Game changer.',
              },
              {
                author: 'D.R.',
                text: 'Have not been able to stop using this app. I first used it while doing productivity work and was in the zone!',
              },
              {
                author: 'V.L.',
                text: 'I really like using Brain.fm as it allows me to concentrate for 1.5 hours at a time.',
              },
              {
                author: 'A.H.',
                text: 'Keeps me in the zone for much longer than anything I’ve found.',
              },
            ],
            title: 'Stay energized',
            value: 0,
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            benefits: [
              'Find your focus any time, any place',
              'Get more done and have fun doing it',
              'Diverse genres tailored for you',
              'Unlimited listening on web and mobile',
              'New sounds added every week',
            ],
            responseCards: [
              {
                body: 'Users who listen for at least 2 hours a week report \n   •  *4x more motivation*  \n   •  *3x fewer distractions* \n   •  *2x more work done*',
                ctaButtonText: 'Keep personalizing',
                image: 'https://cdn.brain.fm/images/onboarding/onboarding_boost_productivity.webp',
                isSkippable: true,
                stepText: 'Goals',
                title: 'Boost your productivity',
                type: OnboardingCardTypes.InfoTwo,
              },
            ],
            testimonials: [
              {
                author: 'J.C.',
                text: 'This app has changed my life.',
              },
              {
                author: 'R.L.',
                text: 'This app is a true blue tool to leveling yourself up.',
              },
              {
                author: 'F.G.',
                text: 'I’ve used Brain.fm to get through heavy amounts of deep work sessions. They do what they say they do.',
              },
              {
                author: 'P.M.',
                text: 'Helps me focus and get more done! The first session I ever did with Brain.fm was incredible.',
              },
              {
                author: 'P.G.',
                text: 'It really works!!! OMG, I am incredibly focused and getting much better rest.',
              },
            ],
            title: 'Other',
            value: 0,
          },
        ],
        scoreId: 'nel',
        stepText: 'Goals',
        title: "What's your focus goal?",
      },
      {
        answers: [
          {
            type: OnboardingQuizAnswerTypes.Default,
            responseCards: [
              {
                belowBodyImage: 'https://cdn.brain.fm/images/onboarding/nsf.png',
                body: 'Setting up sounds for maximum enjoyment and to turbocharge your abilities.',
                ctaButtonText: 'Continue',
                image: 'https://cdn.brain.fm/images/onboarding/onboarding_give-you_boost.webp',
                stepText: 'Effect Level',
                title: 'We’ll give you a boost!',
                type: OnboardingCardTypes.InfoTwo,
              },
            ],
            title: 'No',
            value: 0,
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            responseCards: [
              {
                belowBodyImage: 'https://cdn.brain.fm/images/onboarding/nsf.png',
                body: 'ADHD-type brains need extra stimulation to focus best.',
                ctaButtonText: 'Continue',
                image: 'https://cdn.brain.fm/images/onboarding/onboarding_got_you.webp',
                stepText: 'Effect Level',
                title: 'We’ve got you!',
                type: OnboardingCardTypes.InfoTwo,
              },
            ],
            title: 'Yes',
            value: 4,
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'I’d prefer not to respond',
            value: 0,
          },
        ],
        description: 'Sharing helps us optimize your experience, ADHD or not.',
        hasPrivacyNotice: true,
        scoreId: 'nel',
        stepText: 'Effect Level',
        title: 'Do you have ADHD? ',
      },
      {
        answers: [
          {
            type: OnboardingQuizAnswerTypes.Default,
            responseCards: [
              {
                body: 'You thrive on activity and aren’t easily distracted by your surroundings.',
                ctaButtonText: 'Continue',
                image: 'https://cdn.brain.fm/images/onboarding/onboarding_energize_you.webp',
                stepText: 'Environment',
                title: 'We’ll energize you!',
                type: OnboardingCardTypes.InfoTwo,
              },
            ],
            title: 'Coffee shop or other high energy places',
            value: 2,
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            responseCards: [
              {
                body: 'Your choice indicates you need a stable background to focus best.',
                ctaButtonText: 'Continue',
                image: 'https://cdn.brain.fm/images/onboarding/onboarding_got_you_covered.webp',
                stepText: 'Environment',
                title: 'We’ve got you covered!',
                type: OnboardingCardTypes.InfoTwo,
              },
            ],
            title: 'Library or other quiet places ',
            value: 0,
          },
        ],
        description:
          'Whether you prefer lively environments or peace and quiet will help us determine the right sounds for you.',
        scoreId: 'nel',
        stepText: 'Environment',
        title: 'Where do you work best?',
      },
      {
        answers: [
          {
            type: OnboardingQuizAnswerTypes.Default,
            description: 'Electronic, Grooves, Lofi',
            responseCards: [
              {
                ctaButtonText: 'Apply personalization',
                desktopBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/beats_genres_personalization.png',
                mobileBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/beats_mobile_genres_personalization.png',
                stepText: 'Genre Customization',
                title: 'We’ll personalize your sounds based on these genres!',
                type: OnboardingCardTypes.Hero,
              },
            ],
            title: 'Modern Beats',
            value: 6,
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            description: 'Atmospheric, Drone, Post Rock, Cinematic',
            responseCards: [
              {
                ctaButtonText: 'Apply personalization',
                desktopBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/ambient_genres_personalization.png',
                mobileBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/ambient_mobile_genres_personalization.png',
                stepText: 'Genre Customization',
                title: 'We’ll personalize your sounds based on these genres!',
                type: OnboardingCardTypes.Hero,
              },
            ],
            title: 'Epic, Ambient, and Spacey',
            value: 4,
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            description: 'Acoustic, Piano, Classical',
            responseCards: [
              {
                ctaButtonText: 'Apply personalization',
                desktopBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/unplugged_genres_personalization.png',
                mobileBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/unplugged_mobile_genres_personalization.png',
                stepText: 'Genre Customization',
                title: 'We’ll personalize your sounds based on these genres!',
                type: OnboardingCardTypes.Hero,
              },
            ],
            title: 'Unplugged',
            value: 2,
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            description: 'Rain, Beach, Thunder, Forest, and More',
            responseCards: [
              {
                ctaButtonText: 'Apply personalization',
                desktopBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/nature_genres_personalization.png',
                mobileBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/nature_mobile_genres_personalization.png',
                stepText: 'Genre Customization',
                title: 'We’ll personalize your sounds based on these genres!',
                type: OnboardingCardTypes.Hero,
              },
            ],
            title: 'Nature Soundscapes',
            value: 0,
          },
        ],
        scoreId: 'genres',
        stepText: 'Genres',
        title: 'What styles of music do you like to work to?',
      },
    ],
    scores: [
      {
        breakpoints: [1, 3, 5],
        id: 'nel',
        name: 'nel score',
      },
      {
        breakpoints: [1, 3, 5],
        id: 'genres',
        name: 'genres score',
      },
    ],
  },
};
