import { RootReducerType } from '../../../reducers';

/**
 * Gets users membership and determines if the user is allowed to play a session.
 * @param membership
 * @returns {boolean}
 */
export function isAllowedToPlaySessionWithIndividualSubscription(
  membership: RootReducerType['user']['membership'],
): boolean {
  if (!membership) return false;
  if (!isExpired(new Date(membership.expirationDate))) return true;

  return Boolean(
    membership.isInGracePeriod && !isExpired(new Date(membership.gracePeriodExpirationDate)),
  );
}

function isExpired(date: Date): boolean {
  return date.getTime() < Date.now();
}
