import { createAction } from '@reduxjs/toolkit';
import { select, takeEvery } from 'redux-saga/effects';

import { RootReducerType } from '../../reducers';
import { Analytics } from '../../utils/analytics';
import { TimerEvents } from '../../utils/analytics/events';
import { Logger } from '../../utils/logger';
import { TimerStatuses } from './constants';

export const trackResetTimer = createAction('Timer__trackResetTimer');

function* trackResetTimerSaga() {
  try {
    const timerState: RootReducerType['timer'] = yield select(
      (state: RootReducerType) => state.timer,
    );
    if (timerState.status !== TimerStatuses.Running) return;
    Analytics.logEventWithProperties(TimerEvents.Reset, {
      time_elapsed_at_reset: timerState.currentTime - timerState.startTime,
    } as any);
  } catch (error) {
    Logger.error(new Error('trackResetTimerSaga(): failed'), { reason: error });
  }
}

export function* watchTrackResetTimerSaga() {
  yield takeEvery(trackResetTimer.type, trackResetTimerSaga);
}
