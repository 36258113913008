import { ActivityDataType } from '../types/activities';

export const activities: ActivityDataType = {
  ids: [
    '6042a1bad80ae028b821e953',
    '6042a1bad80ae028b821e955',
    '6042a1bad80ae028b821e956',
    '6042a1bad80ae028b821e957',
    '6042a1bad80ae028b821e959',
    '6042a1bad80ae028b821e95a',
    '6042a1bad80ae028b821e95b',
    '6042a1bad80ae028b821e95d',
  ],
  byId: {
    '6042a1bad80ae028b821e953': {
      _id: '6042a1bad80ae028b821e953',
      type: 'deepWork',
      display: 'Deep Work',
      default: true,
      mentalStateId: '6042a1bad80ae028b821e954',
      mentalStateText: 'Focus',
      description:
        'Music designed to facilitate cognitively demanding tasks that require sustained periods of intense focus.',
    },
    '6042a1bad80ae028b821e955': {
      _id: '6042a1bad80ae028b821e955',
      type: 'creativity',
      display: 'Creativity',
      default: false,
      mentalStateId: '6042a1bad80ae028b821e954',
      mentalStateText: 'Focus',
      description:
        'Music designed to engage and inspire. Use it to help with creative design, ideation, or expression.',
    },
    '6042a1bad80ae028b821e956': {
      _id: '6042a1bad80ae028b821e956',
      type: 'learning',
      display: 'Learning',
      default: false,
      mentalStateId: '6042a1bad80ae028b821e954',
      mentalStateText: 'Focus',
      description: `Use this music to help with knowledge retention or long periods of reading.`,
    },
    '6042a1bad80ae028b821e957': {
      _id: '6042a1bad80ae028b821e957',
      type: 'chill',
      display: 'Chill',
      default: false,
      mentalStateId: '6042a1bad80ae028b821e958',
      mentalStateText: 'Relax',
      description: `Here you'll find engaging music to accompany many types of relaxing activities. Check out this category if you're looking for a more active listening experience.`,
    },
    '6042a1bad80ae028b821e959': {
      _id: '6042a1bad80ae028b821e959',
      type: 'recharge',
      display: 'Recharge',
      default: true,
      mentalStateId: '6042a1bad80ae028b821e958',
      mentalStateText: 'Relax',
      description: `Use this music if you're looking to transition from a state of heightened activity to one of relaxation.`,
    },
    '6042a1bad80ae028b821e95a': {
      _id: '6042a1bad80ae028b821e95a',
      type: 'unguidedMeditation',
      display: 'Meditate',
      default: false,
      mentalStateId: '6042a1bad80ae028b821e958',
      mentalStateText: 'Relax',
      description: `Meditate alone and unassisted.`,
    },
    '6042a1bad80ae028b821e95b': {
      _id: '6042a1bad80ae028b821e95b',
      type: 'deepSleep',
      display: 'Deep Sleep',
      default: true,
      mentalStateId: '6042a1bad80ae028b821e95c',
      mentalStateText: 'Sleep',
      description: `Music designed to promote healthy and prolonged rest.`,
    },
    '6042a1bad80ae028b821e95d': {
      _id: '6042a1bad80ae028b821e95d',
      type: 'powerNap',
      display: 'Power Nap',
      default: false,
      mentalStateId: '6042a1bad80ae028b821e95c',
      mentalStateText: 'Sleep',
      description: `Use this music to take short restorative naps and get back into your day with renewed energy.`,
    },
    TODO_guided_sleep: {
      _id: 'TODO_guided_sleep',
      type: 'guidedSleep',
      display: 'Guided Sleep',
      default: false,
      mentalStateId: '6042a1bad80ae028b821e95c',
      mentalStateText: 'Sleep',
      description: `Receive assistance transitioning from wakefulness into sleep.`,
    },
    TODO_guided_meditation: {
      _id: 'TODO_guided_meditation',
      type: 'guidedMeditation',
      display: 'Guided Meditation',
      default: false,
      mentalStateId: '6042a1bad80ae028b821e958',
      mentalStateText: 'Relax',
      description: `Meditate in response to guidance by trained practitioners.`,
    },
  },
  byArray: [
    {
      _id: '6042a1bad80ae028b821e953',
      type: 'deepWork',
      display: 'Deep Work',
      default: true,
      mentalStateId: '6042a1bad80ae028b821e954',
      mentalStateText: 'Focus',
      description:
        'Music designed to facilitate cognitively demanding tasks that require sustained periods of intense focus.',
    },
    {
      _id: '6042a1bad80ae028b821e955',
      type: 'creativity',
      display: 'Creativity',
      default: false,
      mentalStateId: '6042a1bad80ae028b821e954',
      mentalStateText: 'Focus',
      description:
        'Music designed to engage and inspire. Use it to help with creative design, ideation, or expression.',
    },
    {
      _id: '6042a1bad80ae028b821e956',
      type: 'learning',
      display: 'Learning',
      default: false,
      mentalStateId: '6042a1bad80ae028b821e954',
      mentalStateText: 'Focus',
      description: `Use this music to help with knowledge retention or long periods of reading.`,
    },
    {
      _id: '6042a1bad80ae028b821e957',
      type: 'chill',
      display: 'Chill',
      default: false,
      mentalStateId: '6042a1bad80ae028b821e958',
      mentalStateText: 'Relax',
      description: `Here you'll find engaging music to accompany many types of relaxing activities. Check out this category if you're looking for a more active listening experience.`,
    },
    {
      _id: '6042a1bad80ae028b821e959',
      type: 'recharge',
      display: 'Recharge',
      default: true,
      mentalStateId: '6042a1bad80ae028b821e958',
      mentalStateText: 'Relax',
      description: `Use this music if you're looking to transition from a state of heightened activity to one of relaxation.`,
    },
    {
      _id: '6042a1bad80ae028b821e95a',
      type: 'meditation',
      display: 'Meditation',
      default: false,
      mentalStateId: '6042a1bad80ae028b821e958',
      mentalStateText: 'Relax',
      description: '',
    },
    {
      _id: '6042a1bad80ae028b821e95b',
      type: 'deepSleep',
      display: 'Deep Sleep',
      default: true,
      mentalStateId: '6042a1bad80ae028b821e95c',
      mentalStateText: 'Sleep',
      description: `Music designed to promote healthy and prolonged rest.`,
    },
    {
      _id: '6042a1bad80ae028b821e95d',
      type: 'powerNap',
      display: 'Power Nap',
      default: false,
      mentalStateId: '6042a1bad80ae028b821e95c',
      mentalStateText: 'Sleep',
      description: `Use this music to take short restorative naps and get back into your day with renewed energy.`,
    },
  ],
};
