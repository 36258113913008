import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { usePost } from '../../client/client';
import { Endpoints } from '../../common/endpoints';
import { TRedeemGiftCardResponse, TRedeemGiftCardRequestBody } from './types';

export const useRequestHandler = () => {
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);
  const post = usePost<TRedeemGiftCardRequestBody, TRedeemGiftCardResponse>();

  return useCallback(
    async (cardCode: string) => {
      return await post({
        path: Endpoints.postRedeemGiftCard(cardCode),
        body: {
          userId: userId!,
        },
      });
    },
    [post],
  );
};
