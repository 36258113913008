import { BROWSER_ENVIRONMENT } from '@Globals';
import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createMigrate,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from '../reducers';
import rootSaga from '../sagas';

/**
 * To prevent reducers from being persisted be redux-persist add
 * them to the blacklist array below. To prevent individual properties
 * in a reducer from being persisted create a custom persistConfig
 * for the parent reducer in /srs/reducers/index.ts
 */
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    'activation',
    'app',
    'auth',
    'currentSession',
    'membership',
    'music',
    'payments',
    'paymentsV2',
    'router',
    'sessionManager',
    'timer',
    'ui',
    'user',
    'teams',
    'userV2',
  ],
  migrate: createMigrate({
    '1': function migrate(oldState?: any): any {
      // we removed the sessionHistory reducer, which was persisted in two
      // places, leading us to need to manually remove the local storage key
      // from the instance that is not longer in code anywhere.
      try {
        localStorage.removeItem('persist:sessionHistory');
      } catch (e) {}

      const { sessionHistory, ...state } = oldState || {};
      return state;
    },
  }),
};

export const history = createBrowserHistory();

const rootReducer = createRootReducer(history);
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredActionPaths: ['payload.navigate'],
        warnAfter: 100,
      },
    }),
    sagaMiddleware,
  ],

  enhancers: [sentryReduxEnhancer],
  devTools:
    BROWSER_ENVIRONMENT === 'development'
      ? {
          actionsDenylist: [
            'Timer/update',
            'sessionManager/setTrackTimestamp',
            'CURRENT_TRACK_TIMESTAMP',
          ],
        }
      : false,
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;
