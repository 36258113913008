import { RootReducerType } from '../../../reducers';
import { isAllowedToPlaySessionWithIndividualSubscription } from '../isAllowedToPlaySessionWithIndividualSubscription';
import { isAllowedToPlaySessionWithTeamSubscription } from '../isAllowedToPlaySessionWithTeamSubscription';

/**
 * Gets users membership and determines if the user is allowed to play a session.
 * @param membership
 * @param teams
 * @param verification
 * @returns {boolean}
 */
export function isAllowedToPlaySession(
  membership: RootReducerType['user']['membership'],
  teams: RootReducerType['teams'],
  verification: RootReducerType['user']['verification'],
): boolean {
  return (
    isAllowedToPlaySessionWithIndividualSubscription(membership) ||
    isAllowedToPlaySessionWithTeamSubscription(teams, verification)
  );
}
